import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { HideUntilLoaded } from 'react-animation'

//Components
import PollItem from './pollitem.js'

import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";

const AMPLITUDE_KEY = "ff24f21d0121991161cc8dacb5a08f5f";

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


class Item extends Component {

  render() {

    var dataPoll = this.props.dataPoll
    var dataPicks = this.props.dataPicks

    var question = dataPoll.question
    var grade = dataPoll.grade ? dataPoll.grade : 'None'

    var pollSource = dataPoll.waitlistPoll ? 'Waitlist' : 'Normal'

    var image = dataPoll.bitmojiUrl;

    var name1 = dataPicks.option1.name;
    var name2 = dataPicks.option2.name;
    var name3 = dataPicks.option3.name;
    var name4 = dataPicks.option4.name;

    var option1 = {
      name: 'dua lipa',
      id: '',
    }

    return(
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={AMPLITUDE_KEY}
      >
      <div className="poll-wrapper">

        <div className="width-100">
          <HideUntilLoaded
          animationIn="bounceIn"
          imageToLoad={image}
          >
            <img src={image} alt="Poll image" className="poll-bitmoji"/>
          </HideUntilLoaded>
        </div>

        <div className="poll-wrapper-inside">

          <p className="poll-title">
          {question}
          </p>

          <div className="flexColumn flexCenter width-100">

            <div className="flexRow width-100 vertical-spacing-bottom">
              <div className="poll-option-left flexCenter">
              <Amplitude>
                {({ logEvent }) =>
                  <PollItem
                  name={name1}
                  onClick={()=>{
                    logEvent('Poll vote', {question: question, grade:grade, voteSource:'Web'});
                    this.props.submitVote(1)
                  }}
                  />
                }
              </Amplitude>
              </div>

              <div className="poll-option-right flexCenter">
              <Amplitude>
                {({ logEvent }) =>
                  <PollItem
                  name={name2}
                  onClick={()=>{
                    logEvent('Poll vote', {question: question, pollSource: pollSource, grade:grade});
                    this.props.submitVote(2)
                  }}
                  />
                }
              </Amplitude>
              </div>

            </div>

            <div className="flexRow width-100">
              <div className="poll-option-left flexCenter">
              <Amplitude>
                {({ logEvent }) =>
                  <PollItem
                  name={name3}
                  onClick={()=>{
                    logEvent('Poll vote', {question: question, pollSource: pollSource, grade:grade});
                    this.props.submitVote(3)
                  }}
                  />
                }
              </Amplitude>
              </div>
              <div className="poll-option-right flexCenter">
              <Amplitude>
                {({ logEvent }) =>
                  <PollItem
                  name={name4}
                  onClick={()=>{
                    logEvent('Poll vote', {question: question, pollSource: pollSource, grade:grade});
                    this.props.submitVote(4)
                  }}
                  />
                }
              </Amplitude>
              </div>
            </div>

            <div>
            </div>

          </div>

        </div>

      </div>

      </AmplitudeProvider>


    )
  }
}

export default Item;
