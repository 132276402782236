import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 360
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var animation_duration = 350

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var stringChangeTime = 8000

var title_style = {fontFamily:'Averta-Bold', color:'black', fontSize:24, letterSpacing:0, textTransform:'uppercase'}

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[
        {title:'Aces', values:['0','0']},
        {title:'DF', values:['0','0']},
        {title:'1st %', values:['-','-']},
        {title:'1st Points', values:['-','-']},
        {title:'2nd Points', values:['-','-']},
        {title:'Winners', values:['0','0']},
        {title:'Errors', values:['0','0']},
        {title:'Break Points', values:['0/0','0/0']},
      ],
      stats_receiving:[],
      matchTime:'0:00',
      matchInfo:'',
      max_stats: 0,

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[
        {title:'Aces', values:['0','0']},
        {title:'DF', values:['0','0']},
        {title:'1st %', values:['-','-']},
        {title:'1st Points', values:['-','-']},
        {title:'2nd Points', values:['-','-']},
        {title:'Winners', values:['0','0']},
        {title:'Errors', values:['0','0']},
        {title:'Break Points', values:['0/0','0/0']},
      ],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
      stats_view:"Match",

      stats_opacity: new Animated.Value(1),
      h2h_opacity: new Animated.Value(1),

      images:[null, null],
      names:[null, null],
    };
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }

    if(this.listenControls){
      this.listenControls()
    }
    if(this.listenVote){
      this.listenVote()
    }
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
    })
    this.setup()
  }

  setup = async () => {
    //Get the selected match from Firestore
    this.listenControls = await firebase.firestore()
    .collection('streaming').doc("8MP5DlCnSoAMEzygPl3i")
    .onSnapshot(async (doc) => {
      if(doc.exists && doc.data()){
        var id = doc.data().matchId || null
        var stats_view = doc.data().stats_view || null
        if(id !== this.state.id){
          await this.setState({id})
          this.selectMatch(id)
        }
        if(stats_view && this.state.stats_view !== stats_view){
          this.changeStats(stats_view)
        }
      }
    })
  }

  //
  selectMatch = async (id) => {
    if(this.listenMatch){
      await this.listenMatch()
    }
    if(this.listenMatchStats){
      await this.listenMatchStats()
    }
    if(this.listenVote){
      this.listenVote()
    }

    await this.setState({firstSet:true})

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .get()
    .then(this.handleMatch)
  }

  handleMatch = async ( querySnapshot ) => {
    var match = {}
    var docId = null

    if(querySnapshot.size > 0){
      await querySnapshot.forEach(async (doc) => {
        match = doc.data()
        docId = doc.id
      })
    }

    //Calculate the matchTime
    var durationString = null
    if(match.startedAt){
      var startedAt = match.startedAt
      var finishedAt = !match.finishedAt ? Date.now() : match.finishedAt

      var duration = (moment.duration(finishedAt - startedAt).as('hours')).toString()

      //Clean string
      var durationHours = Number(duration.split('.')[0])
      if(durationHours < 6){
        var durationMinutesNumber = Math.round(Number("."+duration.split('.')[1]) * 60)
        var durationMinutesRaw = durationMinutesNumber == 60 ? 59 : durationMinutesNumber
        var durationMinutes = durationMinutesRaw.toString().length == 1 ? "0"+durationMinutesRaw : durationMinutesRaw
        durationString = durationHours + ':' + durationMinutes
      }
    }

    //Calculate the duration string
    var matchTime = durationString ? "Match Time: "+durationString : ''

    //Match Info string
    var matchInfo = match.season_name.split(", ")[0] + " - " + match.round

    //Flags
    var country_corrections = {
      ["GRE"]:'GRC',
      ["GER"]:'DEU',
      ["LAT"]:'LVA',
      ["CRO"]:'HRV',
      ["SUI"]:'CHE',
    }
    var countries_fix = Object.keys(country_corrections)

    await Promise.all(match.competitors.map(async (player, p_index) => {
      if(countries_fix.includes(player.country_code)){
        player.country_code = country_corrections[player.country_code]
      }
    }))

    //Country code
    var code_1 = await convertIso3Code(match.competitors[0].country_code)
    var code_2 = await convertIso3Code(match.competitors[1].country_code)
    var flag_1 = code_1 ? code_1.iso2 : null
    var flag_2 = code_2 ? code_2.iso2 : null

    var names = [{first:match.competitors[0].name.split(", ")[1], last:match.competitors[0].name.split(", ")[0]}, {first:match.competitors[1].name.split(", ")[1], last:match.competitors[1].name.split(", ")[0]}]
    var flags = [flag_1, flag_2]

    await this.setState({flags, names, match, matchTime, matchInfo, loading:false, mode:'match'})

    if(this.state.firstSet && docId){
      this.setupListeners(docId, match)
      this.setState({firstSet:false})
      // this.setupPlayers(match)
    }
  }

  setupPlayers = async (match) => {
    if(match.competitors[0].name.includes(". ")){
      // var lastname_1 = (match.competitors[0].name.split(". ")[1]).toLowerCase()
      // var lastname_2 = (match.competitors[1].name.split(". ")[1]).toLowerCase()
      //
      // var image_1 = "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_1+"_full_ao20.png"
      // var image_2 = "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_2+"_full_ao20.png"
      //
      // //Get player profiles
      // var image_official_1 = await this.getPlayer(match.competitors[0].id)
      // var image_official_2 = await this.getPlayer(match.competitors[1].id)
      //
      // //Flags
      // var country_corrections = {
      //   ["GRE"]:'GRC',
      //   ["GER"]:'DEU',
      //   ["LAT"]:'LVA',
      //   ["CRO"]:'HRV',
      //   ["SUI"]:'CHE',
      // }
      // var countries_fix = Object.keys(country_corrections)
      //
      // await Promise.all(match.competitors.map(async (player, p_index) => {
      //   if(countries_fix.includes(player.country_code)){
      //     player.country_code = country_corrections[player.country_code]
      //   }
      // }))
      //
      // //Country code
      // var code_1 = await convertIso3Code(match.competitors[0].country_code)
      // var code_2 = await convertIso3Code(match.competitors[1].country_code)
      // var flag_1 = code_1 ? code_1.iso2 : null
      // var flag_2 = code_2 ? code_2.iso2 : null
      //
      // var images = [image_official_1 || image_1, image_official_2 || image_2]
      // var names = [match.competitors[0].abbreviation, match.competitors[1].abbreviation]
      // var flags = [flag_1, flag_2]
      //
      // this.setState({images, names})
    }
  }

  getPlayer = async (id) => {
    var image = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.data()){
            if(doc.data().image_official){
              image = doc.data().image_official
            }
          }
        })
      }
    })

    return image
  }

  setupListeners = async (docId, match) => {
    this.listenVote = await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('data').doc('votes')
    .onSnapshot(this.handleVotes)
  }

  handleVotes = async (doc) => {
    var { match } = this.state
    var votes = [5,5]

    console.log(match)

    if(doc.exists && doc.data()){
      var data = doc.data()

      if(data.votes){
        var votes_0_raw = Object.values(data.votes['0'] || {}) || []
        var votes_1_raw = Object.values(data.votes['1'] || {}) || []

        votes = [votes_0_raw.length + 5, votes_1_raw.length + 5]
      }
    }

    //Generate % and string of the player that is winning
    var string = ''
    var percentage = '50%'
    if(votes[0] === votes[1]){
      string = 'Vote tied'
    }else{
      if(votes[0] > votes[1]){
        percentage = (Math.round(votes[0]/(votes[0] + votes[1])*100)) + "%"
        string = percentage + " " + match.competitors[0].name.split(", ")[0]
      }
      if(votes[1] > votes[0]){
        percentage = (Math.round(votes[1]/(votes[0] + votes[1])*100)) + "%"
        string = percentage + " " + match.competitors[1].name.split(", ")[0]
      }
    }

    var fan_votes = {
      string,
      percentage,
    }

    this.setState({fan_votes})
  }

  changeTheme = async (theme) => {
    var style = {
      background_main:"#101012",
      background_match:'#18181B',
      background_tnns:'#18181B',
      background_scores:"#18181B",
      background_ticker:'#18181B',
      background_tippers:"#18181B",
      font_tippers:"white",
      font_ticker:'white',
      font_color:'white',
      game_color:'white',
      set_color:'white',
      player_color:'white',
      player_font:'Averta-Bold',
      ball_color:GLOBAL.colors.volt,
      event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
      borderRadius:0,
      letterSpacing:0.5,
      alternatingBackground:'rgba(0,0,0,0.24)',
    }

    if(theme === 'ATP1000'){
      style = {
        background_main:"#101012",
        background_match:'#18181B',
        background_tnns:'#18181B',
        background_scores:"#18181B",
        background_ticker:'#18181B',
        background_activeSet:"gold",
        background_tippers:"gold",
        font_tippers:"black",
        font_activeSet:'black',
        font_ticker:'white',
        font_color:'white',
        game_color:'white',
        set_color:'white',
        player_color:'white',
        player_font:'Averta-Bold',
        ball_color:"gold",
        event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
        borderRadius:0,
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.24)',
        reverseOrder:true,
      }
    }

    if(theme === 'UTS'){
      style = {
        background_main:"#101012",
        background_match:'#18181B',
        background_tnns:'#ff002f',
        background_scores:"#18181B",
        background_ticker:'#18181B',
        background_activeSet:"#ff002f",
        background_tippers:"#ff002f",
        font_tippers:"white",
        font_activeSet:'white',
        font_ticker:'white',
        font_color:'white',
        tint_tnns:'white',
        game_color:'white',
        set_color:'white',
        player_color:'white',
        player_font:'Averta-Bold',
        ball_color:"#ff002f",
        event_logo:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/uts%2Futs-promo.png?alt=media&token=10c9aaed-68d4-4b01-8aba-585081fd8ea5",
        borderRadius:0,
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.24)',
        reverseOrder:true,
        leftTab:'#ff002f',
      }
    }

    if(theme === 'Wimbledon'){
      style = {
        background_main:"white",
        background_match:'#EDEEF5',
        background_tnns:'#48167A',
        background_scores:"#EDEEF5",
        background_ticker:'#EDEEF5',
        background_tippers:"#48167A",
        font_tippers:"white",
        font_ticker:'#48167A',
        font_color:'#48167A',
        game_color:'#48167A',
        set_color:'#48167A',
        player_color:'#48167A',
        player_font:'Averta-Bold',
        ball_color:'#FBF508',
        event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/b/b9/Wimbledon.svg/1200px-Wimbledon.svg.png",
        borderRadius:0,
        leftTab:'#0B6F3D',
        hideFlags:true,
        letterSpacing:0.5,
        reverseOrder:true,
        alternatingBackground:'rgba(0,0,0,0.12)',
      }
    }

    if(theme === 'GTL'){
      style = {
        background_main:"white",
        background_match:'#EDEEF5',
        background_tnns:'#514C8A',
        background_scores:"#EDEEF5",
        background_ticker:'#EDEEF5',
        background_tippers:"#514C8A",
        font_tippers:"white",
        font_ticker:'#25642F',
        font_color:'#25642F',
        game_color:'#25642F',
        set_color:'#25642F',
        player_color:'#25642F',
        player_font:'Averta-Bold',
        ball_color:'#514C8A',
        event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
        borderRadius:0,
        leftTab:'#25642F',
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.1)',
      }
    }

    if(theme === 'Roland Garros'){
      style = {
        background_main:"white",
        background_match:'#063F35',
        background_tnns:'#063F35',
        background_scores:"#ECECEC",
        background_ticker:'#EDEEF5',
        background_tippers:"#063F35",
        font_tippers:"white",
        font_ticker:'#000',
        font_color:'white',
        game_color:'#000',
        set_color:'black',
        player_color:'white',
        player_font:'Averta-Bold',
        // ball_color:"#E7946B",
        ball_color:'#D45119',
        //D45622
        event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Logo_Roland-Garros.svg/1200px-Logo_Roland-Garros.svg.png",
        borderRadius:2.5,
        letterSpacing:0.5,
        hideFlags:true,
        reverseOrder:true,
        alternatingBackground:'rgba(0,0,0,0.12)',
      }
    }

    return this.setState({style, theme})
  }


  //Renders
  renderScoreboard = () => {
    var { string_stats, string_tippers, stats_list, stats_serving, stats_receiving, matchTime, matchInfo } = this.state

    var width = 500

    return(
      <>
      <View style={[{width, backgroundColor:'white', padding:0}, MainStyles.flexStartCenter]}>
        {this.renderCard('100%', false, false, 'Serving Stats', this.renderStats(stats_serving, 'Stats'))}
      </View>
      </>
    )
  }

  renderCard = (ratio, marginRight, marginLeft, title, content) => {
    return(
      <View style={{width:ratio, height:'100%', paddingRight:marginRight ? spacing : 0, paddingLeft:marginLeft ? spacing : 0}}>
        <View style={[{width:'100%', height:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_ticker, overflow:'hidden'}, MainStyles.flexStartCenter]}>
          {content}
        </View>
      </View>
    )
  }

  renderStats = (data, title) => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderStatTitle(title)}
        {
          data.length > 0 ?
          data.map((item, index) => this.renderStat(item, index))
          :
          this.renderPlayers()
        }
      </View>
    )
  }

  renderStat = (data, index) => {
    var alternating = index % 2 === 0


    return(
      <Animated.View style={[{width:'100%', backgroundColor:alternating ? '#F4F4F5' : 'transparent', paddingHorizontal:spacing/1, height:50, opacity:this.state.stats_opacity}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        {
          !data ? null :
          <>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:20, color:'#1B1B20', width:'20%', textAlign:'center', letterSpacing:-0.33}}>
            {data.values[0]}
            </Text>
            <Text style={{fontFamily:'Averta-Regular', fontSize:20, color:'#1B1B20', textAlign:'center', width:'60%', letterSpacing:-0.33, opacity:0.5}}>
              {data.title}
            </Text>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:20, color:'#1B1B20', width:'20%', textAlign:'center', letterSpacing:-0.33}}>
            {data.values[1]}
            </Text>
          </>
        }
      </Animated.View>
    )
  }

  renderStatTitle = (title, hideNames) => {
    var { match } = this.state
    var color = 'white'
    return(
      <View style={[{width:'100%', paddingHorizontal:spacing, height:60, backgroundColor:'#389CFF'}, MainStyles.flexColumn, MainStyles.flexCenter]}>

        <View style={[{width:'100%'}, MainStyles.flexCenter, MainStyles.flexRow]}>
          {this.renderPlayer(0)}
          {
            // <Text style={{fontSize:23, color, fontFamily:'Averta-Regular', width:'60%', textAlign:'center', opacity:1}}>
            // STATS
            // </Text>
          }
          {this.renderPlayer(1)}
        </View>
      </View>
    )
  }

  renderPlayer = (index) => {
    var { flags, names } = this.state
    var color = 'white'
    var name = names[index]
    var flag = flags[index]

    var height = 38
    var width = height / 16 * 20

    var flex = index === 0 ? MainStyles.flexStartCenter : MainStyles.flexEndCenter
    var flex2 = index === 0 ? MainStyles.flexCenterStart : MainStyles.flexCenterEnd

    var renderFlag =
    <View style={[index === 0 ? {marginRight:10} : {marginLeft:10}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
      <ReactCountryFlag
        countryCode={flag}
        svg
        style={{
            width,
            height,
            marginBottom:2
        }}
        title="US"
      />
    </View>

    return(
      <View style={[{width:'50%'}, MainStyles.flexRow, flex]}>
        {index === 0 ? renderFlag : null}
        <View style={[flex2, {flex:1}]}>
          <Text style={{fontFamily:'Averta-Semibold', fontSize:16, color, textAlign:index === 0 ? 'left' : 'right', textTransform:'uppercase', marginBottom:-2, opacity:0.67}}>
          {name.first}
          </Text>
          <Text style={{fontFamily:'Averta-Black', fontSize:22, color, textAlign:index === 0 ? 'left' : 'right', textTransform:'uppercase', flexShrink:1, flexWrap:'wrap'}} numberOfLines={1}>
          {name.last}
          </Text>
        </View>
        {index === 1 ? renderFlag : null}
      </View>
    )

    // var height = 80
    // var width = height / 16 * 20
    //
    // return(
    //   <View style={[{width:'20%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
    //     <View style={[{position:'absolute', left:index === 0 ? -10 : null, right:index === 1 ? -10 : null, zIndex:0, opacity:0.12}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
    //       <ReactCountryFlag
    //         countryCode={flag}
    //         svg
    //         style={{
    //             width,
    //             height,
    //         }}
    //         title="US"
    //       />
    //     </View>
    //     <Text style={{fontFamily:'Averta-Black', fontSize:24, zIndex:1, color, textAlign:'center'}}>
    //     {name}
    //     </Text>
    //   </View>
    // )
  }

  renderVote = () => {
    var { fan_votes } = this.state
    return(
      <View style={{backgroundColor:'white'}}>
      {fan_votes ? this.renderOdds(fan_votes.percentage, fan_votes.string, "Fan Vote") : null}
      </View>
    )
  }

  renderOdds = (percentage, string, title) => {
    // var percentage = "80%"
    // var winner = 'Federer'
    var colors = {
      primary:'#17171D',
      secondary:'white',
      accent:'#B6FE00',
      score_bg:'#1B1B20',
      score_font:'white',
      bg_opacity:0.3,
      main_font:'white',
      bg_stats:'#17171D',
      logo:null,
    }

    var height = 120
    var width = 1920
    var spacing = 10
    var spacing_main = 20
    var fontSize = 40
    var borderColor = 'rgba(255,255,255,0.15)'

    var app_size = 32

    try{return(
      <View style={[{width:300, backgroundColor:'white'}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', padding:10, backgroundColor:'#389CFF'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Image
          style={{height:app_size, width:app_size, borderRadius:app_size/4, resizeMode:'contain', marginRight:10}}
          source={require('../appicon.png')}
          />
          <Text style={[title_style, {textAlign:'center', color:'white', fontFamily:'Averta-Bold'}]}>
          Fan Vote
          </Text>
        </View>
        <View style={[{padding:10}, MainStyles.flexCenter]}>
          <View style={{width:'100%', height:16, backgroundColor:'rgba(0,0,20,0.1)', borderRadius:100, borderColor, borderWidth:1, marginBottom:spacing_main, marginTop:spacing_main/2}}>
            <View style={{width:percentage, position:'absolute', left:0, backgroundColor:'#389CFF', borderRadius:100, height:'100%'}}/>
          </View>

          <Text style={[title_style, {fontFamily:'Averta-Semibold'}]}>
          {string}
          </Text>
        </View>
        <View style={[{width:'100%', padding:10, backgroundColor:'white'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Text style={[title_style, {textAlign:'center', color:'black', opacity:1, letterSpacing:2, fontSize:16, fontFamily:'Averta-Regular'}]}>
          downloadtennis.com
          </Text>
        </View>
      </View>
    )}catch(e){return null}
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:'white', fontFamily:'Averta-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to open its scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnhL8u3JpBzlsRcFYspmmO1OcFmlN-wun_veNctIgw=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item.id)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'Averta-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:'white', fontSize:16, fontFamily:'Averta-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : 'white', fontSize:16, fontFamily:'Averta-Regular'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }


  render() {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
        {this.renderVote()}
      </View>
    )
  }
}

export default Item;
