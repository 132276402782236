import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


class Item extends Component {

  render() {

    return(
      <div className="button-wrapper-outside">
      <a
      href={this.props.onClick}
      onClick={this.props.logEvent}
      >

        <button
        className="button-wrapper flexRow flexCenter">

          <p className="button-text">{this.props.title}</p>

        </button>

      </a>
      </div>


    )
  }
}

export default Item;
