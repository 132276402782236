/* @flow */

import React, { PureComponent } from 'react';

import {
  View,
  Text,
  Image,
  Dimensions,
  Pressable,
  Alert
} from 'react-native';

//Modules

//
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

var CircularJSON = require('circular-json')

export default class Item extends PureComponent {

  constructor(props){
    super(props);
      this.state = {
        data:{},
        aspectRatio:1.5,
        collection:this.props.collection,
        id:this.props.id,
      }
    }

    componentDidMount(){
      this.setup()
    }

    componentDidUpdate(prevProps){
      if(CircularJSON.stringify(prevProps.data) !== CircularJSON.stringify(this.props.data) || this.props.data.deleted !== this.props.data.deleted){
        this.setup()
      }
    }

    //Functions
    setup = async () => {
      var { data } = this.props
      this.setState({data})

    }

    handleImage = ({nativeEvent}) => {
      var { height, width } = nativeEvent

      var aspectRatio = width / height
      this.setState({aspectRatio})
    }

    //Message actions
    showOptions = async () => {
      var { data } = this.state

      //Check user is moderator
      var isModerator = true
      var isAdmin = true

      if(!data.deleted || isAdmin){
        var textPreview = data.text ? (data.text.length > 100 ? (data.text).slice(0,95)+"..." : data.text) : null

        var options = [
          {text:'Report Message', onPress:this.reportMessage, style:'destructive'},
          {text:'Cancel', style:'cancel'}
        ]

        if(isModerator || isAdmin){
          options = [
            {text:data.deleted ? 'Undelete Message' : 'Delete Message', onPress:this.deleteMessage},
          ].concat(options)
        }

        if(isAdmin){
          options = [
            {text:'Shadow Ban User', onPress:this.shadowBan},
            {text:'Make User a Moderator', onPress:this.addModerator},
          ].concat(options)
        }

        Alert.alert("Message by "+data.user.name, textPreview || (data.image ? 'Image' : ''), options)
      }
    }

    deleteMessage = async () => {
      var { data, collection, id } = this.state

      console.log('Deleting message', collection, id, data.docId)

      if(data.docId){
        console.log('deleting')
        await firebase.firestore()
        .collection(collection).doc(id)
        .collection('messages').doc(data.docId)
        .set({
          deleted:!data.deleted
        },{merge:true})
      }
    }

    shadowBan = async () => {
      var { data, collection, id } = this.state

      Alert.alert("For how long?","", [
        {text:'1 hour', onPress:()=>this.requestShadowBan(1)},
        {text:'3 hours', onPress:()=>this.requestShadowBan(3)},
        {text:'12 hours', onPress:()=>this.requestShadowBan(12)},
        {text:'24 hours', onPress:()=>this.requestShadowBan(24)},
        {text:'1 week', onPress:()=>this.requestShadowBan(24 * 7)},
        {text:'Cancel', style:'cancel'}
      ])
    }

    requestShadowBan = async (hours) => {
      var { data, collection, id } = this.state

      await firebase.firestore()
      .collection('requests_chat')
      .add({
        shadowBan:hours,
        requestedAt:new Date(),
        requestedBy:GLOBAL.userId,
        user:data.user.id,
        handled:false,
      })

      // await Functions.showAlert("Shadow ban has been requested for "+data.user.name, "", 'success')
    }

    addModerator = async () => {
      var { data, collection, id } = this.state

      var addModerator = null

      await Alert.alert("Add or remove as moderator?","", [
        {text:'Add', onPress:()=>{
          addModerator = true
        }},
        {text:'Remove', onPress:()=>{
          addModerator = false
        }},
      ])

      await firebase.firestore()
      .collection('requests_chat')
      .add({
        addModerator,
        requestedAt:new Date(),
        requestedBy:GLOBAL.userId,
        user:data.user.id,
        handled:false,
      })
    }

    reportMessage = async () => {
      var { data, id, collection } = this.state

      data.reportedBy = GLOBAL.userId
      data.reportedAt = new Date()
      data.reviewed = false

      //Send report
      if(data && id && collection){
        await firebase.firestore()
        .collection(collection).doc(id)
        .collection("reports")
        .add(data)
      }

      //Confirmation
      // await Functions.showAlert("Your report has been sent", "The team will investigate and review the message against our community guidelines", 'success')

      // Functions.amplitudeEvent('Chat - Message Reported', {hasImage:(data.image !== null), id})
    }


    //Renders
    renderMessage = () => {
      var { data } = this.state

      var hide = data.banned && data.user.id !== GLOBAL.userId

      if(!hide){
        if(data.system){
          return(
            <View style={[{width:'100%', paddingVertical:10}, MainStyles.flexStartStart]}>
              <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
                <Image style={{height:14, width:14, resizeMode:'contain', marginRight:10, borderRadius:2}} source={data.icon || require('../../assets/images/appicon.png')}/>
                <Text style={{color:GLOBAL.style.color_font_secondary, fontSize:12, fontFamily:'Averta-Regular', flexShrink:1}}>
                {data.text}
                </Text>
              </View>
            </View>
          )
        }else{
          return(
            <Pressable onLongPress={this.showOptions} style={[{width:'100%', paddingVertical:10}, MainStyles.flexStartStart]}>
              <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>
                <Text style={{color:data.user.color, fontSize:14, fontFamily:'Averta-Bold', marginRight:0}}>
                {data.user.name}{data.text || data.deleted ? this.renderText(data) : null}
                </Text>
              </View>
              {data.image && !data.deleted ? this.renderImage(data.image) : null}
            </Pressable>
          )
        }
      }else{return null}
    }

    renderText = (data) => {
      if(data.deleted){
        return(
          <Text style={{color:GLOBAL.style.color_font_secondary, opacity:0.75, fontSize:12, fontFamily:'Averta-Regular', fontStyle: 'italic', flexShrink:1}}>
          {"  "+"Message deleted."}
          </Text>
        )
      }else{
        return(
          <Text style={{color:GLOBAL.style.color_font, fontSize:14, fontFamily:'Averta-Regular', flexShrink:1}}>
          {"  "+data.text}
          </Text>
        )
      }
    }

    renderImage = (uri) => {
      var { aspectRatio } = this.state
      return(
        <Image
        style={{width:200, height:100, backgroundColor:'red', borderRadius:5, marginTop:5}}
        source={{uri:uri}}
        />
      )
    }

    render() {
      try{
        return this.renderMessage()
      }catch(e){
        // console.log('Error with message render', e)
        return null
      }
    }

}
