import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { View, Text, Pressable, Linking, Image, ActivityIndicator } from "react-native";


//Module
import ReactGA from 'react-ga';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from './mainstyles.js'
import GLOBAL from './global.js'
var moment = require('moment-timezone');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true
    };
  }

  componentDidMount = async () => {
    await this.initializeFirebase()
    this.setup()

    // this.runFunction()
  }

  componentWillUnmount = async () => {

  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
      authDomain: "tennis-lwts.firebaseapp.com",
      projectId: "tennis-lwts",
      storageBucket: "tennis-lwts.appspot.com",
      messagingSenderId: "414782898270",
      appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
      measurementId: "G-D3SD6S9JJW"
    };

    await app.initializeApp(config);

    //Anon auth
    await firebase.auth().signInAnonymously()
    .then(() => {
      // Signed in..
      console.log('signed in')
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  setup = async () => {
    var systems = []

    //Get the viable systems
    await firebase.firestore()
    .collection('betting').doc('systems')
    .collection('strategies')
    .where('viable', '==', true)
    // .limit(1)
    .get()
    .then(async querySnapshot =>{
      console.log(querySnapshot.size, 'viable strategies found')
      return querySnapshot.forEach(doc => {
        // if(doc.id === "Xj4ohm4KYuc6iYrC9tJv"){
        //   this.runFunction(doc.data())
        // }
        var strat = doc.data()
        strat.docId = doc.id
        systems.push(strat)
      })
    })


    //Sort the systems by 30d performance
    await systems.sort((a, b) => (a.performance.last_30_days.units_won < b.performance.last_30_days.units_won) ? 1 : -1);

    this.setState({systems, loading:false})
  }

  runFunction = async (data) => {
    const id = "Xj4ohm4KYuc6iYrC9tJv"

    const strat_string = JSON.stringify(data.strategy)

    var strat_books = JSON.parse(strat_string)
    strat_books.bookmakers = ["Bet365"]

    var strat_min_odds = JSON.parse(strat_string)
    strat_min_odds.min_odds = 1.3

    var strat_upsets_only = JSON.parse(strat_string)
    strat_upsets_only.min_odds = 1.85

    console.log(strat_books)
    console.log(strat_min_odds)
    console.log(strat_upsets_only)

    // await firebase.firestore()
    // .collection('betting').doc('systems')
    // .collection('strategies').doc(id+"-bookmakers")
    // .set({
    //   strategy:strat_books,
    //   clone:true,
    //   viable:true,
    //   updatePicks:true,
    //   next_bet:null,
    // })
    //
    // await firebase.firestore()
    // .collection('betting').doc('systems')
    // .collection('strategies').doc(id+"-minOdds")
    // .set({
    //   strategy:strat_min_odds,
    //   clone:true,
    //   viable:true,
    //   updatePicks:true,
    //   next_bet:null,
    // })
    //
    // await firebase.firestore()
    // .collection('betting').doc('systems')
    // .collection('strategies').doc(id+"-upsetsOnly")
    // .set({
    //   strategy:strat_upsets_only,
    //   clone:true,
    //   viable:true,
    //   updatePicks:true,
    //   next_bet:null,
    // })
  }

  openSystem = (data) => {
    Linking.openURL("/betting-system/"+data.docId)
  }


  //Renders
  renderContent = () => {
    var { options, loading, systems } = this.state
    return(
      <View style={[{width:'100%', padding:24, backgroundColor:'#121212'}, MainStyles.flexStartCenter]}>
        {this.renderTitle()}
        {
          loading ?
          <View style={[{flex:1}, MainStyles.flexCenter]}><ActivityIndicator color='white'/>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
            {"Loading systems"}
            </Text>
          </View>
          : (systems || []).map((item, index)=>this.renderSystem(item, index))
        }
      </View>
    )
  }

  renderSystem = (item, index) => {
    return(
      <Pressable onPress={()=>this.openSystem(item)} style={[{width:'100%', maxWidth:400, padding:24, backgroundColor:'rgba(255,255,255,0.05)', borderRadius:16, marginBottom:16}, MainStyles.flexCenter]}>

        <View style={[{width:'100%', marginBottom:32}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <Text style={{color:'white', fontSize:20, fontWeight:'700', opacity:0.33}}>
          {"#"+(index+1)+" SYSTEM"}
          </Text>
          {
            !item.upcoming_bets.count ? null :
            <View style={[{padding:8, paddingVertical:4, borderRadius:4, backgroundColor:GLOBAL.colors.purple}]}>
              <Text style={{color:'white', fontWeight:'700'}}>
              {item.upcoming_bets.count+(item.upcoming_bets.count > 1 ? " picks available" : " pick available")}
              </Text>
            </View>
          }
        </View>

        <Text style={{color:'white', fontSize:12, fontWeight:'700', opacity:0.33, width:'100%', textAlign:'left', marginBottom:16}}>
        {item.docId}
        </Text>


        {this.renderValues("30 day peformance", [
          {value:item.performance.last_30_days.units_won, key:"Units won"},
          {value:item.performance.last_30_days.win_rate+"%", key:"Win rate"},
          {value:item.performance.last_30_days.roi+"%", key:"ROI"},
          {value:item.performance.last_30_days.total, key:"Total bets"},
          {value:"", key:""},
          {value:"", key:""},
          {value:"", key:""},
          {value:"", key:""},
          {value:"", key:""},
          {value:"", key:""},
          {value:"", key:""},
        ], true)}

        {this.renderValues("Strategy", [
          {value:item.strategy.genders.join(", "), key:"Genders"},
          {value:item.strategy.tours.join(", "), key:"Tours"},
          {value:item.strategy.markets.join(", "), key:"Markets"},
          {value:item.strategy.segments.join(", "), key:"Segments"},
          {value:item.strategy.min_matches_in_signal, key:"Min matches / signal"},
          {value:item.strategy.minimum_signals, key:"Min signals"},
          {value:item.strategy.signal_balance_threshold, key:"Signal balance"},
          {value:item.strategy.unit_ratio_threshold, key:"Unit ratio threshold"},
          {value:item.strategy.units_on_upset, key:"Units on upset"},
          {value:"", key:""},
        ], false)}

      </Pressable>
    )
  }

  renderValues = (title, data, noMargin) => {
    return(
      <View style={[{width:'100%', marginTop:noMargin ? 0 : 16}, MainStyles.flexCenterStart]}>
        {this.renderSectionTitle(title)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {(data || []).map((item, index) => this.renderValue(item))}
        </View>
      </View>
    )
  }

  renderValue = ({value, key}) => {
    if(value || value === 0){
      return(
        <View style={[{marginRight:32, marginBottom:16, width:'100%'}, MainStyles.flexCenterStart]}>
          <Text style={{color:'white', fontSize:19}}>
          {value}
          </Text>
          <Text style={{color:'white', fontSize:15, opacity:0.33, marginTop:4}}>
          {key}
          </Text>
        </View>
      )
    }else{
      return null
    }
  }

  renderSectionTitle = (title) => {
    return(
      <View style={[{width:'100%', marginBottom:16}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
      <Text style={{color:'white', fontSize:12, opacity:0.67, textTransform:'uppercase', fontWeight:'900', textAlign:'left'}}>
      {title}
      </Text>
      <View style={{flex:1, paddingLeft:16}}>
        <View style={{width:'100%', height:1, backgroundColor:'white', opacity:0.1}}/>
      </View>
      </View>
    )
  }

  renderTitle = () => {
    var { loading } = this.state
    return(
      <View style={{marginBottom:40, justifyContent:'center', alignItems:'center'}}>
        <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:24, textAlign:'center'}}>
        Live Betting Systems
        </Text>
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:13, textAlign:'center', opacity:0.5, marginTop:5}}>
        {"Tap a system to view its picks"}
        </Text>
      </View>
    )
  }


  render = () => {
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'#121212'}, MainStyles.flexStartCenter]}>
      {this.renderContent()}
      </View>
    )
  }
}

export default Landing;
