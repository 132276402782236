import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import badge from './appstorebadgedark.png';

//Components
import Poll from './items/poll.js'
import ResultSticker from './items/resultsticker.js'
import MainButton from './items/mainbutton.js'

//Modules
import ReactDOM from "react-dom";
import { AnimateOnChange } from 'react-animation'
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import {Helmet} from "react-helmet";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

const AMPLITUDE_KEY = "ff24f21d0121991161cc8dacb5a08f5f";


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


class Page extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingPoll:true,
      loadingResult:false,
      dataPoll: [],
      dataPicks: [],
    };
  }

  async componentDidMount(){
    await this.initializeFirebase();
    this.getPollData();
  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyCdXGz5UJhZQjNpEz4FBtJCUbCahGHaJ4A",
      authDomain: "app-4pm.firebaseapp.com",
      databaseURL: "https://app-4pm.firebaseio.com",
      projectId: "app-4pm",
      storageBucket: "app-4pm.appspot.com",
      messagingSenderId: "450215977644",
      appId: "1:450215977644:web:4a9cb20f1afc42b214b509",
      measurementId: "G-YESKQ2TETN"
    };

    await app.initializeApp(config);
  }

  async getPollData(){
    //Get params from link
    var userId = this.props.match.params.user;
    var pollId = this.props.match.params.poll;
    const db = firebase.firestore();
    db.collection('users').doc(userId).collection('polls').doc(pollId).get().then(this.handlePollData)
  }


  handlePollData = (doc) => {
    if (!doc.exists) {
      console.log('No such poll could be found!');
      this.setState({
        invalidPoll:true,
        loading: false,
      })
    } else {
      //Get params from link
      var userId = this.props.match.params.user;
      var pollId = this.props.match.params.poll;
      const db = firebase.firestore();

      this.setState({dataPoll:doc.data()});

      console.log('Grade', doc.data().grade)

      //Get picks
      db.collection('users').doc(userId).collection('polls').doc(pollId).collection('picks').doc('picks').get().then(this.handlePicksData)
    }
  }


  handlePicksData = (doc) => {
    if (!doc.exists) {
      this.setState({
        invalidPoll:true,
        loading: false,
      })
    } else {
      this.setState({
        dataPicks: doc.data(),
        loadingPoll: false
      })
    }
  }


  submitVote = async (option) => {
    //Get params from link
    var userId = this.props.match.params.user;
    var pollId = this.props.match.params.poll;
    const db = firebase.firestore();

    this.setState({
      voteSubmitted: true,
      loadingResult: true,
    })

    var pick;
    if (option == 1){ pick = 'option1'}
    if (option == 2){ pick = 'option2'}
    if (option == 3){ pick = 'option3'}
    if (option == 4){ pick = 'option4'}

    var winnerName = this.state.dataPicks[pick].name;

    this.setState({winnerName})

    //Increment vote count for selected option and total votes by one
    await db.collection('users').doc(userId).collection('polls').doc(pollId).collection('picks').doc('picks').set({
      [pick]:{
        votes: firebase.firestore.FieldValue.increment(1)
      }
    }, {merge: true})

    await db.collection('users').doc(userId).collection('polls').doc(pollId).set({
      votes: firebase.firestore.FieldValue.increment(1)
    }, {merge: true})

    //End loading, trigger animation to reveal viral flow
    setTimeout(() => {  this.setState({loadingResult:false}) }, 1000);
  }


  setData = data => {
    this.setState({loadingResult:true})
  }

  finalSticker(){

    var grade = this.state.dataPoll.grade ? this.state.dataPoll.grade : 'Not defined'
    var winnerName = this.state.winnerName.split(' ')[0]

    // if(grade === '2024' || grade === '2025' || grade === '2026'){
    //   return(
    //     <Cell col={12} className="vertical">
    //       <p className="winner-font">
    //       your vote was sent <br/> for {winnerName}!
    //       </p>
    //       <ResultSticker
    //       line1='get your own polls'
    //       line2='about friends at school!'
    //       image='appicon'
    //       />
    //       <Amplitude>
    //         {({ logEvent }) =>
    //           <MainButton
    //           title='get your own polls'
    //           onClick={'https://itunes.apple.com/app/id1488092059'}
    //           logEvent={()=>logEvent('Poll - Live results / Go to app store', {question:this.state.dataPoll.question, grade:grade})}
    //           />
    //         }
    //       </Amplitude>
    //     </Cell>
    //   )
    // }else{
      return(
        <Cell col={12} className="vertical">
          <p className="winner-font">
          your vote was sent <br/> for {winnerName}!
          </p>
          <ResultSticker
          line1='see results of this poll'
          line2='+ get your own polls!'
          image='appicon'
          />
          <Amplitude>
            {({ logEvent }) =>
              <MainButton
              title='see live results of this poll'
              onClick={this.state.dataPoll.shareUrl}
              logEvent={()=>logEvent('Poll - Live results / Go to app store', {question:this.state.dataPoll.question, grade:grade})}
              />
            }
          </Amplitude>
        </Cell>
      )
    // }
  }


  renderPoll(){

    var grade = this.state.dataPoll.grade ? this.state.dataPoll.grade : 'Not defined'

    if(this.state.loadingPoll){
      return(
        <p className="loading-font">loading poll...</p>
      )
    }else{
      if(this.state.voteSubmitted){
        if(this.state.loadingResult){
          return(
            <p className="loading-font">sending your vote...</p>
          )
        }else{
          return(
            <div>
            {this.finalSticker()}
            </div>
          )
        }
      }else{
        return(
          <Cell col={12} className="vertical flexCenter">

          <Poll
          dataPoll={this.state.dataPoll}
          dataPicks={this.state.dataPicks}
          submitVote={this.submitVote}
          loading={()=>this.setState({loading:false})}
          onLoad={this.setData}
          />

          <p className="poll-pick-winner">tap a name to vote!</p>

          </Cell>
        )
      }
    }
  }

  renderAmplitudeEvent(){
    if(!this.state.loadingPoll){
      return(
        <LogOnMount
        eventType="Poll - Web view"
        eventProperties={{question:this.state.dataPoll.question}}
        />
      )
    }
  }

  render() {

    return(
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={AMPLITUDE_KEY}
      >

      <Helmet>
        <meta charSet="utf-8" />

        <title>4PM - live poll about friends</title>
        <meta name="description" content="answer polls about friends at school + see who likes you on 4PM" />

        <meta name="og:title" content="4PM - live poll about friends on 4PM"/>
        <meta name="og:description" content="answer polls about friends at school + see who likes you on 4PM"/>
        <meta name="og:url" content="https://download4pm.com"/>
        <meta name="og:site_name" content="4PM"/>
        <meta name="og:type" content="website"/>
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/1.png?alt=media&token=6987cb79-dec6-4c26-a2f3-050c1a95d0f7"/>
      </Helmet>

      {this.renderAmplitudeEvent()}

        <div className="height">

          <Grid className="background-main flexCenter">

          <AnimateOnChange
          className="vertical flexCenter width-100"
          animationIn="bounceIn"
          animationOut="bounceOut"
          durationIn={750}
          durationOut={750}
          >
          {this.renderPoll()}
          </AnimateOnChange>

          </Grid>

        </div>
      </AmplitudeProvider>
    )
  }
}

export default Page;
