import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 800
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

var animation_duration = 350

var blue = "#389CFF"

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var colors = {
  primary:'#17171D',
  secondary:'black',
  accent:'#60348C',
  score_bg:'#1B1B20',
  score_font:'white',
  bg_opacity:0.2,
  main_font:'white',
  bg_stats:'#17171D',
  logo:null,
}

var volt = '#B6FE00'

var backgroundColor = 'white'
var borderColor = 'rgba(0,0,0,0.1)'

var height = 120
var width = 1920
var spacing = 10
var spacing_main = 20
var fontSize = 40

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      stats_options:[],
      matchTime:'0:00',
      matchInfo:'',

      activeIndex_stats:-1,
      activeIndex_tippers:-1,

      activeIndex_h2h:-1,
      h2h:[],

      tippers:[],
      stats:[],
      stats_obj:{},
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',

      stats_view:"Match",

      stats_opacity: new Animated.Value(1),
      h2h_opacity: new Animated.Value(1),

      images:[null, null],
      names:[null, null],
      flags:[null, null],

      id:null,
    };
  }

  componentDidMount = async () => {
    await this.setup()
    // this.changeTheme()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    if(this.listenControls){
      this.listenControls()
    }
    clearTimeout(this.updateString_tippers)
    clearTimeout(this.updateString_h2h)
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
    })
    this.setup()
  }

  setup = async () => {
    //Get the selected match from Firestore
    this.listenControls = await firebase.firestore()
    .collection('streaming').doc("8MP5DlCnSoAMEzygPl3i")
    .onSnapshot(async (doc) => {
      if(doc.exists && doc.data()){
        var id = doc.data()["matchId-key"] || null
        var delay = doc.data().delay || 0

        // if(id !== this.state.id){
        //   await this.setState({id})
        //   this.selectMatch(id)
        // }

        if(this.state.id === null){
          //First set
          await this.setState({id, delay})
          this.selectMatch(id)
        }else if(id !== this.state.id || delay !== this.state.delay){
          //Not first set, id or delay has changed, remount
          this.props.remount()
        }

      }
    })
  }

  selectMatch = async (id) => {
    this.setState({id})

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .onSnapshot(this.handleMatch)
  }

  handleMatch_old = async ( querySnapshot ) => {
    var match = {}
    var docId = null

    if(querySnapshot.size > 0){
      await querySnapshot.forEach(async (doc) => {
        match = doc.data()
        docId = doc.id

        //Correct the player names
        if(match.competitors[0].name.includes(', ')){
          match.competitors[0].name_full = (match.competitors[0].name.split(", "))[1] + " " + (match.competitors[0].name.split(", "))[0]
          match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
        }

        if(match.competitors[1].name.includes(', ')){
          match.competitors[1].name_full = (match.competitors[1].name.split(", "))[1] + " " + (match.competitors[1].name.split(", "))[0]
          match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
        }
      })
    }

    if(this.state.firstSet && docId){
      this.setupPlayers(match)
    }

    //Calculate the matchTime
    var durationString = null
    if(match.startedAt){
      var startedAt = match.startedAt
      var finishedAt = !match.finishedAt ? Date.now() : match.finishedAt

      var duration = (moment.duration(finishedAt - startedAt).as('hours')).toString()

      //Clean string
      var durationHours = Number(duration.split('.')[0])
      if(durationHours < 6){
        var durationMinutesNumber = Math.round(Number("."+duration.split('.')[1]) * 60)
        var durationMinutesRaw = durationMinutesNumber == 60 ? 59 : durationMinutesNumber
        var durationMinutes = durationMinutesRaw.toString().length == 1 ? "0"+durationMinutesRaw : durationMinutesRaw
        durationString = durationHours + ':' + durationMinutes
      }
    }

    //Calculate the duration string
    var matchTime = durationString ? "Match Time: "+durationString : ''

    //Flags
    var country_corrections = {
      ["GRE"]:'GRC',
      ["GER"]:'DEU',
      ["LAT"]:'LVA',
      ["CRO"]:'HRV',
      ["SUI"]:'CHE',
    }
    var countries_fix = Object.keys(country_corrections)

    await Promise.all(match.competitors.map(async (player, p_index) => {
      if(countries_fix.includes(player.country_code)){
        player.country_code = country_corrections[player.country_code]
      }
    }))

    //Country code
    var code_1 = await convertIso3Code(match.competitors[0].country_code)
    var code_2 = await convertIso3Code(match.competitors[1].country_code)
    var flag_1 = code_1 ? code_1.iso2 : null
    var flag_2 = code_2 ? code_2.iso2 : null

    var names = [{first:match.competitors[0].name.split(", ")[1], last:match.competitors[0].name.split(", ")[0]}, {first:match.competitors[1].name.split(", ")[1], last:match.competitors[1].name.split(", ")[0]}]
    var flags = [flag_1, flag_2]


    //Match Info string
    var matchInfo = match.court_name || match.season_name
    if(match.round){
      matchInfo = matchInfo + " - " + match.round
    }

    //Delay the setting of state
    // var delay = this.state.delay || 0
    // if(this.state.firstSet){
    //   delay = 0
    // }
    var delay = 0

    var marker = Date.now()

    //Set the score string


    setTimeout(()=>{
      this.setState({names, flags, match, matchTime, matchInfo, loading:false, mode:'match', firstSet:false})
    }, delay*1000)
  }

  handleMatch = async (querySnapshot) => {
    var matches = []
    var matches_clean = []

    if(querySnapshot.size > 0){
      await querySnapshot.forEach(async (doc) => {
        matches = [doc.data()]
      })
    }

    await Promise.all(matches.map(async match => {
      var winner = match.winner_id === match.competitors[0].id ? match.competitors[0] : match.competitors[1]
      var loser = match.winner_id === match.competitors[0].id ? match.competitors[1] : match.competitors[0]

      //Check if we need to reverse the score string
      var reverseScore = false
      if(match.winner_id){
        reverseScore = match.winner_id === match.competitors[1].id
      }else{
        if(match.away_score > match.home_score){
          reverseScore = true
        }else if(match.home_score === match.away_score){
          //Tied, see if one player is leading the set
          if(match.period_scores){
            if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){

            }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
              reverseScore = true
            }else{

            }
          }
        }
      }

      //Score string
      var score_string = await this.generateResultString(match, match.tiebreak_scores, reverseScore)

      match.score_string = score_string

      var winnerName = winner.name
      var loserName = loser.name

      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }

      //Country corrections
      // var countries_fix = ["GRE", "GER", "LAT"]
      var country_corrections = {
        ["GRE"]:'GRC',
        ["GER"]:'DEU',
        ["LAT"]:'LVA',
        ["CRO"]:'HRV',
        ["SUI"]:'CHE',
      }
      var countries_fix = Object.keys(country_corrections)

      await Promise.all(match.competitors.map(async (player, p_index) => {
        if(countries_fix.includes(player.country_code)){
          player.country_code = country_corrections[player.country_code]
        }
      }))

      //Country code
      var code_1 = await convertIso3Code(match.competitors[0].country_code)
      var code_2 = await convertIso3Code(match.competitors[1].country_code)
      match.competitors[0].country_cca = code_1 ? code_1.iso2 : null
      match.competitors[1].country_cca = code_2 ? code_2.iso2 : null

      //Sort the order of players depending on who is leading
      if(match.winner_id){
        //Middle string
        match.middleString = 'def'

        //Match has finished
        if(match.winner_id === match.competitors[1].id){
          //Reverse the players
          match.competitors = [
            match.competitors[1],
            match.competitors[0],
          ]
        }
      }else{
        match.middleString = 'leads'

        try{
          //On-going, check who is leading
          if(match.home_score > match.away_score){

          }else if(match.away_score > match.home_score){
            match.competitors = [
              match.competitors[1],
              match.competitors[0],
            ]
          }else{
            //Tied, see if one player is leading the set
            if(match.period_scores){
              if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){
                match.middleString = 'leads'
              }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
                match.competitors = [
                  match.competitors[1],
                  match.competitors[0],
                ]
                match.middleString = 'leads'
              }else{
                match.middleString = 'vs'
              }
            }else{
              match.middleString = 'vs'
            }
          }
        }catch(e){
          console.log('Error', e)
        }
      }

      // var other_match = {
        // title:'Federer leads Berrettini',
        // score:"6-3 4-6 3-2",
        // subtitle:'FRENCH OPEN - R16',
        // image_1:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/federer_head_ao20.png",
        // image_2:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/berrettini_head_ao20.png"
      // }

      var lastname_1 = match.competitors[0].name.split(". ")[1] ? (match.competitors[0].name.split(". "))[1].toLowerCase() : match.competitors[0].abbreviation
      var lastname_2 = match.competitors[1].name.split(". ")[1] ? (match.competitors[1].name.split(". "))[1].toLowerCase() : match.competitors[1].abbreviation

      var seed_1 = match.seeds[match.competitors[0].id] ? " [" + match.seeds[match.competitors[0].id] + "]" : ''
      var seed_2 = match.seeds[match.competitors[1].id] ? " [" + match.seeds[match.competitors[1].id] + "]" : ''

      var images = match.competitor_photos || [null, null]

      var title = lastname_1 + seed_1 + " " + match.middleString + " " + lastname_2 + seed_2
      var image_1 = images[0]
      var image_2 = images[1]

      console.log(lastname_1, lastname_2)

      if(lastname_1 === 'auger-aliassime'){ image_1 = "https://newmedia.xscores.com/tennis/AUGER-ALIASSIME%20F..png"}
      if(lastname_2 === 'auger-aliassime'){ image_2 = "https://newmedia.xscores.com/tennis/AUGER-ALIASSIME%20F..png"}

      // if(lastname_2 === 'rodionov'){
      //   image_2 = "https://www.atptour.com/en/players/jurij-rodionov/r09x/www.atptour.com/-/media/tennis/players/head-shot/2017/rodionov_head17.png"
      // }
      // if(lastname_1 === 'djokovic'){
      //   image_1 = "https://www.atptour.com/-/media/tennis/players/head-shot/2019/djokovic_head_ao19.png"
      // }

      matches_clean.push({
        title,
        score: score_string || 'WARMING UP',
        subtitle: match.season_name.split(", ")[0] + " - " + (match.round || 'Qualifying'),
        image_1,
        image_2
      })

    }))

    await this.setState({loading:false, matches:matches_clean, matches_per_render:1, activeIndex:0})
  }

  generateResultString = async (data, tiebreak_scores, reverseScore) => {
    var score = ''

    try{
      var scores = data.period_scores || []
      //Check the winner to decide order
      var winner_id = reverseScore ? data.competitors[1].id : data.competitors[0].id
      var winner_score_id = winner_id === data.competitors[0].id ? 'home_score' : 'away_score'
      var loser_score_id = winner_id === data.competitors[1].id ? 'home_score' : 'away_score'
      var winner_tb_id = winner_id === data.competitors[0].id ? 'home' : 'away'
      var loser_tb_id = winner_id === data.competitors[1].id ? 'home' : 'away'

      var middle = "-"

      //Create the set strings
      var stringsArray = []
      for (let i = 0; i < scores.length; i++) {
        var scoreString = scores[i][winner_score_id] + middle + scores[i][loser_score_id]

        //Check if there was a tiebreaker
        if((scores[i][winner_score_id] === 7 && scores[i][loser_score_id] === 6) && tiebreak_scores){
          //Winner won the set in tb
          if(tiebreak_scores[i+1]){
            if(tiebreak_scores[i+1][loser_tb_id]){
              scoreString = scores[i][winner_score_id] + middle + scores[i][loser_score_id] +"("+tiebreak_scores[i+1][loser_tb_id]+")"
            }
          }
        }
        if((scores[i][winner_score_id] === 6 && scores[i][loser_score_id] === 7) && tiebreak_scores){
          //Winner lost the set in tb
          if(tiebreak_scores[i+1]){
            if(tiebreak_scores[i+1][winner_tb_id]){
              scoreString =  scores[i][winner_score_id] + middle + scores[i][loser_score_id] + "("+tiebreak_scores[i+1][winner_tb_id] + ")"
            }
          }
        }

        stringsArray.push(
          scoreString
        )
      }

      score = stringsArray.join("  ")

      //Walkover handling
      if(data.match_status === 'walkover'){
        score = "Walkover"
      }

      //Retirement handling
      if(data.match_status === 'retired'){
        score = score + " ret"
      }

      if(data.match_status === 'defaulted'){
        score = score + " def"
      }

      if(data.match_status === 'not_started'){
        score = "Starts "+moment(data.start_time_timestamp, 'x').fromNow()
      }

      if(data.match_status === 'start_delayed'){
        score = 'Start Delayed'
      }

      if(data.match_status === 'match_about_to_start'){
        score = 'Players Warming Up'
      }

    }catch(e){
      console.log('Error', e)
    }

    //Return the string
    return score
  }

  setupPlayers = async (match) => {
    if(match.competitors[0].name.includes(". ")){
      var lastname_1 = (match.competitors[0].name.split(". ")[1]).toLowerCase()
      var lastname_2 = (match.competitors[1].name.split(". ")[1]).toLowerCase()

      var image_1 = match.season_name.toLowerCase().includes('women') ? null : "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_1+"_full_ao20.png"
      var image_2 = match.season_name.toLowerCase().includes('women') ? null : "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_2+"_full_ao20.png"

      //Get player profiles
      var image_official_1 = await this.getPlayer(match.competitors[0].id)
      var image_official_2 = await this.getPlayer(match.competitors[1].id)

      var images = [image_official_1 || image_1, image_official_2 || image_2]
      var names = [match.competitors[0].name_full, match.competitors[1].name_full]

      this.setState({images, names})
    }
  }

  getPlayer = async (id) => {
    var image = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.data()){
            if(doc.data().image_official){
              image = doc.data().image_official
            }
          }
        })
      }
    })

    return image
  }

  //Renders
  renderOtherMatch = () => {

    var { matches, activeIndex } = this.state
    var other_match = matches[activeIndex] ? matches[activeIndex] : null

    // other_match = {
    //   title:'Federer leads Berrettini',
    //   score:"6-3 4-6 3-2",
    //   subtitle:'FRENCH OPEN - R16',
    //   image_1:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/federer_head_ao20.png",
    //   image_2:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/berrettini_head_ao20.png"
    // }

    try{return(
      <View style={[{width:700, paddingVertical:20, backgroundColor, borderRadius:spacing_main/0, borderColor, marginRight:spacing_main, borderWidth:1, overflow:'hidden'}, MainStyles.flexColumn, MainStyles.flexCenter]}>
        {
          !other_match ? null :
          <Animated.View style={[{width:'100%', opacity:this.state.score_opacity}, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:24, color:colors.secondary, letterSpacing:-0.4, textTransform:'uppercase'}}>
            {other_match.title}
            </Text>
            {this.renderLine(false)}
            <Text style={{fontFamily:'Averta-Black', fontSize:32, color:blue, letterSpacing:-0}}>
            {other_match.score}
            </Text>
            <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:colors.secondary, letterSpacing:-0.3, textTransform:'uppercase', marginTop:10, opacity:0.5}}>
            {other_match.subtitle}
            </Text>

            <Image
            style={{height:180, width:180, resizeMode:'contain', position:'absolute', left:-30, bottom:-45}}
            source={{uri:other_match.image_1}}
            />
            <Image
            style={{height:180, width:180, resizeMode:'contain', position:'absolute', right:-30, bottom:-45}}
            source={{uri:other_match.image_2}}
            />
          </Animated.View>
        }
      </View>
    )}catch(e){
      console.log(e)
      return null
    }
  }

  renderLine = (wide) => {
    var source = wide ? require('../../images/line_wide.png') : require('../../images/line_short.png')
    var marginVertical = wide ? spacing_main : spacing_main/2
    try{return(
      <View style={{width:'100%', height:2, marginVertical}}>
        <Image
        style={{width:'100%', height:2, tintColor:blue}}
        source={source}
        />
      </View>
    )}catch(e){return null}
  }


  render() {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'transparent'}, MainStyles.flexCenter]}>
        {loading ? null : this.renderOtherMatch() }
      </View>
    )
  }
}

export default Item;
