import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { View, Text } from "react-native";

import badge from './appstorebadgedark.png';
import image1 from '../assets/images/1.png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'


//Module
import ReactGA from 'react-ga';
var moment = require('moment-timezone');

var ProgressBar = require('progress');
var ss = require('simple-statistics')

const matches_all = require('./bettingsystemmatches.json')

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = async () => {
    console.log('Mount')
    await this.initializeFirebase()

    // await this.systems_updateViableSystems()

    //
    // var markets = [
    //   {"key":'total'},
    //   {"key":'2way'},
    //   {"key":'spread'},
    // ]
    //
    // await markets.sort((a, b) => (a.key > b.key) ? 1 : -1);
    //
    // console.log(markets)

    // this.clone()

    // await this.updateBettingSystems()

    // await this.countViableStrategies()


    // await this.systems_setup(['all'])
    // await this.systems_setup(['last_1_matches'])
    // await this.systems_setup(['last_3_matches'])
    // await this.systems_setup(['last_6_matches'])
    // await this.systems_setup(['last_12_matches'])
    // await this.systems_setup(['last_14_days'])
    // await this.systems_setup(['last_30_days'])
    // await this.systems_setup(['tournament_current'])
    // await this.systems_setup(['tournament_previous'])

    await this.ai_run()

    // await this.ai_createArrayTest({
    //   min:2.34,
    //   max:19.23,
    // })

    // var stats = {
    //   correct:{},
    //   wrong:{},
    // }
    //
    // var samples = {
    //   correct:[1,5,9,3,4,5,6],
    //   wrong:[2,7,5,6],
    // }
    //
    // stats.correct.min = await ss.min(samples.correct)
    // stats.correct.max = await ss.max(samples.correct)
    // stats.correct.mean = await ss.mean(samples.correct)
    // // stats.correct.mode = await ss.mode(samples.correct)
    // // stats.correct.median = await ss.median(samples.correct)
    // stats.correct.variance = await ss.variance(samples.correct)
    // stats.correct.sd = await ss.standardDeviation(samples.correct)
    // stats.correct.n = samples.correct.length
    //
    // stats.wrong.min = await ss.min(samples.wrong)
    // stats.wrong.max = await ss.max(samples.wrong)
    // stats.wrong.mean = await ss.mean(samples.wrong)
    // // stats.wrong.mode = await ss.mode(samples.wrong)
    // // stats.wrong.median = await ss.median(samples.wrong)
    // stats.wrong.variance = await ss.variance(samples.wrong)
    // stats.wrong.sd = await ss.standardDeviation(samples.wrong)
    // stats.wrong.n = samples.wrong.length
    //
    // //Analyse if the difference between each side is significant
    // stats.confidence = await this.stats_tTest(samples)
    //
    // console.log(stats)
    // var matches = await this.getMatches(45,null)
    // await this.download(JSON.stringify(matches), 'bettingsystemmatches.json', 'text/plain')
  }

  componentWillUnmount = async () => {

  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
      authDomain: "tennis-lwts.firebaseapp.com",
      projectId: "tennis-lwts",
      storageBucket: "tennis-lwts.appspot.com",
      messagingSenderId: "414782898270",
      appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
      measurementId: "G-D3SD6S9JJW"
    };

    await app.initializeApp(config);

    //Anon auth
    await firebase.auth().signInAnonymously()
    .then(() => {
      // Signed in..
      console.log('signed in')
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  download = async (content, fileName, contentType) => {
    var a = document.createElement("a");
    var file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  //
  clone = async () => {
    // var strategy = {}
    //
    // var docId = "Xj4ohm4KYuc6iYrC9tJv"
    //
    // //Get the best strategy
    // await firebase.firestore()
    // .collection('betting').doc('systems')
    // .collection('strategies').doc(docId)
    // .get()
    // .then(doc => {
    //   strategy = doc.data()
    //   return
    // })
    //
    // // strategy.strategy.days = [
    // //   "Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"
    // // ]
    // strategy.strategy.spread_on_upset = true
    // strategy.id = strategy.id+"_spreadOnUpset"
    // strategy.clone = true
    //
    // //Set days
    // console.log('Would add', strategy)
    // await firebase.firestore()
    // .collection('betting').doc('systems')
    // .collection('strategies').doc(docId+"-spreadOnUpset")
    // .set(strategy)

  }

  //
  systems_updateViableSystems = async () => {
    //Get the strategies
    var strats = []

    const id = 'strategy_genders-["men"]_segments-["last_30_days"]_min_matches_in_signal-5_units_on_upset-1_unit_ratio_threshold-0.1_markets-["2way","spread","total"]_both_players_signal-true_signal_balance_threshold-0.5_tours-["Challenger","WTA 125K","ITF Men","ITF Women"]_minimum_signals-1'

    await firebase.firestore()
    .collection('betting').doc('systems')
    .collection('strategies')
    .where('id', '==', id)
    .limit(1)
    .get()
    .then(async querySnapshot =>{
      console.log(querySnapshot.size, 'viable strategies found')
      return querySnapshot.forEach(async doc => {
        var strat = doc.data()
        strat.docId = doc.id
        strats.push(strat)
      })
    })

    //Get the matches
    var matches = await this.systems_getMatches(2)

    //Split the matches into dates
    var dates = {}

    await Promise.all(matches.map(async match =>{
      var match_date = moment(match.start_time_timestamp, 'x').format("YYYY-MM-DD")
      if(!dates[match_date]){
        dates[match_date] = {
          date:match_date,
          matches:[]
        }
      }

      dates[match_date].matches.push(match)
    }))

    //Update picks for each strategy
    await Promise.all(strats.map(async strategy =>{
      var upcoming_bets = []

      //Update for each date
      await Promise.all(Object.values(dates).map(async date =>{
        var date_outcome = await this.systems_runStrategy(strategy.strategy, date)

        if(date_outcome.upcoming.length > 0){
          //Add to the upcoming bets list
          await Promise.all(date_outcome.upcoming.map(upcoming => {
            upcoming_bets.push(upcoming)
          }))
        }

        //Update that date for that strategy
        await this.systems_updateSystemDate(strategy, date, date_outcome)
      }))

      //Sort through upcoming bets and find next start
      await upcoming_bets.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);

      console.log(upcoming_bets.length, 'bets upcoming')

      //Update the strategy
      if(strategy.docId){
        // await firebase.firestore()
        // .collection('betting').doc('systems')
        // .collection('strategies').doc(strategy.docId)
        // .set({
        //   lastUpdated:new Date(),
        //   next_bet:upcoming_bets.length ? Number(upcoming_bets[0].start_time_timestamp || 0) : null,
        //   upcoming_bets:{
        //     count:upcoming_bets.length,
        //     data:upcoming_bets,
        //   },
        // },{merge:true})
      }

    }))

    console.log(strats.length, "strategies updated")

  }

  systems_updateSystemDate = async (strategy, date, outcome) => {
    if(strategy.docId && date.date){
      var docId = null

      //Find doc for that date
      // await firebase.firestore()
      // .collection('betting').doc('systems')
      // .collection('strategies').doc(strategy.docId)
      // .collection('dates')
      // .where('date', '==', date.date)
      // .limit(1)
      // .get()
      // .then(async querySnapshot => {
      //   return querySnapshot.forEach(doc => {
      //     docId = doc.id
      //   })
      // })
      //
      // if(!docId){
      //   await firebase.firestore()
      //   .collection('betting').doc('systems')
      //   .collection('strategies').doc(strategy.docId)
      //   .collection('dates')
      //   .add({
      //     date:date.date,
      //   })
      //   .then(doc => {
      //     docId = doc.id
      //     return
      //   })
      // }
      //
      // if(docId){
      //   await firebase.firestore()
      //   .collection('betting').doc('systems')
      //   .collection('strategies').doc(strategy.docId)
      //   .collection('dates').doc(docId)
      //   .set(outcome)
      // }

    }
  }

  systems_runStrategy = async (strategy, date_data) => {
    var outcome = null
    var bets = []
    var upcoming = []

    const date = date_data.date
    const matches = date_data.matches
    const time_period = 1

    const matches_per_second = 1000

    // console.log('Running strategy', strategy, date)

    //Promise through all the matches and based on the params, decide whether a bet would be placed or not
    var throttle = require('promise-ratelimit')((1/matches_per_second)*1000)
    await Promise.all(matches.map(async (match, index) => {
      if(index >= 0){
        await throttle().then(async () => {

          //Check if match is upcoming
          var is_upcoming = (Number(match.start_time_timestamp || 0) > Date.now()) && !match.startedAt

          var match_bets = await this.systems_testStrategyMatch(strategy, match, time_period)

          if(match_bets.length > 0){
            await Promise.all(match_bets.map(bet => {
              bets.push(bet)

              if(is_upcoming){
                //Add the match to upcoming with bets attached
                upcoming.push(bet)
              }
            }))
          }

          return
        })
      }
    }))

    //Go through the bets and calculate the result for this strategy
    if(bets.length > 0){
      outcome = {
       // strategy,
       total:bets.length,
       complete:bets.reduce((accumulator, object) => {return accumulator + (object.complete ? 1 : 0)}, 0),
       correct:bets.reduce((accumulator, object) => {return accumulator + (object.correct ? 1 : 0)}, 0),
       units_wagered:Math.round(bets.reduce((accumulator, object) => {return accumulator + (object.units_wagered || 0)}, 0) * 100)/100,
       units_won:Math.round(bets.reduce((accumulator, object) => {return accumulator + (object.units_won || 0)}, 0) * 100)/100,
       time_period,
      }

      //Attach unit_ratio values per wager and per day
      outcome.unit_ratio_per_wager = outcome.total_complete ? outcome.units_won / outcome.total_complete : 0
      outcome.unit_ratio_per_day = outcome.units_won

      //Attach ROI and win rate
      outcome.win_rate = Math.round(outcome.correct / outcome.total * 100 * 10)/10
      outcome.roi = Math.round(outcome.units_won/outcome.units_wagered * 100 * 10)/10
    }

    //Decide whether date is closed
    const closed = outcome ? outcome.complete === outcome.total : false

    return {
      upcoming,
      outcome,
      bets,
      date,
      closed,
      lastUpdated:new Date(),
    }
  }

  systems_testStrategyMatch = async (strategy, match, time_period) => {
    var bets = await this.systems_getBets(strategy, match)
    return bets
  }

  systems_getBets = async (strategy, match, time_period) => {
    var bets = []

    var analysis = {}
    var all_odds = {}

    try{
      analysis = match.analysis.signals.analysis || {}
      all_odds = match.odds || match.analysis.signals.odds || {}
    }catch(e){
      // console.log("No analysis", match.id)
    }

    var {
      segments,
      min_matches_in_signal,
      units_on_upset,
      unit_ratio_threshold,
      markets,
      both_players_signal,
      signal_balance_threshold,
      tours,
      genders,
      minimum_signals,
      rounds,
      days,
      spread_on_upset,
    } = strategy

    var include_match = true

    //Check the tour against the filter
    if(!tours.includes("All")){
      if(!tours.includes(match.category)){
        include_match = false
      }
    }

    //Check genders
    if(!genders.includes(match.gender)){
      include_match = false
    }

    //Check the day of the week vs filter
    if(days){
      try{
        const match_day = moment(match.start_time_timestamp, 'x').tz(match.timezone).format('dddd')
        // console.log("Days", days, match_day, days.includes(match_day))
        if(!days.includes(match_day)){
          include_match = false
        }
      }catch(e){
        console.log(e)
      }
    }

    if(rounds){
      if(!rounds.includes(match.round)){
        include_match = false
      }
    }

    //
    var force_spread_for_underdog = false
    var force_spread_index = null


    //Test each market and its signals against the strategy and decide whether a bet should be placed or not
    if(include_match){

      // console.log('Including match', match.id)

      var markets_promise = []
      for (const [key, value] of Object.entries(analysis)) {
        markets_promise.push({key, value})
      }

      //Sort the markets by key to force 2way to run first
      await markets_promise.sort((a, b) => (a.key > b.key) ? 1 : -1);

      //Throttle the markets so that if the 2way forces the spread to be taken, it has the info
      var throttle = require('promise-ratelimit')(5000)

      await Promise.all(markets_promise.map(async market_promise => {
        await throttle().then(async () => {
          var { key, value } = market_promise

          var place_bet_index = null

          var market = key
          var options = value || []
          var odds = all_odds[market] || null

          // console.log('Testing market', market, odds)

          if(markets.includes(market) && odds){

            // console.log('Valid market', market)

            var signals = [
              [],
              []
            ]

            var wagers = [
              options[0].id,
              options[1].id,
            ]

            var total_signals = 0

            //Filter signals based on strategy to setup valid signals
            await Promise.all(options.map(async (option, index) => {

              var option_signals = option.data || []

              await Promise.all(option_signals.map(option_signal => {
                var signal_ok = true

                if( !segments.includes(option_signal.key) && !segments.includes('all') ){
                  signal_ok = false
                }

                if( min_matches_in_signal > option_signal.total ){
                  signal_ok = false
                }

                if( unit_ratio_threshold > option_signal.unit_ratio ){
                  signal_ok = false
                }

                if( both_players_signal && !option_signal.both_players ){
                  signal_ok = false
                }

                if(signal_ok){
                  //Signal valid to include given the filters
                  signals[index].push(option_signal)
                }

              }))

            }))

            //Check that the date is ok
            var date = moment(match.start_time_timestamp, 'x').format('YYYY-MM-DD')
            var min_date = time_period ? moment().subtract(time_period, 'days').format('YYYY-MM-DD') : null
            var good_date = date >= min_date || !time_period


            //Check that at least one side of the market has the minimum number of signals
            var has_min_signals = signals[0].length >= minimum_signals || signals[1].length >= minimum_signals

            //Check if the bet should be forced
            const forceBet = market === 'spread' && force_spread_for_underdog

            // console.log("has_min_signals", has_min_signals, "forceBet", forceBet, "good_date", good_date)

            if((has_min_signals || forceBet) && good_date){
              // console.log('Filtered signals', signals)

              //With the signals filtered, calculate the total units and ratios on each side of the bet
              var outcomes = [
                {
                  total:signals[0].reduce((accumulator, object) => {return accumulator + object.total}, 0),
                  units:signals[0].reduce((accumulator, object) => {return accumulator + object.units}, 0),
                  unit_ratio:0,
                },
                {
                  total:signals[1].reduce((accumulator, object) => {return accumulator + object.total}, 0),
                  units:signals[1].reduce((accumulator, object) => {return accumulator + object.units}, 0),
                  unit_ratio:0,
                },
              ]

              outcomes[0].unit_ratio = outcomes[0].total ? outcomes[0].units / outcomes[0].total : 0
              outcomes[1].unit_ratio = outcomes[1].total ? outcomes[1].units / outcomes[1].total : 0

              // console.log('Outcomes', outcomes)

              var favored_market = outcomes[0].unit_ratio > outcomes[1].unit_ratio ? 0 : 1

              total_signals = signals[favored_market].length

              //If a forced bet, set the favored market index
              if(forceBet){
                favored_market = force_spread_index
              }

              var unfavored_market = favored_market === 0 ? 1 : 0
              var signals_balance = outcomes[favored_market].unit_ratio - outcomes[unfavored_market].unit_ratio


              //Calculate whether the unit_ratio balance is sufficiently favoring one market
              var valid_odds = odds[favored_market] && odds[favored_market].odds
              if((signals_balance >= signal_balance_threshold && valid_odds) || forceBet){

                //Meets the threshold, decide how many units to bet
                var units_wagered = 1
                if(market === "2way"){
                  if(odds[favored_market].odds > odds[unfavored_market].odds){
                    //Signals are pointing to underdog on the moneyline, check the strategy for betting on underdogs
                    units_wagered = units_on_upset || 1

                    //If the strategy includes spread_on_upset:true, force the spread for the underdog to also be bet on
                    if(spread_on_upset){
                      force_spread_for_underdog = true
                      force_spread_index = favored_market
                    }
                  }
                }

                //Create the bet
                var bet = {
                  //Match data
                  id:match.id || match.docId || null,
                  competitors:[
                    {id:match.competitors[0].id, name:match.competitors[0].name, country:match.competitors[0].country},
                    {id:match.competitors[1].id, name:match.competitors[1].name, country:match.competitors[1].country},
                  ],
                  tournament_name:match.tournament_name || null,
                  start_time_timestamp:match.start_time_timestamp || null,

                  //Bet data
                  market,
                  bet:options[favored_market].id || null,
                  odds:odds[favored_market].odds || null,
                  units_wagered,
                  signals,
                  signals_balance,
                  outcomes,
                  favored_market,
                  total_signals,
                }

                if(forceBet){
                  bet.forced = true
                }

                //Attach seeds
                var seeds = match.seeds || {}
                if(seeds[match.competitors[0].id]){
                  bet.competitors[0].seed = seeds[match.competitors[0].id]
                }
                if(seeds[match.competitors[1].id]){
                  bet.competitors[1].seed = seeds[match.competitors[1].id]
                }

                //Attach the string for the bet placed
                if(market === '2way'){
                  bet.bet_string = match.competitors[favored_market].name
                }else if(market === 'spread'){
                  bet.bet_string = match.competitors[favored_market].name
                  bet.spread = odds[favored_market].spread
                }else if(market === 'total'){
                  bet.bet_string = favored_market === 0 ? 'Over' : 'Under'
                  bet.total = odds[favored_market].total
                }


                //Calc the games for each player
                var games_player = 0 //The player you're betting with (for spread)
                var games_opponent = 0

                var periods = match.period_scores || []

                for (let i = 0; i < periods.length; i++) {
                  var period = periods[i]
                  var player_key = favored_market === 0 ? 'home_score' : 'away_score'
                  var opponent_key = favored_market === 1 ? 'home_score' : 'away_score'

                  //Increment the scores
                  games_player = games_player + period[player_key] || 0
                  games_opponent = games_opponent + period[opponent_key] || 0
                }

                var games_total = games_player + games_opponent


                //Calculate the outcome of the bet
                var correct = null
                var units_won = null

                if(market === '2way'){
                  correct = match.winner_id === match.competitors[favored_market].id
                }else if(market === 'spread'){
                  var actual_spread = (games_opponent - games_player)
                  correct = actual_spread < bet.spread
                }else if(market === 'total'){
                  correct = bet.bet_string === 'Over' ? games_total > Math.abs(bet.total) : games_total < Math.abs(bet.total)
                }

                //Calc the units gained or lost
                if(correct){
                  units_won = (bet.odds*units_wagered) - units_wagered //Calc the profit
                }else{
                  units_won = -1 * units_wagered //Lose whatever you wagered
                }



                //Add to the bets
                bet.correct = correct
                bet.units_won = units_won
                bet.date = date

                // console.log(match.id, market, units_won)

                bets.push(bet)
              }
            }
          }

          return
        });
      }))
    }

    return bets
  }

  systems_getMatches = async (days) => {

    const time_limit = (Date.now() - (days * 24 * 60 * 60 * 1000)).toString() //Go back 2 days

    var matches = []

    await firebase.firestore()
    .collection('matches_sr')
    .where('start_time_timestamp', '>=', time_limit)
    .orderBy('start_time_timestamp', 'desc')
    .limit(200)
    .get()
    .then(querySnapshot =>{
      console.log(querySnapshot.size,"matches found")
      return querySnapshot.forEach(doc => {
        if(doc.data()){
          if(doc.data().odds && doc.data().court_name){
            if(doc.data().odds['2way']){
              var match = doc.data()

              var { id, competitors, period_scores, tournament_name, status, odds, winner_id, seeds, gender, category, type, start_time_timestamp, timezone, round } = match

              matches.push({
                id,
                analysis:{},
                docId:doc.id,
                competitors,
                period_scores,
                tournament_name,
                status,
                odds,
                winner_id,
                seeds,
                gender,
                category,
                type,
                start_time_timestamp,
                timezone,
                round,
              })
            }
          }
        }
      })

      // return
    })

    console.log("Matches in array", matches.length)
    console.log('Fetching analyses')

    //For each match, get their analyses
    var matches_per_second = 1000
    var throttle = require('promise-ratelimit')((1/matches_per_second)*1000)
    await Promise.all(matches.map(async (match, index) => {
      await throttle().then(async () => {
        // console.log('Getting analysis for', match.docId, index+1)
        return firebase.firestore()
        .collection('matches_sr').doc(match.docId)
        .collection('analysis').doc("signals")
        .get()
        .then(doc =>{
          if(doc.data()){
            // console.log(doc.data())
            matches[index].analysis[doc.id] = doc.data()
          }
          return
        })
        .catch(e=>console.log(e))
      })

    }))

    console.log('All analyses fetched')

    return matches
  }

  //
  getMatches = async (days, match_limit, status, time_limit, return_all) => {
    const time_start = Date.now()

    var matches = []
    if(!time_limit){
      time_limit = (Date.now() - (days) * 24 * 60 * 60 * 1000).toString()
    }

    //For the time period, get the matches and their analyses
    //Get tippers data
    await firebase.firestore()
    .collection('matches_sr')
    .where('type', '==', 'singles')
    .where('status', '==', status || 'closed')
    .where('start_time_timestamp', '>=', time_limit)
    .limit(match_limit || 9000)
    .get()
    .then(querySnapshot =>{
      console.log(querySnapshot.size,"matches found")
      return querySnapshot.forEach(doc => {
        if(doc.data()){
          if((doc.data().winner_id || return_all) && doc.data().odds && doc.data().court_name){
            if(doc.data().odds['2way']){
              var match = doc.data()

              var { competitors, period_scores, tournament_name, status, odds, winner_id, seeds, gender, category, type, start_time_timestamp, timezone, round } = match

              matches.push({
                analysis:{},
                docId:doc.id,
                competitors,
                period_scores,
                tournament_name,
                status,
                odds,
                winner_id,
                seeds,
                gender,
                category,
                type,
                start_time_timestamp,
                timezone,
                round,
              })
            }
          }
        }
      })

      // return
    })

    console.log("Matches in array", matches.length)
    console.log('Fetching analyses')

    //For each match, get their analyses
    var matches_per_second = 1000
    var throttle = require('promise-ratelimit')((1/matches_per_second)*1000)
    await Promise.all(matches.map(async (match, index) => {
      await throttle().then(async () => {
        console.log('Getting analysis for', match.docId, index+1)
        return firebase.firestore()
        .collection('matches_sr').doc(match.docId)
        .collection('analysis').doc("signals")
        .get()
        .then(doc =>{
          if(doc.data()){
            // console.log(doc.data())
            matches[index].analysis[doc.id] = doc.data()
          }
          return
        })
        .catch(e=>console.log(e))
      })

    }))

    console.log('All analyses fetched')

    var duration = Math.round((Date.now() - time_start)/1000 * 10)/10
    var duration_est = duration / days * 30
    // console.log('Done in '+duration+' seconds')
    // console.log('Would take est. '+duration_est+' seconds for 30d')

    return matches
  }


  //AI Mechanism
  ai_run = async () => {
    //Get the strategy
    var strategy = {}

    await firebase.firestore()
    .collection('betting').doc('systems')
    .collection('strategies').doc('Xj4ohm4KYuc6iYrC9tJv-spreadOnUpset')
    .get()
    .then(doc => {
      strategy = doc.data().strategy
      return
    })

    // strategy.min_matches_in_signal = 1
    // strategy.minimum_signals = 1
    strategy.signal_balance_threshold = 0
    strategy.unit_ratio_threshold = 0

    // strategy.days = null


    //Run test date of today
    var date = Number(moment().format('x'))
    await this.ai_setupDate({date, strategy, matches_all})



    //Then what I want to do, is go back 14 days and run the strategy each day using the ai setup to calculate what the modified strategy would have been and would it have increased
  }

  ai_setupDate = async ({date, strategy, matches_all}) => {
    //Get the past 30d of matches for that date
    var matches = []

    const time_lower = Number(moment(date, 'x').subtract(30, 'days').format('x'))

    await Promise.all(matches_all.map(match => {
      const match_start = Number(match.start_time_timestamp || 0)
      if(match_start > time_lower && match.winner_id){
        //Include match
        matches.push(match)
      }
    }))

    console.log(matches.length, 'viable matches to use')

    matches = matches.slice(0,250)

    const date_data = {
      matches,
      date
    }

    console.log('Generating results')

    var results = await this.systems_runStrategy(strategy, date_data)

    const { bets, outcome } = results

    console.log(bets.length, 'bets placed')
    console.log(outcome, 'baseline outcome')

    //Separate into correct and wrong
    var outcomes = {
      signals:{
        correct:[],
        wrong:[],
      },
      bets:{
        correct:[],
        wrong:[],
      }
    }

    await Promise.all(bets.map(async bet => {
      const bet_key = bet.correct ? 'correct' : 'wrong'

      var outcome_bet = bet.outcomes[bet.favored_market]
      outcome_bet.signals_balance = bet.signals_balance
      outcome_bet.total_signals = bet.total_signals

      //Put bet into array
      outcomes.bets[bet_key].push(outcome_bet)

      //Put signals into array
      await Promise.all(bet.signals[bet.favored_market].map(signal => {
        outcomes.signals[bet_key].push(signal)
      }))
    }))

    var stats = {
      bets:{},
      signals:{},
    }

    //For the winning and losing bets, calculate the mean, median and spread for the different ways of cutting the data
    console.log(outcomes)

    //Bet stats
    var bet_keys = ['unit_ratio', 'total', 'total_signals', 'signals_balance']
    await Promise.all(bet_keys.map(async bet_key => {
      stats.bets[bet_key] = await this.ai_analyseStats(outcomes.bets, bet_key)
    }))

    // var signal_keys = ['unit_ratio', 'signals_balance']
    // await Promise.all(signal_keys.map(async signal_key => {
    //   stats.signals[signal_key] = await this.ai_analyseStats(outcomes.signals, signal_key)
    // }))

    console.log(stats)

    const iterations = 10
    const best_strategy = await this.ai_iterateStrategy(strategy, outcome, stats, date_data, 1, iterations)

    console.log('Initial strategy', strategy)
    console.log('Initial outcome', outcome)

    console.log('Best strategy', best_strategy.strategy)
    console.log('Best outcome', best_strategy.outcome)

    //Based on that strategy, create another set of values to finetune


    //Ideally we have function that says iterate X times to find best strategy



    //Propose the strategy for that day - maybe two versions, conservative and normal, conservative with less tolerance for losses


    //Calculate the bets with that strategy for that day


  }

  ai_iterateStrategy = async (strategy, outcome, stats, date_data, index, maxIterations) => {
    console.log('Iteration number', index)
    const confidence_threshold = 95 //% confidence

    var signals_balance = [strategy.signal_balance_threshold]
    var unit_ratio = [strategy.unit_ratio_threshold]

    let signals_balance_stats, unit_ratio_stats

    if(stats.bets.signals_balance.confidence > confidence_threshold){
      const signals_balance_array_info = await this.ai_createArrayTest(stats.bets.signals_balance)
      signals_balance = signals_balance_array_info.array
      signals_balance_stats = signals_balance_array_info.stats
    }
    if(stats.bets.unit_ratio.confidence > confidence_threshold){
      const  unit_ratio_array_info = await this.ai_createArrayTest(stats.bets.unit_ratio)
      unit_ratio = unit_ratio_array_info.array
      unit_ratio_stats = unit_ratio_array_info.stats
    }

    //Create the strategies
    var strategies = []

    await Promise.all(signals_balance.map(async signals_balance_strat => {
      await Promise.all(unit_ratio.map(async unit_ratio_strat => {
        var new_strat = JSON.parse(JSON.stringify(strategy))
        new_strat.signal_balance_threshold = signals_balance_strat
        new_strat.unit_ratio_threshold = unit_ratio_strat
        // new_strat.units = units_strat
        strategies.push(new_strat)
      }))
    }))

    console.log(strategies.length, 'strategies', strategies)

    var best_strat = JSON.parse(JSON.stringify(strategy))
    var best_outcome = JSON.parse(JSON.stringify(outcome))

    //For each value, test a strategy with that value and if the units_won is higher then update it
    var throttle = require('promise-ratelimit')(1000)
    await Promise.all(strategies.map(async test_strategy => {
      await throttle().then(async () => {
        // console.log('Testing strategy', test_strategy)
        const test_results = await this.systems_runStrategy(test_strategy, date_data)
        const test_outcome = test_results.outcome || {}

        // console.log(test_strategy, test_outcome)
        console.log(test_outcome.units_won, best_outcome.units_won, test_outcome.units_won > best_outcome.units_won)

        if(test_outcome.units_won > best_outcome.units_won){
          //Better strategy found
          best_strat = JSON.parse(JSON.stringify(test_strategy))
          best_outcome = JSON.parse(JSON.stringify(test_outcome))
        }

        return
      })
    }))

    //Calculate the stats for the next iteration
    var next_stats = JSON.parse(JSON.stringify(stats))

    next_stats.bets.signals_balance.correct.min = best_strat.signal_balance_threshold - signals_balance_stats.gap
    next_stats.bets.signals_balance.correct.max = best_strat.signal_balance_threshold + signals_balance_stats.gap
    next_stats.bets.signals_balance.correct.mean = best_strat.signal_balance_threshold

    next_stats.bets.unit_ratio.correct.min = best_strat.unit_ratio_threshold - unit_ratio_stats.gap
    next_stats.bets.unit_ratio.correct.max = best_strat.unit_ratio_threshold + unit_ratio_stats.gap
    next_stats.bets.unit_ratio.correct.mean = best_strat.unit_ratio_threshold

    var iteration_outcome = {
      strategy:best_strat,
      outcome:best_outcome,
      next_stats
    }

    console.log('Iteration outcome', iteration_outcome)


    //If the iteration has improved, run again
    if(best_outcome.units_won > outcome.units_won && index < maxIterations){
      console.log('Outcome has improved, run again')
      iteration_outcome = await this.ai_iterateStrategy(iteration_outcome.strategy, iteration_outcome.outcome, iteration_outcome.next_stats, date_data, index+1, maxIterations)
    }else{
      console.log("Outcome hasn't improved, finish.")
    }

    return iteration_outcome
  }

  ai_createArrayTest = async (data) => {
    const min = data.correct.min
    const max = data.correct.max

    var values = []

    const num_tests = 4
    const iteration = await this.ai_arrayIterationGap(data, num_tests)

    for (let i = 0; i < num_tests; i++) {
      values.push(min + (i * iteration))
    }

    return {
      array:values,
      stats:{
        gap:iteration
      }
    }
  }

  ai_arrayIterationGap = (data, num_tests) => {
    const min = data.correct.min
    const max = data.correct.max

    const iteration = (max-min) / (num_tests-1)

    return iteration
  }

  ai_analyseStats = async (data, key) => {
    //Create the data arrays for each side of the signal
    var samples = {
      correct:[],
      wrong:[],
    }

    var stats = {
      correct:{},
      wrong:{},
    }

    //Promise through the data and isolate the values for that key
    await Promise.all(data.correct.map(item => {
      if(item[key]){
        samples.correct.push(item[key])
      }
    }))

    await Promise.all(data.wrong.map(item => {
      if(item[key]){
        samples.wrong.push(item[key])
      }
    }))


    //Calc stats
    stats.correct.min = await ss.min(samples.correct)
    stats.correct.max = await ss.max(samples.correct)
    stats.correct.mean = await ss.mean(samples.correct)
    // stats.correct.mode = await ss.mode(samples.correct)
    // stats.correct.median = await ss.median(samples.correct)
    stats.correct.variance = await ss.variance(samples.correct)
    stats.correct.sd = await ss.standardDeviation(samples.correct)
    stats.correct.n = samples.correct.length

    stats.wrong.min = await ss.min(samples.wrong)
    stats.wrong.max = await ss.max(samples.wrong)
    stats.wrong.mean = await ss.mean(samples.wrong)
    // stats.wrong.mode = await ss.mode(samples.wrong)
    // stats.wrong.median = await ss.median(samples.wrong)
    stats.wrong.variance = await ss.variance(samples.wrong)
    stats.wrong.sd = await ss.standardDeviation(samples.wrong)
    stats.wrong.n = samples.wrong.length

    //Analyse if the difference between each side is significant
    stats.confidence = await this.stats_tTest(samples)

    // console.log(stats)

    return stats
  }

  stats_tTest = (data) => {
    // // const confidence = 95 //%
    // // const tails = 1
    // // const t_threshold = (1-((confidence/100)*tails))
    //
    // //Calc whether wrong values are stat sig different to correct
    // //Welch's t-test.
    // const t_score = (data.correct.mean - data.wrong.mean) / Math.sqrt((data.correct.variance/data.correct.n)+(data.wrong.variance/data.wrong.n))
    // const confidence = Math.round((1 - t_score) * 100 * 100) / 100
    //
    // console.log(t_score)

    // const t_test = ss.tTestTwoSample(data.correct, data.wrong, 0)

    const p_test = ss.permutationTest(data.correct, data.wrong)
    const confidence = Math.round((1 - p_test)* 100 * 100)/100

    return confidence
  }

  calculateSignificance = async (z_score_raw) => {
    var confidence = null
    var z_score = Math.abs(z_score_raw)

    if(z_score >= 2.576){ confidence = 99 }
    else if(z_score >= 1.960){ confidence = 95 }
    else if(z_score >= 1.645){ confidence = 90 }
    else if(z_score >= 1.440){ confidence = 85 }
    else if(z_score >= 1.282){ confidence = 80 }
    else if(z_score >= 1.036){ confidence = 70 }
    else if(z_score >= 0.842){ confidence = 60 }
    else if(z_score >= 0.674){ confidence = 50 }
    else if(z_score >= 0.524){ confidence = 40 }

    return confidence
  }


  //Renders
  render = () => {

    return(
      <View style={{flex:1, width:'100%', height:'100%', backgroundColor:'#121212'}}>
        <Text style={{color:'white'}}>

        </Text>
      </View>
    )
  }
}

export default Landing;
