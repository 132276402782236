import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';

import { View, Text, Dimensions, Image } from "react-native";
import { NewsTicker } from "react-announcement-ticker";
import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Components
import GtlScoreboard from './streamingtools/gtl-scoreboard.js'
import GtlScoreboardAlt from './streamingtools/gtl-scoreboard-alt.js'
import GtlScoreboardGS from './streamingtools/gtl-scoreboard-gs.js'
import Tippers from './streamingtools/tippers.js'
import GtlFantasy from './streamingtools/gtl-brackets.js'
import GtlSounds from './streamingtools/gtl-sounds.js'
import GtlFantasyLeaderboard from './streamingtools/gtl-leaderboards.js'

import SliceScoreboard from './streamingtools/slice-scoreboard.js'

import TTScoreboard from './streamingtools/tt-scoreboard.js'

import CamTicker from './streamingtools/cam-scoresticker.js'
import CamVote from './streamingtools/cam-livechatvote.js'
import CamStats from './streamingtools/cam-stats.js'
import CamSelector from './streamingtools/cam-selector.js'
import CamScoreboard from './streamingtools/cam-scoreboard.js'
import CamDataHub from './streamingtools/cam-datahub.js'
import CamPredictor from './streamingtools/cam-predictor.js'
import CamControls from './streamingtools/cam-controls.js'

import TBSelector from './streamingtools/tb-selector'
import TBSelectorOther from './streamingtools/tb-selector-other'
import TBControls from './streamingtools/tb-controls'
import TBRankings from './streamingtools/tb-rankings'
import TBStats from './streamingtools/tb-stats'
import TBVote from './streamingtools/tb-livevote'
import TBSubs from './streamingtools/tb-subs'
import TBScoreboard from './streamingtools/tb-scoreboard'
import TBKeyMatch from './streamingtools/tb-keymatch'

import THSelector from './streamingtools/th-selector'
import THControls from './streamingtools/th-controls'
import THStats from './streamingtools/th-stats'
import THScoreboard from './streamingtools/th-scoreboard'

import TNNSFunctions from './streamingtools/tnns-functions'
import TNNSYouTube from './streamingtools/tnns-youtube.js'
import TNNSChat from './streamingtools/tnns-chat.js'
import TNNSSelector from './streamingtools/tnns-selector'

import Popularity from './streamingtools/popularity'
import LiveScorePanel from './streamingtools/livescorepanel'

import THScores from './streamingtools/tennishead-scores'

import Creatorly from './streamingtools/creatorly'
import CasaAzul from './streamingtools/casaazul'

//Module
import ReactGA from 'react-ga';

//Helpers
import MainStyles from './mainstyles.js'
import GLOBAL from './global.js'

var CircularJSON = require('circular-json')

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.match.params.user,
      toolId: this.props.match.params.toolId,
      loading: true,
      activeIndex:0,
      matches: [],
      matches_ticker: [],
      matches_ticker_divs: [],
      changingMatches:true,
    };
  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
      authDomain: "tennis-lwts.firebaseapp.com",
      projectId: "tennis-lwts",
      storageBucket: "tennis-lwts.appspot.com",
      messagingSenderId: "414782898270",
      appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
      measurementId: "G-D3SD6S9JJW"
    };

    await app.initializeApp(config);

    //Anon auth
    await firebase.auth().signInAnonymously()
    .then(() => {
      // Signed in..
      console.log('signed in')
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  componentDidMount = async () => {
    var { user, toolId } = this.props.match.params

    //Setup firebase
    await this.initializeFirebase()
    await this.setState({loading:false})
  }

  componentWillUnmount = async () => {
    clearTimeout(this.updateData)
    clearTimeout(this.updateView)
  }

  remountTool = async () => {
    await this.setState({loading:true})

    setTimeout(async()=>{
      await this.setState({loading:false})
    }, 1000)
  }


  //Renders
  renderTool = () => {
    var { toolId, user } = this.state
    var render = null

    var id_theslice = "bpBfexOhIVeZ6J4GvseB"
    var id_gtl = "yBhmZeRfAZ7rzm8jemwX"
    var id_tt = "qWqnHgJg9kBnlZEN4aUd"

    if(user === "camwilliams-ft8asd9" && toolId === "livescoreticker-kj28siud"){ render = <CamTicker/>}
    if(user === "camwilliams-ft8asd9" && toolId === "livechatvote-mn92kda8d"){ render = <CamVote/>}
    if(user === "camwilliams-ft8asd9" && toolId === "stats-al29skd10"){ render = <CamStats/>}
    if(user === "camwilliams-ft8asd9" && toolId === "scoreboard"){ render = <CamScoreboard/>}
    if(user === "camwilliams-ft8asd9" && toolId === "selector"){ render = <CamSelector/>}
    if(user === "camwilliams-ft8asd9" && toolId === "data"){ render = <CamDataHub/>}
    if(user === "camwilliams-ft8asd9" && toolId === "predictor"){ render = <CamPredictor/>}
    if(user === "camwilliams-ft8asd9" && toolId === "controls"){ render = <CamControls/>}

    if(user === "gametolove-las82kd" && toolId === "livescoreboard-kasid87w3b"){ render = <GtlScoreboard/> }
    if(user === "gametolove-las82kd" && toolId === "livescoreboardalt-al923kdn2jd8"){ render = <GtlScoreboardAlt/> }
    if(user === "gametolove-las82kd" && toolId === "livescoreboardgs-01njd82nad2k"){ render = <GtlScoreboardGS/> }
    if(user === "gametolove-las82kd" && toolId === "tippers"){render = <Tippers id={id_gtl}/>}
    if(user === "gametolove-las82kd" && toolId === "brackets"){render = <GtlFantasy/>}
    if(user === "gametolove-las82kd" && toolId === "leaderboard"){render = <GtlFantasyLeaderboard/>}
    if(user === "gametolove-las82kd" && toolId === "sounds"){render = <GtlSounds id={id_gtl}/>}

    if(user === "tnns-a92ksd3" && toolId === "youtube-ls9d2dkd20di0"){render = <TNNSYouTube/>}
    if(user === "tnns-a92ksd3" && toolId === "chat"){render = <TNNSChat remount={this.remountTool}/>}
    if(user === "tnns-a92ksd3" && toolId === "selector"){render = <TNNSSelector/>}
    if(user === "tnns-a92ksd3" && toolId === "functions"){render = <TNNSFunctions/>}

    if(user === "tennishead-lad92mks" && toolId === "scores"){render  = <THScores/>}

    if(user === "theslicetennis-a02ks9" && toolId == 'livescoreboard'){render = <SliceScoreboard id={id_theslice}/>}
    if(user === "theslicetennis-a02ks9" && toolId == 'tippers'){render = <Tippers id={id_theslice}/>}

    if(user === "talktennis-a02ks9" && toolId == 'livescoreboard'){render = <TTScoreboard id={id_tt}/>}

    if(user === "tennisbrothers-lad9s20ejds" && toolId === "selector"){render = <TBSelector remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "selector-other"){render = <TBSelectorOther remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "controls"){render = <TBControls remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "rankings"){render = <TBRankings remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "match-stats"){render = <TBStats remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "scoreboard"){render = <TBScoreboard remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "keymatch"){render = <TBKeyMatch remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "vote"){render = <TBVote remount={this.remountTool}/>}
    if(user === "tennisbrothers-lad9s20ejds" && toolId === "subs"){render = <TBSubs remount={this.remountTool}/>}

    if(user === "tweenerhead-ka92nad652" && toolId === "selector"){render = <THSelector remount={this.remountTool}/>}
    if(user === "tweenerhead-ka92nad652" && toolId === "controls"){render = <THControls remount={this.remountTool}/>}
    if(user === "tweenerhead-ka92nad652" && toolId === "match-stats"){render = <THStats remount={this.remountTool}/>}
    if(user === "tweenerhead-ka92nad652" && toolId === "scoreboard"){render = <THScoreboard remount={this.remountTool}/>}

    if(toolId === 'popularity'){render = <Popularity/>}
    if(toolId === 'livescorepanel'){render = <LiveScorePanel/>}
    if(toolId === 'creatorly'){render = <Creatorly/>}
    if(toolId === 'casaazul'){render = <CasaAzul/>}
    return render
  }

  render() {
    var { loading } = this.state
    return(
      <View style={{flex:1, width:'100%', height:'100%', backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
      {loading ? null : this.renderTool()}
      </View>
    )
  }
}

export default Landing;
