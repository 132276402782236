import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import badge from './appstorebadgedark.png';

//Components
import Poll from './items/poll.js'
import ResultSticker from './items/resultsticker.js'
import MainButton from './items/mainbutton.js'

//Modules
import ReactDOM from "react-dom";
import { AnimateOnChange } from 'react-animation'

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";

const AMPLITUDE_KEY = "ff24f21d0121991161cc8dacb5a08f5f";


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


class Page extends Component {

  constructor(props) {
    super(props);
    this.state = { height: 512, loading:true, loading1:true, image:'', type:'' };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount(){

    var images = [
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/iphonemockup2.png?alt=media&token=44104bdb-fc2c-40ca-b5d7-3b3a91c8dcca',
        type: 'Shows voting action'
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/iphonemockup.png?alt=media&token=11bcb116-a8f8-4226-9f83-badb85490cb1',
        type: 'Simple mockup'
      }
    ]

    const randomImage = images[Math.floor(Math.random() * images.length)];

    var image = randomImage.image;
    var type = randomImage.type;

    await this.setState({image, type})


    setTimeout(() => {  this.setState({loading1:false}) }, 100);
    setTimeout(() => {  this.setState({loading:false}) }, 4000);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  goToiTunes(){

  }


  renderSticker(){
    var firstName = this.props.match.params.firstName;
    var schoolName = this.props.match.params.schoolName.replace('-', ' ').replace('-', ' ').replace('-', ' ').replace('-', ' ').replace('-', ' ').replace('-', ' ');
    var schoolCount = this.props.match.params.schoolCount;

    var url = 'https://itunes.apple.com/app/id1488092059'

    var inviteString;

    var studentCount = ((schoolCount - 1) > 9) ? (schoolCount - 1) : ''



    if(this.state.loading){
      if(this.state.loading1){
        return(null)
      }else{
        return(
          <Cell col={12} className="vertical">
            <ResultSticker
            line1={firstName + ' from ' + schoolName}
            line2='invited you to 4PM!'
            />
            <p className="invite-subtitle">
            loading your invite...
            </p>
          </Cell>
        )
      }
    }else{
      return(
        <Cell col={12} className="vertical">
        <div>
        <div style={{flexDirection:'column'}}>
          <Amplitude>
            {({ logEvent }) =>
              <a href={url} onClick={()=>logEvent('Invite - Go to app store', {schoolName:this.props.match.params.schoolName, clickSpot:'iPhone mockup', imageType:this.state.type})}>
              <img src={this.state.image} alt="Poll image" style={{height:this.state.height/1.5, aspectRatio:1.8, zIndex:1}}/>
              </a>
            }
          </Amplitude>

          <p style={{position:'relative', zIndex:2, bottom:this.state.height/1.85, fontFamily:'Averta-Black', fontSize:this.state.height/80, textTransform:'uppercase', color:'white'}}>
          {schoolName}
          </p>

        </div>


          <p className="invite-subtitle" style={{marginTop:-10}}>
          {firstName} + {studentCount} other friends are on 4PM at {schoolName}
          </p>

          <div style={{marginTop:-60}}>
          <Amplitude>
            {({ logEvent }) =>
              <MainButton
              title='see your invite'
              onClick={url}
              logEvent={()=>logEvent('Invite - Go to app store', {schoolName:this.props.match.params.schoolName, clickSpot:'Button', imageType:this.state.type})}
              />
            }
          </Amplitude>
          </div>

          </div>

        </Cell>
      )
    }
  }

  renderAmplitudeEvent(){
    if(!this.state.loading1){
      return(
        <LogOnMount
        eventType="Invite - Web view"
        eventProperties={{schoolName:this.props.match.params.schoolName, imageType:this.state.type}}
        />
      )
    }
  }

  render() {

    return(
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={AMPLITUDE_KEY}
      >
      {this.renderAmplitudeEvent()}
      <div className="height">

        <Grid className="background-main flexCenter">

        <AnimateOnChange
        className="vertical flexCenter width-100"
        animationIn="bounceIn"
        animationOut="bounceOut"
        durationIn={750}
        durationOut={750}
        >

          {this.renderSticker()}

        </AnimateOnChange>

        </Grid>

      </div>
      </AmplitudeProvider>
    )
  }
}

export default Page;
