import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

import NewsTicker, { Directions, TickerStates } from "react-advanced-news-ticker";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, Dimensions, Switch, Animated, Easing } from "react-native";
import Ticker from 'react-ticker'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 100

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var match_width = 260
var width = 1400

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},
      loading: true,
      activeIndex:0,
      matches: [],
      matches_ticker: [],
      matches_ticker_divs: [],
      changingMatches:true,

      score_opacity: new Animated.Value(1),
      position_left: new Animated.Value(width - 100),

      //Show settings
      show_atp:true,
      show_wta:true,
      show_live:true,
      show_completed:true,
      show_singles:true,
      show_doubles:false,

      firstSet:true,

      tournaments:{},
    };
  }

  componentDidMount = async () => {
    // await this.getTournaments()
    await this.setup_rankings()
  }

  componentWillUnmount = async () => {

  }

  //Functions
  setup_rankings = async () => {
    var { show_atp, show_wta, show_live, show_completed, show_singles, show_doubles } = this.state

    clearTimeout(this.updateData)

    var refreshTime = 20 * 1000 //20s refresh time

    //Arrays
    var rankings = []
    var modes = [
      {id:'1', title:'ATP Singles', active:true, mode:'atp', type:'official', format:'singles', rankWeight:10},
      {id:'2', title:'ATP Singles Race to Turin', active:true, mode:'atp', type:'race', format:'singles', rankWeight:30},
      {id:'3', title:'WTA Singles', active:true, mode:'wta', type:'official', format:'singles', rankWeight:50},
      {id:'4', title:'WTA Singles Race to Shenzhen', active:true, mode:'wta', type:'race', format:'singles', rankWeight:70},
    ]

    await Promise.all(modes.map(async (mode, mode_index) => {

      await firebase.firestore()
      .collection('rankings_sr')
      .where("tour", '==', mode.mode)
      .where("format", '==', mode.format)
      .where("type", '==', mode.type)
      .where("year", '==', 2021)
      .orderBy('week', 'desc')
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        await querySnapshot.forEach(async (doc) => {
          var rankingsRaw = doc.data()

          //Get the top 10
          var top10 = rankingsRaw.competitor_rankings.splice(0,10)

          rankings.push({isTitle:true, title:mode.title, index:mode_index})

          //Promise through all players
          await Promise.all(top10.map(async player => {
            var playerObj = player.competitor

            playerObj.sortRank = player.rank + mode.rankWeight
            playerObj.title = mode.title
            playerObj.rank = player.rank
            playerObj.points = player.points

            if(player.movement === 0){
              playerObj.movement = "-"
              playerObj.movement_color = 'white'
            }
            if(player.movement > 0){
              playerObj.movement = "+"+player.movement
              playerObj.movement_color = GLOBAL.colors.teal
            }
            if(player.movement < 0){
              playerObj.movement = player.movement
              playerObj.movement_color = GLOBAL.colors.pink
            }

            try{
              playerObj.name = playerObj.name.split(", ")[1] + " " + playerObj.name.split(", ")[0]
            }catch(e){
              console.log(e)
            }

            //Name corrections
            var name_corrections = {
              ['Bianca Vanessa Andreescu']:"Bianca Andreescu"
            }

            playerObj.name = name_corrections[playerObj.name] || playerObj.name

            //Country code
            var country_corrections = {
              ["GRE"]:'GRC',
              ["GER"]:'DEU',
              ["LAT"]:'LVA',
              ["CRO"]:'HRV',
              ["SUI"]:'CHE',
            }
            var countries_fix = Object.keys(country_corrections)

            if(countries_fix.includes(playerObj.country_code)){
              playerObj.country_code = country_corrections[playerObj.country_code]
            }

            //Country code
            var code_1 = await convertIso3Code(playerObj.country_code)
            playerObj.country_cca = code_1 ? code_1.iso2 : null

            rankings.push(playerObj)
          }))
        })
      })

    }))

    //Set the width of cards and cards per rotation
    var matches_per_render = 1
    await this.setState({loading:false, matches:rankings, matches_per_render, modes})

    if(this.state.firstSet){
      await this.setState({firstSet:false})
      // this.changeToNext()
      // this.startAnimation()
    }
  }

  onLayout = async ({nativeEvent}) => {
    console.log('Layout set', nativeEvent)
    try{
      var bar_width = nativeEvent.layout.width
      if(bar_width > 0){
        //Data is set
        await this.setState({bar_width})

        //Timers to change the
      }
    }catch(e){
      console.log('Error with width', e)
    }
  }

  onLayoutTitle = async (nativeEvent, title, index) => {
    console.log('Layout title', nativeEvent, title)

    //Set the position for that title
    await this.setState({['ranking_title_'+index]:nativeEvent.left - (nativeEvent.width * 3)})

    if(this.state.bar_width && index === 3){
      await this.setState({runningAnimation:true})
      this.startAnimation()
    }
  }

  startAnimation = async () => {
    var { bar_width, modes } = this.state

    // var duration = width / 1200 * 10 * 1000 //10
    var duration = 320 * (bar_width / 21873) * 1000

    //Get the durations to toggle each title
    var t0 = this.state.ranking_title_0 / bar_width * duration
    var t1 = this.state.ranking_title_1 / bar_width * duration
    var t2 = this.state.ranking_title_2 / bar_width * duration
    var t3 = this.state.ranking_title_3 / bar_width * duration

    //Start the animation
    await Animated.timing(this.state.position_left, {
      toValue: -1 * bar_width,
      duration: duration,
      easing: Easing.linear,
    }).start(this.nextAnimation);

    console.log(t0, t1, t2, t3)

    setTimeout(async()=>{
      this.setState({active_title:modes[0].title})
    },t0)

    setTimeout(async()=>{
      this.setState({active_title:modes[1].title})
    },t1)

    setTimeout(async()=>{
      this.setState({active_title:modes[2].title})
    },t2)

    setTimeout(async()=>{
      this.setState({active_title:modes[3].title})
    },t3)

  }

  nextAnimation = async () => {
    console.log('Next animation')
    await Animated.timing(this.state.position_left, {
      toValue: width,
      duration: 1,
    }).start(this.startAnimation);
  }

  changeToNext = async () => {
    clearTimeout(this.updateView)

    var { matches_per_render, activeIndex, matches } = this.state

    var nextIndex = activeIndex + 1 //starting index of next render

    await this.setState({changingMatches:true})

    var updateTime = matches_per_render * 8000 //8s per 4
    var animation_duration = 350

    this.updateOpacity = setTimeout(async () => {
      await Animated.timing(this.state.score_opacity, {
        toValue: 0,
        duration: animation_duration,
      }).start();
    }, updateTime - animation_duration)

    this.updateOpacity2 = setTimeout(async()=>{
      // Fade In new text
      await Animated.timing(this.state.score_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, updateTime)

    this.updateView = setTimeout(async()=>{
      // setTimeout(async () => {
        var changingIndex = nextIndex < (matches.length) ? nextIndex : 0
        await this.setState({activeIndex:changingIndex, changingMatches:false})
      // }, animation_duration)

      this.changeToNext()
    }, updateTime)
  }

  //Render Matches
  render_ticker = () => {
    var { matches, matches_per_render, activeIndex, matches_ticker, matches_ticker_divs, active_title } = this.state
    var sliceIndex = ((matches_per_render + activeIndex) > (matches.length - 1) ? (matches.length - 1) : (matches_per_render + activeIndex))
    var render_data = matches[activeIndex] ? matches[activeIndex] : null

    var img_height = 60
    var width = img_height / 94 * 450

    var grey = "#E4E4EC"
    var yellow = '#379DFE'
    var blue_1 = "#0080FF"

    var blue_2 = '#225DC7'

    return(
      <View style={[{width:'100%', height:img_height-15, overflow:'hidden', backgroundColor:yellow}, MainStyles.flexCenter, MainStyles.flexColumn]}>

        <View style={[{width:'100%', backgroundColor:yellow, marginBottom:0}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
          <View style={[{height:img_height, backgroundColor:blue_1, padding:10, paddingVertical:0, zIndex:200}, MainStyles.flexCenter]}>
            <Animated.Text style={{fontSize:18, color:'white', fontFamily:'Averta-Black', width:'100%', textAlign:'center', opacity:1, marginHorizontal:5, textTransform:'uppercase', letterSpacing:0.25}} numberOfLines={1}>
            {active_title || ''}
            </Animated.Text>
          </View>
          <View
          style={{
            zIndex:200,
            marginLeft:-25,
            marginRight:-25,
            transform:[{scaleY:-1}],
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: 25,
            borderRightWidth: 25,
            borderBottomWidth: 50,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: blue_1,
          }}
          />
          {
            // <>
            // <View style={[{maxWidth:300, height:'100%', paddingHorizontal:25, paddingRight:5, backgroundColor:blue_1}, MainStyles.flexCenter]}>
            //   <Animated.Text style={{fontSize:18, color:'white', fontFamily:'Averta-Semibold', width:'100%', textAlign:'center', opacity:1}} numberOfLines={1}>
            //   {render_data ? render_data.title : ''}
            //   </Animated.Text>
            // </View>
            // <View
            // style={{
            //   zIndex:-1,
            //   marginLeft:-25,
            //   transform:[{scaleY:-1}],
            //   width: 0,
            //   height: 0,
            //   backgroundColor: "transparent",
            //   borderStyle: "solid",
            //   borderLeftWidth: 25,
            //   borderRightWidth: 25,
            //   borderBottomWidth: 50,
            //   borderLeftColor: "transparent",
            //   borderRightColor: "transparent",
            //   borderBottomColor: blue_1,
            // }}
            // />
            // </>
          }
          {
            // render_data.map((item, index) => this.render_camLiveScore(item, index))
            <Animated.View onLayout={this.onLayout} style={[{flexGrow:1, height:'100%', opacity:1, position:'absolute', left:this.state.position_left, zIndex:0}, MainStyles.flexRow, MainStyles.flexCenter]}>
            {matches ? matches.map((item, index) => this.render_liveScoreAlt(item, 0)) : null}
            </Animated.View>
          }
          <Image
          style={{height:img_height, width, resizeMode:'cover', marginRight:-1, position:'absolute', right:0}}
          source={require('../../images/poweredbytnns-tb.png')}
          />
        </View>

      </View>
    )
  }

  render_liveScoreAlt = (item, index) => {

    var textStyle = {fontSize:20, color:'white', fontFamily:'Averta-Semibold', textAlign:'left', letterSpacing:-0.25, marginRight:5}
    // try{
      return(
        <View key={item.id} style={[{marginHorizontal:40, height:'100%', borderRightWidth:0, borderRightColor:'rgba(0,0,0,0.12)'}, MainStyles.flexCenter, MainStyles.flexRow]}>
          {item.isTitle ? this.renderTitle(item, textStyle) : this.renderPlayer(item, textStyle)}
        </View>
      )
    // }catch(e){
    //   return null
    // }
  }

  renderTitle = (item, index, textStyle) => {
    var img_height = 60
    var width = img_height / 94 * 450

    var grey = "#E4E4EC"
    var yellow = '#379DFE'
    var blue_1 = "#0080FF"

    var blue_2 = '#225DC7'

    return(
      <View onLayout={({nativeEvent}) => this.onLayoutTitle(nativeEvent.layout, item.title, item.index)} style={[MainStyles.flexRow, MainStyles.flexStartCenter, {flexShrink:1}]}>

        <View style={[{height:img_height, backgroundColor:blue_1, padding:10, paddingVertical:0, zIndex:200}, MainStyles.flexCenter]}>
          <Animated.Text style={{fontSize:18, color:'white', fontFamily:'Averta-Black', width:'100%', textAlign:'center', opacity:1, marginHorizontal:5, textTransform:'uppercase', letterSpacing:0.25}} numberOfLines={1}>
          {item ? item.title : ''}
          </Animated.Text>
        </View>
        <View
        style={{
          zIndex:200,
          marginLeft:-25,
          marginRight:-25,
          transform:[{scaleY:-1}],
          width: 0,
          height: 0,
          backgroundColor: "transparent",
          borderStyle: "solid",
          borderLeftWidth: 25,
          borderRightWidth: 25,
          borderBottomWidth: 50,
          borderLeftColor: "transparent",
          borderRightColor: "transparent",
          borderBottomColor: blue_1,
        }}
        />

      </View>
    )
  }

  renderPlayer = (item, textStyle) => {
    var flag = null
    if(item.country_cca){
      flag = item.country_cca
    }

    var isDoubles = item.name.includes("/")

    if(isDoubles){
      flag = null
    }

    var margin = 30

    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {flexShrink:1}]}>

        <Text style={[textStyle,{opacity:1, fontSize:14, opacity:0.75, marginTop:5, textTransform:'uppercase'}]} numberOfLines={1}>
        Ranking
        </Text>
        <Text style={[textStyle,{opacity:1, marginRight:margin}]} numberOfLines={1}>
        {item.rank}
        </Text>
        <Text style={[textStyle,{opacity:1, marginRight:margin, color:item.movement_color}]} numberOfLines={1}>
        {item.movement}
        </Text>

        {
          !flag ? null :
          <View style={[{width:25, marginRight:5}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <ReactCountryFlag
              countryCode={flag}
              svg
              style={{
                  width: 20,
                  height: 16,
              }}
              title="US"
            />
          </View>
        }

        <Text style={[textStyle,{opacity:1, marginRight:margin}]} numberOfLines={1}>
        {item.name}
        </Text>



        <Text style={[textStyle,{opacity:1}]} numberOfLines={1}>
        {item.points}
        </Text>
        <Text style={[textStyle,{opacity:1, fontSize:14, opacity:0.75, marginTop:5, textTransform:'uppercase'}]} numberOfLines={1}>
        points
        </Text>


      </View>
    )
  }


  render() {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width, height:'100%', backgroundColor:'transparent'}, MainStyles.flexCenter]}>
      {this.render_ticker()}
      </View>
    )
  }
}

export default Item;
