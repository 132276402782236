import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { Hoverable, Pressable, } from 'react-native-web-hover'

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import { View, Text, Linking, Image, ActivityIndicator, Alert, Dimensions, Switch, Animated, FlatList, Picker } from "react-native";

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 100

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const axios = require("axios");
var CircularJSON = require('circular-json')

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var match_width = 260

var padding = 10
var height = 90
var blue = '#36B9F2'

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      x:0,
      tour:'atpwta',
    };
    // this.sectionListRef = React.createRef()
  }

  componentDidMount = async () => {
    // await this.getTournaments()
    // await this.setup_camLiveScores()

    await this.setup()
  }

  componentWillUnmount = async () => {
    clearTimeout(this.updateData)
  }

  //
  setup = async (tour) => {

    var tourRef = 'atp'
    if(tour === 'atp'){tourRef = 'atp'}
    if(tour === 'wta'){tourRef = 'wta'}
    if(tour === 'challenger'){tourRef = 'challenger'}
    if(tour === 'itf'){tourRef = 'itf'}

    //Get completed ATP matches (demo)

    var urls = [
      "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTourLiveMatches?="+tourRef+"?=visual?=Pacific/Auckland?=en",
      "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTourMatches?="+tourRef+"?=completed?=visual?=Pacific/Auckland?=en"
    ]

    var matches_raw = []
    await Promise.all(urls.map(async url => {
      var data = await this.firestoreAPI(url)
      try{ matches_raw = matches_raw.concat(data[0].data || []) }catch(e){console.log(e)}
    }))


    //Promise all matches
    var matches = []

    await Promise.all(matches_raw.map(async match => {
      var matchObj = {
        id:match.id,
        set_scores:match.set_scores,
        strings:match.strings || Array(4).fill(''),
        competitors:match.competitors,
        winner_id:match.winner_id,
        docId:match.docId,
        isLive:!match.winner_id,
      }

      if(matchObj.competitors){
        matches.push(matchObj)
      }

    }))

    await matches.sort((a, b) => (a.isLive < b.isLive) ? 1 : -1);

    await this.setState({matches, loading:false, x:0})
  }

  changeTour = async (tour) => {
    await this.setState({tour, loading:true})
    this.setup(tour)
  }

  firestoreAPI = async (url) => {
    var data = null
    await axios.get(url)
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Error', error)
    });

    return data
  }

  handleLayout = async ({nativeEvent}) => {
    var scores_width = nativeEvent.layout.width
    this.setState({scores_width})
  }

  handleScroll = async ({nativeEvent}) => {
    var x = nativeEvent.contentOffset.x
    this.setState({x})
  }

  pushScroll = async (push) => {
    var jump_x = this.state.scores_width / 1.25
    var x = 0
    if(push){
      x = this.state.x + jump_x
    }else{
      x = this.state.x - jump_x
    }
    this.sectionListRef.scrollToOffset({offset:x})
  }


  //TH Renders
  renderMain = () => {
    var { loading } = this.state
    var color = blue
    return(
      <View style={[{maxWidth:1080, width:'100%', backgroundColor:'white', height, borderWidth:1, borderColor:'rgba(0,0,0,0.08)', borderRadius:0, overflow:'hidden'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {this.renderTourSelector(height-2, color)}
        {this.renderButton(false, this.state.x === 0)}
        {!loading ? this.renderMatches() : <View style={[{flex:1}, MainStyles.flexCenter]}><ActivityIndicator size="small" color={color}/></View>}
        {this.renderButton(true, false)}
        {this.renderFooter(height-2, false)}
      </View>
    )
  }

  renderButton = (right, inactive) => {
    return(
      <Pressable onPress={()=>this.pushScroll(right)} style={[{height:'100%', width:32, backgroundColor:GLOBAL.colors.border, borderColor:'rgba(0,0,0,0.024)', borderWidth:0, borderRightWidth:1, borderLeftWidth:1}, MainStyles.flexCenter]}>
        <Image style={[{height:12, width:12, resizeMode:'contain', tintColor:'black', opacity:inactive ? 0.2 : 1}, right ? {transform:[{scaleX:-1}]} : null]} source={require('../../assets/icon-back.png')}/>
      </Pressable>
    )
  }

  renderTourSelector = (height, backgroundColor) => {
    var tours = [
      "ATP & WTA",
      "Challenger",
      "ITF"
    ]
    var activeTourIndex = 0
    var activeTour = tours[activeTourIndex]
    return(
      <>
      <View style={[{padding, paddingRight:padding, height:'100%', backgroundColor, zIndex:2}, MainStyles.flexCenter]}>
        <Picker
          selectedValue={this.state.tour}
          style={{ height: 30, width: 105, fontFamily:'Roboto-Black', fontSize:14, color:'white', backgroundColor:'transparent', borderColor:'rgba(255,255,255,0.2)', borderRadius:100, paddingHorizontal:5 }}
          onValueChange={(tour) => this.changeTour(tour)}
        >
          <Picker.Item label="ATP" value="atp"/>
          <Picker.Item label="WTA" value="wta"/>
          <Picker.Item label="Challenger" value="challenger" />
          <Picker.Item label="ITF" value="itf" />
        </Picker>
      </View>
      {
        // this.renderTriangle(height, backgroundColor)
      }
      </>
    )
  }

  renderTriangle = (height, backgroundColor) => {
    return(
      <View
      style={{
        zIndex:1,
        marginLeft:-height/2,
        marginRight:-height/2,
        transform:[{scaleY:-1}],
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: height/2,
        borderRightWidth: height/2,
        borderBottomWidth: height,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: backgroundColor,
      }}
      />
    )
  }

  renderMatches = () => {
    var { matches } = this.state

    return(
      <FlatList
        ref={ref => (this.sectionListRef = ref)}
        onScroll={this.handleScroll}
        onLayout={this.handleLayout}
        // ListHeaderComponent={this.renderSectionListHeader}
        // ListFooterComponent={this.renderFooter()}
        data={matches}
        style={{flexGrow:1, zIndex:0}}
        contentContainerStyle={{paddingLeft:0}}
        horizontal
        keyExtractor={(item, index) => item.id}
        renderItem={this.renderMatch}
        scrollEnabled={true}
        scrollEventThrottle={0}
      />
    )
  }

  renderFooter = (height, backgroundColor) => {
    var backgroundColor = GLOBAL.colors.border
    return(
      <>
      {
        // <View style={{transform:[{scaleY:-1}]}}>
        // {this.renderTriangle(height, backgroundColor)}
        // </View>
      }
      <Pressable onPress={()=>Linking.openURL("https://downloadtennis.com/scores")} style={[{padding, paddingHorizontal:padding*2, height:'100%', backgroundColor:'white', zIndex:3}, MainStyles.flexCenterStart]}>
        <Text style={{fontFamily:'Roboto-Bold', fontSize:14, color:blue}}>
        View all
        </Text>
      </Pressable>
      </>
    )
  }

  renderTitle = (title, show) => {
    if(show){
      // return(
      //   <View style={[{padding}, MainStyles.flexCenter]}>
      //     <Text style={{fontFamily:'Roboto-Regular', color:'black', fontSize:14}}>
      //     {title}
      //     </Text>
      //   </View>
      // )
      return null
    }else{return null}
  }

  renderMatch = ({index, item}) => {
    var url = 'https://downloadtennis.com/match/'+item.docId
    var style = {fontSize:12, opacity:0.5, fontFamily:'Roboto-Regular'}
    return(
      <Pressable key={index} onPress={()=>Linking.openURL(url)} style={[{padding:padding*1, borderRightWidth:1, borderRightColor:GLOBAL.colors.border}, MainStyles.flexCenterStart]}>

        {({ hovered, focused, pressed }) => (
          <>
            <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {width:'100%', marginBottom:padding, opacity:hovered ? 1 : 1}]}>
              <Text style={[style, {marginRight:padding}, !hovered ? {opacity:1, color:blue} : {opacity:0}]}>
              {item.strings[0]}
              </Text>
              <Text style={[style, {position:'absolute', left:0}, hovered ? {opacity:1} : {opacity:0}]}>
              {!hovered ? '' : 'Tap for detailed match info'}
              </Text>
              <Text style={[style, !hovered ? null : {opacity:0}]}>
              {(item.strings[3] ? (item.strings[3].includes("Match Time: ") ? item.strings[3].split("Match Time: ")[1] : item.strings[3]) : '')}
              </Text>
            </View>

            <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {opacity:1, width:'100%'}]}>
              <View style={[MainStyles.flexCenterStart, {marginRight:padding*2}]}>
                {this.renderPlayer(item.competitors[0], item.winner_id)}
                <View style={{height:padding}}/>
                {this.renderPlayer(item.competitors[1], item.winner_id)}
              </View>
              {this.renderScores(item.set_scores, item.winner_id)}
            </View>

            {
              // !hovered ? null :
              // <Pressable onPress={()=>Linking.openURL(url)} style={[{backgroundColor:'rgba(0,0,0,0)', height:'100%', width:'100%', position:'absolute', top:0, left:0}, MainStyles.flexCenter]}>
              //   <Text style={{fontSize:14, opacity:1, color:'black', fontFamily:'Roboto-Bold'}}>
              //   View match info
              //   </Text>
              // </Pressable>
            }
          </>
        )}

      </Pressable>
    )
  }

  renderPlayer = (player, winner_id) => {
    var isWinner = player.id === winner_id
    var opacity = isWinner || !winner_id ? 1 : 0.5
    return(
      <View key={player.id} style={[{}, MainStyles.flexRow, MainStyles.flexCenter]}>
        <View style={{marginTop:0}}>
        {this.renderFlags(player.flags)}
        </View>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {opacity}]}>
          <Text style={{fontFamily:'Roboto-Medium', fontSize:14}}>
          {player.name}
          </Text>
          {
            !player.seed ? null :
            <Text style={{opacity:0.5, marginLeft:padding/2, fontFamily:'Roboto-Regular', fontSize:14}}>
            {player.seed}
            </Text>
          }
          {
            !player.serving ? null :
            <View style={{height:10, width:10, borderRadius:100, backgroundColor:GLOBAL.colors.volt, borderWidth:1, borderColor:'rgba(0,0,0,0.05)', marginLeft:padding/2}}/>
          }
        </View>
      </View>
    )
  }

  renderFlags = (flags) => {
    var width = 16
    var height = 12
    var ratio = 4

    var isDoubles = flags.length === 2

    var marginRight = isDoubles ? padding*1.5 : padding/2

    if(isDoubles){
      return(
        <View style={[{height, width:width, marginRight}, MainStyles.flexCenter]}>
          <ReactCountryFlag
            countryCode={flags[0]}
            svg
            style={{width, height, position:'absolute', top:-height/ratio, left:0}}
            title={flags[0]}
          />
          <ReactCountryFlag
            countryCode={flags[1]}
            svg
            style={{width, height, position:'absolute', bottom:-height/ratio, left:width/2}}
            title={flags[1]}
          />
        </View>
      )
    }else{
      return(
        <View style={{marginRight}}>
        <ReactCountryFlag
          countryCode={flags[0]}
          svg
          style={{
          width,
          height,
          }}
          title={flags[0]}
        />
        </View>
      )
    }
  }

  renderScores = (set_scores, winner_id) => {
    return(
      <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-padding/2}]}>
      {set_scores.map((item, index) => this.renderScore(item, index, set_scores.length, winner_id))}
      </View>
    )
  }

  renderScore = (set_score, index, num_sets, winner_id) => {
    var style = {fontFamily:'Roboto-Medium', fontSize:14}
    var s1 = set_score[0]
    var s2 = set_score[1]

    var final_set = index === (num_sets - 1)

    var opacity_1 = s1 >= s2 || (final_set && !winner_id) ? 1 : 0.5
    var opacity_2 = s2 >= s1 || (final_set && !winner_id) ? 1 : 0.5

    return(
      <View key={index} style={[{marginHorizontal:padding/2}, MainStyles.flexCenter]}>
        <Text style={[{marginBottom:padding/2, opacity:opacity_1}, style]}>
        {set_score[0]}
        </Text>
        <Text style={[{marginTop:padding/2, opacity:opacity_2}, style]}>
        {set_score[1]}
        </Text>
      </View>
    )
  }

  render() {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'white'}, MainStyles.flexCenter]}>
      {this.renderMain()}
      </View>
    )
  }
}

export default Item;
