import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import { BrowserRouter } from 'react-router-dom';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

import { View, Text, Pressable, Dimensions } from "react-native";

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

const AppCache: React.FC<{}> = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  // e.preventDefault();
  // emptyCacheStorage();
  return (
      <>
      {!isLatestVersion ?
        <Pressable
        onPress={e => {
          e.preventDefault();
          emptyCacheStorage();
        }}
        style={{flex:1, width:dimensions.width, height:dimensions.height, backgroundColor:'#121212', justifyContent:'center', alignItems:'center'}}
        >
          <View
          // onPress={e => {
          //   e.preventDefault();
          //   emptyCacheStorage();
          // }}
          onLayout={e => {
            e.preventDefault();
            emptyCacheStorage();
          }}
          onPress={emptyCacheStorage()}
          style={{flex:1, width:dimensions.width, height:dimensions.height, backgroundColor:'#121212', justifyContent:'center', alignItems:'center'}}>
            <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:24, textAlign:'center', opacity:1}}>
            Tap anywhere to access the latest version
            </Text>
          </View>
        </Pressable>
        :
        <App/>}
      </>
  );
};

ReactDOM.render(
  <ClearCacheProvider duration={5000}>
    <BrowserRouter basename="">
      <AppCache />
    </BrowserRouter>
  </ClearCacheProvider>
  , document.getElementById('root'));
registerServiceWorker();
