/* @flow */
import React, { PureComponent } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Alert,
  ActivityIndicator,
  Platform,
  SafeAreaView,
  Pressable,
  Button,
  ScrollView
} from 'react-native';

//Modules

// import { firebase } from '@react-native-firebase/auth';
// import firestore from '@react-native-firebase/firestore';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

//Components

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')
// const { flag } = require('country-emoji');

var textStyle = {fontFamily:'Averta-Semibold', fontSize:14, color:GLOBAL.style.color_font, letterSpacing:-0.4, paddingRight:12}
var hasNumber = /\d/

const getItem = (data, index) => ({
  id: Math.random().toString(12).substring(0),
  title: `Item ${index+1}`,
  data: data[index],
});

var whichPlatform = Platform.OS
const getItemCount = (data) => 50;

var PLAYER_HEIGHT = 36
var PLAYER_WIDTH = 240

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      tournament: this.props.tournament,
      players_main: [],
      players_qualifiers: [],
      players_featured: [],
      points: [],
      seeds: [],
      seeds_other: [],
      featured:[],
      other:[],
      qualified:[],
      activeIndex:0,
      game:this.props.game || {},
      tabs:[],
      picks:{}
    }
  }

  async componentDidMount(){
    this.setup()
  }

  async componentDidUpdate(prevProps){
    if(prevProps.tournament !== this.props.tournament){
      this.setState({tournament:this.props.tournament})
    }
  }

  setup = async () => {
    var { game, userId_viewing } = this.props

    var fantasy = true
    var { docId, format, title, picksClosed, id } = game

    //Get call API
    var params = {
      docId,
      mode:'getBracket',
    }

    console.log('Calling bracket', docId, title, id)

    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/fantasy_api"
    var data_response = await this.firestoreAPI(url, params)

    console.log(data_response)

    var { data, tabs, empty, picksClosed, picks, score, title, subtitle, username } = data_response

    picksClosed = false

    var activeData = data ? data[0] : null

    var data_display = data.slice(0, data.length - 1)


    await this.setState({tabs, data, data_display, docId, game, activeIndex:0, activeData, picksClosed, score, picks:picks || {}, loading:false, fantasy, title, subtitle, username})
  }

  nextRound = () => {
    var { activeIndex } = this.state
    this.changeRound(activeIndex+1)
  }

  pickPlayer = async (player, bracket_data) => {
    //Update that next round bracket pick
    var { activeIndex, picksClosed } = this.state
    if(picksClosed || player.disablePick){
      console.log('')
    }else{
      var { index_bracket, index_round, string } = player.nextRound
      var id = player.id

      var picks = {...this.state.picks}
      if(!picks[string]){
        picks[string] = {}
      }

      if(id){
        //Set the pick into the user pick object
        picks[string][index_bracket] = id

        //Set the player into the next bracket
        var data = [...this.state.data] || []
        data[index_round].brackets[index_bracket].players = player.players || []
        data[index_round].brackets[index_bracket].id = player.id || null
        data[index_round].brackets[index_bracket].seed = player.seed || null

        //If the other player is picked for future rounds beyond that, remove the pick in later rounds
        var checkNextRound = true

        this.setState({picks, data})
      }

    }


  }

  firestoreAPI = async (url_raw, params) => {
    var data = null

    var language = GLOBAL.language || 'en'

    var tz = await moment.tz.guess(true)
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    if(!params){
      params = {}
    }

    params.userId = 'test-user'

    await axios
    .get(url, {params})
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  }

  changeRound = async (activeIndex) => {
    await this.setState({changingRound:true})
    var { data } = this.state
    var activeData = data[activeIndex]
    await this.setState({activeIndex, activeData, loading:false, changingRound:false})
  }

  //Draws
  renderDraw = () => {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderContent()}
      </View>
    )
  }

  renderGameHeader = () => {
    var { tabs, picksClosed } = this.state
    return(
      <>
      <View style={{height:15}}/>
      </>
    )
  }

  renderContent = () => {
    var { empty, activeData, changingRound, loading, data, data_display } = this.state
    if(!empty && activeData){
      return(
        <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>
        {data_display.map((item, index) => this.renderBrackets(item, index))}
        </View>
      )
    }else if(loading || changingRound){
      return this.renderLoading()
    }else{
      return this.renderEmptyState()
    }
  }

  renderBrackets = (data, index2) => {
    var { changingRound } = this.state
    // return(
    //   <ScrollView
    //   directionalLockEnabled={false}
    //   horizontal={true}
    //   contentContainerStyle={[{flex:1}, MainStyles.flexStartCenter]}
    //   style={{flex:1, width:'100%'}}>
    //     {data.brackets.map((item, index) => this.renderBracket(item, index))}
    //   </ScrollView>
    // )

    return(
      <View
      key={index2}
      style={[{flex:1, backgroundColor:"#121212"}, MainStyles.flexStartStart]}>
        {data.brackets.map((item, index) => this.renderBracket(item, index, index2))}
      </View>
    )
  }

  renderBrackets_old = (data) => {
    var { changingRound } = this.state
    return(
      <FlatList
        ListHeaderComponent={this.renderGameHeader}
        data={changingRound ? [] : data.brackets || []}
        style={{width:'100%', borderTopWidth:1, borderTopColor:GLOBAL.style.color_border}}
        contentContainerStyle={{paddingBottom:30, paddingHorizontal:0, marginTop:0}}
        keyExtractor={(item, index) => index}
        // renderItem={({item, index})=>this.renderBracket(item, index, data?.brackets?.length || 0)}
        renderItem={({item, index})=>this.renderBracket(item, index)}
        ListEmptyComponent={this.renderEmpty}
        // renderSectionHeader={()=>this.renderSearchBar()}
        // stickySectionHeadersEnabled={false}
      />
    )
  }

  renderEmpty = () => {
    return(
      <View style={[{flex:1}, MainStyles.flexCenter]}>
        <ActivityIndicator size="small" color={GLOBAL.style.color_font}/>
      </View>
    )
  }

  renderBracket_old = (item_bracket, index_bracket, length) => {
    var { activeIndex, picksClosed } = this.state
    var isEven = (index_bracket + 1) % 2 === 0
    var isSection = (index_bracket + 1) % 4 === 0
    var marginVertical = activeIndex > 0 && picksClosed ? 25 : 12.5

    var backgroundColor = GLOBAL.style.color_font
    var opacity = 0.2

    return(
      <View style={[{width:'100%', paddingLeft:0, marginVertical}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {paddingLeft:activeIndex === 0 ? 10 : 0, height:'100%'}]}>
          {
            activeIndex === 0 ? null :
            <>
            <View style={{width:10, height:'57%', borderWidth:1, borderColor:backgroundColor, borderLeftWidth:0, borderRightWidth:0, opacity}}/>
            </>
          }
        </View>
        <View style={{flex:1}}>
        {item_bracket.map((item, index) => this.renderPlayers(item, index, item_bracket))}
        </View>

        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {paddingRight:length < 2 ? 10 : 0, height:'100%'}]}>
          {
            length < 2 ? null :
            <>
            <View style={{width:10, height:'57%', borderWidth:1, borderColor:backgroundColor, borderLeftWidth:0, opacity}}/>
            <View style={{width:10, height:1, backgroundColor, opacity, marginVertical:-0.5}}/>
            </>
          }
        </View>
      </View>
    )
  }

  renderBracket = (item, index, index_b) => {
    var { activeIndex, picksClosed, tabs } = this.state
    var isEven = index % 2 === 0
    var isSection = false
    var marginVertical = activeIndex > 0 && picksClosed ? 25 : 12.5

    var length = 2

    var backgroundColor = GLOBAL.style.color_font
    var opacity = 0.2

    var spacingLarge = 70

    var marginMajor = !picksClosed || activeIndex === 0 ? 30 : spacingLarge
    var marginMinor = !picksClosed || activeIndex === 0 ? 10 : spacingLarge

    // var marginMajor = picksClosed ? 50 : 30
    // var marginMinor = 10

    var marginTop = isEven && index !== 0 ? marginMajor : marginMinor
    if(index === 0 && activeIndex > 0){
      marginTop = 20
    }

    var spacing_brackets = 0
    var spacing_baseline = 51
    var line_brackets = 0
    if(index_b === 1){ spacing_brackets = spacing_baseline*1 } //1
    if(index_b === 2){ spacing_brackets = spacing_baseline*3; line_brackets = spacing_baseline*1 } //3
    if(index_b === 3){ spacing_brackets = spacing_baseline*7; line_brackets = spacing_baseline*3 } //7
    if(index_b === 4){ spacing_brackets = spacing_baseline*15; line_brackets = spacing_baseline*7 }
    if(index_b === 5){ spacing_brackets = spacing_baseline*31; line_brackets = spacing_baseline*15 }
    if(index_b === 6){ spacing_brackets = spacing_baseline*63; line_brackets = spacing_baseline*31 }

    var bracket_gap = 20 + spacing_brackets + (index === 0 ? 0 : spacing_brackets )

    if(isEven){
      marginTop = bracket_gap
    }else{
      // marginBottom = size
    }

    line_brackets = line_brackets + 28

    return(
      <View style={[{width:PLAYER_WIDTH, paddingLeft:0, marginTop}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          index_b === 0 ?
          <View style={[{width:20}, MainStyles.flexCenter]}>
            <Text style={{color:GLOBAL.style.color_font, opacity, fontFamily:'Averta-Regular', fontSize:12}}>
            {index+1}
            </Text>
          </View>
          :
          <View style={{width:10, height:1, backgroundColor, opacity}}/>
        }
        <View style={{flex:1}}>
        {this.renderPlayers(item, index)}
        </View>
        {
          isEven && index_b !== (this.state.data_display.length-1) ?
          <View style={[MainStyles.flexRow, {height:'100%', width:20, opacity, marginTop:-PLAYER_HEIGHT}]}>
            <View style={[{height:PLAYER_HEIGHT+marginMinor, width:20, borderWidth:0, borderColor:backgroundColor, borderLeftWidth:0, position:'absolute', top:PLAYER_HEIGHT/2, left:0}, MainStyles.flexCenter]}>
              <View style={{width:20, height:1, backgroundColor, position:'absolute', left:0}}/>
            </View>
          </View>
          :
          <View style={{width:20}}/>
        }
        {
          index_b === 0 ? null :
          isEven ?
          <View style={{position:'absolute', bottom:PLAYER_HEIGHT/2, left:0, height:line_brackets, width:1, backgroundColor, opacity}}>
          </View>
          :
          <View style={{position:'absolute', top:PLAYER_HEIGHT/2, left:0, height:line_brackets, width:1, backgroundColor, opacity}}>
          </View>
        }
      </View>
    )
  }

  renderPlayers = (item_players, index_players, bracket_data) => {
    var { activeIndex, picksClosed, picks } = this.state
    var isEven = index_players % 2 === 0
    var marginBottom = activeIndex > 0 && picksClosed ? 20 : 5
    var marginTop = activeIndex > 0 && picksClosed ? 20 : 5

    var players = item_players.players || item_players.picked_players || []

    var nextRound = item_players.nextRound || {}
    var picked_round = (picks[nextRound.string]) || {}
    var picked = (picked_round[nextRound.index_bracket]) === (item_players.players ? item_players.id : item_players.id_picked)

    var hasResult = item_players.correctPick || item_players.wrongPick
    var borderColor = null
    var backgroundColor = picked ? GLOBAL.style.color_accent_1 : 'rgba(255,255,255,0.1)'

    // picksClosed = false

    return(
      <View style={[{width:'100%'}, MainStyles.flexCenterStart]}>

        <Pressable key={index_players} onPress={()=>this.pickPlayer(item_players, bracket_data)} style={[{width:'100%', paddingHorizontal:10, backgroundColor, borderColor, borderWidth:0, borderRadius:2.5, height:PLAYER_HEIGHT}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            {
              players.map((item, index) => this.renderPlayer({item, index}, item_players.opacity || 1, null))
            }
          </View>
        </Pressable>
        {
          !item_players.footer || !picksClosed ? null :
          <Text style={{color:GLOBAL.style.color_font, fontSize:12, fontFamily:'Averta-Regular', paddingLeft:15, position:'absolute', bottom:-20}}>
          {item_players.footer}
          </Text>
        }
      </View>
    )
  }

  renderPointsValue = (item, index) => {
    return(
      <View key={index} style={[{flex:1, paddingHorizontal:5}, MainStyles.flexCenter]}>
        <Text style={{fontSize:24, color:item.color || GLOBAL.style.color_font, fontFamily:item.fontFamily || 'Averta-Bold', textAlign:'center'}} numberOfLines={1}>
        {item.value}
        </Text>
        <Text style={{fontSize:12, color:GLOBAL.style.color_font, fontFamily:'Averta-Regular', textAlign:'center', opacity:0.5, marginTop:0}} numberOfLines={1}>
        {item.title}
        </Text>
      </View>
    )
  }

  renderPlayer = ({item, index, small}, opacity, color) => {
    small = true
    if(item){
      var { activeIndex } = this.state
      var flag = item.flag || null
      var width = small ? 16 : 20
      var height = small ? 10 : 12
      var fontSize = small ? 12 : 14
      var marginRight = small ? 7.5 : 10

      if(flag === 'GBR'){flag = 'GB'}

      return(
        <View key={index} style={[{width:'100%'}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
          {
            <View style={{width, marginRight}}>
            {
              !flag ? null :
              // <Flag
              //   id={flag}
              //   width={width}
              //   height={height}
              // />
              <ReactCountryFlag
                countryCode={flag}
                svg
                style={{
                    width,
                    height,
                }}
                title="Flag"
              />
            }
            </View>
          }
          <Text style={{fontFamily:'Averta-Regular', color:GLOBAL.style.color_font, opacity:1, flex:1, fontSize}} numberOfLines={1}>
          {item.name}
          </Text>
        </View>
      )
    }else{return null}
  }

  renderEmptyState = () => {
    return(
      <View style={[{flex:1, width:'100%', padding:30}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:'Averta-Black', fontSize:16, color:GLOBAL.style.color_font, textAlign:'center'}}>
        Bracket is not available yet.
        </Text>
        <Text style={{fontFamily:'Averta-Regular', fontSize:14, color:GLOBAL.style.color_font, textAlign:'center', marginTop:10}}>
        Check back soon to make your first picks
        </Text>
      </View>
    )
  }

  renderLoading = () => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color={GLOBAL.style.color_font}/>
      </View>
    )
  }

  renderHeader = () => {
    var { game, title, subtitle } = this.state
    var width = 60
    return(
      <View style={[{width:'100%', borderBottomColor:GLOBAL.style.color_border, borderBottomWidth:0, paddingHorizontal:15, paddingVertical:15, marginTop:15}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

      <View style={[MainStyles.flexCenter]}>
        <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:48}}>
        TNNS
        </Text>
        <Text style={{color:GLOBAL.colors.volt, fontFamily:'Averta-Bold', fontSize:24, marginTop:-5}}>
        FANTASY
        </Text>
      </View>

      <View style={[MainStyles.flexCenter, {marginLeft:60}]}>
        <Text style={{color:'white', fontFamily:'Averta-Semibold', fontSize:16}}>
        JOIN THE GAME
        </Text>
        <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:24, marginTop:-5}}>
        TNNSLIVE.COM
        </Text>
      </View>

      </View>
    )
  }

  renderBase = () => {
    var { picksClosed, activeIndex, tabs } = this.state
    return(
      <View style={{width:'100%'}}>
        <View style={{width:'100%', paddingTop:15, paddingBottom:30, paddingHorizontal:15, borderTopColor:GLOBAL.style.color_border, borderTopWidth:1}}>
          {
            picksClosed ? null : <Button title='Save Picks' loading={this.state.submittingPicks} onPress={this.savePicks}/>
          }
          {
            activeIndex < (tabs.length-1) ? <Button title='Next Round' onPress={this.nextRound} style={{backgroundColor:GLOBAL.style.color_border, marginTop:picksClosed ? 0 : 15}}/> : null
          }
        </View>
        <SafeAreaView/>
      </View>
    )
  }


  render() {
    var { loading } = this.state
    try{
      return(
        <>
        <View style={[MainStyles.flexCenter, {flex:1, width:"100%"}]}>

          <ScrollView style={{flex:1, width:'100%', backgroundColor:'#121212'}} contentContainerStyle={{width:'100%', paddingBottom:60}} directionalLockEnabled={false}>
          {this.renderDraw()}
          </ScrollView>
        </View>
        </>
      )
    }catch(e){
      console.log(e)
      return null
    }
  }

}
