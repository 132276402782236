import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated, KeyboardAvoidingView, TextInput } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

import Message from './tnns-message.js'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 1920
var spacing = 10
var spacing_main = 20
var fontSize = 40
var moment = require('moment-timezone');

var colors = {
  primary:'#17171D',
  secondary:'white',
  accent:'#60348C',
  score_bg:'#1B1B20',
  score_font:'white',
  bg_opacity:0.2,
  main_font:'white',
  bg_stats:'#17171D',
  logo:null,
}

var volt = '#B6FE00'

var backgroundColor = 'rgba(0,0,0,0.15)'
var borderColor = 'rgba(255,255,255,0.15)'

var docId = "htcBQ4ARm8K3bD3bWtPs"


//Wimbledon theme
// colors = {
//   primary:"white",
//   secondary:'#007945',
//   accent:"#60348C",
//   score_bg:'rgba(255,255,255,0.5)',
//   score_font:'#17171D',
//   bg_opacity:0.2,
//   main_font:'black',
//   bg_stats:'#E2E2E4',
//   logo:'#60348C',
// }
// borderColor = 'rgba(0,0,20,0.5)'
// backgroundColor = 'rgba(0,0,20,0.04)'


var title_style = {fontFamily:'Averta-Bold', color:colors.main_font, fontSize:24, letterSpacing:0, textTransform:'uppercase'}

var stringChangeTime = 8000

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var updateTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messages:[],
      matchId:null,
    };
  }

  componentDidMount = async () => {
    this.getMessages()

    // this.generateTournamentSummaries()
  }

  componentWillUnmount = () => {
    if(this.listenMessages){
      this.listenMessages()
    }
  }

  generateTournamentSummaries = async () => {
    //Get active tournaments
    var tournamentsRaw = await this.getAllTournaments()

    var { all_tour, all_challenger, all_itf, all_other, active_tour, active_challenger, active_itf, active_other } = tournamentsRaw

    // Promise through tournaments
    var tourPromises = [
      {doc:'tournaments_all_tour', data:all_tour},
      {doc:'tournaments_all_challenger', data:all_challenger},
      {doc:'tournaments_all_itf', data:all_itf},
      {doc:'tournaments_all_other', data:all_other},
      {doc:'tournaments_active_tour', data:active_tour},
      {doc:'tournaments_active_challenger', data:active_challenger},
      {doc:'tournaments_active_itf', data:active_itf},
      {doc:'tournaments_active_other', data:active_other},
    ]

    // await Promise.all(tourPromises.map(async (tournament, index) => {
    //
    //   var tournaments = tournament.data
    //
    //   await firebase.firestore()
    //   .collection('summaries_sr').doc(tournament.doc)
    //   .set(tournament.data)
    // }))


  }

  getAllTournaments = async () => {
    var all = []

    var all_tour = {}
    var all_challenger = {}
    var all_itf = {}
    var all_other = {}

    var all_tourObj = {}
    var all_challengerObj = {}
    var all_itfObj = {}
    var all_otherObj = {}

    var active = []
    var active_tour = []
    var active_challenger = []
    var active_itf = []
    var active_other = []

    await firebase.firestore()
    .collection('tournaments')
    .where('season', '==', 2021)
    .get()
    .then(async(querySnapshot)=> {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          //Setup
          var tournament = doc.data()
          tournament.docId = doc.id

          if(tournament.startsAt && tournament.endsAt && !tournament.hideFromSummaries && !tournament.cancelled && !tournament.deleted){

            //If the tournament starts in 5 days or finished within the last 2 days, include
            var startsAt = (tournament.startsAt.seconds || tournament.startsAt._seconds)*1000
            var endsAt = (tournament.endsAt.seconds || tournament.endsAt._seconds)*1000
            var startShowing = tournament.startsShowing ? (tournament.startsShowing.seconds || tournament.startsShowing._seconds)*1000 : null

            var bufferStart = Date.now() + (3.5*24*60*60*1000) // 5 days from now
            var bufferEnd = Date.now() - (1.75*24*60*60*1000) // 1.5 days ago

            var active = startsAt < Date.now() && endsAt > Date.now()
            var startsSoon = startsAt > Date.now() && startsAt < bufferStart
            var justFinished = endsAt < Date.now() && endsAt > bufferEnd
            var earlyShowing = (startShowing && endsAt > bufferEnd )? startShowing < Date.now() : false //Manually trigger to show early

            var isActive = active || startsSoon || justFinished || earlyShowing
            var showArrow = startsAt < Date.now()

            //Category flags
            var category = 'Other'
            var rank = 900
            var tags = []

            if(tournament.code === 'ATP' || tournament.code === 'WTA'){
              category = 'Tour'
              rank = tournament.code === 'ATP' ? 1 : 1
              tags = []

              if(tournament.code === 'ATP' && tournament.type){
                if(tournament.type === 'ATP1000'){
                  rank = rank + 0.01
                  tags.push({title:tournament.type, color:"#bf9a53"})
                }else if(tournament.type === 'ATP500'){
                  rank = rank + 0.1
                  tags.push({title:tournament.type, color:"silver"})
                }else{
                  rank = rank + 0.4
                  tags.push({title:tournament.type, color:"#4ACDFF"})
                }
              }
              if(tournament.code === 'WTA' && tournament.type){
                tags.push({title:tournament.type, color:"#9E53FF"})
                if(tournament.type === 'WTA1000'){ rank = rank + 0.01 }
                if(tournament.type === 'WTA500'){ rank = rank + 0.1 }
                if(tournament.type === 'WTA250'){ rank = rank + 0.4 }
                if(tournament.type === 'WTA125'){ rank = rank + 0.9 }
              }
              if(tournament.type === 'Slam'){
                rank = 0.01
                tags = [{title:'Grand Slam', color:"#3CD782"}]
              }

              if(tournament.tags){
                tags = tags.concat(tournament.tags)
              }

            }

            if(tournament.code === 'Challenger'){
              category = 'Challenger'
              rank = 3
              tags = tournament.tags || [{title:(tournament.category ? "Challenger "+tournament.category : "Challenger"), color:'#3CD782'}]
            }

            if(tournament.code === 'ITF'){
              category = 'ITF'
              rank = 4
              tags = tournament.tags || [{title:tournament.category, color:'#006BFF'}]
            }

            //Figure out the surface
            var surface_type = null
            if(tournament.surface){

              if(((tournament.surface).toLowerCase()).includes('clay')){
                surface_type = 'Clay'
              }
              if(((tournament.surface).toLowerCase()).includes('grass')){
                surface_type = 'Grass'
              }
              if(((tournament.surface).toLowerCase()).includes('hard')){
                surface_type = 'Hard'
              }
              if(((tournament.surface).toLowerCase()).includes('indoor')){
                surface_type = 'Indoors'
              }
            }

            //Setup the tournament object
            var month = moment(tournament.start_date, 'YYYY-MM-DD').format('MMMM')
            var subtitle = moment(tournament.start_date, 'YYYY-MM-DD').format('DD MMM') + " - " + moment(tournament.end_date, 'YYYY-MM-DD').format('DD MMM') + " · " + tournament.city + ", " + tournament.country

            if(surface_type){
              subtitle = moment(tournament.start_date, 'YYYY-MM-DD').format('DD MMM') + " - " + moment(tournament.end_date, 'YYYY-MM-DD').format('DD MMM') + " · " + tournament.city + ", " + tournament.country + " · " + surface_type
            }

            var tournObj = {
              name:tournament.name,
              subtitle,
              tags,
              rank,
              category,
              isActive,
              id:doc.id,
              showArrow,
              start_date:tournament.start_date,
              end_date:tournament.end_date,
              priority:tournament.priority || false,
              showAtTop:tournament.showAtTop || false,
            }

            if(tournament.code === 'Challenger'){
              //Add the id_sr for stream guessing
              tournObj.id_sr = tournament.id_sr || []
              tournObj.surface = tournament.surface || ''
              tournObj.id_atp = tournament.id_atp || null
            }

            if(isActive){
              //Push into active
              if(category === 'Tour'){
                active_tour.push(tournObj)
              }
              if(category === 'Challenger'){
                active_challenger.push(tournObj)
              }
              if(category === 'ITF'){
                active_itf.push(tournObj)
              }
              if(category === 'Other'){
                active_other.push(tournObj)
              }
            }

            //Push into all
            if(category === 'Tour'){
              if(!all_tourObj[month]){
                all_tourObj[month] = {
                  data:[],
                  title:month,
                  rank:Number(moment(month,'MMMM').format('M'))
                }
              }
              all_tourObj[month].data.push(tournObj)
            }
            if(category === 'Challenger'){
              if(!all_challengerObj[month]){
                all_challengerObj[month] = {
                  data:[],
                  title:month,
                  rank:Number(moment(month,'MMMM').format('M'))
                }
              }
              all_challengerObj[month].data.push(tournObj)
            }
            if(category === 'ITF'){
              if(!all_itfObj[month]){
                all_itfObj[month] = {
                  data:[],
                  title:month,
                  rank:Number(moment(month,'MMMM').format('M'))
                }
              }
              all_itfObj[month].data.push(tournObj)
            }
            if(category === 'Other'){
              if(!all_otherObj[month]){
                all_otherObj[month] = {
                  data:[],
                  title:month,
                  rank:Number(moment(month,'MMMM').format('M'))
                }
              }
              all_otherObj[month].data.push(tournObj)
            }
          }
        })
      }
      return
    })

    //For each of the ALL arrays, need to create the sectionList objects
    var all_tour_array = Object.values(all_tourObj)
    var all_challenger_array = Object.values(all_challengerObj)
    var all_itf_array = Object.values(all_itfObj)
    var all_other_array = Object.values(all_otherObj)

    //Sort by month rank
    await all_tour_array.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
    await all_challenger_array.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
    await all_itf_array.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
    await all_other_array.sort((a, b) => (a.rank > b.rank) ? 1 : -1);

    //Handle full calendars
    await Promise.all(all_tour_array.map(async (group, index) => {
      await group.data.sort((a, b) => (a.title < b.title) ? 1 : -1);
      await group.data.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

      for (let i = 0; i < group.data.length && !all_tour.itemIndex; i++) {
        var match = group.data[i]
        var start_at = Number(moment(match.start_date, 'YYYY-MM-DD').format('x')) < Date.now()
        var end_at = Number(moment(match.end_date, 'YYYY-MM-DD').format('x')) > Date.now()

        if(start_at && end_at && !all_tour.itemIndex){
          all_tour.sectionIndex = index
          all_tour.itemIndex = i
        }
      }

    }))
    all_tour.tournaments = all_tour_array

    await Promise.all(all_challenger_array.map(async (group, index) => {
      await group.data.sort((a, b) => (a.title < b.title) ? 1 : -1);
      await group.data.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

      for (let i = 0; i < group.data.length && !all_challenger.itemIndex; i++) {
        var match = group.data[i]
        var start_at = Number(moment(match.start_date, 'YYYY-MM-DD').format('x')) < Date.now()
        var end_at = Number(moment(match.end_date, 'YYYY-MM-DD').format('x')) > Date.now()

        if(start_at && end_at && !all_challenger.itemIndex){
          all_challenger.sectionIndex = index
          all_challenger.itemIndex = i
        }
      }

    }))
    all_challenger.tournaments = all_challenger_array

    //ITF
    await Promise.all(all_itf_array.map(async (group, index) => {
      await group.data.sort((a, b) => (a.title < b.title) ? 1 : -1);
      await group.data.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

      for (let i = 0; i < group.data.length && !all_itf.itemIndex; i++) {
        var match = group.data[i]
        var start_at = Number(moment(match.start_date, 'YYYY-MM-DD').format('x')) < Date.now()
        var end_at = Number(moment(match.end_date, 'YYYY-MM-DD').format('x')) > Date.now()

        if(start_at && end_at && !all_itf.itemIndex){
          all_itf.sectionIndex = index
          all_itf.itemIndex = i
        }
      }

    }))
    all_itf.tournaments = all_itf_array

    await Promise.all(all_other_array.map(async (group, index) => {
      await group.data.sort((a, b) => (a.title < b.title) ? 1 : -1);
      await group.data.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

      for (let i = 0; i < group.data.length && !all_other.itemIndex; i++) {
        var match = group.data[i]
        var start_at = Number(moment(match.start_date, 'YYYY-MM-DD').format('x')) < Date.now()
        var end_at = Number(moment(match.end_date, 'YYYY-MM-DD').format('x')) > Date.now()

        if(start_at && end_at && !all_other.itemIndex){
          all_other.sectionIndex = index
          all_other.itemIndex = i
        }
      }

    }))
    all_other.tournaments = all_other_array

    console.log(active_tour)

    //Handle active tournaments
    await active_tour.sort((a, b) => (a.name > b.name) ? 1 : -1);
    // await active_tour.sort((a, b) => (a.priority < b.priority) ? 1 : -1);
    await active_tour.sort((a, b) => (a.rank < b.rank) ? 1 : -1);
    await active_tour.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);
    // await active_tour.sort((a, b) => (a.showAtTop < b.showAtTop) ? 1 : -1);

    var active_tour_obj = {}
    await Promise.all(active_tour.map(async (active_tour_event, index) => {
      if(!active_tour_obj[active_tour_event.start_date]){
        active_tour_obj[active_tour_event.start_date] = {
          rank: Number(moment(active_tour_event.start_date, 'YYYY-MM-DD').format('x')),
          data: [],
        }
      }
      active_tour_obj[active_tour_event.start_date].data.push(active_tour_event)
    }))

    var active_tour_array = Object.values(active_tour_obj)
    await active_tour_array.sort((a, b) => (a.rank > b.rank) ? 1 : -1)

    var active_tour_clean = []
    await Promise.all(active_tour_array.map(async (active_tour_group, index) => {
      await active_tour_group.data.sort((a, b) => (a.name < b.name) ? 1 : -1);
      await active_tour_group.data.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
    }))

    for (let i = 0; i < active_tour_array.length; i++) {
      for (let j = 0; j < active_tour_array[i].data.length; j++) {
        active_tour_clean.push(active_tour_array[i].data[j])
      }
    }

    // console.log('Clean',active_tour_clean)


    await active_challenger.sort((a, b) => (a.name > b.name) ? 1 : -1);
    await active_challenger.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

    await active_itf.sort((a, b) => (a.name > b.name) ? 1 : -1);
    await active_itf.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

    await active_other.sort((a, b) => (a.name > b.name) ? 1 : -1);
    await active_other.sort((a, b) => (Number(moment(a.start_date, 'YYYY-MM-DD').format('x')) > Number(moment(b.start_date, 'YYYY-MM-DD').format('x'))) ? 1 : -1);

    //Return the tournaments
    return { all_tour, all_challenger, all_itf, all_other, active_tour:{tournaments:active_tour_clean}, active_challenger:{tournaments:active_challenger}, active_itf:{tournaments:active_itf}, active_other:{tournaments:active_other} }
  }

  getMessages = async () => {
    var matchId = null

    await firebase.firestore()
    .collection('streaming').doc(docId)
    .get()
    .then(doc => {
      matchId = doc.data().matchId || null
    })

    if(matchId){
      if(matchId !== this.state.matchId && this.state.matchId !== null){
        this.props.remount()
      }else{
        this.setState({matchId})
        this.listenMessages = await firebase.firestore()
        .collection('matches_sr').doc(matchId)
        .collection('messages')
        .orderBy('createdAtTimestamp', 'desc')
        .onSnapshot(this.handleMessages)
      }
    }
  }

  handleMessages = async (querySnapshot) => {
    const messages = querySnapshot.docs.map(doc => {
      const firebaseData = doc.data();

      const data = {
        id: doc.id,
        docId: doc.id,
        text: '',
        createdAt: new Date().getTime(),
        ...firebaseData
      };

      return data;
    });

    console.log(messages)


    await this.setState({messages, loading:false})
  }

  renderLoading = () => {
      return(
        <View style={[{flex:1, width:'100%'}, MainStyles.flexCenter]}>

          <Text style={{fontFamily:'Averta-Black', fontSize:60, color:'white', textTransform:'uppercase'}}>
            TNNS
          </Text>
          <Text style={{fontFamily:'Averta-Black', fontSize:32, color:GLOBAL.style.color_accent_2, marginTop:-10, textTransform:'uppercase', marginBottom:20}}>
            CHAT
          </Text>
          <ActivityIndicator size='small' color={'white'}/>
          <Text style={{fontFamily:'Averta-Regular', fontSize:14, color:'white', marginTop:10}}>
          Loading
          </Text>
        </View>
      )
    }

  renderContent = () => {
    var { type } = this.props
    var { loading, messages } = this.state
    return(
      <View style={{flex:1, width:'100%', backgroundColor:'#121212', borderTopColor:GLOBAL.style.color_border, borderTopWidth:type === 'match' ? 0 : 1}}>
        {loading ? this.renderLoading() : this.renderChat()}
      </View>
    )
  }

  renderChat = () => {
    return(
      <KeyboardAvoidingView
      behavior='padding' style={[{flex:1, width:'100%'}, MainStyles.flexEndCenter]} keyboardVerticalOffset={this.state.keyboardVerticalOffset || 150}>
      {this.renderMessages()}
      {this.renderInput()}
      </KeyboardAvoidingView>
    )
  }

  renderMessages = () => {
    var { messages } = this.state
    return(
      <FlatList
      data={messages}
      ref={(ref) => { this.flatlistMessages = ref }}
      renderItem={this.renderMessage}
      keyExtractor={(item) => item.id}
      style={{width:'100%', flex:1, marginBottom:0, paddingBottom:10}}
      contentContainerStyle={{marginTop:0, paddingHorizontal:20, width:'100%', paddingBottom:20}}
      showsVerticalScrollIndicator={true}
      inverted
      autoCorrect={false}
      ListFooterComponent={this.renderChatHeader()}
      />
    )
  }

  renderChatHeader = () => {
    return(
      <Text style={[{width:'100%', paddingVertical:10, paddingHorizontal:20}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:12, color:GLOBAL.style.color_font_secondary, textAlign:'center', opacity:0.5}}>
        Welcome to the chat room!
        </Text>
      </Text>
    )
  }

  renderMessage = ({item, index}) => {
    var { collection, id } = this.state
    return(
      <Message key={index} data={item} index={index} collection={collection} id={id}/>
    )
  }

  renderGifButton = () => {
    return(
      <Pressable onPress={this.showGiphyModal} style={[MainStyles.flexCenter, {padding:20}]}>
        <View style={[{width:24, height:24, borderRadius:4, backgroundColor:GLOBAL.style.color_accent_1}, MainStyles.flexCenter]}>
          <Text style={{color:GLOBAL.style.color_font_accent, fontSize:10, fontFamily:'Averta-Bold'}}>
          GIF
          </Text>
        </View>
      </Pressable>
    )
  }

  renderInput = () => {
    return(
      <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexCenter]}>

        {
          this.renderGifButton()
        }

        <View style={[{flexGrow:1, height:40, borderRadius:5, borderWidth:1, marginRight:20, borderColor:GLOBAL.style.color_border, paddingHorizontal:10}, MainStyles.flexCenter]}>
          <TextInput
            ref={(ref) => { this.chatTextInput = ref }}
            style={{color:'GLOBAL.style.color_font', fontFamily:'Averta-Regular', fontSize:15, letterSpacing:-0, width:'100%', height:'100%'}}
            onChangeText={message => this.setState({message})}
            value={this.state.message}
            placeholder={'Add to the discussion'}
            placeholderTextColor={GLOBAL.style.color_font_secondary}
            // autoFocus
            blurOnSubmit={false}
            returnKeyType='send'
            onSubmitEditing={this.sendMessage}
            maxLength={280}
            caretHidden={true}
            autoCorrect={false}
          />
          {
            !this.state.message || this.state.message === '' ? null :
            <Pressable style={{position:'absolute', right:5, padding:5}} onPress={this.sendMessage}>
              <Text style={{fontFamily:'Averta-Bold', color:'white', fontSize:14}}>
              Send
              </Text>
            </Pressable>
          }
        </View>
      </View>
    )
  }

  render() {
    const { message, mode } = this.state;

    // return this.renderCustom()
    // return this.renderNormal()

    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }
}

export default Item;
