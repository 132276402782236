import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, TextInput } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var moment = require('moment-timezone');

var stringChangeTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tippers:[],
      loading:true,
    };
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  setup = async () => {
    //Get tippers data
    await firebase.firestore()
    .collection('streaming').doc(this.props.id)
    .get().then(this.handleTippers)
  }

  handleTippers = async (doc) => {
    var tippers = Array(this.props.single_line ? 1 : 10).fill('')

    if(doc.data() && doc.exists){
      var tippers_raw = doc.data().topTippers || []

      await Promise.all(tippers_raw.map(async (tipper, index) => {
        tippers[index] = tipper
      }))

      this.setState({tippers})
    }
  }

  updateField = async (index, value) => {
    var { tippers } = this.state
    tippers[index] = value
    this.setState({tippers})
  }

  updateTippers = async () => {
    var tippers_raw = this.state.tippers
    var tippers = []

    await Promise.all(tippers_raw.map(async (tipper, index) => {
      if(tipper && tipper !== ''){
        tippers.push(tipper)
      }
    }))

    await firebase.firestore()
    .collection('streaming').doc(this.props.id)
    .set({
      topTippers:tippers
    },{merge:true})
  }

  renderTipper = ({index, item}) => {
    return(
      <View style={{width:this.props.single_line ? 800 : 400, marginVertical:10, borderRadius:5, backgroundColor:'#18181B'}}>
        <TextInput
        style={[{color:'white', fontSize:18, fontFamily:'Averta-Regular', padding:20}]}
        value={item}
        onChangeText={(value)=>this.updateField(index, value)}
        multiline={true}
        />
      </View>
    )
  }


  render() {
    var { loading, tippers } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
        <Text>
        </Text>
        <FlatList
        style={{flex:1, paddingBottom:50}}
        contentContainerStyle={{flex:1, width:'100%', paddingBottom:50}}
        renderItem={this.renderTipper}
        data={tippers}
        ListHeaderComponent={
          <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
            <View style={[MainStyles.flexCenterStart]}>
              <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
              Top Tippers
              </Text>
              <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
              Update any field and tap Save to update your top tippers
              </Text>
            </View>
          </View>
        }
        ListFooterComponent={
          <Pressable onPress={this.updateTippers} style={[{width:200, height:60, borderRadius:10, backgroundColor:GLOBAL.gradients.main[0], marginBottom:50, marginTop:40}, MainStyles.flexCenter]}>
            <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:16}}>
            Save
            </Text>
          </Pressable>
        }
        />
      </View>
    )
  }
}

export default Item;
