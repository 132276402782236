import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { View, Text, Pressable, Linking, Image, ActivityIndicator } from "react-native";


//Module
import ReactGA from 'react-ga';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from './mainstyles.js'
import GLOBAL from './global.js'

import Date from './bettingsystemdate'

const MAX_WIDTH = 400
var moment = require('moment-timezone');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      id:this.props.match.params.id,
      show:false,
      units:100,
    };
  }

  componentDidMount = async () => {
    await this.initializeFirebase()
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
      authDomain: "tennis-lwts.firebaseapp.com",
      projectId: "tennis-lwts",
      storageBucket: "tennis-lwts.appspot.com",
      messagingSenderId: "414782898270",
      appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
      measurementId: "G-D3SD6S9JJW"
    };

    await app.initializeApp(config);

    //Anon auth
    await firebase.auth().signInAnonymously()
    .then(() => {
      // Signed in..
      console.log('signed in')
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  setup = async () => {
    var system = {}

    //Get the system
    await firebase.firestore()
    .collection('betting').doc('systems')
    .collection('strategies').doc(this.state.id)
    .get()
    .then(async doc =>{
      system = doc.data()
      system.docId = doc.id
    })

    //Get the dates
    var dates = []

    await firebase.firestore()
    .collection('betting').doc('systems')
    .collection('strategies').doc(this.state.id)
    .collection('dates')
    .orderBy('date', 'desc')
    .limit(30)
    .get()
    .then(async querySnapshot => {
      return querySnapshot.forEach(async doc => {
        dates.push(doc.data())
      })
    })

    await dates.sort((a, b) => (a.date > b.date) ? 1 : -1);

    //Promise through the dates and find the max units you would've required to support this strategy
    var largest_unit_offset = 0
    var units_held = 0

    for(var i = 0; i < dates.length; i++) {
      const date = dates[i]

      if(date.outcome){
        //Place the bets
        units_held = units_held - date.outcome.units_wagered

        if(largest_unit_offset > units_held){largest_unit_offset = units_held}

        //Return the units
        units_held = units_held + date.outcome.units_wagered

        if(largest_unit_offset > units_held){largest_unit_offset = units_held}

        //Return the winnings
        units_held = units_held + date.outcome.units_won

        if(largest_unit_offset > units_held){largest_unit_offset = units_held}
      }
    }

    await dates.sort((a, b) => (a.date < b.date) ? 1 : -1);

    this.setState({system, dates, largest_unit_offset, loading:false})
  }

  show = () => {
    this.setState({show:!this.state.show})
  }

  increase = () => {
    this.setState({units:this.state.units*2})
  }

  decrease = () => {
    this.setState({units:this.state.units/2})
  }

  commify = (n) => {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}


  //Renders
  renderContent = () => {
    var { options, loading, systems } = this.state
    return(
      <View style={[{width:'100%', padding:24, backgroundColor:'#121212'}, MainStyles.flexStartCenter]}>
        {this.renderTitle()}
        {
          loading ?
          <View style={[{flex:1}, MainStyles.flexCenter]}><ActivityIndicator color='white'/>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
            {"Loading system"}
            </Text>
          </View>
          :
          <>
          {this.renderSystem()}
          {this.renderReturn()}
          {this.renderDates()}
          </>
        }
      </View>
    )
  }

  renderSystem = () => {
    var { system } = this.state
    var item = system

    return(
      <View style={[{width:'100%', maxWidth:MAX_WIDTH, padding:24, backgroundColor:'rgba(255,255,255,0.05)', borderRadius:16, marginBottom:16}, MainStyles.flexCenter]}>

        <View style={[{width:'100%', marginBottom:32}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <Text style={{color:'white', fontSize:20, fontWeight:'700'}}>
          {"SYSTEM"}
          </Text>
          {
            !item.upcoming_bets.count ? null :
            <View style={[{padding:8, paddingVertical:4, borderRadius:4, backgroundColor:GLOBAL.colors.purple}]}>
              <Text style={{color:'white', fontWeight:'700'}}>
              {item.upcoming_bets.count+(item.upcoming_bets.count > 1 ? " picks available" : " pick available")}
              </Text>
            </View>
          }
        </View>


        {
          !this.state.show ? null :
          <>
          {this.renderValues("30 day peformance", [
            {value:item.performance.last_30_days.units_won, key:"Units won"},
            {value:Math.round(item.performance.last_30_days.correct/item.performance.last_30_days.total*100*100)/100 +"%", key:"Win rate"},
            {value:item.performance.last_30_days.roi+"%", key:"ROI"},
            {value:item.performance.last_30_days.total, key:"Total bets"},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
          ], true)}

          {this.renderValues("Strategy", [
            {value:item.strategy.genders.join(", "), key:"Genders"},
            {value:item.strategy.tours.join(", "), key:"Tours"},
            {value:item.strategy.markets.join(", "), key:"Markets"},
            {value:item.strategy.segments.join(", "), key:"Segments"},
            {value:item.strategy.min_matches_in_signal, key:"Min matches / signal"},
            {value:item.strategy.minimum_signals, key:"Min signals"},
            {value:item.strategy.signal_balance_threshold, key:"Signal balance"},
            {value:item.strategy.unit_ratio_threshold, key:"Unit ratio threshold"},
            {value:item.strategy.units_on_upset, key:"Units on upset"},
            {value:"", key:""},
          ], false)}
          </>
        }

        <Pressable onPress={this.show} style={[{width:"100%", height:48, backgroundColor:"white", borderRadius:8}, MainStyles.flexCenter]}>
          <Text style={{color:'#121212', fontSize:15, fontWeight:'700'}}>
          {this.state.show ? "Hide system info" : "Show system info"}
          </Text>
        </Pressable>

      </View>
    )
  }

  renderValues = (title, data, noMargin) => {
    return(
      <View style={[{width:'100%', marginTop:noMargin ? 0 : 16}, MainStyles.flexCenterStart]}>
        {this.renderSectionTitle(title)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {(data || []).map((item, index) => this.renderValue(item))}
        </View>
      </View>
    )
  }

  renderValue = ({value, key}) => {
    if(value || value === 0){
      return(
        <View style={[{marginRight:32, marginBottom:16, width:'100%'}, MainStyles.flexCenterStart]}>
          <Text style={{color:'white', fontSize:19}}>
          {value}
          </Text>
          <Text style={{color:'white', fontSize:15, opacity:0.33, marginTop:4}}>
          {key}
          </Text>
        </View>
      )
    }else{
      return null
    }
  }

  renderSectionTitle = (title) => {
    return(
      <View style={[{width:'100%', marginBottom:16}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
      <Text style={{color:'white', fontSize:12, opacity:0.67, textTransform:'uppercase', fontWeight:'900', textAlign:'left'}}>
      {title}
      </Text>
      <View style={{flex:1, paddingLeft:16}}>
        <View style={{width:'100%', height:1, backgroundColor:'white', opacity:0.1}}/>
      </View>
      </View>
    )
  }

  renderTitle = () => {
    var { loading } = this.state
    return(
      <View style={{marginBottom:40, justifyContent:'center', alignItems:'center'}}>
        <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:24, textAlign:'center'}}>
        Betting system performance
        </Text>
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:13, textAlign:'center', opacity:0.5, marginTop:5}}>
        {"System ID: "+this.state.id}
        </Text>
      </View>
    )
  }

  renderReturn = () => {
    var { system, dates, largest_unit_offset } = this.state
    var item = system

    var money_won = this.commify(Math.round(item.performance.last_30_days.units_won*this.state.units))
    var money_max_negative = this.commify(Math.abs(Math.round(largest_unit_offset * this.state.units)))
    var unit = this.commify(this.state.units)

    return(
      <View style={[{width:'100%', maxWidth:MAX_WIDTH, marginTop:32}, MainStyles.flexCenterStart]}>
        <Text style={{color:'white', fontSize:20, fontWeight:'700', paddingHorizontal:24}}>
        {"ESTIMATED RETURN"}
        </Text>
        <View style={[{width:'100%', padding:24, backgroundColor:'rgba(255,255,255,0.05)', borderRadius:16, marginBottom:16, marginTop:16}, MainStyles.flexCenter]}>
          <Text style={{color:'white', width:'100%', textAlign:'left', fontSize:15, opacity:0.5, lineHeight:18}}>
          Following this system for 30d with units of
          </Text>
          <View style={[{width:'100%', marginTop:16}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <Text style={{flex:1, color:'white', fontSize:32}}>
            {"$"+unit}
            </Text>

            {this.renderButton("⬇️", this.decrease)}
            {this.renderButton("⬆️", this.increase)}
          </View>

          <Text style={{color:'rgba(255,255,255,0.5)', width:'100%', textAlign:'left', fontSize:15, lineHeight:18, marginTop:16}}>
          You would have won <Text style={{color:'white', width:'100%', textAlign:'left', opacity:1, fontWeight:'700', lineHeight:18}}>{"$"+money_won}</Text> in the last 30 days
          </Text>

          <Text style={{color:'rgba(255,255,255,0.5)', width:'100%', textAlign:'left', fontSize:15, lineHeight:18, marginTop:16}}>
          You would have needed to put up <Text style={{color:'white', width:'100%', textAlign:'left', opacity:1, fontWeight:'700', lineHeight:18}}>{"$"+money_max_negative}</Text> ({Math.abs(Math.round(largest_unit_offset * 10)/10)} units) to fund this strategy
          </Text>
        </View>
      </View>
    )
  }

  renderButton = (text, onPress) => {
    return(
      <Pressable onPress={onPress} style={[{height:36, width:36, borderRadius:6, marginLeft:12}, MainStyles.flexCenter]}>
      <Text style={{fontSize:36}}>
      {text}
      </Text>
      </Pressable>
    )
  }

  renderDates = () => {
    var { dates } = this.state

    return(
      <View style={[{width:'100%', maxWidth:MAX_WIDTH, marginTop:32}, MainStyles.flexCenterStart]}>
        <Text style={{color:'white', fontSize:20, fontWeight:'700', paddingHorizontal:24}}>
        {"DAILY PICKS"}
        </Text>
        {dates.map((item, index)=>this.renderDate(item, index))}
      </View>
    )
  }

  renderDate = (item, index) => {
    return <Date item={item} index={index}/>
  }

  renderValuesRow = (title, data, noMargin) => {
    return(
      <View style={[{marginTop:noMargin ? 0 : 16}, MainStyles.flexCenterStart]}>
        {this.renderSectionTitle(title)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {(data || []).map((item, index) => this.renderValue(item))}
        </View>
      </View>
    )
  }


  render = () => {
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'#121212'}, MainStyles.flexStartCenter]}>
      {this.renderContent()}
      </View>
    )
  }
}

export default Landing;
