import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { View, Text, Pressable, Linking, Image, ActivityIndicator } from "react-native";


//Module
import ReactGA from 'react-ga';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from './mainstyles.js'
import GLOBAL from './global.js'

const MAX_WIDTH = 400
var moment = require('moment-timezone');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item:this.props.item,
      show:false,
    };
  }

  show = () => {
    this.setState({show:!this.state.show})
  }


  //Renders
  renderContent = () => {
    var { item } = this.state
    var picks = 0
    if(item.outcome){
      picks = item.outcome.total || 0
    }

    return(
      <View style={[{width:'100%', padding:24, backgroundColor:'rgba(255,255,255,0.05)', borderRadius:16, marginTop:16}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', marginBottom:!item.outcome ? 0 : 32}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <Text style={{color:'white', fontSize:15, fontWeight:'700'}}>
          {moment(item.date, 'YYYY-MM-DD').format("ddd, D MMM YYYY")}
          </Text>
          <View style={[{}, MainStyles.flexRow, MainStyles.flexCenter]}>
          {
            item.closed === false && picks ?
            <View style={[{padding:8, paddingVertical:4, borderRadius:4, backgroundColor:GLOBAL.colors.pink, marginRight:8}]}>
              <Text style={{color:'white', fontWeight:'700'}}>
              In progress
              </Text>
            </View>
            : null
          }
          {
            <View style={[{padding:8, paddingVertical:4, borderRadius:4, backgroundColor:!picks ? "rgba(255,255,255,0.1)" : GLOBAL.colors.purple}]}>
              <Text style={{color:'white', fontWeight:'700'}}>
              {(picks || "No")+(picks === 1 ? " pick made" : " picks made")}
              </Text>
            </View>
          }
          </View>
        </View>

        {
          !item.outcome ? null :
          <>
          {this.renderValues("Peformance", [
            {value:(item.outcome.units_won > 0 ? "+"+item.outcome.units_won : item.outcome.units_won), key:"Units won"},
            {value:Math.round(item.outcome.correct/item.outcome.complete*100)+"%", key:"Win rate"},
            {value:item.outcome.roi+"%", key:"ROI"},
            {value:item.outcome.total, key:"Total bets"},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
            {value:"", key:""},
          ], true)}
          </>
        }

        {
          !this.state.show ? null : this.renderBets()
        }

        {
          !item.outcome ? null :
          <Pressable onPress={this.show} style={[{width:"100%", height:48, backgroundColor:"#121212", borderRadius:8, marginTop:16}, MainStyles.flexCenter]}>
            <Text style={{color:'white', fontSize:15, fontWeight:'700'}}>
            {this.state.show ? "Hide bets" : "Show bets"}
            </Text>
          </Pressable>
        }

      </View>
    )
  }

  renderBets = () => {
    var bets = this.state.item.bets || []

    return(
      <View style={[{width:'100%'}, MainStyles.flexCenter]}>
      {bets.map((item, index)=>this.renderBet(item, index))}
      </View>
    )
  }

  renderBet = (item, index) => {
    var backgroundColor = item.closed || item.units_won ? (item.correct ? GLOBAL.colors.teal : GLOBAL.colors.pink) : 'rgba(255,255,255,0.05)'
    var string_status = item.closed || item.units_won ? (item.correct ? "Correct" : "Wrong") : "In Play"

    var units_won = Math.round((item.units_won || 0) * 100)/100

    return(
      <View style={{width:'100%', paddingVertical:12, borderTopWidth:1, borderTopColor:'rgba(255,255,255,0.05)'}}>
      <View style={[{width:'100%', marginBottom:32}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{color:'white', fontSize:15, fontWeight:'700'}}>
        Bet {index+1}
        </Text>
        <View style={[{padding:8, paddingVertical:4, borderRadius:4, backgroundColor}]}>
          <Text style={{color:'white', fontWeight:'700'}}>
          {string_status}
          </Text>
        </View>
      </View>
      {this.renderValues("", [
        {value:item.market, key:"Market"},
        // {value:item.bet, key:"Bet"},
        {value:item.bet_string.split(", ").reverse().join(" "), key:"Bet"},
        {value:item.odds, key:"Odds"},
        {value:(units_won > 0 ? "+"+units_won : units_won), key:"Units won"},
        {value:item.id, key:"Match ID"},
        {value:"", key:""},
        {value:"", key:""},
      ], true)}
      </View>
    )
  }

  renderValues = (title, data, noMargin) => {
    return(
      <View style={[{width:'100%', marginTop:noMargin ? 0 : 16}, MainStyles.flexCenterStart]}>
        {this.renderSectionTitle(title)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {(data || []).map((item, index) => this.renderValue(item))}
        </View>
      </View>
    )
  }

  renderValue = ({value, key}) => {
    if(value || value === 0){
      return(
        <View style={[{marginRight:32, marginBottom:16, width:'100%'}, MainStyles.flexCenterStart]}>
          <Text style={{color:'white', fontSize:15}}>
          {value}
          </Text>
          <Text style={{color:'white', fontSize:13, opacity:0.33, marginTop:4}}>
          {key}
          </Text>
        </View>
      )
    }else{
      return null
    }
  }

  renderSectionTitle = (title) => {
    if(title){
      return(
        <View style={[{width:'100%', marginBottom:16}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <Text style={{color:'white', fontSize:12, opacity:0.67, textTransform:'uppercase', fontWeight:'900', textAlign:'left'}}>
          {title}
          </Text>
          <View style={{flex:1, paddingLeft:16}}>
            <View style={{width:'100%', height:1, backgroundColor:'white', opacity:0.1}}/>
          </View>
        </View>
      )
    }else{return null}
  }

  renderTitle = () => {
    var { loading } = this.state
    return(
      <View style={{marginBottom:40, justifyContent:'center', alignItems:'center'}}>
        <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:24, textAlign:'center'}}>
        Live Betting Systems
        </Text>
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:13, textAlign:'center', opacity:0.5, marginTop:5}}>
        {"Tap a system to view its picks"}
        </Text>
      </View>
    )
  }


  render = () => {
    return(
      <View key={this.props.index} style={[{width:'100%'}, MainStyles.flexStartCenter]}>
      {this.renderContent()}
      </View>
    )
  }
}

export default Landing;
