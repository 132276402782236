import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, TextInput, ScrollView, SectionList, Dimensions } from "react-native";

import Bracket from './bracket.js'

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';
const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

var height = 120
var moment = require('moment-timezone');

var stringChangeTime = 8000

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      game:null,
    };
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  firestoreAPI = async (url_raw, params) => {
    var data = null

    var language = GLOBAL.language || 'en'

    var tz = await moment.tz.guess(true)
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    if(!params){
      params = {}
    }

    params.userId = 'test-user'
    params.streaming = true

    await axios
    .get(url, {params})
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  }

  setup = async () => {
    //Get the fantasy home
    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/fantasy_api"
    var data_r = await this.firestoreAPI(url, {mode:'home'})
    var { data } = data_r
    var { sections } = data

    //Promise through sections and clean
    await Promise.all(sections.map(async (section, index) => {
      if(section.type === 'leaderboard'){
        if(section.data){
          if(section.data.length === 1){
            var events = section.data[0].data
            sections[index].data = events
            section.showRow = true
          }
        }
      }
    }))

    this.setState({loading:false, sections})
  }

  openBracketGame = (game) => {
    //Override the docId
    // game.docId = "jzbuWmPW0KI1yxUx6Tj3"

    this.setState({game})
  }

  //


  renderContent = () => {
    var { game, loading } = this.state
    return(
      <View style={[{flex:1}, MainStyles.flexStartCenter]}>
        {loading ? this.renderLoading() : game ? this.renderBracket() : this.renderSelector()}
      </View>
    )
  }

  renderLoading = () => {
    return(
      <View style={[{flex:1}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color='black'/>
      </View>
    )
  }

  renderSelector = () => {
    return(
      <>
      {this.renderBracketChallenge()}
      </>
    )
  }

  renderBracketChallenge = () => {
    var { loading, sections } = this.state
    return(
      <SectionList
        ListHeaderComponent={this.renderFantasyHeader}
        sections={sections || []}
        style={{width:'100%', borderTopColor:GLOBAL.style.color_border, borderTopWidth:0}}
        contentContainerStyle={{paddingBottom:60}}
        keyExtractor={(item, index) => index}
        renderItem={this.renderGameRow}
        renderSectionHeader={({ section: { title, subtitle, hideSeparator, sectionTitle, hideTitle,  showLeaderboardAbove} }, index) => (this.renderTitle({title, subtitle, hideSeparator, sectionTitle, hideTitle, showLeaderboardAbove}))}
        stickySectionHeadersEnabled={false}
        numColumns={2}
        // onRefresh={()=>this.callAPI(true)}
        // refreshing={this.state.refreshing}
      />
    )

    // return
  }

  renderGameRow = ({item, index, type, leaderboard_mode}) => {
    var { activeIndex } = this.state

    if(!item){
      return null
    }else if(item.type === 'username'){
      return null
    }else if(item.type === 'leaderboards' || item.mode){
      return null
    }else{
      return(
        <View key={item.key} style={[MainStyles.flexCenterStart, {width:'100%', flexWrap:'wrap', paddingHorizontal:15}]}>
          {
            Array.isArray(item) ?
            item.map((item2, index2) => this.renderBracketGame({item:item2, index:index2}))
            :
            this.renderBracketGame({item:item, index:index})
          }
        </View>
      )
    }
  }

  renderBracketGame = ({item, index}) => {
    var margin = index % 2 === 0 ? {paddingRight:5} : {paddingLeft:5}

    var img_size = 28

    var tag = item.tag || {}
    var featured_players = item.featured_players || [null, null, null]
    var title = item.title || item.name
    var subtitle = item.subtitle || "Fantasy"
    var status = item.status
    var backgroundColor = '#121212'
    var color = "white"

    var player_size = dimensions.width / 3.6

    var small_device = dimensions.width < 300

    var logo = item.logo

    var bg1 = backgroundColor
    var bg2 = 'rgba(0,0,0,0.2)'

    var borderRadius = 5

    var bg_image = item.backgroundImage || "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"

    return(
      <View key={index} style={[{width:'100%', maxWidth:400, marginBottom:10}, (true ? null : margin)]}>
        <Pressable onPress={()=>this.openBracketGame(item)} style={[{width:'100%', height:110, borderRadius, backgroundColor, padding:0, overflow:'hidden'}, MainStyles.flexBetweenCenter]}>

          {
            <Image style={{width:'100%', height:'100%', position:'absolute', opacity:0.75}} source={{uri:bg_image}}/>

          }

          <View style={[{width:'100%', flex:1}, MainStyles.flexStartEnd]}>
            {
              !item.tag ? null :
              <View style={{paddingHorizontal:10, paddingVertical:10, backgroundColor:GLOBAL.style.alternatingBackground, borderBottomRightRadius:0, borderBottomLeftRadius:5}}>
                <Image
                style={[{
                  height:img_size,
                  width:img_size * (tag.aspectRatio || 2)
                  }]}
                source={{uri:tag.url}}
                // resizeMode={FastImage.resizeMode.contain}
                // tintColor={tag.tintColor || null}
                />
              </View>
            }
          </View>

          <View style={[{marginBottom:10, width:'100%', paddingBottom:0, paddingHorizontal:10}, MainStyles.flexCenterStart]}>

            <Text style={{fontSize:small_device ? 14 : 14, color, fontFamily:'Averta-Bold', letterSpacing:0, textAlign:'left'}} numberOfLines={2}>
            {title}
            </Text>
            <Text style={{fontSize:small_device ? 12 : 14, color, fontFamily:'Averta-Regular', opacity:0.5, marginTop:2.5}}>
            {subtitle}
            </Text>
          </View>

        </Pressable>
      </View>
      )
  }

  renderSeparator = (disable) => {
    if(!disable){
      return(
        <View style={{width:dimensions.width, paddingHorizontal:0}}>
          <View style={{width:'100%', height:1, backgroundColor:GLOBAL.style.color_border, marginVertical:15, opacity:0.5}}/>
        </View>
      )
    }else{return null}
  }

  renderTitle = ({title, subtitle, hideSeparator, sectionTitle, hideTitle, showLeaderboardAbove}) => {
    var { infoMessage, points, activeIndex } = this.state
    if(!hideTitle){
      return(
        <>
        {showLeaderboardAbove ? this.renderFantasyLeaderboard(showLeaderboardAbove) : null}
        {this.renderSeparator(hideSeparator)}
        <View style={{width:'100%', marginBottom:7.5, paddingHorizontal:15}}>
          {
            !sectionTitle ? null :
            <Text style={{color:GLOBAL.style.color_font, fontSize:20, marginBottom:5, fontFamily:'Averta-Black', textAlign:'left', width:'100%', letterSpacing:1.5, textTransform:'uppercase', paddingHorizontal:0}}>
            {sectionTitle}
            </Text>
          }

          {
            !title ? null :
            <Text style={{color:GLOBAL.style.color_font, fontSize:14, fontFamily:'Averta-Bold', textAlign:'left', width:'100%', letterSpacing:1.5, textTransform:'uppercase', paddingHorizontal:0}}>
            {title}
            </Text>
          }

          {
            !subtitle ? null :
            <Text style={{color:GLOBAL.style.color_font, fontSize:14, fontFamily:'Averta-Regular', textAlign:'left', width:'100%', letterSpacing:0, marginTop:2.5, opacity:0.5, paddingHorizontal:0}}>
            {subtitle}
            </Text>
          }
        </View>
        </>
      )
    }else{return null}
  }

  //
  renderBracket = () => {
    var { game } = this.state
    return(
      <>
      <Bracket game={game}/>
      </>
    )
  }

  renderHeader = () => {
    var { game } = this.state
    // if(!game){
      return(
        <View style={[{width:'100%', backgroundColor:'#121212', borderBottomColor:GLOBAL.style.color_border, borderBottomWidth:1, paddingHorizontal:15, paddingVertical:15, marginTop:0}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>

        <View style={[MainStyles.flexCenter]}>
          <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:40}}>
          TNNS
          </Text>
          <Text style={{color:GLOBAL.colors.volt, fontFamily:'Averta-Bold', fontSize:20, marginTop:-5}}>
          FANTASY
          </Text>
        </View>

        <View style={[MainStyles.flexCenterEnd, {marginLeft:0}]}>
          <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, opacity:0.5}}>
          JOIN THE GAME
          </Text>
          <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:20, marginTop:0}}>
          TNNSLIVE.COM
          </Text>
        </View>

        </View>
      )
    // }else{return null}
  }

  render() {
    var { loading, tippers } = this.state
    return(
      <>
      {this.renderHeader()}
      <ScrollView style={{flex:1, width:'100%', backgroundColor:'#121212'}} contentContainerStyle={{height:'100%'}} horizontal={true}>
        {this.renderContent()}
      </ScrollView>
      </>
    )
  }
}

export default Item;
