import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, ScrollView, SectionList, TextInput, Button } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

var height = 160
var width = 550
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

var animation_duration = 350

var blue = "#389CFF"


//Widths
var width_stat = 150

var borderColor = 'rgba(0,0,0,0.1)'
var borderWidth = 1

const row_width = 100

const { createClient } = require('@typeform/api-client')

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      videos:[],
      loading_string:"Fetching creator stats",
    };
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  //Functions
  setup = async () => {
    var users = []

    this.setState({loading_string:"Fetching the typeform responses"})

    //Get the typeform responses
    var data = await this.getTypeformResponses()

    this.setState({loading_string:"Got the typeform responses, processing each response and fetching their account info"})

    //For each user
    await Promise.all(data.map(async response => {
      var user_data = await this.handleReponse(response)
      users.push(user_data)
    }))

    //Sort by most recent to least
    await users.sort((a, b) => (a.rank < b.rank) ? 1 : -1);

    var last_24h = []
    await Promise.all(users.map(async response => {
      if(response.rank > (Date.now() - (24) * 60 * 60 * 1000)){
        last_24h.push(response)
      }
    }))

    this.setState({data:users, last_24h, loading:false})

    // //Export the file to a csv
    // await this.exportCSV(users)

  }

  exportCSV = async () => {
    var { data } = this.state
    const { Parser } = require('json2csv');

    const fields = ['name', 'state', 'address', 'age', 'birthday', 'instagram', 'instagram_followers', 'instagram_url', 'tiktok', 'tiktok_followers', 'tiktok_url', 'submitted_at'];

    //Package code
    const opts = { fields };
    const parser = new Parser(opts);
    const csvContent = parser.parse(data);

    // console.log(csvContent)

    const csvData = "data:text/csv;charset=utf-8,"+csvContent
    var encodedUri = encodeURI(csvData);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "casaazul_applications_"+moment().format('YYYY-MM-DD')+".csv");

    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }

  handleReponse = async (data) => {
    var answers = data.answers || []
    var tiktok = null
    var instagram = null

    var tiktok_field_id = "lHQFDgXULa3U"
    var ig_field_id = "gVwaItV4Qtrz"

    var ids = {
      ig:"",
      tt:"",
      name:"",
      state:"",
      birthday:"",
      address:"",
      phone_number:"",
    }

    var ids = {
      ["gVwaItV4Qtrz"]:'instagram',
      ["lHQFDgXULa3U"]:'tiktok',
      ["1NLFvW2Ga134"]:'name',
      ["QpU8tMx9F2IP"]:'birthday',
      ["YC15onzCEqSl"]:'state',
      ["o3GNKNG0JGrn"]:'address',
      ["Cf9QkePMiUAb"]:'phone_number',
    }

    var response_data = {}

    await Promise.all(answers.map(async answer => {
      if(ids[answer.field.id]){
        response_data[ids[answer.field.id]] = answer.text || answer.date || answer.phone_number || null
      }
    }))

    //Attach the form submission date
    response_data.submitted_at = data.submitted_at
    response_data.rank = Number(moment(data.submitted_at).format('x'))

    if(response_data.instagram){
      //Get the IG stats
      response_data.instagram_followers = await this.getInstagramFollowers(response_data.instagram)
      response_data.instagram_url = "https://instagram.com/"+response_data.instagram
    }

    if(response_data.tiktok){
      //Get the tt stats
      var followers = await this.getTiktokFollowers(response_data.tiktok)
      if(followers){
        response_data.tiktok_followers = followers.followers
      }else{
        response_data.tiktok_followers = "Account not found"
      }
      response_data.tiktok_url = "https://tiktok.com/@"+response_data.tiktok
    }

    //Calculate user age
    response_data.age = moment().diff(response_data.birthday, 'years') //26
    response_data.birthday = moment(response_data.birthday).format('YYYY-MM-DD') //1996-09-14

    return response_data

  }

  getTypeformResponses = async () => {
    var id = "GBTuGa7LwoCPi1XyZmVvUhx5nthXWg2anbGGsRWWy21u"
    var secret = "7NVtHNrEEfn2C3gdaT9jqMS9Wc3oZZQ1pxKDSDbHNNX4"
    var uid = "kHguqY3p" //Casa Azul main form

    var token = "tfp_x6egpREY88oQWv3HzN8Uxyga83kfMf7CW8XEAm5HWDK_3pf1vPwbieVKkX"

    var data = []

    const typeformAPI = createClient({ token })

    await typeformAPI.responses.list({
      uid,
      completed:true,
    }).then(response => {
      data = response.items || []
      // console.log(response.items)
      return
    })

    // console.log('Fetching responses')
    //
    // let url = new URL("https://api.typeform.com/forms/"+uid+"/responses")
    // // let params = {included_response_ids: id}
    // // url.searchParams.append("included_response_ids", id)
    // await fetch(url, {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: {
    //     "Authorization": `Bearer ${id}`,
    //     'Access-Control-Allow-Credentials':true
    //   },
    // })
    // .then(response => response.json())
    // .then((res) => {
    //   console.log('done')
    // console.log(res);
    // })
    // .catch(e => console.log(e))

    return data
  }

  getInstagramFollowers = async (username) => {
    var count = 'Account not found'
    username = "kimkardashian"
    const followers = require('instagram-followers');
    await followers(username).then(no => {
      // console.log(username, no)
        count = no
    });
    return count
  }

  getTiktokFollowers = async (username) => {
    var backup_api = true
    var data = await this.callAPI(username, backup_api)

    //Isolate the videos
    var videos = []

    // console.log(data)

    await this.setState({loading_string:"Successfully fetched creator videos.  Calculating engagement..."})

    try{
      if(backup_api){
        videos = data.data.aweme_list || []
      }else{
        videos = data.body.itemListData
      }

    }catch(e){
      console.log(e)
    }

    var followers = 0
    var followers_raw = 0
    var engagement_rate = 0

    var likes_all = 0
    var comments_all = 0
    var shares_all = 0
    var views_all = 0

    //Promise through videos and attach data
    await Promise.all(videos.map(async (video, index) => {

      if(backup_api){
        followers = await this.numberWithCommas(data.followers) //14976 14,976
        followers_raw = data.followers
      }

    }))

    return {followers, followers_raw}
  }

  getData = async () => {
    var limit = 10 //10 videos
    var { username } = this.state
    if(!username){
      alert("Don't forget to add a username!")
    }else{
      this.setState({loading:true})
      var backup_api = true
      var data = await this.callAPI(username, backup_api)

      //Isolate the videos
      var videos = []

      console.log(data)

      await this.setState({loading_string:"Successfully fetched creator videos.  Calculating engagement..."})

      try{
        if(backup_api){
          videos = data.data.aweme_list || []
        }else{
          videos = data.body.itemListData
        }

      }catch(e){
        console.log(e)
      }

      var followers = 0
      var followers_raw = 0
      var engagement_rate = 0

      var likes_all = 0
      var comments_all = 0
      var shares_all = 0
      var views_all = 0

      //Promise through videos and attach data
      await Promise.all(videos.map(async (video, index) => {

        if(backup_api){
          followers = await this.numberWithCommas(data.followers)
          followers_raw = data.followers

          if(index < limit){
            likes_all = likes_all + video.statistics.digg_count
            comments_all = comments_all + video.statistics.comment_count
            shares_all = shares_all + video.statistics.share_count
            views_all = views_all + video.statistics.play_count
          }

          var comments = await this.numberWithCommas(video.statistics.comment_count)
          var likes = await this.numberWithCommas(video.statistics.digg_count)
          var shares = await this.numberWithCommas(video.statistics.share_count)
          var views = await this.numberWithCommas(video.statistics.play_count)

          var engagement = Math.round((video.statistics.comment_count + video.statistics.digg_count + video.statistics.share_count) / followers_raw * 100 * 100) / 100 + "%"

          try{
            videos[index] = {
              image:video.video.animated_cover.url_list[0] || null,
              comments,
              likes,
              shares,
              engagement,
              views,
              id:video.aweme_id,
            }
          }catch(e){
            console.log(e)
          }

        }else{
          if(video.itemInfos){
            followers = await this.numberWithCommas(video.authorStats.followerCount)
            followers_raw = video.authorStats.followerCount

            if(index < limit){
              likes_all = likes_all + video.itemInfos.diggCount
              comments_all = comments_all + video.itemInfos.commentCount
              shares_all = shares_all + video.itemInfos.shareCount
              views_all = views_all + video.itemInfos.playCount
            }

            var comments = await this.numberWithCommas(video.itemInfos.commentCount)
            var likes = await this.numberWithCommas(video.itemInfos.diggCount)
            var shares = await this.numberWithCommas(video.itemInfos.shareCount)
            var views = await this.numberWithCommas(video.itemInfos.playCount)

            var engagement = Math.round((video.itemInfos.commentCount + video.itemInfos.diggCount + video.itemInfos.shareCount) / followers_raw * 100 * 100) / 100 + "%"

            try{
              videos[index] = {
                image:video.itemInfos.coversDynamic[0],
                comments,
                likes,
                shares,
                engagement,
                views,
                id:video.itemInfos.id,
              }
            }catch(e){
              console.log(e)
            }
          }
        }

        }))


      var engagement_rate = Math.round((likes_all + comments_all + shares_all) / videos.length / followers_raw * 100 * 100) / 100 + "%"

      likes_all = await this.numberWithCommas(likes_all)
      comments_all = await this.numberWithCommas(comments_all)
      shares_all = await this.numberWithCommas(shares_all)
      views_all = await this.numberWithCommas(views_all)

      if(videos.length > limit){
        videos = videos.slice(0,limit)
      }

      await this.setState({videos, likes_all, views_all, comments_all, shares_all, followers, engagement_rate, videos_loaded:true, loading:false})
    }
  }

  async numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  openVideo = (item) => {
     var { id } = item

     var url = "https://www.tiktok.com/@"+this.state.username+"/video/"+id

     window.open(url)
  }

  callAPI = async (user_name, backup_api) => {
    var data = null

    const axios = require("axios");

    //MAIN
    var options = {
      method: 'GET',
      url: 'https://tiktok-scraper2.p.rapidapi.com/user/videos',
      params: { user_name },
      headers: {
        'X-RapidAPI-Host': 'tiktok-scraper2.p.rapidapi.com',
        'X-RapidAPI-Key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9'
      }
    };

    if(backup_api){
      options = {
        method: 'GET',
        url: 'https://tiktok-best-experience.p.rapidapi.com/user/'+user_name+'/feed',
        // params: {max_cursor: '1630342868000'},
        headers: {
          'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com',
          'X-RapidAPI-Key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9'
        }
      };
    }

    await axios.request(options).then(function (response) {
    	data = response.data
    }).catch(function (error) {
    	console.error(error);
    });

    if(backup_api){
      //Need to get the followers
      var userId = null
      try{
        userId = data.data.aweme_list[0].author.uid
      }catch(e){
        console.log(e)
      }
      // alert(userId)
      var options_2 = {
        method: 'GET',
        url: 'https://tiktok-best-experience.p.rapidapi.com/user/id/'+userId+'/followers',
        headers: {
          'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com',
          'X-RapidAPI-Key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9'
        }
      }

      await axios.request(options_2).then(function (response) {
      	var data_user = response.data
        data.followers = data_user.data.total
      }).catch(function (error) {
      	console.error(error);
      });
    }

    return data
  }


  //Renders
  renderLoading = () => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color='black'/>
      </View>
    )
  }

  renderContent = () => {
    var { data, last_24h } = this.state
    // return(
    //   <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={[{width:'100%', paddingHorizontal:32, paddingTop:32}, MainStyles.flexStartCenter]}>
    //
    //     {
    //       <>
    //       {
    //         this.state.loading ?
    //         <View style={[MainStyles.flexCenter]}>
    //           <ActivityIndicator size='small' color='black'/>
    //           <Text>
    //           {this.state.loading_string}
    //           </Text>
    //         </View>
    //         :
    //         <>
    //         <Text style={{width:'100%', textAlign:'center', marginBottom:16}}>
    //         {data.length} responses received.  {last_24h.length} {last_24h.length === 1 ? "response" : "responses"} in the last 24 hours.
    //         </Text>
    //         <Button title={'Download CSV'} onPress={this.exportCSV} style={{marginTop:0}}/>
    //         </>
    //       }
    //       </>
    //     }
    //   </ScrollView>
    // )
    return(
      <>
      {
        this.state.loading ?
        <View style={[MainStyles.flexCenter]}>
          <ActivityIndicator size='small' color='black'/>
          <Text style={{marginTop:32}}>
          {this.state.loading_string}
          </Text>
        </View>
        :
        <>
        <Text style={{width:'100%', textAlign:'center', marginBottom:32}}>
        {data.length} responses received.  {last_24h.length} {last_24h.length === 1 ? "response" : "responses"} in the last 24 hours.
        </Text>
        <Button title={'Download CSV'} onPress={this.exportCSV} style={{marginTop:0}}/>
        </>
      }
      </>
    )
  }

  renderUsers = () => {
    var { data } = this.state
    return(
      <View style={[{width:'100%'}, MainStyles.flexCenter]}>
      {this.renderHeader(data[0])}
      {data.map(this.renderUser)}
      </View>
    )
  }

  renderHeader = (data) => {
    var keys = Object.keys(data)
    console.log(keys)
    // return(
    //   <View style={{width:'100%'}}>
    //   {keys.map(this.renderHeaderValue)}
    //   </View>
    // )
  }

  renderHeaderValue = (data, length) => {
    return(
      <View style={{width:'100%', flex:1/length}}>
      <Text>
      {data}
      </Text>
      </View>
    )
  }

  renderUser = (data) => {
    var values = Object.values(data)
    return(
      <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
      {values.map(this.renderValue)}
      </View>
    )
  }

  renderValue = (data) => {
    return(
      <View style={{width:row_width, height:60, padding:8, borderWidth:1, borderColor:'black', marginRight:-1, marginBottom:-1}}>
      <Text style={{fontSize:12}}>
      {data}
      </Text>
      </View>
    )
  }

  render = () => {
    var { loading, videos_loaded } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'white'}, MainStyles.flexCenter]}>
        {this.renderContent()}
      </View>
    )
  }
}

export default Item;
