import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 1920
var spacing = 10
var spacing_main = 20
var fontSize = 40
var moment = require('moment-timezone');

var colors = {
  primary:'#17171D',
  secondary:'white',
  accent:'#60348C',
  score_bg:'#1B1B20',
  score_font:'white',
  bg_opacity:0.2,
  main_font:'white',
  bg_stats:'#17171D',
  logo:null,
}

var volt = '#B6FE00'

var backgroundColor = 'rgba(0,0,0,0.15)'
var borderColor = 'rgba(255,255,255,0.15)'


//Wimbledon theme
// colors = {
//   primary:"white",
//   secondary:'#007945',
//   accent:"#60348C",
//   score_bg:'rgba(255,255,255,0.5)',
//   score_font:'#17171D',
//   bg_opacity:0.2,
//   main_font:'black',
//   bg_stats:'#E2E2E4',
//   logo:'#60348C',
// }
// borderColor = 'rgba(0,0,20,0.5)'
// backgroundColor = 'rgba(0,0,20,0.04)'


var title_style = {fontFamily:'Averta-Bold', color:colors.main_font, fontSize:24, letterSpacing:0, textTransform:'uppercase'}

var stringChangeTime = 8000

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var updateTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      activeIndex:0,
      activeIndex_season_stats:-1,
      activeIndex_match_stats:0,
      match_stats:[],
      match_stats_array:[],
      matches: [],
      id: null,

      loading:true,
      firstSet:true,
      firstSetMatches:true,

      score_opacity: new Animated.Value(1),
      stats_opacity: new Animated.Value(1),
      season_stats_opacity: new Animated.Value(1),

      mode:'selectMatch',
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      matchTime:'0:00',
      matchInfo:'',
      fan_votes:null,

      activeIndex_stats:-1,
      activeIndex_tippers:-1,

      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',

      images:[null, null],
      names:[null, null],

      stats_season:{},
    };
  }

  componentDidMount = async () => {
    this.setup_LiveScores()
    this.changeStatsDataShown()
    this.start('Court Phillipe Chatrier', 'Court 2')
    // this.start('Court 2')
    // this.setCourts()
  }

  componentWillUnmount = async () => {
    this.cancelListeners()
    clearTimeout(this.updateString_tippers)
    clearTimeout(this.updateString_stats)
    clearTimeout(this.updateCourtSchedule)
  }

  cancelListeners = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    if(this.listenVote){
      this.listenVote()
    }
  }

  reset = async () => {
    var { court } = this.state

    await this.setState({
      activeIndex:0,
      activeIndex_match_stats:0,
      match_stats:[],
      match_stats_array:[],
      matches: [],
      id: null,

      loading:true,
      firstSet:true,
      firstSetMatches:true,

      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      matchTime:'0:00',
      matchInfo:'',
      fan_votes:null,

      odds:null,
      odds_string:null,

      activeIndex_stats:-1,
      activeIndex_tippers:-1,

      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
      images:[null, null],
      names:[null, null],
      stats_season:{},
      up_next:null,
    })

    this.setup_LiveScores()
    this.changeStatsDataShown()
    this.start(court)
  }


  //Main
  setCourts = async () => {
    this.setState({
      courts:['Centre Court', 'Court 1', 'Court 2']
    })
  }

  start = async (court, court_backup) => {
    // var court = prompt('Which court do you want to show scores for?')
    // var date = prompt('Which date? Format needs to be YYYY-MM-DD.')

    // var court = 'Centre Court'
    var date = '2022-05-22'
    var tournament = 'French Open'
    // tournament = 'ITF Turkey'

    await this.setState({court, court_backup, date, tournament})

    this.setupSchedule()
  }

  setupSchedule = async (courtOverride) => {
    console.log('Checking schedule for court')

    var { court, date, tournament } = this.state

    var tournament_id = 'IYLAzJIlLTSQwgG9mcXZ' //French Open
    // tournament_id = "oA0npajA8njOkI44Dlvf"

    //Get the schedule for that tournament & date
    var matches = []

    await firebase.firestore()
    .collection('tournaments').doc(tournament_id)
    .collection('scheduled').doc(date)
    .get()
    .then(doc => {
      matches = doc.data().matches || []
    })

    //Filter out matches for that court
    var court_matches = []

    var last_match = null
    var next_match = null
    var active_match = null

    var finished = []
    var scheduled = []
    var up_next_array = []
    var live = null

    var live_statuses = ['match_about_to_start', 'about_to_start', '1st_set', '2nd_set', '3rd_set', '4th_set', '5th_set', 'interrupted', 'live']
    var scheduled_statuses = ['not_started', 'start_delayed']

    var court_filter = courtOverride || court

    await Promise.all(matches.map(async match => {
      match.start_time_timestamp = match.resume_time ? match.resume_time.seconds : Number(match.start_time_timestamp)
      if(match.court_name === court_filter){
        if(live_statuses.includes(match.match_status) || live_statuses.includes(match.status)){
          live = match
        }
        if(match.finishedAt){
          finished.push(match)
        }
        if(scheduled_statuses.includes(match.match_status)){
          scheduled.push(match)
        }
      }
      if(match.court_name === court){
        if(scheduled_statuses.includes(match.match_status)){
          up_next_array.push(match)
        }
      }
    }))

    //If main court is not playing, fall back
    if(!live && finished.length === 0 && !courtOverride){
      //No matches, try the other court
      console.log('Moving to backup court')
      this.setupSchedule(this.state.court_backup)
    }else{
      //Sort the matches
      await finished.sort((a, b) => (a.finishedAt < b.finishedAt) ? 1 : -1); //Largest value first
      await scheduled.sort((a, b) => (a.start_time_timestamp > b.start_time_timestamp) ? 1 : -1); //Smallest value first
      await up_next_array.sort((a, b) => (a.start_time_timestamp > b.start_time_timestamp) ? 1 : -1); //Smallest value first

      //Figure out which match to show
      var match = null
      if(live){
        match = live.id
      }else if(finished.length > 0){
        match = finished[0].id
      }else if(scheduled.length > 0){
        //Show next scheduled
        match = scheduled[0].id
      }

      //Set scheduled match
      var up_next = null
      if(up_next_array.length > 0){
        var scheduled_match = up_next_array[0]
        var scheduled_competitors = scheduled_match.competitors
        // title, score, subtitle, image_1, image_2
        var title = 'Coming up on '+court
        var score = ((scheduled_competitors[0].name).split(", "))[0] + " vs " + ((scheduled_competitors[1].name).split(", "))[0]
        var subtitle = scheduled_match.start_time_timestamp < Date.now() ? "About to start" : 'Starts ' + moment(scheduled_match.start_time_timestamp, 'x').fromNow()
        up_next = {
          title,
          score,
          subtitle,
          image_1: match.competitor_photos ?  match.competitor_photos[0] : null,
          image_2: match.competitor_photos ?  match.competitor_photos[1] : null,
        }
      }

      this.setState({up_next})

      if(match && match !== this.state.id){
        //Changing match, reset the board
        if(this.state.id === null){
          this.selectMatch(match)
          // this.selectMatch("sr:sport_event:27887872")
        }else{
          this.reset()
        }
      }

      console.log('Matches scheduled', scheduled)

      //Set timeout to re-check every minute
      this.updateCourtSchedule = setTimeout(async () => {
        this.setupSchedule()
        // this.reset()
      },60 * 1000)
    }
  }

  selectMatch = async (id) => {
    await this.changeTheme()
    await this.setState({id})

    //Cancel old listeners
    await this.cancelListeners()

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .onSnapshot(this.handleMatch)
  }

  handleMatch = async ( querySnapshot ) => {
    var match = {}
    var docId = null

    if(querySnapshot.size > 0){
      await querySnapshot.forEach(async (doc) => {
        match = doc.data()
        docId = doc.id

        //Correct the player names
        if(match.competitors[0].name.includes(', ')){
          match.competitors[0].name_full = (match.competitors[0].name.split(", "))[1] + " " + (match.competitors[0].name.split(", "))[0]
          match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
        }

        if(match.competitors[1].name.includes(', ')){
          match.competitors[1].name_full = (match.competitors[1].name.split(", "))[1] + " " + (match.competitors[1].name.split(", "))[0]
          match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
        }
      })
    }

    //Calculate the matchTime
    var durationString = null
    if(match.startedAt){
      var startedAt = match.startedAt
      var finishedAt = !match.finishedAt ? Date.now() : match.finishedAt

      var duration = (moment.duration(finishedAt - startedAt).as('hours')).toString()

      //Clean string
      var durationHours = Number(duration.split('.')[0])
      if(durationHours < 6){
        var durationMinutesNumber = Math.round(Number("."+duration.split('.')[1]) * 60)
        var durationMinutesRaw = durationMinutesNumber == 60 ? 59 : durationMinutesNumber
        var durationMinutes = durationMinutesRaw.toString().length == 1 ? "0"+durationMinutesRaw : durationMinutesRaw
        durationString = durationHours + ':' + durationMinutes
      }
    }

    //Calculate the duration string
    var matchTime = durationString ? durationString : null

    //
    if(match.match_status.includes('_')){
      match.match_status = (match.match_status.split("_")).join(" ")
    }

    if(match.match_status === 'closed' || match.match_status === 'ended'){
      match.match_status = 'Finished'
    }

    if(match.match_status === 'not started'){
      match.match_status = 'Starts '+moment(match.start_time_timestamp, 'x').fromNow()
    }

    match.competitor_photos = match.competitor_photos || [null, null]

    //Match Info string
    var matchInfo = match.season_name.split(", ")[0] + " - " + match.round

    if(this.state.firstSet){
      //Get the probability for the match
      var odds = null
      await firebase.firestore()
      .collection('matches_sr').doc(docId)
      .collection('info').doc('data')
      .get()
      .then(doc => {
        if(doc.exists && doc.data()){
          odds = doc.data().probability || null
        }
      })

      if(odds){
        var odds_index = odds[0].probability > odds[1].probability ? 0 : 1
        var odds = Math.round(odds[odds_index].probability) + "% "
        var odds_string = odds + ((match.competitors[odds_index].name).split(", "))[0]
        this.setState({odds_string, odds})
      }
    }

    await this.setState({match, matchTime, matchInfo, loading:false, mode:'match'})

    if(this.state.firstSet && docId){
      this.setupListeners(docId, match)
      this.setupPlayers(match)
    }

    this.setState({firstSet:false})
  }

  //Other scores
  setup_LiveScores = async () => {
    clearTimeout(this.updateData)

    var refreshTime = 60 * 1000 //20s refresh time

    //Arrays
    var live_raw = []
    var completed_raw = []
    // var scheduled_raw = []
    var matches_clean = []

    var show_live = true
    var show_completed = true
    var show_doubles = false
    var show_singles = true
    var show_atp = true
    var show_wta = false

    if(show_live){
      //Get Live
      await firebase.firestore()
      .collection('summaries_sr').doc('live_atp')
      .get()
      .then(async (doc) => {
        live_raw = Object.values(doc.data().matches)
      })

      await firebase.firestore()
      .collection('summaries_sr').doc('live_wta')
      .get()
      .then(async (doc) => {
        live_raw = live_raw.concat(Object.values(doc.data().matches))
      })
    }

    if(show_completed){
      //Get completed
      await firebase.firestore()
      .collection('summaries_sr').doc('completed_atp')
      .get()
      .then(async (doc) => {
        completed_raw = Object.values(doc.data().matches)
      })

      await firebase.firestore()
      .collection('summaries_sr').doc('completed_wta')
      .get()
      .then(async (doc) => {
        completed_raw = completed_raw.concat(Object.values(doc.data().matches))
      })
    }

    // if(completed_raw.length === 0){
    //   //Get completed
    //   await firebase.firestore()
    //   .collection('summaries_sr').doc('scheduled_atp')
    //   .get()
    //   .then(async (doc) => {
    //     completed_raw = Object.values(doc.data().matches)
    //   })
    //
    //   await firebase.firestore()
    //   .collection('summaries_sr').doc('scheduled_wta')
    //   .get()
    //   .then(async (doc) => {
    //     completed_raw = completed_raw.concat(Object.values(doc.data().matches))
    //   })
    // }

    //Setup the allowed formats and tour array
    var allowed_tours = []
    var allowed_formats = []

    if(show_singles){allowed_formats.push('singles')}
    // if(show_doubles){allowed_formats.push('doubles')}

    var live = []
    var completed = []

    //Promise through Live, filter tours and formats
    await Promise.all(live_raw.map(async match => {
      if(allowed_tours.includes(match.category) && allowed_formats.includes(match.type)){
        live.push(match)
      }
    }))

    //Promise through Completed, filter tours and formats and any matches finished more than X hours ago
    var completed_limit = 24 * 60 * 60 * 1000 //Within last 24 hours
    await Promise.all(completed_raw.map(async match => {
      var push_match = allowed_formats.includes(match.type) && (Number(match.start_time_timestamp) >= (Date.now() - completed_limit))
      if(push_match){
        completed.push(match)
      }
    }))

    console.log('Live matches', live_raw.length)
    console.log('Completed matches', completed_raw.length)

    //Sort live by startedAt
    await live.sort((a, b) => (a.startedAt > b.startedAt) ? 1 : -1);

    //Sort completed by finishedAt
    await completed.sort((a, b) => (a.finishedAt < b.finishedAt) ? 1 : -1);

    if(live.length > 12){
      completed = completed.slice(0, (completed.length > 10 ? 8 : completed.length-1))
    }

    var matches = live.concat(completed)

    //For each match, push into the ticker format
    await Promise.all(matches.map(async match => {
      var winner = match.winner_id === match.competitors[0].id ? match.competitors[0] : match.competitors[1]
      var loser = match.winner_id === match.competitors[0].id ? match.competitors[1] : match.competitors[0]

      //Check if we need to reverse the score string
      var reverseScore = false
      if(match.winner_id){
        reverseScore = match.winner_id === match.competitors[1].id
      }else{
        if(match.away_score > match.home_score){
          reverseScore = true
        }else if(match.home_score === match.away_score){
          //Tied, see if one player is leading the set
          if(match.period_scores){
            if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){

            }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
              reverseScore = true
            }else{

            }
          }
        }
      }

      //Score string
      var score_string = await this.generateResultString(match, match.tiebreak_scores, reverseScore)

      match.score_string = score_string

      var winnerName = winner.name
      var loserName = loser.name

      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }

      //Country corrections
      // var countries_fix = ["GRE", "GER", "LAT"]
      var country_corrections = {
        ["GRE"]:'GRC',
        ["GER"]:'DEU',
        ["LAT"]:'LVA',
        ["CRO"]:'HRV',
        ["SUI"]:'CHE',
      }
      var countries_fix = Object.keys(country_corrections)

      await Promise.all(match.competitors.map(async (player, p_index) => {
        if(countries_fix.includes(player.country_code)){
          player.country_code = country_corrections[player.country_code]
        }
      }))

      //Country code
      var code_1 = await convertIso3Code(match.competitors[0].country_code)
      var code_2 = await convertIso3Code(match.competitors[1].country_code)
      match.competitors[0].country_cca = code_1 ? code_1.iso2 : null
      match.competitors[1].country_cca = code_2 ? code_2.iso2 : null

      //Sort the order of players depending on who is leading
      if(match.winner_id){
        //Middle string
        match.middleString = 'def'

        //Match has finished
        if(match.winner_id === match.competitors[1].id){
          //Reverse the players
          match.competitors = [
            match.competitors[1],
            match.competitors[0],
          ]
        }
      }else{
        match.middleString = 'leads'

        try{
          //On-going, check who is leading
          if(match.home_score > match.away_score){

          }else if(match.away_score > match.home_score){
            match.competitors = [
              match.competitors[1],
              match.competitors[0],
            ]
          }else{
            //Tied, see if one player is leading the set
            if(match.period_scores){
              if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){
                match.middleString = 'leads'
              }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
                match.competitors = [
                  match.competitors[1],
                  match.competitors[0],
                ]
                match.middleString = 'leads'
              }else{
                match.middleString = 'vs'
              }
            }else{
              match.middleString = 'vs'
            }
          }
        }catch(e){
          console.log('Error', e)
        }
      }

      // var other_match = {
        // title:'Federer leads Berrettini',
        // score:"6-3 4-6 3-2",
        // subtitle:'FRENCH OPEN - R16',
        // image_1:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/federer_head_ao20.png",
        // image_2:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/berrettini_head_ao20.png"
      // }

      var lastname_1 = (match.competitors[0].name.split(". "))[1].toLowerCase()
      var lastname_2 = (match.competitors[1].name.split(". "))[1].toLowerCase()

      var seed_1 = match.seeds[match.competitors[0].id] ? " [" + match.seeds[match.competitors[0].id] + "]" : ''
      var seed_2 = match.seeds[match.competitors[1].id] ? " [" + match.seeds[match.competitors[1].id] + "]" : ''

      var images = match.competitor_photos || [null, null]

      var title = lastname_1 + seed_1 + " " + match.middleString + " " + lastname_2 + seed_2
      var image_1 = images[0]
      var image_2 = images[1]

      // if(lastname_2 === 'rodionov'){
      //   image_2 = "https://www.atptour.com/en/players/jurij-rodionov/r09x/www.atptour.com/-/media/tennis/players/head-shot/2017/rodionov_head17.png"
      // }
      // if(lastname_1 === 'djokovic'){
      //   image_1 = "https://www.atptour.com/-/media/tennis/players/head-shot/2019/djokovic_head_ao19.png"
      // }

      matches_clean.push({
        title,
        score: score_string || 'WARMING UP',
        subtitle: match.season_name.split(", ")[0] + " - " + (match.round || 'Qualifying'),
        image_1,
        image_2
      })

    }))

    console.log('Matches clean', matches_clean.length)

    //Set the width of cards and cards per rotation
    // var matches_per_render = Math.floor(((dimensions.width - 100) / match_width))
    var matches_per_render = 1
    await this.setState({loading:false, matches:matches_clean, matches_per_render})

    if(this.state.firstSetMatches){
      await this.setState({firstSetMatches:false})
      this.changeDataShown()
    }

    //Set timeout
    this.updateData = setTimeout(async () => {
      this.setup_LiveScores()
    },refreshTime)
  }

  generateResultString = async (data, tiebreak_scores, reverseScore) => {
    var score = ''

    try{
      var scores = data.period_scores || []
      //Check the winner to decide order
      var winner_id = reverseScore ? data.competitors[1].id : data.competitors[0].id
      var winner_score_id = winner_id === data.competitors[0].id ? 'home_score' : 'away_score'
      var loser_score_id = winner_id === data.competitors[1].id ? 'home_score' : 'away_score'
      var winner_tb_id = winner_id === data.competitors[0].id ? 'home' : 'away'
      var loser_tb_id = winner_id === data.competitors[1].id ? 'home' : 'away'

      var middle = "-"

      //Create the set strings
      var stringsArray = []
      for (let i = 0; i < scores.length; i++) {
        var scoreString = scores[i][winner_score_id] + middle + scores[i][loser_score_id]

        //Check if there was a tiebreaker
        if((scores[i][winner_score_id] === 7 && scores[i][loser_score_id] === 6) && tiebreak_scores){
          //Winner won the set in tb
          if(tiebreak_scores[i+1]){
            if(tiebreak_scores[i+1][loser_tb_id]){
              scoreString = scores[i][winner_score_id] + middle + scores[i][loser_score_id] +"("+tiebreak_scores[i+1][loser_tb_id]+")"
            }
          }
        }
        if((scores[i][winner_score_id] === 6 && scores[i][loser_score_id] === 7) && tiebreak_scores){
          //Winner lost the set in tb
          if(tiebreak_scores[i+1]){
            if(tiebreak_scores[i+1][winner_tb_id]){
              scoreString =  scores[i][winner_score_id] + middle + scores[i][loser_score_id] + "("+tiebreak_scores[i+1][winner_tb_id] + ")"
            }
          }
        }

        stringsArray.push(
          scoreString
        )
      }

      score = stringsArray.join("  ")

      //Walkover handling
      if(data.match_status === 'walkover'){
        score = "Walkover"
      }

      //Retirement handling
      if(data.match_status === 'retired'){
        score = score + " ret"
      }

      if(data.match_status === 'defaulted'){
        score = score + " def"
      }
    }catch(e){
      console.log('Error', e)
    }

    //Return the string
    return score
  }

  changeDataShown = async () => {
    clearTimeout(this.updateView)

    var { matches_per_render, activeIndex, matches } = this.state

    var nextIndex = activeIndex + 1 //starting index of next render

    await this.setState({changingMatches:true})

    var animation_duration = 500

    this.updateOpacity = setTimeout(async () => {
      await Animated.timing(this.state.score_opacity, {
        toValue: 0,
        duration: animation_duration,
      }).start();
    }, updateTime - animation_duration)

    this.updateOpacity2 = setTimeout(async()=>{
      // Fade In new text
      await Animated.timing(this.state.score_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, updateTime)

    this.updateView = setTimeout(async()=>{
      // setTimeout(async () => {
        var changingIndex = nextIndex < (matches.length) ? nextIndex : 0
        await this.setState({activeIndex:changingIndex, changingMatches:false})

        // console.log('Starting index changed to', changingIndex, 'Matches per render', matches_per_render, 'Matches in carousel', matches.length, matches[changingIndex])
      // }, animation_duration)

      this.changeDataShown()
    }, updateTime)
  }


  //Stats board
  changeStatsDataShown = async () => {
    clearTimeout(this.updateSeasonStats)

    //Setup stats
    var statsArray = [
      {
        header:"2021 ATP Season",
        title:'Serve Leaders',
        players:[
          {firstName:'John', lastName:'Isner', rank:'1', value:'316', image:'https://www.atptour.com/-/media/alias/player-headshot/I186', flag:'US'},
          {firstName:'Milos', lastName:'Raonic', rank:'2', value:'308', image:'https://www.atptour.com/-/media/alias/player-headshot/R975', flag:'CA'},
          {firstName:'Reilly', lastName:'Opelka', rank:'3', value:'302', image:'https://www.atptour.com/-/media/alias/player-headshot/O522', flag:'US'},
          {firstName:'Nick', lastName:'Kyrgios', rank:'4', value:'300', image:'https://www.atptour.com/-/media/alias/player-headshot/KE17', flag:'AU'},
          {firstName:'Roger', lastName:'Federer', rank:'5', value:'296', image:'https://www.atptour.com/-/media/alias/player-headshot/F324', flag:'CH'},
        ]
      },
      {
        header:"2021 ATP Season",
        title:'Return Leaders',
        players:[
          {firstName:'Rafael', lastName:'Nadal', rank:'1', value:'170', image:'https://www.atptour.com/-/media/alias/player-headshot/N409', flag:'ES'},
          {firstName:'Diego', lastName:'Schwartzman', rank:'2', value:'169', image:'https://www.atptour.com/-/media/alias/player-headshot/SM37', flag:'AR'},
          {firstName:'Novak', lastName:'Djokovic', rank:'3', value:'168', image:'https://www.atptour.com/-/media/alias/player-headshot/D643', flag:'RS'},
          {firstName:'Daniil', lastName:'Medvedev', rank:'4', value:'158', image:'https://www.atptour.com/-/media/alias/player-headshot/MM58', flag:'RU'},
          {firstName:'Pedro', lastName:'Martinez', rank:'5', value:'158', image:'https://www.atptour.com/-/media/alias/player-headshot/MO44', flag:'ES'},
        ]
      },

      {
        header:"2021 ATP Season",
        title:'Pressure Leaders',
        players:[
          {firstName:'Lorenzo', lastName:'Musetti', rank:'1', value:'257', image:'https://www.atptour.com/-/media/alias/player-headshot/M0EJ', flag:'IT'},
          {firstName:'Novak', lastName:'Djokovic', rank:'2', value:'254', image:'https://www.atptour.com/-/media/alias/player-headshot/D643', flag:'RS'},
          {firstName:'Rafael', lastName:'Nadal', rank:'3', value:'245', image:'https://www.atptour.com/-/media/alias/player-headshot/N409', flag:'ES'},
          {firstName:'Federico', lastName:'Coria', rank:'4', value:'243', image:'https://www.atptour.com/-/media/alias/player-headshot/CE77', flag:'AR'},
          {firstName:'Dominic', lastName:'Thiem', rank:'5', value:'237', image:'https://www.atptour.com/-/media/alias/player-headshot/TB69', flag:'AT'},
        ]
      },
      {
        header:"2021 ATP Season",
        title:'Singles Rankings',
        players:[
          {firstName:'Novak', lastName:'Djokovic', rank:'1', value:'12,113', image:'https://www.atptour.com/-/media/alias/player-headshot/D643', flag:'RS'},
          {firstName:'Daniil', lastName:'Medvedev', rank:'2', value:'10,280', image:'https://www.atptour.com/-/media/alias/player-headshot/MM58', flag:'RU'},
          {firstName:'Rafael', lastName:'Nadal', rank:'3', value:'8,630', image:'https://www.atptour.com/-/media/alias/player-headshot/N409', flag:'ES'},
          {firstName:'Stefanos', lastName:'Tsitsipas', rank:'4', value:'7,980', image:'https://www.atptour.com/-/media/alias/player-headshot/te51', flag:'GR'},
          {firstName:'Dominic', lastName:'Thiem', rank:'5', value:'7,425', image:'https://www.atptour.com/-/media/alias/player-headshot/TB69', flag:'AT'},
        ]
      },
      {
        header:"2021 WTA Season",
        title:'Singles Rankings',
        players:[
          {firstName:'Ashleigh', lastName:'Barty', rank:'1', value:'7875', image:'https://photoresources.wtatennis.com/photo-resources/2020/09/30/d05d8377-816c-4499-bc48-5e307c893cc7/Barty_Hero-Smile.png?width=1900', flag:'AU'},
          {firstName:'Naomi', lastName:'Osaka', rank:'2', value:'7346', image:'https://photoresources.wtatennis.com/photo-resources/2021/01/19/f5e01763-eee7-449f-999a-dcee1011c20e/Osaka_Hero-Smile.png?width=1900', flag:'JP'},
          {firstName:'Simona', lastName:'Halep', rank:'3', value:'6330', image:'https://photoresources.wtatennis.com/photo-resources/2019/10/08/62ecc4f9-a397-4c6f-9c8c-cea093c5ee9c/WzQOhOCP.png?width=1900', flag:'RO'},
          {firstName:'Aryna', lastName:'Sabalenka', rank:'4', value:'6195', image:'https://photoresources.wtatennis.com/photo-resources/2020/09/30/077d0d47-22d6-4efc-b3a9-e13ff64b0934/Sabalenka_Hero-Smile.png?width=1900', flag:'BY'},
          {firstName:'Elina', lastName:'Svitolina', rank:'5', value:'5835', image:'https://photoresources.wtatennis.com/photo-resources/2021/01/12/d3ab2f03-ce4c-483f-9174-97b29d0bed3d/Svitolina_Hero-Smile.png?width=1900', flag:'UA'},
        ]
      },
      {
        header:"2021 ATP Season",
        title:'Singles Race',
        players:[
          {firstName:'Novak', lastName:'Djokovic', rank:'1', value:'5,170', image:'https://www.atptour.com/-/media/alias/player-headshot/D643', flag:'RS'},
          {firstName:'Stefanos', lastName:'Tsitsipas', rank:'2', value:'4,560', image:'https://www.atptour.com/-/media/alias/player-headshot/te51', flag:'GR'},
          {firstName:'Andrey', lastName:'Rublev', rank:'3', value:'3,070', image:'https://www.atptour.com/-/media/alias/player-headshot/re44', flag:'RU'},
          {firstName:'Alexander', lastName:'Zverev', rank:'4', value:'3,015', image:'https://www.atptour.com/-/media/alias/player-headshot/z355', flag:'DE'},
          {firstName:'Rafael', lastName:'Nadal', rank:'5', value:'2,940', image:'https://www.atptour.com/-/media/alias/player-headshot/N409', flag:'ES'},
        ]
      },
      {
        header:"2021 WTA Season",
        title:'Singles Race',
        players:[
          {firstName:'Ashleigh', lastName:'Barty', rank:'1', value:'3381', image:'https://photoresources.wtatennis.com/photo-resources/2020/09/30/d05d8377-816c-4499-bc48-5e307c893cc7/Barty_Hero-Smile.png?width=1900', flag:'AU'},
          {firstName:'Barbora', lastName:'Krejcikova', rank:'2', value:'3258', image:'https://photoresources.wtatennis.com/photo-resources/2020/09/30/51357b9f-48f7-48ab-948a-f86e07e5dae6/Krejcikova_Hero-Smile.png?width=1900', flag:'CZ'},
          {firstName:'Aryna', lastName:'Sabalenka', rank:'3', value:'2758', image:'https://photoresources.wtatennis.com/photo-resources/2020/09/30/077d0d47-22d6-4efc-b3a9-e13ff64b0934/Sabalenka_Hero-Smile.png?width=1900', flag:'BY'},
          {firstName:'Naomi', lastName:'Osaka', rank:'4', value:'2536', image:'https://photoresources.wtatennis.com/photo-resources/2021/01/19/f5e01763-eee7-449f-999a-dcee1011c20e/Osaka_Hero-Smile.png?width=1900', flag:'JP'},
          {firstName:'Iga', lastName:'Swiatek', rank:'5', value:'2440', image:'https://photoresources.wtatennis.com/photo-resources/2020/07/02/a2e26a27-95bd-4c5c-9662-7f0d22603b74/Swiatek_Hero-Smile.png?width=1900', flag:'PL'},
        ]
      },
      {
        header:"2021 ATP Season",
        title:'YTD Titles',
        players:[
          {firstName:'Novak', lastName:'Djokovic', rank:'1', value:'3', image:'https://www.atptour.com/-/media/alias/player-headshot/D643', flag:'RS'},
          {firstName:'Rafael', lastName:'Nadal', rank:'2', value:'2', image:'https://www.atptour.com/-/media/alias/player-headshot/N409', flag:'ES'},
          {firstName:'Stefanos', lastName:'Tsitsipas', rank:'3', value:'2', image:'https://www.atptour.com/-/media/alias/player-headshot/te51', flag:'GR'},
          {firstName:'Matteo', lastName:'Berrettini', rank:'4', value:'2', image:'https://www.atptour.com/-/media/alias/player-headshot/bk40', flag:'IT'},
          {firstName:'Daniil', lastName:'Medvedev', rank:'5', value:'2', image:'https://www.atptour.com/-/media/alias/player-headshot/MM58', flag:'RU'},
        ]
      },
      {
        header:"2021 ATP Season",
        title:'YTD Win/Loss',
        players:[
          {firstName:'Novak', lastName:'Djokovic', rank:'1', value:'90%', image:'https://www.atptour.com/-/media/alias/player-headshot/D643', flag:'RS'},
          {firstName:'Rafael', lastName:'Nadal', rank:'2', value:'85%', image:'https://www.atptour.com/-/media/alias/player-headshot/N409', flag:'ES'},
          {firstName:'Stefanos', lastName:'Tsitsipas', rank:'3', value:'81%', image:'https://www.atptour.com/-/media/alias/player-headshot/te51', flag:'GR'},
          {firstName:'Daniil', lastName:'Medvedev', rank:'4', value:'81%', image:'https://www.atptour.com/-/media/alias/player-headshot/MM58', flag:'RU'},
          {firstName:'Matteo', lastName:'Berrettini', rank:'5', value:'79%', image:'https://www.atptour.com/-/media/alias/player-headshot/bk40', flag:'IT'},

        ]
      },
    ]

    var activeIndex = this.state.activeIndex_season_stats
    var nextIndex = activeIndex + 1 //starting index of next render

    await this.setState({changingMatches:true})

    var updateTime = 12000
    var animation_duration = 500

    this.updateOpacity_seasonstats = setTimeout(async () => {
      await Animated.timing(this.state.season_stats_opacity, {
        toValue: 0,
        duration: animation_duration,
      }).start();
    }, updateTime - animation_duration)

    this.updateOpacity2_seasonstats = setTimeout(async()=>{
      // Fade In new text
      await Animated.timing(this.state.season_stats_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, updateTime)

    this.updateSeasonStats = setTimeout(async()=>{
      // setTimeout(async () => {
        var changingIndex = nextIndex < (statsArray.length) ? nextIndex : 0
        var stats_season = statsArray[changingIndex]
        await this.setState({stats_season, activeIndex_season_stats:changingIndex, changingMatches:false})

      this.changeStatsDataShown()
    }, updateTime)
  }


  //Match stats
  changeMatchStats = async () => {
    var { activeIndex_match_stats, match_stats_array } = this.state

    clearTimeout(this.updateMatchStats)

    var activeIndex = activeIndex_match_stats
    var nextIndex = activeIndex + 1 //starting index of next render

    await this.setState({changingMatches:true})

    var updateTime = 10000
    var animation_duration = 500

    this.updateOpacity_matchstats = setTimeout(async () => {
      await Animated.timing(this.state.stats_opacity, {
        toValue: 0,
        duration: animation_duration,
      }).start();
    }, updateTime - animation_duration)

    this.updateOpacity2_matchstats = setTimeout(async()=>{
      // Fade In new text
      await Animated.timing(this.state.stats_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, updateTime)

    this.updateMatchStats = setTimeout(async()=>{
      // setTimeout(async () => {
        var changingIndex = nextIndex < (match_stats_array.length) ? nextIndex : 0
        var match_stats = match_stats_array[changingIndex]
        await this.setState({match_stats, activeIndex_match_stats:changingIndex, changingMatches:false})

      this.changeMatchStats()
    }, updateTime)
  }

  //
  setupPlayers = async (match) => {
    if(match.competitors[0].name.includes(". ") && match.category === 'ATP'){
      var lastname_1 = (match.competitors[0].name.split(". ")[1]).toLowerCase()
      var lastname_2 = (match.competitors[1].name.split(". ")[1]).toLowerCase()

      var image_1 = "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_1+"_full_ao20.png"
      var image_2 = "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_2+"_full_ao20.png"

      //Get player profiles
      var image_official_1 = await this.getPlayer(match.competitors[0].id)
      var image_official_2 = await this.getPlayer(match.competitors[1].id)

      var images = [image_official_1 || image_1, image_official_2 || image_2]
      var names = [match.competitors[0].name_full, match.competitors[1].name_full]

      this.setState({images, names})
    }
  }

  getPlayer = async (id) => {
    var image = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.data()){
            if(doc.data().image_official){
              image = doc.data().image_official
            }
          }
        })
      }
    })

    return image
  }

  setupListeners = async (docId, match) => {
    var status = match.match_status
    //Get stats & h2h
    this.listenMatchStats = await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('info').doc('stats')
    .onSnapshot(this.handleStats)

    this.listenVote = await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('data').doc('votes')
    .onSnapshot(this.handleVotes)

    this.listenTippers = await firebase.firestore()
    .collection('streaming').doc("yBhmZeRfAZ7rzm8jemwX")
    .onSnapshot(this.handleTippers)

    if(status === 'not_started'){
      await firebase.firestore()
      .collection('matches_sr').doc(docId)
      .collection('info').doc('data')
      .get()
      .then(async(doc)=>{
        if(doc.exists && doc.data()){
          var stats = []
          var stats_title = 'H2H Record'
          var h2h = doc.data().h2h_v2

          if(h2h){
            //Generate the H2H record string
            var record = h2h.wins
            if(record[0] > record[1]){
              stats.push(match.competitors[0].name + " leads the H2H " + record[0] + "-" + record[1])
            }else if(record[1] > record[0]){
              stats.push(match.competitors[1].name + " leads the H2H " + record[1] + "-" + record[0])
            }else{
              //Tied
              stats.push("H2H is tied at " + record[0] + "-" + record[1])
            }

            var matches = h2h.matches || []
            await Promise.all(matches.map(async (h2h_match, index) => {
              stats.push(h2h_match.winner + " won in the "+ h2h_match.round + " at the " + h2h_match.tournament + " in " + moment(h2h_match.timestamp, 'x').format('YYYY') + "  -  " + h2h_match.score)
            }))

            await this.setState({stats, stats_title})
            this.changeString_stats()
          }

        }
      })
    }
  }

  handleTippers = async (doc) => {
    //Set state and setup string changing timers
    // var tippers = [
    //   "Here's the first stat",
    //   "Here's the next stat",
    //   "And what about htis stats",
    // ]

    var tippers = []

    if(doc.data() && doc.exists){
      tippers = doc.data().topTippers || []
      var tippers_raw = doc.data().topTippers || []

      tippers = [tippers.join("   ·   ")]

      await this.setState({tippers, tippers_raw})

      this.changeString_tippers()
    }
  }

  handleStats = async (doc) => {
    var { match } = this.state
    if(doc.data() && doc.exists){
      var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM"]
      var stats_list = []

      //Setup names
      var p1 = match.competitors[0].name.split(". ")[1] || ''
      var p2 = match.competitors[1].name.split(". ")[1] || ''

      var stats_serving = [{
        isTitle:true,
        title:'Stats on serve',
        values:[p1, p2],
        bold:true,
      },]
      var stats_receiving = [{
        isTitle:true,
        title:'Stats on return',
        values:[p1, p2],
        bold:true,
      },]
      var stats_other = [{
        isTitle:true,
        title:'Other stats',
        values:[p1, p2],
        bold:true,
      },]

      var statsRaw = doc.data().stats || []

      var blocked_stats = ["Return Games Won"]

      var stats_allowed = ["Winners", 'Unforced Errors', 'Aces', 'Double Faults', "1st Serve %", '1st Serve Points Won', ]

      //Promise through stats and add values
      if(statsRaw){
        await Promise.all(statsRaw.map(async stat_period => {
          if(stat_period.title === 'Match'){
            var period_stats = stat_period.stats.stats || []
            await Promise.all(period_stats.map(async stat_group => {
              await Promise.all(stat_group.data.map(async stat => {
                if(stat.values){
                  if((stat.values[0] || stat.values[0] === 0) && (stat.values[1] || stat.values[1] === 0) && !blocked_stats.includes(stat.title)){
                    var p1_stat = p1 + " - " + stat.values[0]
                    var p2_stat = p2 + " - " + stat.values[1]

                    // if(stat.title === 'Winners'){
                    //   stats_other.push({
                    //     title:'Winners',
                    //     values:[stat.values[0], stat.values[1]]
                    //   })
                    // }
                    // if(stat.title === 'Unforced Errors'){
                    //   stats_other.push({
                    //     title:'Errors',
                    //     values:[stat.values[0], stat.values[1]]
                    //   })
                    // }

                    if(stat.title === 'Aces'){
                      stats_serving.push({
                        title:'Aces',
                        values:[stat.values[0], stat.values[1]]
                      })
                    }
                    if(stat.title === 'Double Faults'){
                      stats_serving.push({
                        title:'DF',
                        values:[stat.values[0], stat.values[1]]
                      })
                    }
                    if(stat.title === '1st Serve %'){
                      stats_serving.push({
                        title:'1st In',
                        values:[stat.values[0].split(" ")[0], stat.values[1].split(" ")[0]]
                      })
                    }
                    if(stat.title === '1st Serve Points Won'){
                      stats_serving.push({
                        title:'1st Win',
                        values:[stat.values[0].split(" ")[0], stat.values[1].split(" ")[0]]
                      })
                    }
                    if(stat.title === '2nd Serve Points Won'){
                      stats_serving.push({
                        title:'2nd Win',
                        values:[stat.values[0].split(" ")[0], stat.values[1].split(" ")[0]]
                      })
                    }

                    if(stat.title === 'Serve Rating'){
                      stats_other.push({
                        title:'Serve Rating',
                        values:[stat.values[0], stat.values[1]]
                      })
                    }

                    if(stat.title === 'Return Rating'){
                      stats_other.push({
                        title:'Return Rating',
                        values:[stat.values[0], stat.values[1]]
                      })
                    }

                    if(stat.title === 'Break Points Saved'){
                      stats_other.push({
                        title:'BP Saved',
                        values:[stat.values[0], stat.values[1]]
                      })
                    }


                  }
                }
              }))
            }))
          }
        }))
      }

      var match_stats = [stats_serving, stats_other]

      await this.setState({match_stats_array:match_stats})

      this.changeMatchStats()

    }else{
      // var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM", "Stats available soon"]
      // await this.setState({stats})
      // this.changeString_stats()
    }
  }

  handleVotes = async (doc) => {
    var { match } = this.state
    var votes = [5,5]

    if(doc.exists && doc.data()){
      var data = doc.data()

      if(data.votes){
        var votes_0_raw = Object.values(data.votes['0'] || {}) || []
        var votes_1_raw = Object.values(data.votes['1'] || {}) || []

        votes = [votes_0_raw.length + 5, votes_1_raw.length + 5]
      }
    }

    //Generate % and string of the player that is winning
    var string = ''
    var percentage = '50%'
    if(votes[0] === votes[1]){
      string = 'Vote tied'
    }else{
      if(votes[0] > votes[1]){
        percentage = (Math.round(votes[0]/(votes[0] + votes[1])*100)) + "%"
        string = percentage + " " + match.competitors[0].name.split(". ")[1]
      }
      if(votes[1] > votes[0]){
        percentage = (Math.round(votes[1]/(votes[0] + votes[1])*100)) + "%"
        string = percentage + " " + match.competitors[1].name.split(". ")[1]
      }
    }

    var fan_votes = {
      string,
      percentage,
    }

    this.setState({fan_votes})
  }

  changeTheme = async (theme) => {
    var style = {
      background_main:"#101012",
      background_match:'#18181B',
      background_tnns:'#18181B',
      background_scores:"#18181B",
      background_ticker:'#18181B',
      background_tippers:"#18181B",
      font_tippers:colors.secondary,
      font_ticker:colors.secondary,
      font_color:colors.secondary,
      game_color:colors.secondary,
      set_color:colors.secondary,
      player_color:colors.secondary,
      player_font:'Averta-Bold',
      ball_color:colors.accent,
      event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
      borderRadius:10,
      letterSpacing:0.5,
      alternatingBackground:'rgba(0,0,0,0.24)',
    }

    if(theme === 'ATP1000'){
      style = {
        background_main:"#101012",
        background_match:'#18181B',
        background_tnns:'#18181B',
        background_scores:"#18181B",
        background_ticker:'#18181B',
        background_activeSet:"gold",
        background_tippers:"gold",
        font_tippers:"black",
        font_activeSet:'black',
        font_ticker:colors.secondary,
        font_color:colors.secondary,
        game_color:colors.secondary,
        set_color:colors.secondary,
        player_color:colors.secondary,
        player_font:'Averta-Bold',
        ball_color:"gold",
        event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
        borderRadius:0,
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.24)',
        reverseOrder:true,
      }
    }

    if(theme === 'UTS'){
      style = {
        background_main:"#101012",
        background_match:'#18181B',
        background_tnns:'#ff002f',
        background_scores:"#18181B",
        background_ticker:'#18181B',
        background_activeSet:"#ff002f",
        background_tippers:"#ff002f",
        font_tippers:colors.secondary,
        font_activeSet:colors.secondary,
        font_ticker:colors.secondary,
        font_color:colors.secondary,
        tint_tnns:colors.secondary,
        game_color:colors.secondary,
        set_color:colors.secondary,
        player_color:colors.secondary,
        player_font:'Averta-Bold',
        ball_color:"#ff002f",
        event_logo:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/uts%2Futs-promo.png?alt=media&token=10c9aaed-68d4-4b01-8aba-585081fd8ea5",
        borderRadius:0,
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.24)',
        reverseOrder:true,
        leftTab:'#ff002f',
      }
    }

    if(theme === 'Wimbledon'){
      style = {
        background_main:colors.secondary,
        background_match:'#EDEEF5',
        background_tnns:'#48167A',
        background_scores:"#EDEEF5",
        background_ticker:'#EDEEF5',
        background_tippers:"#48167A",
        font_tippers:colors.secondary,
        font_ticker:'#48167A',
        font_color:'#48167A',
        game_color:'#48167A',
        set_color:'#48167A',
        player_color:'#48167A',
        player_font:'Averta-Bold',
        ball_color:'#FBF508',
        event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/b/b9/Wimbledon.svg/1200px-Wimbledon.svg.png",
        borderRadius:0,
        leftTab:'#0B6F3D',
        hideFlags:true,
        letterSpacing:0.5,
        reverseOrder:true,
        alternatingBackground:'rgba(0,0,0,0.12)',
      }
    }

    if(theme === 'GTL'){
      style = {
        background_main:colors.secondary,
        background_match:'#EDEEF5',
        background_tnns:'#514C8A',
        background_scores:"#EDEEF5",
        background_ticker:'#EDEEF5',
        background_tippers:"#514C8A",
        font_tippers:colors.secondary,
        font_ticker:'#25642F',
        font_color:'#25642F',
        game_color:'#25642F',
        set_color:'#25642F',
        player_color:'#25642F',
        player_font:'Averta-Bold',
        ball_color:'#514C8A',
        event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
        borderRadius:0,
        leftTab:'#25642F',
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.1)',
      }
    }

    if(theme === 'Roland Garros'){
      style = {
        background_main:colors.secondary,
        background_match:'#063F35',
        background_tnns:'#063F35',
        background_scores:"#ECECEC",
        background_ticker:'#EDEEF5',
        background_tippers:"#063F35",
        font_tippers:colors.secondary,
        font_ticker:'#000',
        font_color:colors.secondary,
        game_color:'#000',
        set_color:'black',
        player_color:colors.secondary,
        player_font:'Averta-Bold',
        // ball_color:"#E7946B",
        ball_color:'#D45119',
        //D45622
        event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Logo_Roland-Garros.svg/1200px-Logo_Roland-Garros.svg.png",
        borderRadius:2.5,
        letterSpacing:0.5,
        hideFlags:true,
        reverseOrder:true,
        alternatingBackground:'rgba(0,0,0,0.12)',
      }
    }

    return this.setState({style, theme})
  }

  //String changers
  changeString_stats = async () => {
    clearTimeout(this.updateString_stats)

    var { activeIndex_stats, stats } = this.state
    var nextIndex = activeIndex_stats+1
    if((activeIndex_stats+1) >= (stats.length)){
      nextIndex = 0
    }

    var string_stats = stats[nextIndex]

    await this.setState({string_stats, activeIndex_stats:nextIndex})

    this.updateString_stats = setTimeout(async () => {
      this.changeString_stats()
    }, stringChangeTime)
  }

  changeString_tippers = async () => {
    clearTimeout(this.updateString_tippers)
    var { activeIndex_tippers, tippers } = this.state

    var nextIndex = activeIndex_tippers+1
    if((activeIndex_tippers+1) >= (tippers.length)){
      nextIndex = 0
    }

    var string_tippers = tippers[nextIndex]
    await this.setState({string_tippers, activeIndex_tippers:nextIndex})

    this.updateString_tippers = setTimeout(async () => {
      this.changeString_tippers()
    }, stringChangeTime / 2)
  }

  changeImage = async () => {
    var newImage = prompt('Change the image by entering the new URL')

    if(newImage){
      var style = this.state.style
      style.event_logo = newImage
      this.setState({style})
    }
  }


  //Renders
  renderScoreboard = () => {
    var { string_stats, string_tippers, stats_list, stats_serving, stats_receiving, matchTime, matchInfo } = this.state

    var height = width/1920*1080

    return(
      <View style={[{width, height, backgroundColor:this.state.style.background_main, padding:spacing*2}, MainStyles.flexStartCenter]}>
        {this.renderTicker(matchInfo, 'Info', matchTime)}
        <View style={[{width:'100%', paddingVertical:spacing*2}, MainStyles.flexRow, MainStyles.flexCenter]}>
          {
            this.renderFeatureCard()
          }
          {this.renderScore()}
          {this.renderImage({uri:this.state.style.event_logo}, true, this.changeImage)}
          {
            // this.renderImage({uri:'https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj'})
          }
        </View>
        {
          stats_serving.length > 0 ?
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>

            {this.renderTippers('35%')}

            <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {width:'65%', paddingLeft:spacing*2}]}>
              {this.renderCard('100%', false, false, 'Serving Stats', this.renderStats(stats_serving, 'Stats'))}
              <View style={{height:spacing*2}}/>
              {
                // this.renderCard('100%', false, false, 'Receiving Stats', this.renderStats(stats_receiving, 'Time Violations'))
              }
            </View>

          </View>
          :
          <View style={{width:'100%'}}>
          {this.renderTicker(string_stats, 'H2H')}
          </View>
        }
        {stats_serving.length > 0 ? null :
          <View style={[{width:'100%', marginTop:spacing*2}, MainStyles.flexRow, MainStyles.flexStartStart]}>
          {this.renderTippers('40%')}
          {this.renderPreviewImage('60%')}
          </View>
        }
      </View>
    )
  }

  renderCard = (ratio, marginRight, marginLeft, title, content) => {
    return(
      <View style={{width:ratio, height:'100%', paddingRight:marginRight ? spacing : 0, paddingLeft:marginLeft ? spacing : 0}}>
        <View style={[{width:'100%', height:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_ticker, overflow:'hidden'}, MainStyles.flexStartCenter]}>
          {content}
        </View>
      </View>
    )
  }

  renderTippers = (width) => {
    var { tippers_raw } = this.state
    return(
      <View style={{width, marginTop:spacing*0, flexGrow:1}}>
        <View style={[{width:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tippers, flexGrow:1}, MainStyles.flexStartCenter]}>
          {this.renderStatTitle('Top Tippers', true, this.state.style.font_tippers)}
          {tippers_raw.map((item, index) => this.renderTipper(item, index))}
        </View>
      </View>
    )
  }

  renderTipper = (item, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style.alternatingBackground : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_tippers, textAlign:'left'}}>
          {item}
        </Text>
      </View>
    )
  }

  renderStats = (data, title) => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderStatTitle(title)}
        {data.map((item, index) => this.renderStat(item, index))}
      </View>
    )
  }

  renderStat = (data, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style.alternatingBackground : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, textAlign:'left'}}>
          {data.title}
        </Text>

        <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
          {data.values[0]}
          </Text>
          <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
          {data.values[1]}
          </Text>
        </View>
      </View>
    )
  }

  renderStatTitle = (title, hideNames, color) => {
    var { match } = this.state
    return(
      <View style={[{width:'100%', padding:spacing*2, paddingHorizontal:spacing}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>
        <Text style={{fontSize:24, color:color || this.state.style.font_ticker, fontFamily:'Averta-Black', width:'100%', textAlign:'left'}}>
        {title}
        </Text>

        {
          hideNames ? null :
          <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'Averta-Bold', fontSize:18, color:color || this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
            {match.competitors[0].abbreviation}
            </Text>
            <Text style={{fontFamily:'Averta-Bold', fontSize:18, color:color || this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
            {match.competitors[1].abbreviation}
            </Text>
          </View>
        }
      </View>
    )
  }

  //

  renderTicker = (string, title, right) => {
    return(
        <View style={[{width:'100%', backgroundColor:this.state.style.background_main, height:32, paddingHorizontal:0}, MainStyles.flexCenter]}>

          <View style={[{width:'100%', backgroundColor:this.state.style.background_ticker, height:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <View style={[{height:'100%', paddingHorizontal:spacing*2, backgroundColor:'rgba(0,0,20,0.15)'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left'}}>
              {title}
              </Text>
            </View>

            <View style={[{flexGrow:1}, MainStyles.flexCenterStart]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left', paddingLeft:spacing*2}}>
              {string}
              </Text>
            </View>

            <View style={[{height:'100%', paddingHorizontal:spacing*2, backgroundColor:'rgba(0,0,20,0.15)'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left'}}>
              {right}
              </Text>
            </View>
          </View>

        </View>
    )
  }

  renderFeatureCard = () => {
    return(
      <View style={[{width:height*1.5, height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tnns}, MainStyles.flexCenter]}>
        <Image
        style={{resizeMode:'contain', width:'100%', height:'100%', tintColor:this.state.style.tint_tnns || colors.secondary}}
        source={require('../../images/poweredbytnns.png')}
        />
      </View>
    )
  }

  renderImage = (source, disableMargin, onPress) => {
    return(
      <Pressable onPress={onPress || null}>
        <Image
        style={{height, width:height, borderRadius:this.state.style.borderRadius, marginLeft:disableMargin ? 0 : spacing, resizeMode:'cover'}}
        source={source}
        />
      </Pressable>
    )
  }

  renderPreviewImage = (width) => {
    var { id, match, images, names } = this.state

    var image_1 = images[0] || null
    var image_2 = images[1] || null

    var name_1 = names[0] || null
    var name_2 = names[1] || null

    var height = 350

    var img_height = height - 40
    var img_width = img_height / 737 * 463

    var name_style = {fontFamily:'Averta-Black', color:colors.secondary, fontSize:36, color:this.state.style.font_tippers, textTransform:'uppercase', textAlign:'center', width:'85%', flexWrap:'wrap'}
    var secondary_style = {fontFamily:'Averta-Semibold', fontSize:20, color:this.state.style.font_tippers, textTransform:'uppercase', opacity:0.5, textAlign:'center'}

    if(image_1 && image_1){
      return(
        <View style={{width, marginTop:spacing*0, flexGrow:1, marginLeft:spacing*2, paddingRight:spacing*2}}>
          <View style={[{width:'100%', height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tippers, flexGrow:1, overflow:'hidden', padding:20}, MainStyles.flexCenter]}>

            <Image
            style={{width:'100%', height:'100%', resizeMode:'cover', opacity:0.25, position:'absolute'}}
            source={{uri:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"}}
            />

            <View style={[{}, MainStyles.flexCenter]}>
              <Text style={[secondary_style, {marginBottom:20}]}>
              {match.season_name.split(", ")[0] + " - " + match.round}
              </Text>

              <Text style={[name_style]}>
              {name_1}
              </Text>

              <Text style={[secondary_style, {marginVertical:5}]}>
              vs
              </Text>

              <Text style={[name_style]}>
              {name_2}
              </Text>
            </View>

            <Image
            style={{position:'absolute', left:-35, bottom:0, height:img_height, width:img_width, resizeMode:'contain'}}
            source={{uri:image_1}}
            />

            <Image
            style={{position:'absolute', right:-35, bottom:0, height:img_height, width:img_width, resizeMode:'contain'}}
            source={{uri:image_2}}
            />

          </View>
        </View>
      )
    }
  }


  //Score renders
  renderScore = () => {
    var item = this.state.match
    return(
      <View style={[{height, flex:1, borderRadius:this.state.style.borderRadius, overflow:'hidden'}, MainStyles.flexRow, MainStyles.flexCenter]}>
        <View style={[{flex:1, height:'100%', paddingHorizontal:spacing_main}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{flexShrink:1, marginRight:spacing*2, height:'100%'}, MainStyles.flexColumn, MainStyles.flexCenterStart]}>
            {this.renderPlayer(item.competitors[0], 0, item.winner_id, item.seeds)}
            <View style={{height:20}}/>
            {this.renderPlayer(item.competitors[1], 1, item.winner_id, item.seeds)}
          </View>

          {this.renderVisualScores(item)}
        </View>
      </View>
    )
  }

  renderPlayer = (item, index, winner_id, seeds) => {

    var winner = winner_id ? winner_id === item.id : true
    var textStyle = {fontSize, color:colors.score_font, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:this.state.style.letterSpacing}

    var flag = item.flag

    var isDoubles = item.name.includes("/")

    if(isDoubles || this.state.style.hideFlags){
      flag = null
    }

    var game = this.state.match.game_state || {}
    var player_gs_id = index === 0 ? 'home' : 'away'
    var isServing = game.serving === player_gs_id && !winner_id

    var renderServing = null

    if(isServing){
      var serve_size = 24
      renderServing = <View style={{height:18, width:18, borderRadius:20, backgroundColor:this.state.style.ball_color, marginLeft:15}}/>

      if(this.state.theme === 'Wimbledon'){
        renderServing =
        <Image
        style={{width:9, height:18, resizeMode:'contain', tintColor:this.state.style.player_color, marginLeft:15}}
        source={require('../scorearrow.png')}
        />
      }
    }

    var seed = null
    if(seeds){
      seed = seeds[item.id]
    }


    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%'}]}>

        <View style={{flexShrink:1}}>
          <Text style={[textStyle,{opacity:winner ? 1 : 0.5, marginLeft:!flag ? 10 : 45, textTransform:'uppercase', flexShrink:0.25}]} numberOfLines={1}>
          {item.name}
          </Text>
        </View>

        <Text style={[textStyle, {opacity:0.33, marginLeft:10, fontFamily:'Averta-Bold'}]}>
        {seed}
        </Text>

        {renderServing}

      </View>
    )
  }

  renderVisualScores = (data) => {
    //Setup number of sets required
    var best_of = data.best_of || 3
    var scores = data.period_scores ? new Array(data.period_scores.length) : []
    scores.fill('')

    var height = 100

    return(
      <View style={[{height:'100%'}, MainStyles.flexRow, MainStyles.flexEndCenter]}>
        {
          this.state.style.reverseOrder ?
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          {this.renderVisualGameScore(data, height)}
          </View>
          :
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {this.renderVisualGameScore(data, height)}
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          </View>
        }

        {this.renderVisualArrow(data, height)}
      </View>
    )
  }

  renderVisualArrow = (data, height) => {
    return(
      <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:10, marginRight:-20, height:height}]}>
        {this.renderArrow(data.competitors[0].id === data.winner_id, height/1.2)}
        <View style={{height:34}}/>
        {this.renderArrow(data.competitors[1].id === data.winner_id, height/1.2)}
      </View>
    )

    // return null
  }

  renderArrow = (active, height) => {
    var height = 32
    var width = height / 2
    if(active){
      return(
        <View style={[{height}, MainStyles.flexCenter]}>
          <Image
          style={{width, height, resizeMode:'contain', tintColor:this.state.style.set_color}}
          source={require('../scorearrow.png')}
          />
        </View>
      )
    }else{
      return(
        <View style={{width, height}}/>
      )
    }
  }

  renderVisualGameScore = (data, height) => {
    var { mode } = this.state
    if(!data.winner_id && mode !== 'scheduled' && data.game_state){

      var scores = [0,0]
      if(data.game_state){
        scores = [data.game_state.home_score || 0, data.game_state.away_score || 0]
      }

      return(
        <View key={'gamescore'} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:20, marginRight:30, height, width:30}]}>
          {this.renderVisualSetScore(scores[0], null, null, null, true, data.tiebreak_scores, colors.secondary)}
          <View style={{height:20, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}}/>
          {this.renderVisualSetScore(scores[1], null, null, null, true, data.tiebreak_scores, colors.secondary)}
        </View>
      )
    }else{return null}
  }

  renderVisualSetScores = (scores, index, height, activeSet) => {
    var tiebreak_scores = {}
    var setScore = scores ? (scores[index] ? [scores[index].home_score, scores[index].away_score] : ['','']) : ['', '']
    var showScores = this.state.mode !== 'scheduled'
    return(
      <View key={index} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:0, height, width:60, backgroundColor:activeSet && this.state.style.background_activeSet ? this.state.style.background_activeSet : 'transparent' }]}>
        {showScores ? this.renderVisualSetScore(setScore[0], setScore[1], index+1, 'home', setScore[0] >= setScore[1], tiebreak_scores, colors.score_font, activeSet) : null}
        <View style={{height:20, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}}/>
        {showScores ? this.renderVisualSetScore(setScore[1], setScore[0], index+1, 'away', setScore[1] >= setScore[0], tiebreak_scores, colors.score_font, activeSet) : null}
      </View>
    )
  }

  renderVisualSetScore = (score, otherScore, set, player, active, tiebreak_scores, color, activeScore) => {
    var tb = null
    // if(score === 0){score = '0'}
    if(score === 50){score = 'AD'}
    // if(score === 6 && otherScore === 7){
    //   if(tiebreak_scores?[set]){
    //     tb =
    //     <Text style={{position:'absolute', top:-6, right:-6, fontSize:12, fontFamily:'Averta-Bold', color:colors.secondary, opacity:active ? 1 : 0.5, letterSpacing:-0}}>
    //     {tiebreak_scores[set][player]}
    //     </Text>
    //   }
    // }

    return(
      <View style={[MainStyles.flexCenter]}>
        <Text style={{fontSize, color, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:-0}}>
        {score}
        </Text>
      </View>
    )
  }

  //Buttons
  renderButtons = () => {
    var options = this.state.courts || []

    return(
      <View style={[{width:'100%', marginTop:40}, MainStyles.flexCenter]}>
        <Text style={{color:colors.secondary, fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
        Pick a court
        </Text>
        <View style={[{width:'100%', padding:20, flexWrap:'wrap', marginTop:0}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {options.map((item, index) => this.renderOption(item, index))}
        </View>
      </View>
    )
  }

  renderOption = (item, index) => {
    return(
      <Pressable onPress={()=>this.start(item)} style={{padding:20, minWidth:140, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10}}>
        <Text style={{color:colors.secondary, fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
        {item}
        </Text>
      </Pressable>
    )
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:colors.secondary, fontFamily:'Averta-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:colors.secondary, fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:colors.secondary, fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to open its scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item.id)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'Averta-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:colors.secondary, fontSize:16, fontFamily:'Averta-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : colors.secondary, fontSize:16, fontFamily:'Averta-Regular'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }



  //TNNS YouTube renders
  renderMain = () => {
    var height = width/1920*1080
    var backgroundColor = colors.primary
    // backgroundColor = "#462B8D"
    try{return(
      <View style={{width, height, backgroundColor, overflow:'hidden'}}>
        {this.renderBackground()}
        <View style={[{flex:1, width:'100%', padding:spacing_main*1}, MainStyles.flexRow, MainStyles.flexCenter]}>
          {this.renderLeft()}
          {this.renderRight()}
        </View>
      </View>
    )}catch(e){return null}
  }

  renderLeft = () => {
    try{return(
      <View style={[{flex:1, height:'100%'}, MainStyles.flexColumn]}>
        {this.renderMainBoard()}
        {
          // this.renderTNNSCTA()
          <View style={{height:spacing_main}}/>
        }
        {this.renderBase()}
      </View>
    )}catch(e){return null}
  }

  renderRight = () => {
    try{return(
      <View style={[{width:280, marginLeft:spacing_main, height:'100%'}, MainStyles.flexColumn, MainStyles.flexCenter]}>
        {this.renderStatCenter()}
        {this.renderLogo()}
      </View>
    )}catch(e){return null}
  }

  renderBase = () => {
    try{return(
      <View style={[{width:'100%', height:164}, MainStyles.flexCenter, MainStyles.flexRow]}>
        {this.renderOtherMatch()}
        {this.renderStats()}
      </View>
    )}catch(e){return null}
  }

  //Components
  renderMainBoard = () => {
    var { match, tournament, fan_votes, up_next, court } = this.state

    var img_width = 460
    var img_height = img_width

    var scale_1 = 1.0
    var scale_2 = 1.0

    // var img_1 = require('../../images/goffin.png')
    // var img_2 = require('../../images/moutet.png')
    //
    // var odds = '67%'
    // var odds_string = odds+" Goffin"

    var odds = this.state.odds || '50%'
    var odds_string = this.state.odds_string || '-'
    var images = match.competitor_photos


    try{return(
      <View style={[{flex:1, width:'100%', backgroundColor, borderRadius:spacing_main/4, borderColor, borderWidth:1, overflow:'hidden', padding:spacing_main}, MainStyles.flexColumn, MainStyles.flexStartCenter]}>

        <Image
        style={[{height:img_height, width:img_width, resizeMode:'contain', position:'absolute', bottom:-img_height*0.1, left:-20, zIndex:0}]}
        source={{uri:images[0]}}
        />

        <Image
        style={[{height:img_height, width:img_width, resizeMode:'contain', position:'absolute', bottom:-img_height*0.1, right:-20, zIndex:0}]}
        source={{uri:images[1]}}
        />

        <View style={[{width:'100%'}, MainStyles.flexCenter]}>
          <Text style={title_style}>
          {match ? match.round + " - " + match.court_name : tournament}
          </Text>
          {this.renderMatchTime()}
          {this.renderMatchStatus()}
        </View>

        {this.renderLine()}

        <View style={[{width:'100%', flex:1}, MainStyles.flexColumn, MainStyles.flexBetweenCenter]}>
          <View style={{width:840, height:184, borderWidth:1, borderColor, borderRadius:spacing_main/2, marginTop:spacing_main*2, backgroundColor:colors.score_bg, transform:[{scale:1.05}]}}>
            {this.renderScore()}
          </View>
          {
            // this.renderMatchTime()
            this.renderQRCode()
          }
          {
            up_next && (match.winner_id || match.court_name !== this.state.court) ? this.renderUpNext() :
            <>
              <View style={[{width:'100%', marginBottom:spacing_main}, MainStyles.flexRow, MainStyles.flexCenter]}>
                {this.renderOdds(odds, odds_string, 'Pre-Match Odds')}
                {fan_votes ? this.renderOdds(fan_votes.percentage, fan_votes.string, "Fan Vote") : null}
              </View>
            </>
          }

        </View>

      </View>
    )}catch(e){return null}
  }

  renderLine = (wide) => {
    var source = wide ? require('../../images/line_wide.png') : require('../../images/line_short.png')
    var marginVertical = wide ? spacing_main : spacing_main/2
    try{return(
      <View style={{width:'100%', height:2, marginVertical}}>
        <Image
        style={{width:'100%', height:2, tintColor:colors.accent}}
        source={source}
        />
      </View>
    )}catch(e){return null}
  }

  renderMatchStatus = () => {
    var { match } = this.state
    try{return(
      <View style={[{position:'absolute', left:10}, MainStyles.flexCenter, MainStyles.flexRow]}>
        <Text style={[title_style]}>
        {match.match_status || ''}
        </Text>
      </View>
    )}catch(e){return null}
  }

  renderMatchTime = () => {
    var { matchTime } = this.state
    var time = "1:38"
    // return(
    //   <View style={[{width:'100%'}, MainStyles.flexCenter]}>
    //     <Text style={title_style}>
    //     Match Time
    //     </Text>
    //     <Text style={[title_style, {fontSize:42, marginTop:spacing_main/2}]}>
    //     {matchTime || 'Starting Soon'}
    //     </Text>
    //   </View>
    // )
    try{return(
      <View style={[{position:'absolute', right:10}, MainStyles.flexCenter, MainStyles.flexRow]}>
        <Text style={title_style}>
        {matchTime ? "Match Time - " : ''}
        </Text>
        <Text style={[title_style]}>
        {matchTime || ''}
        </Text>
      </View>
    )}catch(e){return null}
  }

  renderOdds = (percentage, string, title) => {
    // var percentage = "80%"
    // var winner = 'Federer'
    try{return(
      <View style={[{width:240, marginHorizontal:spacing_main}, MainStyles.flexCenter]}>
        <Text style={[title_style, {textAlign:'center'}]}>
        {title}
        </Text>

        <View style={{width:'100%', height:16, backgroundColor:'rgba(0,0,20,0.1)', borderRadius:100, borderColor, borderWidth:1, marginVertical:spacing_main}}>
          <View style={{width:percentage, position:'absolute', left:0, backgroundColor:colors.accent, borderRadius:100, height:'100%'}}/>
        </View>

        <Text style={[title_style, {fontFamily:'Averta-Regular'}]}>
        {string}
        </Text>
      </View>
    )}catch(e){return null}
  }

  renderTNNSCTA = () => {
    var qr_size = 100

    try{return(
      <View style={[{width:'100%', marginVertical:spacing_main}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Image
        style={{height:qr_size, width:qr_size, resizeMode:'contain', tintColor:colors.secondary, marginRight:spacing_main}}
        source={require('../../images/qr-code.png')}
        />
        <View style={[{width:'100%'}, MainStyles.flexCenterStart, MainStyles.flexColumn]}>
          <Text style={{fontFamily:'Averta-Bold', fontSize:24, color:colors.secondary, textTransform:'uppercase', letterSpacing:1, opacity:1, textAlign:'left'}}>
          Scan the code for detailed match stats, live commentary + more live scores
          </Text>
          <Text style={{fontFamily:'Averta-Semibold', fontSize:30, color:colors.secondary, textTransform:'uppercase', letterSpacing:3.5, opacity:0.5, marginTop:5, textAlign:'left'}}>
          or visit Downloadtennis.com
          </Text>
        </View>
      </View>
    )}catch(e){return null}
  }

  renderQRCode = () => {
    var { match_stats, match } = this.state

    var qr_size = 140

    var string = "Scan the code to vote +\nview the match preview & H2H record"

    if(match_stats){
      if(match_stats.length > 0){
        string = "Scan the code for detailed match stats, live commentary, Wimbledon results + more"
      }
    }

    if(match.winner_id){
      string = "Scan the code for more Wimbledon live scores, schedules, news + live commentary"
    }

    try{return(
      <View style={[{width:'50%'}, MainStyles.flexCenter, MainStyles.flexColumn]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:24, color:colors.secondary, textTransform:'uppercase', letterSpacing:2, opacity:1, textAlign:'center'}}>
        {string}
        </Text>
        {
          <View style={[{}, MainStyles.flexColumn, MainStyles.flexCenter]}>
            <Image
            style={{height:qr_size, width:qr_size, resizeMode:'contain', tintColor:colors.accent, marginVertical:spacing_main}}
            source={require('../../images/qr-code.png')}
            />
            <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
              <Text style={{fontFamily:'Averta-Regular', fontSize:24, color:colors.secondary, textTransform:'uppercase', letterSpacing:2, opacity:0.5, textAlign:'center'}}>
              or visit
              </Text>
              <Text style={{fontFamily:'Averta-Bold', fontSize:24, color:colors.secondary, textTransform:'uppercase', letterSpacing:2, opacity:1, textAlign:'center', marginLeft:10}}>downloadtennis.com</Text>
            </View>

          </View>

          // <Text style={{fontFamily:'Averta-Black', fontSize:32, color:colors.secondary, textTransform:'uppercase', letterSpacing:4, opacity:1, textAlign:'center', marginTop:spacing_main}}>
          // downloadtennis.com
          // </Text>

        }


      </View>
    )}catch(e){return null}
  }

  renderOtherMatch = () => {

    var { matches, activeIndex } = this.state
    var other_match = matches[activeIndex] ? matches[activeIndex] : null

    // var other_match = {
    //   title:'Federer leads Berrettini',
    //   score:"6-3 4-6 3-2",
    //   subtitle:'FRENCH OPEN - R16',
    //   image_1:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/federer_head_ao20.png",
    //   image_2:"https://www.atptour.com/-/media/tennis/players/head-shot/2020/berrettini_head_ao20.png"
    // }

    try{return(
      <View style={[{flex:1, height:'100%', backgroundColor, borderRadius:spacing_main/4, borderColor, marginRight:spacing_main, borderWidth:1, overflow:'hidden'}, MainStyles.flexColumn, MainStyles.flexCenter]}>
        {
          !other_match ? null :
          <Animated.View style={[{width:'100%', opacity:this.state.score_opacity}, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:24, color:colors.secondary, letterSpacing:-0.4, textTransform:'uppercase'}}>
            {other_match.title}
            </Text>
            {this.renderLine(false)}
            <Text style={{fontFamily:'Averta-Black', fontSize:32, color:colors.secondary, letterSpacing:-0}}>
            {other_match.score}
            </Text>
            <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:colors.secondary, letterSpacing:-0.3, textTransform:'uppercase', marginTop:10, opacity:0.5}}>
            {other_match.subtitle}
            </Text>

            <Image
            style={{height:180, width:180, resizeMode:'contain', position:'absolute', left:-30, bottom:-45}}
            source={{uri:other_match.image_1}}
            />
            <Image
            style={{height:180, width:180, resizeMode:'contain', position:'absolute', right:-30, bottom:-45}}
            source={{uri:other_match.image_2}}
            />
          </Animated.View>
        }
      </View>
    )}catch(e){return null}
  }

  renderUpNext = () => {

    var { up_next } = this.state

    var other_match = up_next

    if(up_next){
      try{return(
        <View style={[{marginBottom:10, marginTop:-10}, MainStyles.flexCenter]}>
          <View style={[{padding:20, minWidth:700, backgroundColor, borderRadius:spacing_main/4, borderColor, marginRight:spacing_main, borderWidth:1, overflow:'hidden'}, MainStyles.flexColumn, MainStyles.flexCenter]}>
            <View style={[{width:'100%', opacity:1}, MainStyles.flexCenter]}>
              <Text style={{fontFamily:'Averta-Semibold', fontSize:24, color:colors.secondary, letterSpacing:-0.4, textTransform:'uppercase'}}>
              {other_match.title}
              </Text>
              {this.renderLine(false)}
              <Text style={{fontFamily:'Averta-Black', fontSize:32, color:colors.secondary, letterSpacing:-0}}>
              {other_match.score}
              </Text>
              <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:colors.secondary, letterSpacing:-0.3, textTransform:'uppercase', marginTop:10, opacity:0.5}}>
              {other_match.subtitle}
              </Text>

              <Image
              style={{height:180, width:180, resizeMode:'contain', position:'absolute', left:-30, bottom:-45}}
              source={{uri:other_match.image_1}}
              />
              <Image
              style={{height:180, width:180, resizeMode:'contain', position:'absolute', right:-30, bottom:-45}}
              source={{uri:other_match.image_2}}
              />
            </View>
          </View>
        </View>
      )}catch(e){return null}
    }else{return null}
  }

  //Stats

  renderStats = () => {
    var { match_stats } = this.state

    var stat = match_stats || []

    try{return(
      <View style={[{width:844, height:'100%', paddingHorizontal:20, paddingVertical:10, backgroundColor, borderRadius:spacing_main/4, borderColor, borderWidth:1}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {
          stat.length === 0 ?
          <>
            <View style={{position:'absolute', top:47, width:'100%', height:2}}>
              {this.renderLine(false)}
            </View>
            <Text style={[{textAlign:'center', width:'100%', fontFamily:'Averta-Bold', fontSize:24, color:colors.main_font, letterSpacing:-0.4, textTransform:'uppercase'}]}>
              Match stats available soon
            </Text>
          </>
          :
          <>
            <View style={{position:'absolute', top:47, width:'100%', height:2}}>
              {this.renderLine(false)}
            </View>
            {stat.map((item, index) => this.renderStatItem(item, index))}
          </>
        }
      </View>
    )}catch(e){return null}
  }

  renderStatItem = (item, index) => {
    var width_style = item.isTitle ? {width:220} : {flex:1}

    var textStyle = item.isTitle ? {textAlign:'left', width:'100%', fontFamily:'Averta-Bold', fontSize:24, color:colors.secondary, letterSpacing:-0.4, textTransform:'uppercase'} : {textAlign:'center', fontFamily:'Averta-Regular', fontSize:24, color:colors.main_font, letterSpacing:-0.4, textTransform:'uppercase'}
    var titleStyle = item.isTitle ? {fontFamily:'Averta-Regular'} : {fontFamily:'Averta-Bold'}
    try{return(
      <Animated.View style={[{justifyContent:'space-around', alignItems:'center', height:'100%', opacity:this.state.stats_opacity}, width_style, MainStyles.flexColumn]}>
        <Text style={[textStyle, titleStyle, {marginBottom:5, color:colors.main_font}]}>
          {item.title}
        </Text>
        <Text style={[textStyle]}>
          {item.values[0]}
        </Text>
        <Text style={[textStyle]}>
          {item.values[1]}
        </Text>
      </Animated.View>
    )}catch(e){return null}
  }

  //

  renderLogo = () => {
    var width = 160
    var height = width / 140 * 102
    try{return(
      <View style={[{width:'100%', height:185}, MainStyles.flexCenter]}>
        <Image
        style={{height, width, resizeMode:'contain', tintColor:colors.logo}}
        source={require('../../images/tennislive.png')}
        />
        <Text style={{fontFamily:'Averta-Semibold', fontSize:16, color:colors.secondary, textTransform:'uppercase', letterSpacing:3, opacity:0.5, marginTop:10, textAlign:'left'}}>
        Downloadtennis.com
        </Text>
      </View>
    )}catch(e){return null}
  }

  renderBackground = () => {
    return(
      <Image
      style={{width:'100%', height:'100%', resizeMode:'cover', opacity:colors.bg_opacity || 0.3, position:'absolute'}}
      source={{uri:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"}}
      // source={{uri:"https://i.gifer.com/IprC.gif"}}
      />
    )
  }

  //Stats Right
  renderStatCenter = () => {
    var { stats_season } = this.state

    try{return(
      <View style={[{flex:1, width:'100%', backgroundColor, borderRadius:spacing_main/4, borderColor, borderWidth:1, overflow:'hidden'}, MainStyles.flexColumn]}>
        {
          !stats_season.title ? null :
          <>
          <Text style={{fontFamily:'Averta-Regular', fontSize:24, color:colors.main_font, opacity:0.5, textAlign:'left', width:'100%', paddingLeft:10, marginTop:10, textTransform:'uppercase'}}>
          {stats_season.header}
          </Text>
          <Animated.Text style={{fontFamily:'Averta-Bold', fontSize:24, color:colors.main_font, opacity:1, textAlign:'left', width:'100%', paddingLeft:10, marginBottom:10, textTransform:'uppercase', opacity:this.state.season_stats_opacity}}>
          {stats_season.title}
          </Animated.Text>
          {stats_season.players.map((item, index) => this.renderSeasonStatItem(item, index))}
          </>
        }
      </View>
    )}catch(e){return null}
  }

  renderSeasonStatItem = (item, index) => {
    var backgroundColor = 'rgba(0,0,0,0.12)'

    var img_size = 110

    var playerTextStyle = {fontFamily:'Averta-Semibold', fontSize:18, color:colors.main_font, opacity:1, textAlign:'left', textTransform:'uppercase'}

    var flag = item.flag
    var ratio = 20

    try{return(
      <Animated.View key={index} style={[{width:'100%', flex:1, opacity:this.state.season_stats_opacity, overflow:'hidden', borderBottomColor:borderColor, borderBottomWidth:index === 4 ? 0 : 1}, MainStyles.flexCenter]}>
        <View style={[{flex:1, width:'100%', backgroundColor}, MainStyles.flexCenter]}>
          {
            !flag ? null :
            <View style={[{position:'absolute', right:0, top:-12, opacity:0.8}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
              <ReactCountryFlag
                countryCode={flag}
                svg
                style={{
                  width: 20*ratio,
                  height: 16*ratio,
                }}
                title=""
              />
            </View>
          }

          <View style={[{height:30, width:30, backgroundColor:'white', position:'absolute', top:0, left:0}, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:24, color:'black'}}>
            {item.rank}
            </Text>
          </View>

          <Image
          style={{height:img_size, width:img_size, resizeMode:'contain', position:'absolute', bottom:-20, left:10}}
          source={{uri:item.image}}
          />
        </View>
        <View style={[{width:'100%', padding:5, paddingHorizontal:10, backgroundColor:colors.bg_stats}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexColumn, MainStyles.flexCenterStart]}>
            <Text style={[playerTextStyle, {opacity:0.5}]}>
            {item.firstName}
            </Text>
            <Text style={[playerTextStyle, {fontFamily:'Averta-Bold'}]}>
            {item.lastName}
            </Text>
          </View>

          <Text style={[playerTextStyle, {fontFamily:'Averta-Bold', fontSize:42, textAlign:'right', color:colors.secondary}]}>
          {item.value}
          </Text>

        </View>
      </Animated.View>
    )}catch(e){return null}
  }


  render() {
    var { loading, mode } = this.state

    try{return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexStartCenter]}>
        {mode === 'selectMatch' ? this.renderButtons() : this.renderMain() }
      </View>
    )}catch(e){return null}
  }
}

export default Item;
