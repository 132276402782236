import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import badge from './appstorebadgedark.png';

import { Hook, Console, Decode } from 'console-feed'

//Components
import Poll from './items/poll.js'
import ResultSticker from './items/resultsticker.js'
import MainButton from './items/mainbutton.js'

//Modules
import ReactDOM from "react-dom";
import { AnimateOnChange } from 'react-animation'
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import {Helmet} from "react-helmet";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

const AMPLITUDE_KEY = "ff24f21d0121991161cc8dacb5a08f5f";
const axios = require("axios");


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


class Page extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      loading: true,
      logs: [],
    };
  }

  async componentDidMount(){

    Hook(window.console, log => {
      this.setState(({ logs }) => ({ logs: [...logs, Decode(log)] }))
    })


    await this.initializeFirebase();
    this.getUserData();
  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyCdXGz5UJhZQjNpEz4FBtJCUbCahGHaJ4A",
      authDomain: "app-4pm.firebaseapp.com",
      databaseURL: "https://app-4pm.firebaseio.com",
      projectId: "app-4pm",
      storageBucket: "app-4pm.appspot.com",
      messagingSenderId: "450215977644",
      appId: "1:450215977644:web:4a9cb20f1afc42b214b509",
      measurementId: "G-YESKQ2TETN"
    };

    await app.initializeApp(config);
  }

  async getUserData(){
    //Get params from link
    var { cryptoId } = this.props.match.params
    console.log("ID is ", cryptoId)

    //Variables
    var userData = null
    var userId = null

    //Find user
    await firebase.firestore()
    .collection('usersWeb')
    .where('identifiers.randomChat', '==', cryptoId)
    .get()
    .then(querySnapshot => {
      if(querySnapshot.size > 0){
        return querySnapshot.forEach(async function(doc) {
          userData = doc.data()
          userId = userData.userId
        })
      }else{return}
    })

    //With the user known, generate a deep link
    var linkUrl = null
    //
    // //API call
    // var apiKey = 'AIzaSyCdXGz5UJhZQjNpEz4FBtJCUbCahGHaJ4A'
    //
    // const parameters = {
    //   dynamicLinkInfo: {
    //     domainUriPrefix: "https://download4pm.com/add",
    //     link: "https://download4pm.com/?anonymousId="+userId,
    //     iosInfo: {
    //       iosBundleId: 'com.downloadcrush.crush',
    //       iosAppStoreId: "1488092059",
    //       "iosCustomScheme": 'crush',
    //     },
    //     socialMetaTagInfo: {
    //       "socialTitle": 'chat with me anonymously on 4PM!',
    //       "socialDescription": "chat with friends + answer polls about each other on 4PM",
    //       "socialImageLink": "https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/appshareog.png?alt=media&token=f3ab5766-9069-42f1-873d-13817d1f4295"
    //     },
    //     "navigationInfo": {
    //       "enableForcedRedirect": true,
    //     },
    //   },
    //   "suffix": {
    //     "option": "UNGUESSABLE"
    //   }
    // }
    //
    // await axios.post("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key="+apiKey, parameters)
    // .then(response => {linkUrl = response.data.shortLink; console.log(response.data)});

    // linkUrl = "https://download4pm.page.link/?link=https%3A%2F%2Fapps%2Eapple%2Ecom%2Fapp%2Fid1488092059%3Fls%3D1%3Fid%3D%2AJKm8qjHXqdNjROo3Wf1d9VYlXTh1%2A1VlVf70l1yXH46D3ToBd"
    // // linkUrl = "https://download4pm.page.link/?anonymousId=JKm8qjHXqdNjROo3Wf1d9VYlXTh1"
    // // https://yoloapp.app.link/bunch-test?bunchId=5df577a579bde710c19ace7e
    // // linkUrl = "https://download4pm.page.link/?link=https%3A%2F%2Fapps%2Eapple%2Ecom%2Fapp%2Fid1488092059%3Fls%3D1%3Fid%3D%2AJKm8qjHXqdNjROo3Wf1d9VYlXTh1%2A1VlVf70l1yXH46D3ToBd"
    //
    // linkUrl = "https://download4pm.com/add/?link=https://download4pm.com/?anonymousId%3DVhQ695MtyYaP7HTO0dQZiqFGZAE3&isi=1488092059&ibi=com.downloadcrush.crush&efr=1"
    //
    // "https://download4pm.page.link/?link=https%3A%2F%2Fapps%2Eapple%2Ecom%2Fapp%2Fid1488092059%3Fls%3D1%3Fid%3D%2AJKm8qjHXqdNjROo3Wf1d9VYlXTh1%2A1VlVf70l1yXH46D3ToBd"

    var linkUrl = "https://download4pm.page.link/?link=https://download4pm.com/?anonymousId%3D"+userId+"&isi=1488092059&ibi=com.downloadcrush.crush&efr=1"

    console.log('Link is', linkUrl)

    //Set userData into state
    this.setState({userData, loading:false, linkUrl})
  }



  renderPoll(){

    var grade = this.state.dataPoll.grade ? this.state.dataPoll.grade : 'Not defined'

    if(this.state.loadingPoll){
      return(
        <p className="loading-font">loading poll...</p>
      )
    }else{
      if(this.state.voteSubmitted){
        if(this.state.loadingResult){
          return(
            <p className="loading-font">sending your vote...</p>
          )
        }else{
          return(
            <div>
            {this.finalSticker()}
            </div>
          )
        }
      }else{
        return(
          <Cell col={12} className="vertical flexCenter">

          <Poll
          dataPoll={this.state.dataPoll}
          dataPicks={this.state.dataPicks}
          submitVote={this.submitVote}
          loading={()=>this.setState({loading:false})}
          onLoad={this.setData}
          />

          <p className="poll-pick-winner">tap a name to vote!</p>

          </Cell>
        )
      }
    }
  }

  renderAmplitudeEvent(){
    var { userData, loading } = this.state
    if(!loading){
      // var grade = userData.grade
      // var school = userData.school
      return(
        <LogOnMount
        eventType="Random Chat - Web - View"
        // eventProperties={{grade, school}}
        />
      )
    }
  }

  renderContent(){
    var { loading, userData, linkUrl } = this.state

    var height = window.innerHeight
    var width = window.innerWidth

    var bitmojiSize = height*0.1

    var phoneHeight = height * 0.67
    var phoneWidth = phoneHeight/2200*1221
    var chatDemo = "https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/anonchatdemo.png?alt=media&token=d5e3816b-af77-4e5a-847c-72c2117040bd"

    var name = userData ? userData.firstName : 'me'

    if(!loading){
      return(
        <Cell col={12} className="flexColumn flexCenter vertical">

          <p style={{color:'#001021', fontSize:20, fontFamily:'Averta-Bold', letterSpacing:-0.5, marginBottom:-0}}>chat with {name}</p>

          <p className="gradient-text" style={{fontFamily:'Averta-Black', letterSpacing:-0.5, lineHeight:1}}>anonymously</p>

          <img src={userData.bitmojiUrl} alt="bitmoji" style={{height:bitmojiSize, width:bitmojiSize, resizeMode:'contain', marginBottom:-bitmojiSize/1.6, marginTop:16}}/>
          <img src={chatDemo} alt="bitmoji" style={{height:phoneHeight, resizeMode:'contain'}}/>

          <Amplitude>
            {({ logEvent }) =>
              <a
              href={linkUrl}
              onClick={()=>logEvent('Random Chat - Web - Open link')}
              style={{textDecoration:'none'}}
              >
                <div className='gradient-main' style={{borderRadius:16, paddingLeft:24, paddingRight:24, verticalAlign:'middle', height:48, justifyContent:'flex-center', flexDirection:'column', maxWidth:'100%', minWidth:width/2}}>
                  <p style={{fontFamily:'Averta-Bold', color:'white', fontSize:18, lineHeight:48/18, letterSpacing:-0.5}}>
                  start a chat
                  </p>
                </div>
              </a>
            }
          </Amplitude>

        </Cell>
      )
    }else{
      return(
        <p className="loading-font" style={{color:'#001021'}}>loading...</p>
      )
    }
  }

  renderButton(){
    var { linkUrl, userData } = this.state
    var name = userData.firstName || 'me'
    // return(
    //   <div style={{height:48, width:300, "max-width":'100%', "padding-horizontal":16, borderRadius:16, backgroundColor:'white'}} className='flexCenter'>
    //     <p className="button-text">chat with me anonymously</p>
    //   </div>
    // )
    return(
      <div className="button-wrapper-outside">
      <a
      href={linkUrl}
      // onClick={this.props.logEvent}
      >

        <button
        className="button-wrapper flexRow flexCenter">

          <p className="button-text">{"chat with "+name+" anonymously"}</p>

        </button>

      </a>
      </div>
    )
  }

  //

  renderConsole(){
    return(
      <Console
        logs={this.state.logs}
        variant="dark"
        filter={['log', 'error']}
        style={{backgroundColor:'red'}}
      />
    )
  }

  render() {

    var name = 'me'

    return(
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={AMPLITUDE_KEY}
      >

      <Helmet>
        <meta charSet="utf-8" />

        <title>{"chat with "+name+" anonymously on 4PM"}</title>
        <meta name="description" content="chat with friends + answer polls about each other on 4PM" />

        <meta name="og:title" content={"chat with "+name+" anonymously on 4PM"}/>
        <meta name="og:description" content="chat with friends + answer polls about each other on 4PM"/>
        <meta name="og:url" content="https://download4pm.com"/>
        <meta name="og:site_name" content="4PM"/>
        <meta name="og:type" content="website"/>
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/1.png?alt=media&token=6987cb79-dec6-4c26-a2f3-050c1a95d0f7"/>
      </Helmet>

      {this.renderAmplitudeEvent()}

        <div className="height">

          <Grid className="background-grey flexCenter">

          <AnimateOnChange
          className="vertical flexCenter width-100"
          animationIn="bounceIn"
          animationOut="bounceOut"
          durationIn={750}
          durationOut={750}
          >
          {this.renderContent()}
          </AnimateOnChange>

          </Grid>

        </div>
      </AmplitudeProvider>
    )
  }
}

export default Page;
