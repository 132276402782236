import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, Dimensions, Switch, Animated } from "react-native";

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';
var CircularJSON = require('circular-json')
const axios = require("axios");
var height = 100

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var match_width = 260

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prediction:{},
      match:{},
      style:{},
      loading: true,
      activeIndex:0,
      matches: [],
      matches_ticker: [],
      matches_ticker_divs: [],
      changingMatches:true,
      mode:'',

      score_opacity: new Animated.Value(1),

      //Show settings
      show_atp:true,
      show_wta:true,
      show_live:true,
      show_completed:true,
      show_singles:true,
      show_doubles:false,

      firstSet:true,

      tournaments:{},
    };
  }

  componentDidMount = async () => {
    // await this.getTournaments()
    await this.setup()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    if(this.listenControls){
      this.listenControls()
    }
  }

  //Functions
  setup = async () => {
    //Get the selected match from Firestore
    this.listenControls = await firebase.firestore()
    .collection('streaming').doc("C5aaURtU7WANlm1jDEzW")
    .onSnapshot(async (doc) => {
      if(doc.exists && doc.data()){
        var id = doc.data().matchId || null
        if(id !== this.state.id){
          await this.setState({id})
          this.selectMatch(id)
        }
      }
    })
  }

  selectMatch = async (id) => {
    this.setState({id})

    if(this.listenMatch){
      this.listenMatch()
    }

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .onSnapshot(this.handleMatch)
  }

  handleMatch = async ( querySnapshot ) => {
    try{
      var match = {}
      var docId = null

      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          match = doc.data()
        })
      }

      //Get the market
      var outcomes = match.outcomes || {}
      var outcome = outcomes.live || outcomes.prematch || null
      var mode = outcomes.live ? 'Live' : 'Pre-Match'

      var prediction = {
        name:'Djokovic',
        value:'71%',
        images:[match.competitor_photos[0]],
      }

      if(outcome){
        var home_percentage = Math.round(outcome.outcomes[0].probability)
        var away_percentage = Math.round(outcome.outcomes[1].probability)

        if(home_percentage > away_percentage){
          prediction.name = match.competitors[0].name.split(", ")[0]
          prediction.value = home_percentage+'%'
          prediction.images = [match.competitor_photos[0]]
        }else if(home_percentage < away_percentage){
          prediction.name = match.competitors[1].name.split(", ")[0]
          prediction.value = away_percentage+'%'
          prediction.images = [match.competitor_photos[1]]
        }else if(home_percentage === away_percentage){
          prediction.name = 'Toss Up'
          prediction.value = '50%'
          prediction.images = match.competitor_photos
        }
      }

      this.setState({prediction, mode})
    }catch(e){
      console.log(e)
    }
  }

  //Render Matches
  render_camLiveScores = () => {
    var { matches, matches_per_render, activeIndex, matches_ticker, matches_ticker_divs, prediction } = this.state
    var sliceIndex = ((matches_per_render + activeIndex) > (matches.length - 1) ? (matches.length - 1) : (matches_per_render + activeIndex))
    var render_data = matches[activeIndex] ? matches[activeIndex] : null

    var img_height = 56
    var width = img_height / 94 * 450

    var grey = "#E4E4EC"
    var yellow = '#fcd946'

    var images = prediction.images || []

    try{
      return(
        <View style={[{width:289*1.25, height:185*1.25, overflow:'hidden', marginBottom:50, backgroundColor:'white'}, MainStyles.flexCenter, MainStyles.flexColumn]}>

        <View style={[{width:'100%', backgroundColor:'black', padding:20, paddingVertical:10}, MainStyles.flexCenter]}>
          <Text style={{fontSize:24, color:'white', fontFamily:'Roboto-Black', textAlign:'left', width:'100%', marginHorizontal:0}}>
          {this.state.mode} Win Predictor
          </Text>
        </View>

        <Animated.View animation='fadeIn' style={[{flexGrow:1, width:'100%'}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart, {paddingLeft:20}]}>
            <Text style={{fontSize:54, color:'black', fontFamily:'Averta-Black', textAlign:'right', marginTop:-10, marginHorizontal:0, textTransform:'uppercase'}}>
            {prediction.value}
            </Text>
            <Text style={{fontSize:20, color:'black', fontFamily:'Averta-Bold', textAlign:'right', marginTop:-5, textTransform:'uppercase', opacity:0.5}}>
            {prediction.name}
            </Text>
          </View>

          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {height:'100%'}]}>
          {images.map((item, index) =>
            <Image
            key={index}
            style={{height:110, width:110, resizeMode:'contain', marginLeft:-20, marginBottom:-14}}
            source={{uri:item}}
            />
          )}
          </View>
        </Animated.View>

        <View style={[{width:'100%', backgroundColor:grey, padding:20, paddingVertical:10}, MainStyles.flexCenterEnd]}>
          <Image
          style={{height:36, width:36, resizeMode:'cover', borderRadius:5, overflow:'hidden', position:'absolute', left:20}}
          source={require('../../images/appstore.png')}
          />
          <Text style={{fontSize:10, color:'black', fontFamily:'Roboto-Medium', textAlign:'right', marginHorizontal:0, marginBottom:0, textTransform:'uppercase', opacity:0.5}}>
          Powered by
          </Text>
          <Text style={{fontSize:24, color:'black', fontFamily:'Averta-Black', textAlign:'right', marginHorizontal:0, textTransform:'uppercase'}}>
          tnnslive.com
          </Text>
        </View>

        </View>
      )
    }catch(e){
      return(
        <View style={[{width:289*1.25, height:185*1.25, overflow:'hidden', marginBottom:50, backgroundColor:'white'}, MainStyles.flexCenter, MainStyles.flexColumn]}>

        <View style={[{width:'100%', backgroundColor:'black', padding:20, paddingVertical:10}, MainStyles.flexCenter]}>
          <Text style={{fontSize:24, color:'white', fontFamily:'Roboto-Black', textAlign:'left', width:'100%', marginHorizontal:0}}>
          Live Win Predictor
          </Text>
        </View>

        <Animated.View animation='fadeIn' style={[{flexGrow:1, width:'100%'}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart, {paddingLeft:20}]}>
            <Text style={{fontSize:54, color:'black', fontFamily:'Averta-Black', textAlign:'right', marginTop:-10, marginHorizontal:0, textTransform:'uppercase'}}>
            69%
            </Text>
            <Text style={{fontSize:20, color:'black', fontFamily:'Averta-Bold', textAlign:'right', marginTop:-5, textTransform:'uppercase', opacity:0.5}}>
            Djokovic
            </Text>
          </View>


        </Animated.View>

        <View style={[{width:'100%', backgroundColor:grey, padding:20, paddingVertical:10}, MainStyles.flexCenterEnd]}>
          <Image
          style={{height:36, width:36, resizeMode:'cover', borderRadius:5, overflow:'hidden', position:'absolute', left:20}}
          source={require('../../images/appstore.png')}
          />
          <Text style={{fontSize:10, color:'black', fontFamily:'Roboto-Medium', textAlign:'right', marginHorizontal:0, marginBottom:0, textTransform:'uppercase', opacity:0.5}}>
          Powered by
          </Text>
          <Text style={{fontSize:24, color:'black', fontFamily:'Averta-Black', textAlign:'right', marginHorizontal:0, textTransform:'uppercase'}}>
          tnnslive.com
          </Text>
        </View>

        </View>
      )
    }
  }

  render() {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
      {this.render_camLiveScores()}
      </View>
    )
  }
}

export default Item;
