// var buttonSize = 42

var fontColor = '#001021'
var buttonSize = 42

const GLOBAL = {
   username: 'Test Username',
   grid: 16,
   sizes:{
     button: buttonSize,
     bitmoji: buttonSize * 0.85,
     icon: buttonSize / 2,
   },
   colors: {
     alternatingBackground: 'rgba(0,0,0,0.2)',
     blue: '#50FFFC',
     yellow: '#FFEA00',
     teal: '#3CD782',
     pink: '#EC1181',

     grey: '#F4F5F8',
     darkBlue: '#006BFF',
     lightBlue: '#4ACDFF',
     font: fontColor,
     fontLight: 'rgba(0,16,33,0.33)',
     lightGrey: 'rgba(0,16,33,0.12)',
     lightLightGrey: 'rgba(0,16,33,0.06)',
     border: 'rgba(0,16,33,0.04)',
     // pink: '#FD297B',
     orange: '#FF5864',
     button: '#00D2FF',
     // purple: '#4A00E0',
     purple: '#9E53FF',
     backgroundGrey: '#EBEFF6',
     snapchat: '#FFFC00',
     youtube: "#e52d27",
     tiktok: "#1d1d1d",
     transparentBlack: 'rgba(0,0,0,0.20)',
     backgroundDark: '#06070D',
     green: "#29F599",
     volt: "#C5FF00",
     utr: "#29BAB6",
   },
   font:{
     button: {fontFamily:'Averta-Black', fontSize:18, color:'white', letterSpacing:-0.5, textAlign:'center'},
     title: {fontFamily:'Averta-Black', fontSize:24, color:fontColor, letterSpacing:-0.5, textAlign:'center'},
     normal: {fontFamily:'Averta-Semibold', fontSize:16, color:fontColor, letterSpacing:-0.5, textAlign:'center'},
     minor: {fontFamily:'Averta-Semibold', fontSize:14, color:fontColor, letterSpacing:-0.5, textAlign:'center'},
     sectionHeader: {fontFamily:'Averta-Bold', fontSize:12, color:fontColor, letterSpacing:-0.5, textAlign:'left', textTransform:'uppercase', marginBottom:16, marginTop:32}
   },
   emojis:{
     otherHalf:'🤩',
     squad:'😎',
   },
   gradients:{
     main: ['#2C1470', '#121212'],
     // blue: ['#006BFF','#00D2FF'],
     icon: ['#1481FF','#871BFF','#EE139F','#FD5152'],
     blue: ['#315BE9', '#38C4FB'],
     game: ['#0047FF', '#42E1FF'],
     purple: ['#2119FF', '#DE53FF'],
     orange: ['#FA7099', '#FBDF40'],
     peach: ['#FF512F', '#F09819'],
     // green: ["#11998e", "#38ef7d"],
     green: ["#37A0FB", "#29F599"],
     pink: ['#FD297B', '#FF5864', '#FF655B'],
     gold: ['#bf9a53', '#dec489', '#f6e084', '#d1b372', '#e3cc97', '#bf9a53'],
     secondary: {
       color: ['#79D5FA', '#F284FD', '#F9F73E'],
       // color: ['#006BFF','#00D2FF'],
       start: {x: 1, y: 0},
       end: {x: 0, y: 1}
     },
     // orange:,
   },
   shadows:{
     text:{
      textShadowOffset: { width: 0, height: 1 },
      textShadowRadius: 4,
      textShadowColor: 'rgba(0,16,33,0.20)',
    },
    view:{
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.06,
      shadowRadius: 3.84,
    },
   },

   style:{
     gradient:['#2C1470', '#121212'],
     color_font:'white',
     color_font_accent:'white',
     color_font_secondary:'rgba(255,255,255,0.5)',
     color_border:'rgba(255,255,255,0.05)',
     color_tab_font_main:'black',
     color_tab_font_secondary:'white',
     color_tab_main:'white',
     color_tab_secondary:'rgba(255,255,255,0.1)',
     id:'dark',
    visible:true,
    gradient:['#170162', '#0E0E12'],

    color_font_secondary:'rgba(255,255,255,0.5)',

    color_border:'rgba(255,255,255,0.05)',

    color_tab_font_main:'black',

    color_tab_secondary:'rgba(255,255,255,0.1)',

    // color_accent_1:GLOBAL.gradients.main[0],
    color_accent_1:'#20057E',

    alternatingBackground:'rgba(0,0,0,0.24)',

    color_bell:"white",
    color_logo:"white",

    color_notification_font:'#121212',

    header_background:["transparent", 'transparent'],
    header_color:"white",
    visible:true,
    name:'Dark'
   },

   styles:{
     tabView:{borderRadius:800, paddingHorizontal:16, paddingVertical:8, marginRight:0},
     tabViewActive:{backgroundColor:'white'},
     tabText:{fontFamily:'Averta-Regular', color:'rgba(255,255,255,0.67)', fontSize:16, letterSpacing:-0.3},
     tabTextActive:{color:fontColor, fontFamily:'Averta-Bold'}
   },

   controls:{},

   rankingsATP:{},
   rankingsWTA:{},
   liveMatchApi:{},
   liveMatches:{},
   tournamentsObj:{},
   allMatchesObj:{},
   allMatches:{},
   upcomingMatches:[],
   tournamentsIds:{},
   tournamentsObj:{},
   tournamentsSr:{},
   todayActiveIndex:0,
   todayActiveTitle:'ATP',

   dummyMatch:{
      // "winner_id": "sr:competitor:122366",
      // "finishedAt": 1618754050000,
      "tiebreak_scores": [],
      "competition_name": "ATP Monte Carlo, Monaco Men Singles",
      "start_time": {
        "seconds": 1618749000,
        "nanoseconds": 0
      },
      "sr:competitor:106755": true,
      "competitors": [
        {
          "qualifier": "home",
          "name": "R. Federer",
          "country": "Switzerland",
          "country_code": "SUI",
          "id": "sr:competitor:122366",
          "abbreviation": "FED",
          "seed": 3,
          "flag": "🇨🇭",
          "country_cca": "GR"
        },
        {
          "qualifier": "away",
          "name": "R. Nadal",
          "country": "Spain",
          "country_code": "ESP",
          "id": "sr:competitor:106755",
          "abbreviation": "NAD",
          "seed": 2,
          "flag": "🇪🇸",
          "country_cca": "RU"
        }
      ],
      "startedAt": 1618749795000,
      "start_time_confirmed": true,
      "lastUpdatedTimestamp": 1618797122394,
      "period_scores": [

      ],
      "best_of": 3,
      "status": "not_started",
      "home_score": 2,
      "match_status": "not_started",
      "gender": "men",
      "start_time_timestamp": "1618749000000",
      "sr:competitor:122366": true,
      "court_name": "The 02 Arena",
      "round": "Final",
      "round_abbreviated": "Final",
      "duration": "1:11",
      highlights:'123',
      live_audio:'123',
      preview:'123',
    },

    dummyMatchCompleted:{
       "winner_id": "sr:competitor:122366",
       "finishedAt": 1618754050000,
       "tiebreak_scores": [
         {number:2, home:7, away:9}
       ],
       "game_state": {
         "tie_break": false,
         "away_score": 15,
         "advantage": "home",
         "last_point_result": "receiver_winner",
         "serving": "away",
         "home_score": 40,
         "point_type": "match"
       },
       "competition_name": "ATP Monte Carlo, Monaco Men Singles",
       "start_time": {
         "seconds": 1618749000,
         "nanoseconds": 0
       },
       "sr:competitor:106755": true,
       "competitors": [
         {
           "qualifier": "home",
           "name": "R. Federer",
           "country": "Switzerland",
           "country_code": "SUI",
           "id": "sr:competitor:122366",
           "abbreviation": "FED",
           "seed": 3,
           "flag": "🇨🇭",
           "country_cca": "GR"
         },
         {
           "qualifier": "away",
           "name": "R. Nadal",
           "country": "Spain",
           "country_code": "ESP",
           "id": "sr:competitor:106755",
           "abbreviation": "NAD",
           "seed": 2,
           "flag": "🇪🇸",
           "country_cca": "RU"
         }
       ],
       "startedAt": 1618749795000,
       "start_time_confirmed": true,
       "lastUpdatedTimestamp": 1618797122394,
       "period_scores": [
           {
             "type": "set",
             "home_score": 6,
             "away_score": 3,
             "number": 1
           },
           {
             "type": "set",
             "home_score": 6,
             "away_score": 7,
             "number": 2
           },
           {
             "type": "set",
             "home_score": 6,
             "away_score": 4,
           "number": 3
         },
       ],
       "best_of": 3,
       "status": "ended",
       "home_score": 2,
       "match_status": "ended",
       "gender": "men",
       "start_time_timestamp": "1618749000000",
       "sr:competitor:122366": true,
       "court_name": "The 02 Arena",
       "round": "Final",
       "round_abbreviated": "Final",
       "duration": "1:11",
       highlights:'123',
       live_audio:'123',
     },

     dummyMatchLive:{
      "tiebreak_scores": [],
      "game_state": {
        "tie_break": false,
        "away_score": 30,
        "advantage": "home",
        "last_point_result": "",
        "serving": "away",
        "home_score": 40,
        "point_type": "match",
      },
      match_info_string:'Break Point',
      "competition_name": "ATP Monte Carlo, Monaco Men Singles",
      "start_time": {
        "seconds": 1618749000,
        "nanoseconds": 0
      },
      "sr:competitor:106755": true,
      "competitors": [
        {
          "qualifier": "home",
          "name": "N. Djokovic",
          "country": "Serbia",
          "country_code": "SUI",
          "id": "sr:competitor:122366",
          "abbreviation": "DJO",
          "seed": 1,
          "flag": "🇷🇸",
          "country_cca": "GR"
        },
        {
          "qualifier": "away",
          "name": "D. Shapovalov",
          "country": "Canada",
          "country_code": "CAN",
          "id": "sr:competitor:106755",
          "abbreviation": "SHA",
          "seed": 9,
          "flag": "🇨🇦",
          "country_cca": "RU"
        }
      ],
      "startedAt": 1618749795000,
      "start_time_confirmed": true,
      "lastUpdatedTimestamp": 1618797122394,
      "period_scores": [
          {
            "type": "set",
            "home_score": 7,
            "away_score": 5,
            "number": 1
          },
          {
            "type": "set",
            "home_score": 3,
            "away_score": 3,
            "number": 2
          },
      ],
      "best_of": 3,
      "status": "2nd_set",
      "home_score": 2,
      "match_status": "2nd_set",
      "gender": "men",
      "start_time_timestamp": "1618749000000",
      "sr:competitor:122366": true,
      "court_name": "Arthur Ashe Stadium",
      "round": "Round of 16",
      "round_abbreviated": "Round of 16",
      "duration": "1:11",
      // highlights:'123',
      // live_audio:'123',
      // live_video:'123',
    }
}

 export default GLOBAL
