import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated, TextInput } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 360
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var animation_duration = 350

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var stringChangeTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      options:[],
    };
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }

    if(this.listenControls){
      this.listenControls()
    }
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
      delay:0,
    })
    this.setup()
  }

  setup = async () => {
    //Get the selected match from Firestore
    this.listenControls = await firebase.firestore()
    .collection('streaming').doc("8MP5DlCnSoAMEzygPl3i")
    .onSnapshot(async (doc) => {
      if(doc.exists && doc.data()){
        var options = doc.data().stats_options || []
        var delay = doc.data().delay || 0
        var stats_view = doc.data().stats_view || 'Match'
        this.setState({options, delay, stats_view})
      }
    })
  }

  changeSet = async (stats_view) => {
    await firebase.firestore()
    .collection('streaming').doc("8MP5DlCnSoAMEzygPl3i")
    .set({
      stats_view,
    },{merge:true})
  }

  changeDelay = async () => {
    var { delay } = this.state
    await firebase.firestore()
    .collection('streaming').doc("8MP5DlCnSoAMEzygPl3i")
    .set({
      delay:Number(delay),
    },{merge:true})
  }

  //
  renderOptions = () => {
    var { options } = this.state
    return(
      <View style={[{width:'100%', justifyContent:'center', alignItems:'center', flexDirection:'column'}]}>
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginBottom:20}}>
        Pick which stats to show
        </Text>
        <View style={[{flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {options.map((item, index) => this.renderOption(item, index))}
        </View>
      </View>
    )
  }

  renderOption = (item, index) => {
    var active = item === this.state.stats_view
    return(
      <Pressable onPress={()=>this.changeSet(item)} style={{padding:20, minWidth:200, backgroundColor:active ? GLOBAL.colors.volt : '#2C1470', borderRadius:10, marginHorizontal:10, marginBottom:20}}>
        <Text style={{color:active ? 'black' : 'white', fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
        {item}
        </Text>
      </Pressable>
    )
  }

  renderDelay = () => {
    var { options } = this.state
    return(
      <View style={[{width:'100%', justifyContent:'center', alignItems:'center', flexDirection:'column', marginTop:60}]}>
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginBottom:20}}>
        Control the delay on the scoreboard (seconds).
        </Text>
        {this.renderDelayInput()}
        <Pressable onPress={this.changeDelay} style={{padding:20, minWidth:200, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10, marginBottom:20}}>
          <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
          Save delay
          </Text>
        </Pressable>
      </View>
    )
  }

  renderDelayInput = () => {
    var value = this.state.delay
    return(
      <View style={{width:200, marginVertical:10, borderRadius:5, backgroundColor:'#18181B'}}>
        <TextInput
        style={{color:'white', fontSize:18, fontFamily:'Averta-Regular', padding:20, textAlign:'center'}}
        value={value}
        onChangeText={(delay)=>this.setState({delay})}
        />
      </View>

    )
  }


  render() {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
        {this.renderOptions()}
        {this.renderDelay()}
      </View>
    )
  }
}

export default Item;
