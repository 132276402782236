import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, ScrollView, SectionList, TextInput, Button } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

var height = 160
var width = 550
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

var animation_duration = 350

var blue = "#389CFF"


//Widths
var width_stat = 150

var borderColor = 'rgba(0,0,0,0.1)'
var borderWidth = 1

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      videos:[],
      loading_string:"Fetching creator stats",
    };
  }

  componentDidMount = async () => {
    // this.getRatings()
    this.setState({loading:false})
  }

  componentWillUnmount = async () => {

  }

  //Functions
  getData = async () => {
    var limit = 10 //10 videos
    var { username } = this.state
    if(!username){
      alert("Don't forget to add a username!")
    }else{
      this.setState({loading:true})
      var backup_api = true
      var data = await this.callAPI(username, backup_api)

      //Isolate the videos
      var videos = []

      console.log(data)

      await this.setState({loading_string:"Successfully fetched creator videos.  Calculating engagement..."})

      try{
        if(backup_api){
          videos = data.data.aweme_list || []
        }else{
          videos = data.body.itemListData
        }

      }catch(e){
        console.log(e)
      }

      var followers = 0
      var followers_raw = 0
      var engagement_rate = 0

      var likes_all = 0
      var comments_all = 0
      var shares_all = 0
      var views_all = 0

      //Promise through videos and attach data
      await Promise.all(videos.map(async (video, index) => {

        if(backup_api){
          followers = await this.numberWithCommas(data.followers)
          followers_raw = data.followers

          if(index < limit){
            likes_all = likes_all + video.statistics.digg_count
            comments_all = comments_all + video.statistics.comment_count
            shares_all = shares_all + video.statistics.share_count
            views_all = views_all + video.statistics.play_count
          }

          var comments = await this.numberWithCommas(video.statistics.comment_count)
          var likes = await this.numberWithCommas(video.statistics.digg_count)
          var shares = await this.numberWithCommas(video.statistics.share_count)
          var views = await this.numberWithCommas(video.statistics.play_count)

          var engagement = Math.round((video.statistics.comment_count + video.statistics.digg_count + video.statistics.share_count) / followers_raw * 100 * 100) / 100 + "%"

          try{
            videos[index] = {
              image:video.video.animated_cover.url_list[0] || null,
              comments,
              likes,
              shares,
              engagement,
              views,
              id:video.aweme_id,
            }
          }catch(e){
            console.log(e)
          }

        }else{
          if(video.itemInfos){
            followers = await this.numberWithCommas(video.authorStats.followerCount)
            followers_raw = video.authorStats.followerCount

            if(index < limit){
              likes_all = likes_all + video.itemInfos.diggCount
              comments_all = comments_all + video.itemInfos.commentCount
              shares_all = shares_all + video.itemInfos.shareCount
              views_all = views_all + video.itemInfos.playCount
            }

            var comments = await this.numberWithCommas(video.itemInfos.commentCount)
            var likes = await this.numberWithCommas(video.itemInfos.diggCount)
            var shares = await this.numberWithCommas(video.itemInfos.shareCount)
            var views = await this.numberWithCommas(video.itemInfos.playCount)

            var engagement = Math.round((video.itemInfos.commentCount + video.itemInfos.diggCount + video.itemInfos.shareCount) / followers_raw * 100 * 100) / 100 + "%"

            try{
              videos[index] = {
                image:video.itemInfos.coversDynamic[0],
                comments,
                likes,
                shares,
                engagement,
                views,
                id:video.itemInfos.id,
              }
            }catch(e){
              console.log(e)
            }
          }
        }

        }))


      var engagement_rate = Math.round((likes_all + comments_all + shares_all) / videos.length / followers_raw * 100 * 100) / 100 + "%"

      likes_all = await this.numberWithCommas(likes_all)
      comments_all = await this.numberWithCommas(comments_all)
      shares_all = await this.numberWithCommas(shares_all)
      views_all = await this.numberWithCommas(views_all)

      if(videos.length > limit){
        videos = videos.slice(0,limit)
      }

      await this.setState({videos, likes_all, views_all, comments_all, shares_all, followers, engagement_rate, videos_loaded:true, loading:false})
    }
  }

  async numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  openVideo = (item) => {
     var { id } = item

     var url = "https://www.tiktok.com/@"+this.state.username+"/video/"+id

     window.open(url)
  }

  callAPI = async (user_name, backup_api) => {
    var data = null

    const axios = require("axios");

    //MAIN
    var options = {
      method: 'GET',
      url: 'https://tiktok-scraper2.p.rapidapi.com/user/videos',
      params: { user_name },
      headers: {
        'X-RapidAPI-Host': 'tiktok-scraper2.p.rapidapi.com',
        'X-RapidAPI-Key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9'
      }
    };

    if(backup_api){
      options = {
        method: 'GET',
        url: 'https://tiktok-best-experience.p.rapidapi.com/user/'+user_name+'/feed',
        // params: {max_cursor: '1630342868000'},
        headers: {
          'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com',
          'X-RapidAPI-Key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9'
        }
      };
    }

    await axios.request(options).then(function (response) {
    	data = response.data
    }).catch(function (error) {
    	console.error(error);
    });

    if(backup_api){
      //Need to get the followers
      var userId = null
      try{
        userId = data.data.aweme_list[0].author.uid
      }catch(e){
        console.log(e)
      }
      // alert(userId)
      var options_2 = {
        method: 'GET',
        url: 'https://tiktok-best-experience.p.rapidapi.com/user/id/'+userId+'/followers',
        headers: {
          'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com',
          'X-RapidAPI-Key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9'
        }
      }

      await axios.request(options_2).then(function (response) {
      	var data_user = response.data
        data.followers = data_user.data.total
      }).catch(function (error) {
      	console.error(error);
      });
    }

    return data
  }


  //Renders
  renderLoading = () => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color='black'/>
      </View>
    )
  }

  renderContent = () => {
    var { videos, videos_loaded, engagement_rate, views_all, likes_all, comments_all, shares_all, followers, likes } = this.state
    return(
      <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={[{width:'100%', paddingHorizontal:32, paddingTop:32}, MainStyles.flexStartCenter]}>

        {
          videos_loaded ?
          <>
          <Text style={{fontSize:24, fontWeight:'700'}}>
          @{this.state.username}
          </Text>
          <Text>
          Followers: {this.state.followers}
          </Text>
          <Text style={{marginVertical:16}}>
          Avg Engagement Rate: {this.state.engagement_rate}
          </Text>
          <Text style={{fontWeight:'700', marginBottom:8}}>
          For their last {videos.length} videos
          </Text>
          <Text>
          Total views: {this.state.views_all}
          </Text>
          <Text>
          Total likes: {this.state.likes_all}
          </Text>
          <Text>
          Total comments: {this.state.comments_all}
          </Text>
          <Text>
          Total shares: {this.state.shares_all}
          </Text>
          {this.renderVideos()}
          </>
          :
          <>
          <Text>
          Enter username
          </Text>
          <TextInput
          style={{fontSize:14, color:'black', padding:8, borderRadius:4, borderColor:'rgba(0,0,0,0.05)', borderWidth:1, marginTop:8, marginBottom:32}}
          placeholder='eg charlidamelio'
          value={this.state.username}
          onChangeText={(username) => this.setState({username})}
          onSubmitEditing={this.getData}
          />

          {
            this.state.loading ?
            <View style={[MainStyles.flexCenter]}>
              <ActivityIndicator size='small' color='black'/>
              <Text>
              {this.state.loading_string}
              </Text>
            </View>
            :
            <Button title={'Get data'} onPress={this.getData} style={{marginTop:0}}/>
          }
          </>
        }
      </ScrollView>
    )
  }

  renderVideos = () => {
    var { videos } = this.state

    var width = 300

    if(videos.length > 0){
      return(
        <View style={[MainStyles.flexCenter, MainStyles.flexRow, {flexWrap:'wrap', marginTop:64, maxWidth:1000}]}>
          {videos.map((item, index) => this.renderVideo(item, index, width))}
        </View>
      )
    }else{
      return(
        <View style={[MainStyles.flexCenter]}>
          <Text>
          No videos found for that creator
          </Text>
        </View>
      )
    }
  }

  renderVideo = (item, index) => {
    var height = 320
    var width = height / 2
    return(
      <Pressable onPress={()=>this.openVideo(item)} key={index} style={[MainStyles.flexCenterStart, {width, marginHorizontal:8, marginVertical:16, borderRadius:4, backgroundColor:'rgba(0,0,0,0)'}]}>
        <Image style={{height, width, borderRadius:4, marginBottom:16}} source={{uri:item.image}}/>
        <Text style={{marginBottom:8}}>
        Engagement rate: {item.engagement}
        </Text>
        <Text>
        Views: {item.views}
        </Text>
        <Text>
        Likes: {item.likes}
        </Text>
        <Text>
        Comments: {item.comments}
        </Text>
        <Text>
        Shares: {item.shares}
        </Text>
      </Pressable>
    )
  }

  renderMatch = ({item, index}, bold) => {
    return(
      <View style={[{width:400, marginVertical:16}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Text style={{width:100, fontWeight:bold ? '900' : '300'}}>
        {item ? item.votes : "Popularity"}
        </Text>
        <Text style={{fontWeight:bold ? '900' : '300'}}>
        {item ? item.competitors[0].name + " vs " + item.competitors[1].name : 'Match'}
        </Text>
      </View>
    )
  }

  render() {
    var { loading, videos_loaded } = this.state
    return(
      <View style={[{width:'100%'}]} style={[{flex:1, width:'100%', height:'100%', backgroundColor:'white'}, MainStyles.flexCenter]}>
        {this.renderContent()}
      </View>
    )
  }
}

export default Item;
