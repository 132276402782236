import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 360
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var animation_duration = 350

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var stringChangeTime = 8000

var title_style = {fontFamily:'Averta-Bold', color:'black', fontSize:24, letterSpacing:0, textTransform:'uppercase'}

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      subscribers:null,
    };
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }

    if(this.listenControls){
      this.listenControls()
    }
    if(this.listenVote){
      this.listenVote()
    }
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
    })
    this.setup()
  }

  setup = async () => {
    //Get the selected match from Firestore
    this.listenControls = await firebase.firestore()
    .collection('streaming').doc("8MP5DlCnSoAMEzygPl3i")
    .onSnapshot(async (doc) => {
      if(doc.exists && doc.data()){
        var subscribers = doc.data().subscribers || null
        this.setState({subscribers})
      }
    })
  }


  //Renders
  renderScoreboard = () => {
    var { string_stats, string_tippers, stats_list, stats_serving, stats_receiving, matchTime, matchInfo } = this.state

    var width = 500

    return(
      <>
      <View style={[{width, backgroundColor:'white', padding:0}, MainStyles.flexStartCenter]}>
        {this.renderCard('100%', false, false, 'Serving Stats', this.renderStats(stats_serving, 'Stats'))}
      </View>
      </>
    )
  }

  renderCard = (ratio, marginRight, marginLeft, title, content) => {
    return(
      <View style={{width:ratio, height:'100%', paddingRight:marginRight ? spacing : 0, paddingLeft:marginLeft ? spacing : 0}}>
        <View style={[{width:'100%', height:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_ticker, overflow:'hidden'}, MainStyles.flexStartCenter]}>
          {content}
        </View>
      </View>
    )
  }

  renderStats = (data, title) => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderStatTitle(title)}
        {
          data.length > 0 ?
          data.map((item, index) => this.renderStat(item, index))
          :
          this.renderPlayers()
        }
      </View>
    )
  }

  renderStat = (data, index) => {
    var alternating = index % 2 === 0


    return(
      <Animated.View style={[{width:'100%', backgroundColor:alternating ? '#F4F4F5' : 'transparent', paddingHorizontal:spacing/1, height:50, opacity:this.state.stats_opacity}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        {
          !data ? null :
          <>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:20, color:'#1B1B20', width:'20%', textAlign:'center', letterSpacing:-0.33}}>
            {data.values[0]}
            </Text>
            <Text style={{fontFamily:'Averta-Regular', fontSize:20, color:'#1B1B20', textAlign:'center', width:'60%', letterSpacing:-0.33, opacity:0.5}}>
              {data.title}
            </Text>
            <Text style={{fontFamily:'Averta-Semibold', fontSize:20, color:'#1B1B20', width:'20%', textAlign:'center', letterSpacing:-0.33}}>
            {data.values[1]}
            </Text>
          </>
        }
      </Animated.View>
    )
  }

  renderStatTitle = (title, hideNames) => {
    var { match } = this.state
    var color = 'white'
    return(
      <View style={[{width:'100%', paddingHorizontal:spacing, height:60, backgroundColor:'#389CFF'}, MainStyles.flexColumn, MainStyles.flexCenter]}>

        <View style={[{width:'100%'}, MainStyles.flexCenter, MainStyles.flexRow]}>
          {this.renderPlayer(0)}
          {
            // <Text style={{fontSize:23, color, fontFamily:'Averta-Regular', width:'60%', textAlign:'center', opacity:1}}>
            // STATS
            // </Text>
          }
          {this.renderPlayer(1)}
        </View>
      </View>
    )
  }

  renderPlayer = (index) => {
    var { flags, names } = this.state
    var color = 'white'
    var name = names[index]
    var flag = flags[index]

    var height = 38
    var width = height / 16 * 20

    var flex = index === 0 ? MainStyles.flexStartCenter : MainStyles.flexEndCenter
    var flex2 = index === 0 ? MainStyles.flexCenterStart : MainStyles.flexCenterEnd

    var renderFlag =
    <View style={[index === 0 ? {marginRight:10} : {marginLeft:10}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
      <ReactCountryFlag
        countryCode={flag}
        svg
        style={{
            width,
            height,
            marginBottom:2
        }}
        title="US"
      />
    </View>

    return(
      <View style={[{width:'50%'}, MainStyles.flexRow, flex]}>
        {index === 0 ? renderFlag : null}
        <View style={[flex2, {flex:1}]}>
          <Text style={{fontFamily:'Averta-Semibold', fontSize:16, color, textAlign:index === 0 ? 'left' : 'right', textTransform:'uppercase', marginBottom:-2, opacity:0.67}}>
          {name.first}
          </Text>
          <Text style={{fontFamily:'Averta-Black', fontSize:22, color, textAlign:index === 0 ? 'left' : 'right', textTransform:'uppercase', flexShrink:1, flexWrap:'wrap'}} numberOfLines={1}>
          {name.last}
          </Text>
        </View>
        {index === 1 ? renderFlag : null}
      </View>
    )

    // var height = 80
    // var width = height / 16 * 20
    //
    // return(
    //   <View style={[{width:'20%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
    //     <View style={[{position:'absolute', left:index === 0 ? -10 : null, right:index === 1 ? -10 : null, zIndex:0, opacity:0.12}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
    //       <ReactCountryFlag
    //         countryCode={flag}
    //         svg
    //         style={{
    //             width,
    //             height,
    //         }}
    //         title="US"
    //       />
    //     </View>
    //     <Text style={{fontFamily:'Averta-Black', fontSize:24, zIndex:1, color, textAlign:'center'}}>
    //     {name}
    //     </Text>
    //   </View>
    // )
  }

  renderVote = () => {
    return(
      <View style={{backgroundColor:'white'}}>
      {this.renderOdds()}
      </View>
    )
  }

  renderOdds = () => {
    // var percentage = "80%"
    // var winner = 'Federer'
    var colors = {
      primary:'#17171D',
      secondary:'white',
      accent:'#B6FE00',
      score_bg:'#1B1B20',
      score_font:'white',
      bg_opacity:0.3,
      main_font:'white',
      bg_stats:'#17171D',
      logo:null,
    }

    var height = 120
    var width = 1920
    var spacing = 10
    var spacing_main = 20
    var fontSize = 40
    var borderColor = 'rgba(255,255,255,0.15)'

    var app_size = 60

    var { subscribers } = this.state

    try{return(
      <View style={[{width:240, backgroundColor:'white'}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', padding:10, backgroundColor:'#389CFF'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Text style={[title_style, {textAlign:'center', color:'white', fontFamily:'Averta-Bold'}]}>
          Live Sub Count
          </Text>

        </View>
        <View style={[{padding:5}, MainStyles.flexCenter]}>
          <Text style={[title_style, {fontFamily:'Averta-Bold', fontSize:42}]}>
          {' '}
          </Text>

          <Image
          style={{height:app_size, width:app_size, borderRadius:app_size/4, resizeMode:'contain', marginRight:10}}
          source={{uri:"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg/1200px-YouTube_play_button_icon_%282013%E2%80%932017%29.svg.png"}}
          />
        </View>
      </View>
    )}catch(e){
      return null
    }
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:'white', fontFamily:'Averta-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to open its scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnhL8u3JpBzlsRcFYspmmO1OcFmlN-wun_veNctIgw=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item.id)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'Averta-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:'white', fontSize:16, fontFamily:'Averta-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : 'white', fontSize:16, fontFamily:'Averta-Regular'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }


  render() {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
        {this.renderVote()}
      </View>
    )
  }
}

export default Item;
