import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import ReactCountryFlag from "react-country-flag"
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated, ImageBackground } from "react-native";
import LinearGradient from 'react-native-web-linear-gradient';

import AroundTheCourts from './aroundthecourts.js'

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'
import Sound from './sound'

//Sounds
import SerenaGrunt from '../sounds/serenagrunting.mp3'
import AlertSound from '../sounds/alert.mp3'

//Module
import ReactGA from 'react-ga';

var height = 160
var width = 1100

var spacing = 9

var fontSize = 42
var moment = require('moment-timezone');

var stringChangeTime = 8000

var animation_duration = 350

var lineColor = 'rgba(0,0,0,0.06)'
var separatePlayers = 20

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      sounds:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      stats_options:[],
      matchTime:'0:00',
      matchInfo:'',
      prediction:{},

      activeIndex_stats:-1,
      activeIndex_tippers:-1,

      activeIndex_h2h:-1,
      h2h:[],

      tippers:[],
      stats:[],
      stats_obj:{},
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',

      stats_view:"Match",

      stats_opacity: new Animated.Value(1),
      h2h_opacity: new Animated.Value(1),

      images:[null, null],
      names:[null, null],
    };
  }

  componentDidMount = async () => {
    await this.setup()
    await this.changeStatsView('Match')

    // await this.selectMatch("sr:sport_event:27485230")
    // await this.selectMatch("sr:sport_event:31564721")

    this.changeTheme('Black')
    // this.changeTheme('Indian Wells')

    // this.playSound('test')
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    clearTimeout(this.updateString_tippers)
    clearTimeout(this.updateString_h2h)
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
    })
    this.setup()
  }

  setup = async () => {
    //Get available matches - live + scheduled
    var live = []
    var live_raw = []
    var scheduled_raw = []
    var scheduled = []
    var completed = []

    var categories = ['ATP', 'WTA', "Challenger", "Davis Cup"]

    await firebase.firestore()
    .collection('summaries_sr').doc('live')
    .get()
    .then(async (doc) => {
      live = Object.values(doc.data().matches)
    })

    await firebase.firestore()
    .collection('summaries_sr').doc('scheduled')
    .get()
    .then(async (doc) => {
      scheduled_raw = Object.values(doc.data().matches)
    })

    await Promise.all(live_raw.map(async match => {
      match.isLive = true
      if(categories.includes(match.category)){
        live.push(match)
      }
    }))

    //Promise through upcoming and only show ones starting in the next hour
    await Promise.all(scheduled_raw.map(async match => {
      var buffer = Date.now() + (24 * 60 * 60 * 1000)
      if(Number(match.start_time_timestamp) <= buffer && categories.includes(match.category)){
        scheduled.push(match)
      }
    }))

    await live.sort((a, b) => (a.startedAt < b.startedAt) ? 1 : -1);
    await scheduled.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);
    // await matches_uts.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);
    // await completed.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);

    var matches = live.concat(scheduled)

    // await matches.sort((a, b) => (a.startedAt < b.startedAt) ? 1 : -1);

    await Promise.all(matches.map(async match => {
      //Fix the names
      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }
    }))

    matches = [{id:'sr:sport_event:27485230', title:'Demo Match', category:'ATP', competitors:[{name:'Demo Match - Thiem'}, {name:'Andujar'}]}].concat(matches)

    this.setState({matches, loading:false})
  }

  //GS
  callGoalServe = async (url, runXML) => {
    const axios = require("axios");
    var parseString = require('xml2js').parseString;
    var CircularJSON = require('circular-json')

    var responseData = {}
    var rawData = {}

    if(runXML){
      // XML method
      await axios
    	.get(url, {
    		"Content-Type": "application/xml; charset=utf-8"
    	 })
    	.then(async (response) => {
    		rawData = response
        return
    	})
    	.catch(async (error) => {
    	});

      //Parse XML into JSON
      parseString(rawData.data, async (err, result) => {
        if(err){
          responseData = null
        }else{
          responseData = result
        }
        return
      });
    }else{

      //JSON method
      var jsonUrl = url.includes('?') ? url+'&?json=1' : url+'?json=1'


      await axios
    	.get(jsonUrl)
    	.then(async (response) => {
        //Clean up and remove @ symbols
        var stringifyRaw = CircularJSON.stringify(response)
        var stringify = stringifyRaw.replace(/@/g,"")

        var cleanResponse = CircularJSON.parse(stringify);


    		responseData = cleanResponse
        return
    	})
    	.catch(async (error) => {
    	});

    }

    return responseData
  }

  getGoalServe = async () => {

    try{
      //Live matches
      var url = "http://www.goalserve.com/getfeed/c01a41a89350498c796708d7a3194e20/tennis_scores/home"
      var data = await this.callGoalServe(url)

      var matchId = '1150532'
      var matchData = null

      var tournaments = data.data.scores.category

      await Promise.all(tournaments.map(async tournament => {
        var matches = Array.isArray(tournament.match) ? tournament.match : [tournament.match]
        await Promise.all(matches.map(async match => {
          if(match.id === matchId){
            //Found
            matchData = match
          }
        }))
      }))

      //
      if(matchData){
        //Match found, create the game_state object
        console.log('Match found', matchData)

        // "period_scores": [
        //     {
        //         "home_score": 5,
        //         "away_score": 7,
        //         "type": "set",
        //         "number": 1
        //     },
        //     {
        //         "home_score": 0,
        //         "away_score": 0,
        //         "type": "set",
        //         "number": 2
        //     }
        // ],
        // "game_state": {
        //     "serving": "home",
        //     "last_point_result": "server_winner",
        //     "tie_break": false
        // }

        var s1 = {
          home_score:Number(matchData.player[0].s1),
          away_score:Number(matchData.player[1].s1),
          type:'set',
          number:1,
        }

        var s2 = {
          home_score:Number(matchData.player[0].s2),
          away_score:Number(matchData.player[1].s2),
          type:'set',
          number:2,
        }

        var s3 = {
          home_score:Number(matchData.player[0].s3),
          away_score:Number(matchData.player[1].s3),
          type:'set',
          number:3,
        }

        var period_scores = [s1]
        if(matchData.player[0].s2){
          period_scores = [s1].concat(s2)
        }
        if(matchData.player[0].s3){
          period_scores = [s1].concat(s2).concat(s3)
        }

        var game_state
      }

    }catch(e){
      console.log('Error', e)
    }
  }



  //

  selectMatch = async (id) => {
    this.setState({id})

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .onSnapshot(this.handleMatch)
  }

  handleMatch = async ( querySnapshot ) => {
    var match = {}
    var docId = null

    if(querySnapshot.size > 0){
      await querySnapshot.forEach(async (doc) => {
        match = doc.data()
        docId = doc.id

        //Correct the player names
        if(match.competitors[0].name.includes(', ')){
          match.competitors[0].name_full = (match.competitors[0].name.split(", "))[1] + " " + (match.competitors[0].name.split(", "))[0]
          match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
        }

        if(match.competitors[1].name.includes(', ')){
          match.competitors[1].name_full = (match.competitors[1].name.split(", "))[1] + " " + (match.competitors[1].name.split(", "))[0]
          match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
        }
      })
    }

    if(this.state.firstSet && docId){
      this.setupListeners(docId, match)
      this.setupPlayers(match)
    }

    //Calculate the matchTime
    var durationString = null
    if(match.startedAt){
      var startedAt = match.startedAt
      var finishedAt = !match.finishedAt ? Date.now() : match.finishedAt

      var duration = (moment.duration(finishedAt - startedAt).as('hours')).toString()

      //Clean string
      var durationHours = Number(duration.split('.')[0])
      if(durationHours < 6){
        var durationMinutesNumber = Math.round(Number("."+duration.split('.')[1]) * 60)
        var durationMinutesRaw = durationMinutesNumber == 60 ? 59 : durationMinutesNumber
        var durationMinutes = durationMinutesRaw.toString().length == 1 ? "0"+durationMinutesRaw : durationMinutesRaw
        durationString = durationHours + ':' + durationMinutes
      }
    }

    match.competitor_photos = match.competitor_photos || [null, null]

    //Calculate the duration string
    var matchTime = match.match_time_string || durationString ? "Match Time: "+durationString : ''


    //Match Info string
    var matchInfo = match.season_name.split(", ")[0] + " - " + match.round


    //Prediction
    try{
      if(match){
        //Get the market
        var outcomes = match.outcomes || {}
        var outcome = outcomes.live || outcomes.prematch || null
        var mode = outcomes.live ? 'Live' : 'Pre-Match'

        var prediction = {}

        if(outcome){
          var home_percentage = Math.round(outcome.outcomes[0].probability)
          var away_percentage = Math.round(outcome.outcomes[1].probability)

          if(home_percentage > away_percentage){
            prediction.name = match.competitors[0].name.split(", ")[0]
            prediction.value = home_percentage+'%'
            prediction.images = [match.competitor_photos[0]]
          }else if(home_percentage < away_percentage){
            prediction.name = match.competitors[1].name.split(", ")[0]
            prediction.value = away_percentage+'%'
            prediction.images = [match.competitor_photos[1]]
          }else if(home_percentage === away_percentage){
            prediction.name = 'Toss Up'
            prediction.value = '50%'
            prediction.images = match.competitor_photos
          }
        }

        await this.setState({prediction, prediction_mode:mode})
      }
    }catch(e){
      console.log(e)
    }

    this.setState({match, matchTime, matchInfo, loading:false, mode:'match', firstSet:false})
  }

  setupPlayers = async (match) => {
    if(match.competitors[0].name.includes(". ")){
      var lastname_1 = match.competitors[0].name
      var lastname_2 = match.competitors[1].name

      try{
        lastname_1 = (match.competitors[0].name.split(". ")[1]).toLowerCase()
        lastname_2 = (match.competitors[1].name.split(". ")[1]).toLowerCase()
      }catch(e){
        console.log(e)
      }

      var image_1 = match.season_name.toLowerCase().includes('women') ? null : "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_1+"_full_ao20.png"
      var image_2 = match.season_name.toLowerCase().includes('women') ? null : "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_2+"_full_ao20.png"

      //Get player profiles
      var image_official_1 = await this.getPlayer(match.competitors[0].id)
      var image_official_2 = await this.getPlayer(match.competitors[1].id)

      var images = [image_official_1 || image_1, image_official_2 || image_2]
      var names = [match.competitors[0].name_full, match.competitors[1].name_full]

      this.setState({images, names})
    }
  }

  getPlayer = async (id) => {
    var image = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.data()){
            if(doc.data().image_official){
              image = doc.data().image_official
            }
          }
        })
      }
    })

    return image
  }

  setupListeners = async (docId, match) => {
    var status = match.match_status
    //Get stats & h2h
    this.listenMatchStats = await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('info').doc('stats')
    .onSnapshot(this.handleStats)

    this.listenTippers = await firebase.firestore()
    .collection('streaming').doc("yBhmZeRfAZ7rzm8jemwX")
    .onSnapshot(this.handleTippers)

    await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('info').doc('data')
    .get()
    .then(async(doc)=>{
      if(doc.exists && doc.data()){
        var stats = []
        var stats_title = 'H2H Record'
        var h2h = doc.data().h2h_v2

        if(h2h){
          //Generate the H2H record string
          var record = h2h.wins
          var leader_string = ''
          if(record[0] > record[1]){
            leader_string = match.competitors[0].name + " leads the H2H " + record[0] + "-" + record[1]
          }else if(record[1] > record[0]){
            leader_string = match.competitors[1].name + " leads the H2H " + record[1] + "-" + record[0]
          }else{
            //Tied
            leader_string = "H2H is tied at " + record[0] + "-" + record[1]
          }

          // stats.push(leader_string)
          var title_h2h = leader_string

          var matches = h2h.matches || []
          await Promise.all(matches.map(async (h2h_match, index) => {
            stats.push(h2h_match.winner + " won in the "+ h2h_match.round + " at the " + h2h_match.tournament + " in " + moment(h2h_match.timestamp, 'x').format('YYYY') + "  -  " + h2h_match.score)
          }))

          await this.setState({h2h:stats, title_h2h})
          this.changeString_h2h()
        }

      }
    })
  }

  handleTippers = async (doc) => {
    //Set state and setup string changing timers
    // var tippers = [
    //   "Here's the first stat",
    //   "Here's the next stat",
    //   "And what about htis stats",
    // ]

    var tippers = []

    if(doc.data() && doc.exists){

      this.handleSounds(doc.data().sounds || {})

      var view_mode = doc.data().view_mode || {}
      if(view_mode.stats){
        if(view_mode.stats !== this.state.stats_view){
          this.changeStats(view_mode.stats)
        }
      }

      tippers = doc.data().topTippers || []
      var tippers_raw = doc.data().topTippers || []

      tippers = [tippers.join("   ·   ")]

      await this.setState({tippers, tippers_raw})

      this.changeString_tippers()
    }
  }

  handleStats = async (doc) => {
    var { match } = this.state
    if(doc.data() && doc.exists){
      var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM"]
      var stats_list = []

      var stats_obj = {}
      var stats_options = []

      var stats_serving = []
      var stats_receiving = []

      var statsRaw = doc.data().stats || []

      //Setup names
      var p1 = match.competitors[0].name || ''
      var p2 = match.competitors[1].name || ''

      //Promise through stats and add values
      if(statsRaw){
        await Promise.all(statsRaw.map(async stat_period => {
          // if(stat_period.title === 'Match'){
            var period_stats = await this.cleanStats(stat_period.stats.stats || [])
            stats_options.push(stat_period.title)
            stats_obj[stat_period.title] = period_stats
          // }
        }))
      }

      await this.setState({stats_obj, stats_options})

      this.changeStats()

      var stats_title = 'Match Stats'
      await this.setState({stats, stats_title, stats_list})
      this.changeString_stats()

    }else{
      var stats = ["Match Stats powered by TNNS - TNNSLIVE.COM", "Stats available soon"]
      await this.setState({stats})
      this.changeString_stats()
    }
  }

  cleanStats = async (period_stats) => {
    var stats_serving = []
    var stats_receiving = []
    var blocked_stats = ["Return Games Won"]

    await Promise.all(period_stats.map(async stat_group => {
      await Promise.all(stat_group.data.map(async stat => {
        if(stat.values){
          if((stat.values[0] || stat.values[0] === 0) && (stat.values[1] || stat.values[1] === 0) && !blocked_stats.includes(stat.title)){
            var stat_obj = {title:stat.title, values:stat.values}

            if((stat_group.title === 'Service' && stat.title !== "Service Games Won") || stat_group.title === 'General'){
              stats_serving.push(stat_obj)
            }
            if(stat_group.title === 'Return' || stat_group.title === 'Time Violations'){
              stats_receiving.push(stat_obj)
            }
          }
        }
      }))
    }))

    return { stats_serving, stats_receiving }
  }

  changeTheme = async (theme) => {
    var style = {
      background_main:"#101012",
      background_match:'#18181B',
      background_tnns:'#18181B',
      background_scores:"#18181B",
      background_ticker:'#18181B',
      background_tippers:"#18181B",
      font_tippers:"white",
      font_ticker:'white',
      font_color:'white',
      game_color:'white',
      set_color:'white',
      player_color:'white',
      player_font:'ClanOT-Bold',
      ball_color:GLOBAL.colors.volt,
      // event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
      borderRadius:10,
      letterSpacing:0.5,
      alternatingBackground:'rgba(0,0,0,0.24)',
    }

    var style_new = {
      background:{
        image:require('../../images/gtl-bg-green-blur.jpg'),
        primary:'white',
        secondary:'#438669',
        tertiary:'#088B69',
        alternating:'#F6F7F7',
        score:"#EDEEF5",
        tippers:"#03281E",
      },
      color:{
        primary:'#121212',
        secondary:'white',
        tertiary:'white',
        score:"#25642F",
        tippers:"white",
      },
      borderRadius:4,
    }

    if(theme === "Light Blue"){
      style_new = {
        background:{
          image:require('../../images/gtl-bg-blue-blur.jpg'),
          primary:'white',
          secondary:'#00488A',
          tertiary:'#001F3B',
          alternating:'#F6F7F7',
          score:"#EDEEF5",
          tippers:"#001F3B",
        },
        color:{
          primary:'#121212',
          secondary:'white',
          tertiary:'white',
          score:"#001F3B",
          tippers:"white",
        },
        borderRadius:4,
      }
    }

    if(theme === "Black"){
      style_new = {
        background:{
          image:require('../../images/gtl-bg-black-blur.jpg'),
          primary:'#3B3B3B',
          alternating:'#454545',

          secondary:'#84754E',
          // tertiary:'#001F3B',
          score:"#454545",
          tippers:"#454545",
        },
        color:{
          primary:'white',
          secondary:'white',
          // tertiary:'white',
          score:"white",
          tippers:"white",
        },
        borderRadius:4,
      }
    }

    return this.setState({style, style_new, theme})

    // return this.setState({style, theme})
  }

  changeStats = async (id) => {
    var { stats_obj, stats_options, stats_view } = this.state

    await Animated.timing(this.state.stats_opacity, {
      toValue: 0,
      duration: animation_duration,
    }).start();

    await setTimeout(async ()=>{
      if(id){
        await this.setState({stats_view:id})
      }

      var stats_id = stats_obj[this.state.stats_view] ? this.state.stats_view : 'Match'

      if(id === 'H2H'){
        stats_id = 'H2H'
      }

      await this.setState({stats_view:stats_id})

      var stats_receiving = stats_obj[stats_id] ? stats_obj[stats_id].stats_receiving : []
      var stats_serving = stats_obj[stats_id] ? stats_obj[stats_id].stats_serving : []

      await this.setState({stats_receiving, stats_serving})

      await Animated.timing(this.state.stats_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, 350)
  }

  //Sounds
  handleSounds = async (sounds_new) => {
    var { sounds } = this.state
    await Promise.all(Object.keys(sounds_new).map(async sound => {
      if(sounds_new[sound] !== sounds[sound] && sounds[sound]){
        this.playSound(sound)
      }
    }))

    this.setState({sounds:sounds_new})
  }

  playSound = async (sound) => {
    // alert('play sound '+sound)

    var sounds = {
      test:SerenaGrunt,
      test2:AlertSound,
    }

    var audio = sounds[sound]

    if(audio){
      var soundbite = new Audio(audio)
      soundbite.play()
    }


  }

  //
  changeStatsView = async (id) => {
    await firebase.firestore()
    .collection('streaming').doc('yBhmZeRfAZ7rzm8jemwX')
    .set({
      view_mode:{
        stats:id,
      }
    },{merge:true})
  }

  //String changers
  changeString_stats = async () => {
    clearTimeout(this.updateString_stats)

    var { activeIndex_stats, stats } = this.state
    var nextIndex = activeIndex_stats+1
    if((activeIndex_stats+1) >= (stats.length)){
      nextIndex = 0
    }

    var string_stats = stats[nextIndex]

    await this.setState({string_stats, activeIndex_stats:nextIndex})

    this.updateString_stats = setTimeout(async () => {
      this.changeString_stats()
    }, stringChangeTime)
  }

  changeString_h2h = async () => {
    clearTimeout(this.updateString_h2h)

    await Animated.timing(this.state.h2h_opacity, {
      toValue: 0,
      duration: animation_duration,
    }).start();

    await setTimeout(async ()=>{
      var { activeIndex_h2h, h2h } = this.state
      var nextIndex = activeIndex_h2h+1
      if((activeIndex_h2h+1) >= (h2h.length)){
        nextIndex = 0
      }

      var string_h2h = h2h[nextIndex]

      await this.setState({string_h2h, activeIndex_h2h:nextIndex})

      console.log('H2H string updated', string_h2h)

      this.updateString_h2h = setTimeout(async () => {
        this.changeString_h2h()
      }, stringChangeTime)

      await Animated.timing(this.state.h2h_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();

    }, animation_duration)
  }

  changeString_tippers = async () => {
    clearTimeout(this.updateString_tippers)
    var { activeIndex_tippers, tippers } = this.state

    var nextIndex = activeIndex_tippers+1
    if((activeIndex_tippers+1) >= (tippers.length)){
      nextIndex = 0
    }

    var string_tippers = tippers[nextIndex]
    await this.setState({string_tippers, activeIndex_tippers:nextIndex})

    this.updateString_tippers = setTimeout(async () => {
      this.changeString_tippers()
    }, stringChangeTime / 2)
  }

  changeImage = async () => {
    var newImage = prompt('Change the image by entering the new URL')

    if(newImage){
      var style = this.state.style
      style.event_logo = newImage
      this.setState({style})
    }
  }


  //Renders
  renderScoreboard = () => {
    var { string_stats, string_tippers, stats_list, stats_serving, stats_receiving, matchTime, matchInfo } = this.state

    var height = width / 276 * 165

    var showStats = stats_serving.length > 0
    // console.log('show stats?', showStats, stats_serving.length, stats_serving)
    // var showStats = false

    return(
      <View style={{width, height, padding:spacing*1, backgroundColor:'white'}}>
        <ImageBackground source={this.state.style_new.background.image} resizeMode="cover" style={[{width:'100%', height:'100%', padding:spacing*1, borderRadius:spacing, overflow:'hidden'}, MainStyles.flexStartCenter]}>

          <Image
          style={{width:'100%', height:height+20, resizeMode:'cover', opacity:1, top:-20, position:'absolute'}}
          source={this.state.style.background_image || null}
          />

          {
            // this.renderTicker(matchInfo, 'Info', matchTime)
          }

          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            {
              !matchInfo ? null :
              <View style={[{height:32, paddingHorizontal:spacing*1.5, borderRadius:this.state.style_new.borderRadius, backgroundColor:this.state.style_new.background.secondary}, MainStyles.flexCenter]}>
                <Text style={{fontFamily:'ClanOT-Bold', fontSize:16, color:this.state.style_new.color.secondary}}>
                {matchInfo}
                </Text>
              </View>
            }

            <View style={[{flex:1}, MainStyles.flexRow, MainStyles.flexEndCenter]}>
              {
                !matchTime ? null :
                <View style={[{height:32, paddingHorizontal:spacing*1.5, borderRadius:this.state.style_new.borderRadius, backgroundColor:this.state.style_new.background.secondary, marginRight:spacing}, MainStyles.flexCenter]}>
                  <Text style={{fontFamily:'ClanOT-Bold', fontSize:16, color:this.state.style_new.color.secondary}}>
                  {matchTime}
                  </Text>
                </View>
              }
              <View style={[{height:32, paddingHorizontal:spacing*1.5, borderRadius:this.state.style_new.borderRadius, backgroundColor:"#FD0164", marginRight:0}, MainStyles.flexRow, MainStyles.flexCenter]}>
                <View style={{height:10, width:10, borderRadius:100, backgroundColor:'white', marginRight:6}}/>
                <Text style={{fontFamily:'ClanOT-Bold', fontSize:16, color:'white'}}>
                LIVE
                </Text>
              </View>
            </View>
          </View>

          <View style={[{width:'100%', paddingVertical:spacing*1}, MainStyles.flexRow, MainStyles.flexCenter]}>
            {
              this.renderFeatureCard()
            }
            {this.renderScore()}
            {
              // this.renderImage({uri:this.state.style.event_logo}, true, this.changeImage)
            }
            {
              // this.renderImage({uri:'https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj'})
            }
          </View>
          {
            showStats ?
            <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>

              {this.renderTippers('35%')}

              <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {width:'65%', paddingLeft:spacing*1.5}]}>
                {this.renderCard('100%', false, false, 'Serving Stats', this.renderStats(stats_serving, 'Stats'))}
                <View style={{height:spacing*2}}/>
                {
                  // this.renderCard('100%', false, false, 'Receiving Stats', this.renderStats(stats_receiving, 'Time Violations'))
                }
              </View>

            </View>
            :
            null
          }
          {showStats ? null :
            <View style={[{width:'100%', marginTop:spacing*0}, MainStyles.flexRow, MainStyles.flexStartStart]}>
            {this.renderTippers('40%')}
            {this.renderPreviewImage('60%')}
            </View>
          }
        </ImageBackground>
      </View>
    )
  }

  renderPrediction = () => {
    var { prediction, prediction_mode } = this.state
    var images = prediction.images || []

    var color = this.state.style.font_ticker

    try{
      if(!prediction || !prediction.value){return null}else{
        return(
          <View style={[{width:'100%', padding:spacing}, MainStyles.flexCenterStart]}>
            <Text style={{fontSize:15, color, fontFamily:'ClanOT-Bold', textAlign:'left'}}>
            TNNS {prediction_mode} Win Predictor
            </Text>
            <View style={[MainStyles.flexStartCenter, MainStyles.flexRow, {paddingLeft:0, marginTop:spacing}]}>
              <Text style={{fontSize:24, marginRight:spacing*1, color, fontFamily:'ClanOT-Black', textAlign:'left', textTransform:'uppercase'}}>
              {prediction.value}
              </Text>
              <Text style={{fontSize:24, color, fontFamily:'ClanOT-Bold', textAlign:'left', textTransform:'uppercase', opacity:0.67}}>
              {prediction.name}
              </Text>
            </View>
          </View>
        )
      }
    }catch(e){
      return null
    }
  }

  renderCard = (ratio, marginRight, marginLeft, title, content, backgroundColor) => {
    return(
      <View style={{width:ratio, height:'100%', paddingRight:marginRight ? spacing : 0, paddingLeft:marginLeft ? spacing : 0}}>
        <View style={[{width:'100%', height:'100%', borderRadius:this.state.style_new.borderRadius, backgroundColor:this.state.style_new.background.primary, overflow:'hidden'}, MainStyles.flexStartCenter]}>
          {content}
        </View>
      </View>
    )
  }

  renderTippers = (width) => {
    var { tippers_raw, prediction } = this.state
    return(
      <View style={{width, marginTop:spacing*0, flexGrow:1}}>
        <View style={[{width:'100%', borderRadius:this.state.style_new.borderRadius, backgroundColor:this.state.style_new.background.secondary, flexGrow:1}, MainStyles.flexStartCenter]}>
          {this.renderStatTitle('Top Tippers', true, this.state.style_new.color.tippers, true)}
          {tippers_raw.map((item, index) => this.renderTipper(item, index))}
        </View>

        <View style={{width:'100%', marginTop:10}}>
        {
          this.renderAroundCourts()
        }
        </View>

      </View>
    )
  }

  renderTipper = (item, index) => {
    var color = this.state.style.font_toptippers ? this.state.style.font_toptippers[index] || this.state.style.font_tippers : this.state.style.font_tippers
    var bg_norm = alternating ? this.state.style.alternatingBackground : 'transparent'
    var gradient = this.state.style.gradient_toptippers ? this.state.style.gradient_toptippers[index] || null : null
    var alternating = index % 2 === 0
    var image = this.state.style.image_toptippers ? this.state.style.image_toptippers[index] || null : null
    return(
      <>
      <View style={[{width:'100%', backgroundColor:bg_norm, padding:spacing, paddingVertical:spacing/1.5}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        {
          !gradient ? null :
          <LinearGradient colors={gradient} start={{x:0,y:0}} end={{x:1,y:1}} style={{position:'absolute', width:'100%', height:'100%', left:0, zIndex:1}}/>
        }
        <Text style={{fontFamily:'ClanOT-News', fontSize:18, color, textAlign:'left', zIndex:2}}>
          {item}
        </Text>

        {
          !image ? null :
          <Image
          source={image}
          style={{height:20, width:20, resizeMode:'contain', position:'absolute', right:spacing, zIndex:3}}
          />
        }
      </View>
      </>
    )
  }

  renderStats = (data, title) => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderStatTitle(this.state.stats_view)}
        {data.map((item, index) => this.renderStat(item, index))}
      </View>
    )
  }

  renderStat = (data, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style_new.background.alternating : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Animated.Text style={{fontFamily:'ClanOT-Medium', fontSize:18, color:this.state.style_new.color.primary, textAlign:'left', opacity:this.state.stats_opacity}}>
          {data.title}
        </Animated.Text>

        <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Animated.Text style={{fontFamily:'ClanOT-News', fontSize:18, color:this.state.style_new.color.primary, width:'50%', textAlign:'left', opacity:this.state.stats_opacity}}>
          {data.values[0]}
          </Animated.Text>
          <Animated.Text style={{fontFamily:'ClanOT-News', fontSize:18, color:this.state.style_new.color.primary, width:'50%', textAlign:'left', opacity:this.state.stats_opacity}}>
          {data.values[1]}
          </Animated.Text>
        </View>
      </View>
    )
  }

  renderStatTitle = (title, hideNames, color, disableAnimation) => {
    var { match } = this.state

    var opacity = !disableAnimation ? this.state.stats_opacity : 1

    return(
      <View style={[{width:'100%', padding:spacing*1, paddingHorizontal:spacing}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>
        <Animated.Text style={{fontSize:20, color:color || this.state.style_new.color.score, fontFamily:'ClanOT-Black', width:'100%', textAlign:'left', opacity}}>
        {title}
        </Animated.Text>

        {
          hideNames ? null :
          <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'ClanOT-Bold', fontSize:18, color:color || this.state.style_new.color.score, width:'50%', textAlign:'left', opacity}}>
            {match.competitors[0].abbreviation}
            </Text>
            <Text style={{fontFamily:'ClanOT-Bold', fontSize:18, color:color || this.state.style_new.color.score, width:'50%', textAlign:'left', opacity}}>
            {match.competitors[1].abbreviation}
            </Text>
          </View>
        }
      </View>
    )
  }

  //

  renderTicker = (string, title, right) => {
    return(
        <View style={[{width:'100%', backgroundColor:this.state.style.background_main, height:32, paddingHorizontal:0}, MainStyles.flexCenter]}>

          <View style={[{width:'100%', backgroundColor:this.state.style.background_ticker, height:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <View style={[{height:'100%', paddingHorizontal:spacing*2, backgroundColor:'rgba(0,0,20,0.05)'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'ClanOT-Bold', textAlign:'left'}}>
              {title}
              </Text>
            </View>

            <View style={[{flexGrow:1}, MainStyles.flexCenterStart]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'ClanOT-Bold', textAlign:'left', paddingLeft:spacing*2}}>
              {string}
              </Text>
            </View>

            <View style={[{height:'100%', paddingHorizontal:spacing*2, backgroundColor:'rgba(0,0,20,0.05)'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'ClanOT-Bold', textAlign:'left'}}>
              {right}
              </Text>
            </View>
          </View>

        </View>
    )
  }

  renderFeatureCard = () => {
    var color = this.state.style_new.color.secondary
    // return(
    //   <View style={[{width:height*1.5, height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tnns}, MainStyles.flexCenter]}>
    //     <Image
    //     style={{resizeMode:'contain', width:'100%', height:'100%', tintColor:this.state.style.tint_tnns || 'white'}}
    //     source={require('../../images/poweredbytnns.png')}
    //     />
    //   </View>
    // )
    return(
      <View style={[{width:height, height, borderRadius:this.state.style_new.borderRadius, overflow:'hidden', backgroundColor:this.state.style_new.background.tippers, marginRight:spacing*1.5}, MainStyles.flexEndCenter]}>
        <View style={[{flex:1}, MainStyles.flexCenter]}>
          <Text style={{fontFamily:'ClanOT-News', fontSize:12, marginBottom:0, opacity:0.5, color}}>
          POWERED BY
          </Text>
          <Text style={{fontFamily:'ClanOT-Black', fontSize:42, color}}>
          TNNS
          </Text>
          <Text style={{fontFamily:'ClanOT-Medium', fontSize:24, color, marginTop:-5, opacity:0.67}}>
          LIVE
          </Text>
        </View>
        <View style={[{width:'100%', paddingVertical:5, backgroundColor:'rgba(0,0,0,0.2)', marginTop:0}]}>
          <Text style={{fontFamily:'ClanOT-Bold', fontSize:14, color, width:'100%', textAlign:'center'}}>
          TNNSLIVE.COM
          </Text>
        </View>
      </View>
    )
  }

  renderImage = (source, disableMargin, onPress) => {
    return(
      <Pressable onPress={onPress || null}>
        <Image
        style={{height, width:height, borderRadius:this.state.style.borderRadius, marginLeft:disableMargin ? 0 : spacing, resizeMode:this.state.style.event_resize || 'cover', tintColor:this.state.style.event_tint || null}}
        source={source}
        />
      </Pressable>
    )
  }

  renderPreviewImage = (width) => {
    var { id, match, names, string_h2h, title_h2h, match } = this.state

    var images = match.competitor_photos || [null,null]

    var image_1 = images[0] || null
    var image_2 = images[1] || null

    var name_1 = names[0] || null
    var name_2 = names[1] || null

    var height = 280

    var img_width = 200
    var img_height = img_width

    var name_style = {fontFamily:'ClanOT-Black', color:'white', fontSize:36, color:this.state.style_new.color.tippers, textTransform:'uppercase', textAlign:'center', width:'85%', flexWrap:'wrap'}
    var secondary_style = {fontFamily:'ClanOT-Medium', fontSize:20, color:this.state.style_new.color.tippers, textTransform:'uppercase', opacity:0.5, textAlign:'center'}

    // if(image_1 && image_1){
      return(
        <View style={{width, marginTop:spacing*0, flexGrow:1, marginLeft:spacing*1.5, paddingRight:spacing*2}}>
          <View style={[{width:'100%', height, borderRadius:this.state.style_new.borderRadius, backgroundColor:this.state.style_new.background.tippers, flexGrow:1, overflow:'hidden', padding:20}, MainStyles.flexCenter]}>

            <Image
            style={{width:'100%', height:'100%', resizeMode:'cover', opacity:0.25, position:'absolute'}}
            source={{uri:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"}}
            />

            <View style={[{}, MainStyles.flexCenter]}>
              <Text style={[secondary_style, {marginBottom:20}]}>
              {match.season_name.split(", ")[0] + " - " + match.round}
              </Text>

              <Text style={[name_style]}>
              {name_1}
              </Text>

              <Text style={[secondary_style, {marginVertical:5}]}>
              vs
              </Text>

              <Text style={[name_style]}>
              {name_2}
              </Text>

            </View>

            {
              // <Image
              // style={{position:'absolute', left:-25, bottom:-15, height:img_height, width:img_width, resizeMode:'contain'}}
              // source={{uri:image_1}}
              // />
              //
              // <Image
              // style={{position:'absolute', right:-25, bottom:-15, height:img_height, width:img_width, resizeMode:'contain'}}
              // source={{uri:image_2}}
              // />
            }

          </View>

          <View style={{width:'100%', marginTop:spacing, backgroundColor:this.state.style_new.background.tippers, padding:spacing*2, borderRadius:this.state.style_new.borderRadius}}>
            <Text style={[secondary_style, {marginBottom:10, marginTop:0, color:this.state.style_new.color.tippers, opacity:1, fontFamily:'ClanOT-Black', fontSize:24}]}>
            {title_h2h}
            </Text>
            <Animated.Text style={[secondary_style, {marginBottom:0, width:'100%', fontFamily:'ClanOT-Medium', color:this.state.style_new.color.tippers, opacity:0.5}]}>
            {string_h2h}
            </Animated.Text>
          </View>
        </View>
      )
    // }
  }


  //Score renders
  renderScore = () => {
    var item = this.state.match
    return(
      <View style={[{height, flex:1, borderRadius:this.state.style_new.borderRadius, marginHorizontal:spacing*0, marginRight:0, backgroundColor:this.state.style_new.background.primary, overflow:'hidden'}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {
          // !this.state.style.leftTab ? null :
          // <View style={{height:'100%', width:16, marginRight:5, backgroundColor:this.state.style.leftTab}}/>
        }
        <View style={[{flex:1, height:'100%', paddingLeft:spacing}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{flexShrink:1, marginRight:spacing*2, height:'100%'}, MainStyles.flexColumn, MainStyles.flexCenterStart]}>
            {this.renderPlayer(item.competitors[0], 0, item.winner_id, item.seeds)}
            <View style={[{height:separatePlayers, width:'100%'}, MainStyles.flexCenter]}>
              <View style={{height:1, width:'100%', backgroundColor:lineColor}}/>
            </View>
            {this.renderPlayer(item.competitors[1], 1, item.winner_id, item.seeds)}
          </View>
          {this.renderVisualScores(item)}
        </View>
      </View>
    )
  }

  renderPlayer = (item, index, winner_id, seeds) => {

    var winner = winner_id ? winner_id === item.id : true
    var textStyle = {fontSize, color:this.state.style_new.color.primary, fontFamily:'ClanOT-Bold', textAlign:'left', letterSpacing:0}

    var flag = item.flag

    var isDoubles = item.name.includes("/")

    if(isDoubles || this.state.style.hideFlags){
      flag = null
    }

    var game = this.state.match.game_state || {}
    var player_gs_id = index === 0 ? 'home' : 'away'
    var isServing = game.serving === player_gs_id && !winner_id

    var renderServing = null

    if(isServing){
      renderServing = <View style={{height:16, width:16, borderRadius:100, backgroundColor:this.state.style_new.background.secondary, marginLeft:15}}/>

      if(this.state.theme === 'Wimbledon'){
        renderServing =
        <Image
        style={{width:9, height:18, resizeMode:'contain', tintColor:this.state.style.player_color, marginLeft:15}}
        source={require('../scorearrow.png')}
        />
      }
    }

    var seed = null
    if(seeds){
      seed = seeds[item.id]
    }


    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%'}]}>

        <View style={{flexShrink:1}}>
          <Text style={[textStyle,{opacity:winner ? 1 : 0.5, marginLeft:!flag ? 10 : 45, textTransform:'uppercase', flexShrink:0.25}]} numberOfLines={1}>
          {item.name}
          </Text>
        </View>

        <Text style={[textStyle, {opacity:0.33, marginLeft:16, fontFamily:'ClanOT-Bold'}]}>
        {seed}
        </Text>

        {renderServing}

      </View>
    )
  }

  renderVisualScores = (data) => {
    //Setup number of sets required
    var best_of = data.best_of || 3
    var scores = data.period_scores ? new Array(data.period_scores.length) : []
    scores.fill('')

    var height = 100

    return(
      <View style={[{height:'100%', backgroundColor:this.state.style_new.background.score}, MainStyles.flexRow, MainStyles.flexEndCenter]}>
        {
          this.state.style.reverseOrder ?
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          {this.renderVisualGameScore(data, height)}
          </View>
          :
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {this.renderVisualGameScore(data, height)}
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          </View>
        }

        {this.renderVisualArrow(data, height)}
      </View>
    )
  }

  renderVisualArrow = (data, height) => {
    // return(
    //   <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:10, height:height}]}>
    //     {this.renderArrow(data.competitors[0].id === data.winner_id, height/1.2)}
    //     {this.renderArrow(data.competitors[1].id === data.winner_id, height/1.2)}
    //   </View>
    // )

    return null
  }

  renderArrow = (active, height) => {
    if(active){
      return(
        <View style={[{height}, MainStyles.flexCenter]}>
          <Image
          style={{width:9, height:18, resizeMode:'contain', tintColor:this.state.style.set_color}}
          source={require('../scorearrow.png')}
          />
        </View>
      )
    }else{
      return(
        <View style={{width:6, height}}/>
      )
    }
  }

  renderVisualGameScore = (data, height) => {
    var { mode } = this.state
    if(!data.winner_id && mode !== 'scheduled' && data.game_state){

      var scores = [0,0]
      if(data.game_state){
        scores = [data.game_state.home_score || 0, data.game_state.away_score || 0]
      }

      return(
        <View key={'gamescore'} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:20, marginRight:20*1.5, height, width:30}]}>
          {this.renderVisualSetScore(scores[0], null, null, null, true, data.tiebreak_scores, this.state.style_new.color.score)}
          <View style={{height:separatePlayers, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}}/>
          {this.renderVisualSetScore(scores[1], null, null, null, true, data.tiebreak_scores, this.state.style_new.color.score)}
        </View>
      )
    }else{return null}
  }

  renderVisualSetScores = (scores, index, height, activeSet) => {
    var tiebreak_scores = {}
    var setScore = scores ? (scores[index] ? [scores[index].home_score, scores[index].away_score] : ['','']) : ['', '']
    var showScores = this.state.mode !== 'scheduled'



    return(
      <View key={index} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:0, height, width:60, backgroundColor:activeSet && this.state.style.background_activeSet ? this.state.style.background_activeSet : 'transparent' }]}>
        {showScores ? this.renderVisualSetScore(setScore[0], setScore[1], index+1, 'home', setScore[0] >= setScore[1], tiebreak_scores, this.state.style_new.color.score, activeSet) : null}
        <View style={[{height:separatePlayers, width:'100%'}, MainStyles.flexCenter]}>
          <View style={{height:1, width:'100%', backgroundColor:lineColor}}/>
        </View>
        {showScores ? this.renderVisualSetScore(setScore[1], setScore[0], index+1, 'away', setScore[1] >= setScore[0], tiebreak_scores, this.state.style_new.color.score, activeSet) : null}
      </View>
    )
  }

  renderVisualSetScore = (score, otherScore, set, player, active, tiebreak_scores, color, activeScore) => {
    var tb = null
    // if(score === 0){score = '0'}
    if(score === 50){score = 'AD'}
    // if(score === 6 && otherScore === 7){
    //   if(tiebreak_scores?[set]){
    //     tb =
    //     <Text style={{position:'absolute', top:-6, right:-6, fontSize:12, fontFamily:'ClanOT-Bold', color:'white', opacity:active ? 1 : 0.5, letterSpacing:-0}}>
    //     {tiebreak_scores[set][player]}
    //     </Text>
    //   }
    // }

    // var larger = score > otherScore
    // if(larger && this.state.style.game_larger_color && set && (!activeScore || this.state.match.winner_id)){
    //   color = this.state.style.game_larger_color
    // }

    var opacity = 1
    if(score < otherScore){
      opacity = 0.67
    }

    return(
      <View style={[MainStyles.flexCenter]}>
        <Text style={{fontSize, color, opacity, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:-0}}>
        {score}
        </Text>
      </View>
    )
  }

  //Buttons
  renderButtons = () => {
    var { stats_options } = this.state

    var options = [
      'Default', 'Light Blue', 'Black'
      // 'Miami', 'Indian Wells', 'Dubai','Acapulco' , 'AO', 'ATP Cup','Paris Masters', 'WTA Finals', 'ATP Finals','Laver Cup', 'US Open', 'Olympics', 'Wimbledon', 'Wimbledon W/O Background', 'UTS', 'Roland Garros', 'ATP1000', 'GTL', 'Default'
    ]
    var view_options = stats_options.concat(['H2H'])

    return(
      <View style={[{width:'100%', marginTop:40}, MainStyles.flexCenter]}>

        <Pressable onPress={this.reset} style={{padding:20, minWidth:140, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10, marginBottom:20}}>
          <Text style={{color:'white', fontFamily:'ClanOT-Bold', fontSize:16, textAlign:'center', opacity:1}}>
          Back to Match List
          </Text>
        </Pressable>

        <Text style={{color:'white', fontFamily:'ClanOT-News', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
        Pick a theme
        </Text>
        <View style={[{width:'100%', padding:20, flexWrap:'wrap', marginTop:0}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {options.map((item, index) => this.renderOption({title:item, onPress:()=>this.changeTheme(item)}, index))}
        </View>

        <Text style={{color:'white', fontFamily:'ClanOT-News', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
        Change match stats view
        </Text>
        <View style={[{width:'100%', padding:20, flexWrap:'wrap', marginTop:0}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {view_options.map((item, index) => this.renderOption({title:item, onPress:()=>this.changeStatsView(item)}, index))}
        </View>

      </View>
    )
  }

  renderOption = (item, index) => {
    return(
      <Pressable onPress={item.onPress} style={{padding:20, marginBottom:10, minWidth:140, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10}}>
        <Text style={{color:'white', fontFamily:'ClanOT-Bold', fontSize:16, textAlign:'center', opacity:1}}>
        {item.title}
        </Text>
      </Pressable>
    )
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:'white', fontFamily:'ClanOT-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:'white', fontFamily:'ClanOT-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:'white', fontFamily:'ClanOT-News', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to open its scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item.id)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'ClanOT-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:'white', fontSize:16, fontFamily:'ClanOT-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : 'white', fontSize:16, fontFamily:'ClanOT-News'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }

  renderAroundCourts = () => {
    return(
      <View style={{width:'100%', height:120, borderRadius:this.state.style_new.borderRadius, overflow:'hidden'}}>
        <AroundTheCourts style={this.state.style_new}/>
      </View>
    )
  }


  render = () => {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexStartCenter]}>
        {mode === 'selectMatch' ? this.renderSelectMatch() : this.renderScoreboard() }
        {mode === 'selectMatch' ? null : this.renderButtons()}
      </View>
    )
  }
}

export default Item;
