import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 1100
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

var docId = "C5aaURtU7WANlm1jDEzW"

var animation_duration = 350

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      stats_options:[],
      matchTime:'0:00',
      matchInfo:'',

      activeIndex_stats:-1,
      activeIndex_tippers:-1,

      activeIndex_h2h:-1,
      h2h:[],

      tippers:[],
      stats:[],
      stats_obj:{},
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',

      stats_view:"Match",

      stats_opacity: new Animated.Value(1),
      h2h_opacity: new Animated.Value(1),

      images:[null, null],
      names:[null, null],
    };
  }

  componentDidMount = async () => {
    await this.setup()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    clearTimeout(this.updateString_tippers)
    clearTimeout(this.updateString_h2h)
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
    })
    this.setup()
  }

  setup = async () => {
    //Get available matches - live + scheduled
    var live = []
    var live_raw = []
    var scheduled_raw = []
    var scheduled = []
    var completed = []

    var categories = ['ATP', 'WTA', 'Davis Cup']

    await firebase.firestore()
    .collection('summaries_sr').doc('live')
    .get()
    .then(async (doc) => {
      live = Object.values(doc.data().matches)
    })

    await firebase.firestore()
    .collection('summaries_sr').doc('scheduled')
    .get()
    .then(async (doc) => {
      scheduled_raw = Object.values(doc.data().matches)
    })

    await Promise.all(live_raw.map(async match => {
      match.isLive = true
      // if(categories.includes(match.category)){
        live.push(match)
      // }
    }))

    //Promise through upcoming and only show ones starting in the next hour
    await Promise.all(scheduled_raw.map(async match => {
      var buffer = Date.now() + (24 * 60 * 60 * 1000)
      if(Number(match.start_time_timestamp) <= buffer){
        scheduled.push(match)
      }
    }))

    await live.sort((a, b) => (a.startedAt < b.startedAt) ? 1 : -1);
    await scheduled.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);
    // await matches_uts.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);
    // await completed.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);

    var matches = live.concat(scheduled)

    // await matches.sort((a, b) => (a.startedAt < b.startedAt) ? 1 : -1);

    await Promise.all(matches.map(async match => {
      //Fix the names
      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }
    }))

    this.setState({matches, loading:false})
  }

  //GS
  selectMatch = async (match) => {
    await firebase.firestore()
    .collection('streaming').doc(docId)
    .set({matchId:match.id},{merge:true})

    var names = match.competitors[0].name + " vs " + match.competitors[1].name

    alert("Match updated to "+names+".  Other widgets will now show data for that match.")
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:'white', fontFamily:'Averta-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to activate it on the scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnhL8u3JpBzlsRcFYspmmO1OcFmlN-wun_veNctIgw=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'Averta-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:'white', fontSize:16, fontFamily:'Averta-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : 'white', fontSize:16, fontFamily:'Averta-Regular'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }


  render() {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexStartCenter]}>
        {mode === 'selectMatch' ? this.renderSelectMatch() : null }
      </View>
    )
  }
}

export default Item;
