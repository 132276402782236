import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 1100
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      match_adjusted:null,
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      matchTime:'0:00',
      matchInfo:'',

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',

      images:[null, null],
      names:[null, null],
    };
  }

  componentDidMount = async () => {
    this.setup()

    // this.selectMatch("sr:sport_event:27485230")
    // this.selectMatch("sr:sport_event:27485224")
    // this.changeTheme('Roland Garros')
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    if(this.updateGoalServe){
      this.updateGoalServe()
    }
    if(this.listenGoalServe){
      this.listenGoalServe()
    }
    clearTimeout(this.updateString_tippers)
    clearTimeout(this.updateString_stats)
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
    })
    this.setup()
  }

  setup = async () => {
    //Get available matches - live + scheduled
    var live = []
    var scheduled_raw = []
    var scheduled = []
    var completed = []

    await firebase.firestore()
    .collection('summaries_sr').doc('live')
    .get()
    .then(async (doc) => {
      live = Object.values(doc.data().matches)
    })

    await firebase.firestore()
    .collection('summaries_sr').doc('scheduled')
    .get()
    .then(async (doc) => {
      scheduled_raw = Object.values(doc.data().matches)
    })

    await Promise.all(live.map(async match => {
      match.isLive = true
    }))

    //Promise through upcoming and only show ones starting in the next hour
    await Promise.all(scheduled_raw.map(async match => {
      var buffer = Date.now() + (24 * 60 * 60 * 1000)
      if(Number(match.start_time_timestamp) <= buffer){
        scheduled.push(match)
      }
    }))

    await live.sort((a, b) => (a.startedAt < b.startedAt) ? 1 : -1);
    await scheduled.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);
    // await completed.sort((a, b) => (Number(a.start_time_timestamp) > Number(b.start_time_timestamp)) ? 1 : -1);

    var matches = live.concat(scheduled)

    // await matches.sort((a, b) => (a.startedAt < b.startedAt) ? 1 : -1);
    var matches_filtered = []
    var filter = ["ATP", "WTA", "ATP Challenger", "Challenger", "Davis Cup", "Exhibition"]
    await Promise.all(matches.map(async match => {
      //Fix the names
      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }

      if(filter.includes(match.category)){
        matches_filtered.push(match)
      }
    }))

    this.setState({matches:matches_filtered, loading:false})
  }

  //GS
  callGoalServe = async (url, runXML) => {
    const axios = require("axios");
    var parseString = require('xml2js').parseString;
    var CircularJSON = require('circular-json')

    var responseData = {}
    var rawData = {}

    if(runXML){
      // XML method
      await axios
    	.get(url, {
    		"Content-Type": "application/xml; charset=utf-8"
    	 })
    	.then(async (response) => {
    		rawData = response
        return
    	})
    	.catch(async (error) => {
        console.log('GS error', error)
    	});

      //Parse XML into JSON
      parseString(rawData.data, async (err, result) => {
        if(err){
          responseData = null
        }else{
          responseData = result
        }
        return
      });
    }else{

      //JSON method
      var jsonUrl = url.includes('?') ? url+'&?json=1' : url+'?json=1'


      await axios
    	.get(jsonUrl)
    	.then(async (response) => {
        //Clean up and remove @ symbols
        var stringifyRaw = CircularJSON.stringify(response)
        var stringify = stringifyRaw.replace(/@/g,"")

        var cleanResponse = CircularJSON.parse(stringify);


    		responseData = cleanResponse
        return
    	})
    	.catch(async (error) => {
        console.log('GS error', error)
    	});

    }

    return responseData
  }

  getGoalServe = async () => {
    this.listenGoalServe = await firebase.firestore()
    .collection('summaries_gs').doc('today')
    .onSnapshot(this.handleGoalServe)
  }

  handleGoalServe = async (doc) => {
    try{
      var data = doc.data()

      var matchData = null

      var match_sr = this.state.match || {}
      var match_adjusted = Object.assign(match_sr, {})

      var matches = Object.values(data)

      if(match_sr){

        var p1_sr = ((match_sr.competitors[0].name.split(". "))[1]).toLowerCase()
        var p2_sr = ((match_sr.competitors[1].name.split(". "))[1]).toLowerCase()

        await Promise.all(matches.map(async match => {
          var p1_gs = (match.player[0].name).toLowerCase()
          var p2_gs = (match.player[1].name).toLowerCase()

          if(p1_gs.includes(". ")){
            //Singles names
            p1_gs = p1_gs.split(". ")[1]
            p2_gs = p2_gs.split(". ")[1]
          }

          if(p1_gs === p1_sr || p2_gs === p2_sr){
            matchData = match
          }
        }))
      }

      //
      if(matchData){
        //Match found, create the game_state object
        var s1 = {
          home_score:Number(matchData.player[0].s1.split(".")[0]),
          away_score:Number(matchData.player[1].s1.split(".")[0]),
          home_score_tb:Number(matchData.player[0].s1.split(".")[1]),
          away_score_tb:Number(matchData.player[1].s1.split(".")[1]),
          type:'set',
          number:1,
        }

        var s2 = {
          home_score:Number(matchData.player[0].s2.split(".")[0]),
          away_score:Number(matchData.player[1].s2.split(".")[0]),
          home_score_tb:Number(matchData.player[0].s2.split(".")[1]),
          away_score_tb:Number(matchData.player[1].s2.split(".")[1]),
          type:'set',
          number:2,
        }

        var s3 = {
          home_score:Number(matchData.player[0].s3.split(".")[0]),
          away_score:Number(matchData.player[1].s3.split(".")[0]),
          home_score_tb:Number(matchData.player[0].s3.split(".")[1]),
          away_score_tb:Number(matchData.player[1].s3.split(".")[1]),
          type:'set',
          number:3,
        }

        var s4 = {
          home_score:Number(matchData.player[0].s4.split(".")[0]),
          away_score:Number(matchData.player[1].s4.split(".")[0]),
          home_score_tb:Number(matchData.player[0].s4.split(".")[1]),
          away_score_tb:Number(matchData.player[1].s4.split(".")[1]),
          type:'set',
          number:4,
        }

        var s5 = {
          home_score:Number(matchData.player[0].s5.split(".")[0]),
          away_score:Number(matchData.player[1].s5.split(".")[0]),
          home_score_tb:Number(matchData.player[0].s5.split(".")[1]),
          away_score_tb:Number(matchData.player[1].s5.split(".")[1]),
          type:'set',
          number:5,
        }

        var period_scores = [s1]
        if(matchData.player[0].s2){
          period_scores = [s1].concat(s2)
        }
        if(matchData.player[0].s3){
          period_scores = [s1].concat(s2).concat(s3)
        }
        if(matchData.player[0].s4){
          period_scores = [s1].concat(s2).concat(s3).concat(s4)
        }
        if(matchData.player[0].s5){
          period_scores = [s1].concat(s2).concat(s3).concat(s4).concat(s5)
        }

        //Set the game state
        var game_state = {
          serving:matchData.player[0].serve === "True" ? 'home' : (matchData.player[1].serve === "True" ? 'away' : null),
          home_score:matchData.player[0].game_score,
          away_score:matchData.player[1].game_score,
        }

        // console.log('Serving:',game_state.serving, 'Home:', matchData.player[0].serve, 'Away:', matchData.player[1].serve, '-', Date.now())

        match_adjusted.period_scores = period_scores
        match_adjusted.game_state = game_state

        this.setState({match_adjusted})
      }

    }catch(e){
      console.log('Error', e)
    }
  }


  //

  selectMatch = async (id) => {
    // alert('match selected '+id)
    await this.changeTheme()
    this.setState({id})

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .onSnapshot(this.handleMatch)
  }

  handleMatch = async ( querySnapshot ) => {
    var match = {}
    var docId = null

    if(querySnapshot.size > 0){
      await querySnapshot.forEach(async (doc) => {
        match = doc.data()
        docId = doc.id

        //Correct the player names
        if(match.competitors[0].name.includes(', ')){
          match.competitors[0].name_full = (match.competitors[0].name.split(", "))[1] + " " + (match.competitors[0].name.split(", "))[0]
          match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
        }

        if(match.competitors[1].name.includes(', ')){
          match.competitors[1].name_full = (match.competitors[1].name.split(", "))[1] + " " + (match.competitors[1].name.split(", "))[0]
          match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
        }
      })
    }

    if(this.state.firstSet && docId){
      this.setupListeners(docId, match)
      this.setupPlayers(match)
    }

    //Calculate the matchTime
    var durationString = null
    if(match.startedAt){
      var startedAt = match.startedAt
      var finishedAt = !match.finishedAt ? Date.now() : match.finishedAt

      var duration = (moment.duration(finishedAt - startedAt).as('hours')).toString()

      //Clean string
      var durationHours = Number(duration.split('.')[0])
      if(durationHours < 6){
        var durationMinutesNumber = Math.round(Number("."+duration.split('.')[1]) * 60)
        var durationMinutesRaw = durationMinutesNumber == 60 ? 59 : durationMinutesNumber
        var durationMinutes = durationMinutesRaw.toString().length == 1 ? "0"+durationMinutesRaw : durationMinutesRaw
        durationString = durationHours + ':' + durationMinutes
      }
    }

    //Calculate the duration string
    var matchTime = durationString ? "Match Time: "+durationString : ''


    //Match Info string
    var matchInfo = match.season_name.split(", ")[0] + " - " + match.round

    this.setState({match, matchTime, matchInfo, loading:false, mode:'match', firstSet:false})
  }

  setupPlayers = async (match) => {
    if(match.competitors[0].name.includes(". ") && match.category === 'ATP'){
      var lastname_1 = (match.competitors[0].name.split(". ")[1]).toLowerCase()
      var lastname_2 = (match.competitors[1].name.split(". ")[1]).toLowerCase()

      var image_1 = "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_1+"_full_ao20.png"
      var image_2 = "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_2+"_full_ao20.png"

      //Get player profiles
      var image_official_1 = await this.getPlayer(match.competitors[0].id)
      var image_official_2 = await this.getPlayer(match.competitors[1].id)

      var images = [image_official_1 || image_1, image_official_2 || image_2]
      var names = [match.competitors[0].name_full, match.competitors[1].name_full]

      this.setState({images, names})
    }
  }

  getPlayer = async (id) => {
    var image = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.data()){
            if(doc.data().image_official){
              image = doc.data().image_official
            }
          }
        })
      }
    })

    return image
  }

  setupListeners = async (docId, match) => {
    var status = match.match_status

    //Goalserve
    this.getGoalServe()

    //Get stats & h2h
    this.listenMatchStats = await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('info').doc('stats')
    .onSnapshot(this.handleStats)

    this.listenTippers = await firebase.firestore()
    .collection('streaming').doc("yBhmZeRfAZ7rzm8jemwX")
    .onSnapshot(this.handleTippers)

    if(status === 'not_started'){
      await firebase.firestore()
      .collection('matches_sr').doc(docId)
      .collection('info').doc('data')
      .get()
      .then(async(doc)=>{
        if(doc.exists && doc.data()){
          var stats = []
          var stats_title = 'H2H Record'
          var h2h = doc.data().h2h_v2

          if(h2h){
            //Generate the H2H record string
            var record = h2h.wins
            if(record[0] > record[1]){
              stats.push(match.competitors[0].name + " leads the H2H " + record[0] + "-" + record[1])
            }else if(record[1] > record[0]){
              stats.push(match.competitors[1].name + " leads the H2H " + record[1] + "-" + record[0])
            }else{
              //Tied
              stats.push("H2H is tied at " + record[0] + "-" + record[1])
            }

            var matches = h2h.matches || []
            await Promise.all(matches.map(async (h2h_match, index) => {
              stats.push(h2h_match.winner + " won in the "+ h2h_match.round + " at the " + h2h_match.tournament + " in " + moment(h2h_match.timestamp, 'x').format('YYYY') + "  -  " + h2h_match.score)
            }))

            await this.setState({stats, stats_title})
            this.changeString_stats()
          }

        }
      })
    }
  }

  handleTippers = async (doc) => {
    //Set state and setup string changing timers
    // var tippers = [
    //   "Here's the first stat",
    //   "Here's the next stat",
    //   "And what about htis stats",
    // ]

    var tippers = []

    if(doc.data() && doc.exists){
      tippers = doc.data().topTippers || []
      var tippers_raw = doc.data().topTippers || []

      tippers = [tippers.join("   ·   ")]

      await this.setState({tippers, tippers_raw})

      this.changeString_tippers()
    }
  }

  handleStats = async (doc) => {
    var { match } = this.state
    if(doc.data() && doc.exists){
      var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM"]
      var stats_list = []
      var stats_serving = []
      var stats_receiving = []
      var statsRaw = doc.data().stats || []

      //Setup names
      var p1 = match.competitors[0].name || ''
      var p2 = match.competitors[1].name || ''

      var blocked_stats = ["Return Games Won"]

      //Promise through stats and add values
      if(statsRaw){
        await Promise.all(statsRaw.map(async stat_period => {
          if(stat_period.title === 'Match'){
            var period_stats = stat_period.stats.stats || []
            await Promise.all(period_stats.map(async stat_group => {
              await Promise.all(stat_group.data.map(async stat => {
                if(stat.values){
                  if((stat.values[0] || stat.values[0] === 0) && (stat.values[1] || stat.values[1] === 0) && !blocked_stats.includes(stat.title)){
                    var p1_stat = p1 + " - " + stat.values[0]
                    var p2_stat = p2 + " - " + stat.values[1]

                    stats.push(
                      stat.title + ":     " + p1_stat + "     " + p2_stat
                    )

                    var stat_obj = {title:stat.title, values:stat.values}

                    stats_list.push(stat_obj)

                    if(stat_group.title === 'Service' && stat.title !== "Service Games Won"){
                      stats_serving.push(stat_obj)
                    }
                    if(stat_group.title === 'Return'){
                      stats_receiving.push(stat_obj)
                    }
                  }
                }
              }))
            }))
          }
        }))
      }

      var stats_title = 'Match Stats'
      await this.setState({stats, stats_title, stats_list, stats_serving, stats_receiving})
      this.changeString_stats()

    }else{
      var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM", "Stats available soon"]
      await this.setState({stats})
      this.changeString_stats()
    }
  }

  changeTheme = async (theme) => {
    var style = {
      background_main:"#101012",
      background_match:'#18181B',
      background_tnns:'#18181B',
      background_scores:"#18181B",
      background_ticker:'#18181B',
      background_tippers:"#18181B",
      font_tippers:"white",
      font_ticker:'white',
      font_color:'white',
      game_color:'white',
      set_color:'white',
      player_color:'white',
      player_font:'Averta-Bold',
      ball_color:GLOBAL.colors.volt,
      event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
      borderRadius:10,
      letterSpacing:0.5,
      alternatingBackground:'rgba(0,0,0,0.24)',
    }

    if(theme === 'ATP1000'){
      style = {
        background_main:"#101012",
        background_match:'#18181B',
        background_tnns:'#18181B',
        background_scores:"#18181B",
        background_ticker:'#18181B',
        background_activeSet:"gold",
        background_tippers:"gold",
        font_tippers:"black",
        font_activeSet:'black',
        font_ticker:'white',
        font_color:'white',
        game_color:'white',
        set_color:'white',
        player_color:'white',
        player_font:'Averta-Bold',
        ball_color:"gold",
        event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
        borderRadius:0,
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.24)',
        reverseOrder:true,
      }
    }

    if(theme === 'ATP250'){
      style = {
        background_main:"#101012",
        background_match:'#18181B',
        background_tnns:'#18181B',
        background_scores:"#18181B",
        background_ticker:'#18181B',
        background_activeSet:"#00ABF9",
        background_tippers:"#00ABF9",
        font_tippers:"white",
        font_activeSet:'white',
        font_ticker:'white',
        font_color:'white',
        game_color:'white',
        set_color:'white',
        player_color:'white',
        player_font:'Averta-Bold',
        ball_color:GLOBAL.colors.volt,
        event_logo:"https://pbs.twimg.com/profile_images/1273138636339458048/8gOeQFiT_400x400.jpg",
        borderRadius:0,
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.2)',
        reverseOrder:true,
      }
    }

    if(theme === 'Wimbledon'){
      style = {
        background_main:"white",
        background_match:'#EDEEF5',
        background_tnns:'#48167A',
        background_scores:"#EDEEF5",
        background_ticker:'#EDEEF5',
        background_tippers:"#48167A",
        font_tippers:"white",
        font_ticker:'#48167A',
        font_color:'#48167A',
        game_color:'#48167A',
        set_color:'#48167A',
        player_color:'#48167A',
        player_font:'Averta-Bold',
        ball_color:'#FBF508',
        // event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/b/b9/Wimbledon.svg/1200px-Wimbledon.svg.png",
        borderRadius:0,
        leftTab:'#0B6F3D',
        hideFlags:true,
        letterSpacing:0.5,
        reverseOrder:true,
        alternatingBackground:'rgba(0,0,0,0.12)',
      }
    }

    if(theme === 'GTL'){
      style = {
        background_main:"white",
        background_match:'#EDEEF5',
        background_tnns:'#514C8A',
        background_scores:"#EDEEF5",
        background_ticker:'#EDEEF5',
        background_tippers:"#514C8A",
        font_tippers:"white",
        font_ticker:'#25642F',
        font_color:'#25642F',
        game_color:'#25642F',
        set_color:'#25642F',
        player_color:'#25642F',
        player_font:'Averta-Bold',
        ball_color:'#514C8A',
        event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
        borderRadius:0,
        leftTab:'#25642F',
        letterSpacing:0.5,
        alternatingBackground:'rgba(0,0,0,0.1)',
      }
    }

    if(theme === 'Roland Garros'){
      // style = {
      //   background_main:"white",
      //   background_match:'#063F35',
      //   background_tnns:'#063F35',
      //   background_scores:"#ECECEC",
      //   background_ticker:'#EDEEF5',
      //   background_tippers:"#063F35",
      //   font_tippers:"white",
      //   font_ticker:'#7C3B10',
      //   font_color:'white',
      //   game_color:'#7C3B10',
      //   set_color:'black',
      //   player_color:'white',
      //   player_font:'Averta-Bold',
      //   // ball_color:"#E7946B",
      //   ball_color:'#F4E820',
      //   //D45622
      //   event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Logo_Roland-Garros.svg/1200px-Logo_Roland-Garros.svg.png",
      //   borderRadius:2.5,
      //   letterSpacing:0.5,
      //   hideFlags:true,
      //   reverseOrder:true,
      //   alternatingBackground:'rgba(0,0,0,0.12)',
      // }
      style = {
        background_main:"white",
        background_match:'#063F35',
        background_tnns:'#063F35',
        background_scores:"#ECECEC",
        background_ticker:'#EDEEF5',
        background_tippers:"#063F35",
        font_tippers:"white",
        font_ticker:'#000',
        font_color:'white',
        game_color:'#000',
        set_color:'black',
        player_color:'white',
        player_font:'Averta-Bold',
        // ball_color:"#E7946B",
        ball_color:'#D45119',
        //D45622
        event_logo:"https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Logo_Roland-Garros.svg/1200px-Logo_Roland-Garros.svg.png",
        borderRadius:2.5,
        letterSpacing:0.5,
        hideFlags:true,
        reverseOrder:true,
        alternatingBackground:'rgba(0,0,0,0.12)',
      }
    }

    return this.setState({style, theme})
  }

  //String changers
  changeString_stats = async () => {
    clearTimeout(this.updateString_stats)

    var { activeIndex_stats, stats } = this.state
    var nextIndex = activeIndex_stats+1
    if((activeIndex_stats+1) >= (stats.length)){
      nextIndex = 0
    }

    var string_stats = stats[nextIndex]

    await this.setState({string_stats, activeIndex_stats:nextIndex})

    this.updateString_stats = setTimeout(async () => {
      this.changeString_stats()
    }, stringChangeTime)
  }

  changeString_tippers = async () => {
    clearTimeout(this.updateString_tippers)
    var { activeIndex_tippers, tippers } = this.state

    var nextIndex = activeIndex_tippers+1
    if((activeIndex_tippers+1) >= (tippers.length)){
      nextIndex = 0
    }

    var string_tippers = tippers[nextIndex]
    await this.setState({string_tippers, activeIndex_tippers:nextIndex})

    this.updateString_tippers = setTimeout(async () => {
      this.changeString_tippers()
    }, stringChangeTime / 2)
  }

  changeImage = async () => {
    var newImage = prompt('Change the image by entering the new URL')

    if(newImage){
      var style = this.state.style
      style.event_logo = newImage
      this.setState({style})
    }
  }


  //Renders
  renderScoreboard = () => {
    var { string_stats, string_tippers, stats_list, stats_serving, stats_receiving, matchTime, matchInfo } = this.state

    var height = width / 1142*800

    return(
      <View style={[{width, height, backgroundColor:this.state.style.background_main, padding:spacing*2}, MainStyles.flexStartCenter]}>
        {this.renderTicker(matchInfo, 'Info', matchTime)}
        <View style={[{width:'100%', paddingVertical:spacing*2}, MainStyles.flexRow, MainStyles.flexCenter]}>
          {
            this.renderFeatureCard()
          }
          {this.renderScore()}
          {this.renderImage({uri:this.state.style.event_logo}, true, this.changeImage)}
          {
            // this.renderImage({uri:'https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj'})
          }
        </View>
        {
          stats_serving.length > 0 ?
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>

            {this.renderTippers('35%')}

            <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {width:'65%', paddingLeft:spacing*2}]}>
              {this.renderCard('100%', false, false, 'Serving Stats', this.renderStats(stats_serving, 'Stats'))}
              <View style={{height:spacing*2}}/>
              {
                // this.renderCard('100%', false, false, 'Receiving Stats', this.renderStats(stats_receiving, 'Receiving'))
              }
            </View>

          </View>
          :
          <View style={{width:'100%'}}>
          {this.renderTicker(string_stats, 'H2H')}
          </View>
        }
        {
          stats_serving.length > 0 ? null :
          <View style={[{width:'100%', marginTop:spacing*2}, MainStyles.flexRow, MainStyles.flexStartStart]}>
          {this.renderTippers('40%')}
          {this.renderPreviewImage('60%')}
          </View>
        }
      </View>
    )
  }

  renderCard = (ratio, marginRight, marginLeft, title, content) => {
    return(
      <View style={{width:ratio, height:'100%', paddingRight:marginRight ? spacing : 0, paddingLeft:marginLeft ? spacing : 0}}>
        <View style={[{width:'100%', height:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_ticker, overflow:'hidden'}, MainStyles.flexStartCenter]}>
          {content}
        </View>
      </View>
    )
  }

  renderPreviewImage = (width) => {
    var { id, match, images, names } = this.state

    var image_1 = images[0] || null
    var image_2 = images[1] || null

    var name_1 = names[0] || null
    var name_2 = names[1] || null

    var height = 350

    var img_height = height - 40
    var img_width = img_height / 737 * 463

    var name_style = {fontFamily:'Averta-Black', color:'white', fontSize:36, color:this.state.style.font_tippers, textTransform:'uppercase', textAlign:'center', width:'85%', flexWrap:'wrap'}
    var secondary_style = {fontFamily:'Averta-Semibold', fontSize:20, color:this.state.style.font_tippers, textTransform:'uppercase', opacity:0.5, textAlign:'center'}

    if(image_1 && image_1){
      return(
        <View style={{width, marginTop:spacing*0, flexGrow:1, marginLeft:spacing*2, paddingRight:spacing*2}}>
          <View style={[{width:'100%', height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tippers, flexGrow:1, overflow:'hidden', padding:20}, MainStyles.flexCenter]}>

            <Image
            style={{width:'100%', height:'100%', resizeMode:'cover', opacity:0.25, position:'absolute'}}
            source={{uri:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"}}
            />

            <View style={[{}, MainStyles.flexCenter]}>
              <Text style={[secondary_style, {marginBottom:20}]}>
              {match.season_name.split(", ")[0] + " - " + match.round}
              </Text>

              <Text style={[name_style]}>
              {name_1}
              </Text>

              <Text style={[secondary_style]}>
              vs
              </Text>

              <Text style={[name_style]}>
              {name_2}
              </Text>
            </View>

            <Image
            style={{position:'absolute', left:-35, bottom:0, height:img_height, width:img_width, resizeMode:'contain'}}
            source={{uri:image_1}}
            />

            <Image
            style={{position:'absolute', right:-35, bottom:0, height:img_height, width:img_width, resizeMode:'contain'}}
            source={{uri:image_2}}
            />

          </View>
        </View>
      )
    }
  }

  renderTippers = (width) => {
    var { tippers_raw } = this.state
    return(
      <View style={{width, marginTop:spacing*0, flexGrow:1}}>
        <View style={[{width:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tippers, flexGrow:1}, MainStyles.flexStartCenter]}>
          {this.renderStatTitle('Top Tippers', true, this.state.style.font_tippers)}
          {tippers_raw.map((item, index) => this.renderTipper(item, index))}
        </View>
      </View>
    )
  }

  renderTipper = (item, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style.alternatingBackground : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_tippers, textAlign:'left'}}>
          {item}
        </Text>
      </View>
    )
  }

  renderStats = (data, title) => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderStatTitle(title)}
        {data.map((item, index) => this.renderStat(item, index))}
      </View>
    )
  }

  renderStat = (data, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style.alternatingBackground : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, textAlign:'left'}}>
          {data.title}
        </Text>

        <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
          {data.values[0]}
          </Text>
          <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
          {data.values[1]}
          </Text>
        </View>
      </View>
    )
  }

  renderStatTitle = (title, hideNames, color) => {
    var { match } = this.state
    return(
      <View style={[{width:'100%', padding:spacing*2, paddingHorizontal:spacing}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>
        <Text style={{fontSize:24, color:color || this.state.style.font_ticker, fontFamily:'Averta-Black', width:'100%', textAlign:'left'}}>
        {title}
        </Text>

        {
          hideNames ? null :
          <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'Averta-Bold', fontSize:18, color:color || this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
            {match.competitors[0].abbreviation}
            </Text>
            <Text style={{fontFamily:'Averta-Bold', fontSize:18, color:color || this.state.style.font_ticker, width:'50%', textAlign:'left'}}>
            {match.competitors[1].abbreviation}
            </Text>
          </View>
        }
      </View>
    )
  }

  //

  renderTicker = (string, title, right) => {
    return(
        <View style={[{width:'100%', backgroundColor:this.state.style.background_main, height:32, paddingHorizontal:0}, MainStyles.flexCenter]}>

          <View style={[{width:'100%', backgroundColor:this.state.style.background_ticker, height:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <View style={[{height:'100%', paddingHorizontal:spacing*2, backgroundColor:'rgba(0,0,20,0.15)'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left'}}>
              {title}
              </Text>
            </View>

            <View style={[{flexGrow:1}, MainStyles.flexCenterStart]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left', paddingLeft:spacing*2}}>
              {string}
              </Text>
            </View>

            <View style={[{height:'100%', paddingHorizontal:spacing*2, backgroundColor:'rgba(0,0,20,0.15)'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:15, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left'}}>
              {right}
              </Text>
            </View>
          </View>

        </View>
    )
  }

  renderFeatureCard = () => {
    return(
      <View style={[{width:height*1.5, height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tnns}, MainStyles.flexCenter]}>
        <Image
        style={{resizeMode:'contain', width:'100%', height:'100%'}}
        source={require('../../images/poweredbytnns.png')}
        />
      </View>
    )
  }

  renderImage = (source, disableMargin, onPress) => {
    return(
      <Pressable onPress={onPress || null}>
        <Image
        style={{height, width:height, borderRadius:this.state.style.borderRadius, marginLeft:disableMargin ? 0 : spacing, resizeMode:'cover'}}
        source={source}
        />
      </Pressable>
    )
  }


  //Score renders
  renderScore = () => {
    var item = this.state.match_adjusted

    if(item){
      return(
        <View style={[{height, flex:1, borderRadius:this.state.style.borderRadius, marginHorizontal:spacing*2, backgroundColor:this.state.style.background_match, overflow:'hidden'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          {
            !this.state.style.leftTab ? null :
            <View style={{height:'100%', width:10, marginRight:5, backgroundColor:this.state.style.leftTab}}/>
          }
          <View style={[{flex:1, height:'100%', paddingHorizontal:spacing}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
            <View style={[{flexShrink:1, marginRight:spacing*2, height:'100%'}, MainStyles.flexColumn, MainStyles.flexCenterStart]}>
              {this.renderPlayer(item.competitors[0], 0, item.winner_id, item.seeds)}
              <View style={{height:10}}/>
              {this.renderPlayer(item.competitors[1], 1, item.winner_id, item.seeds)}
            </View>
            {this.renderVisualScores(item)}
          </View>
        </View>
      )
    }else{
      return null
    }

  }

  renderPlayer = (item, index, winner_id, seeds) => {

    var winner = winner_id ? winner_id === item.id : true
    var textStyle = {fontSize, color:this.state.style.player_color, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:this.state.style.letterSpacing}

    var flag = item.flag

    var isDoubles = item.name.includes("/")

    if(isDoubles || this.state.style.hideFlags){
      flag = null
    }

    var game = this.state.match_adjusted.game_state || {}
    var player_gs_id = index === 0 ? 'home' : 'away'
    var isServing = game.serving === player_gs_id && !winner_id

    var renderServing = null

    if(isServing){
      renderServing = <View style={{height:16, width:16, borderRadius:100, backgroundColor:this.state.style.ball_color, marginLeft:15}}/>

      if(this.state.theme === 'Wimbledon'){
        renderServing =
        <Image
        style={{width:9, height:18, resizeMode:'contain', tintColor:this.state.style.player_color, marginLeft:15}}
        source={require('../scorearrow.png')}
        />
      }
    }

    var seed = null
    if(seeds){
      seed = seeds[item.id]
    }

    // return(
    //   <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {flex:1}]}>
        // <Text style={[{fontSize, position:'absolute', left:10}]} numberOfLines={1}>
        // {flag}
        // </Text>
        //
        // <Text style={[textStyle,{opacity:winner ? 1 : 0.5, marginLeft:!flag ? 10 : 45, textTransform:'uppercase', flexShrink:1}]} numberOfLines={1}>
        // {item.name} and banana man
        // </Text>
        //
        // <Text style={[textStyle, {opacity:0.33, marginLeft:10, fontFamily:'Averta-Bold'}]}>
        // {seed}
        // </Text>
        //
        // {renderServing}
    //   </View>
    // )

    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%'}]}>

        <View style={{flexShrink:1}}>
          <Text style={[textStyle,{opacity:winner ? 1 : 0.5, marginLeft:!flag ? 10 : 45, textTransform:'uppercase', flexShrink:0.25}]} numberOfLines={1}>
          {item.name}
          </Text>
        </View>

        <Text style={[textStyle, {opacity:0.33, marginLeft:10, fontFamily:'Averta-Bold'}]}>
        {seed}
        </Text>

        {renderServing}

      </View>
    )
  }

  renderVisualScores = (data) => {
    //Setup number of sets required
    var best_of = data.best_of || 3
    var scores = data.period_scores ? new Array(data.period_scores.length) : []
    scores.fill('')

    var height = 100

    return(
      <View style={[{height:'100%', backgroundColor:this.state.style.background_scores}, MainStyles.flexRow, MainStyles.flexEndCenter]}>
        {
          this.state.style.reverseOrder ?
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          {this.renderVisualGameScore(data, height)}
          </View>
          :
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {this.renderVisualGameScore(data, height)}
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          </View>
        }

        {this.renderVisualArrow(data, height)}
      </View>
    )
  }

  renderVisualArrow = (data, height) => {
    // return(
    //   <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:10, height:height}]}>
    //     {this.renderArrow(data.competitors[0].id === data.winner_id, height/1.2)}
    //     {this.renderArrow(data.competitors[1].id === data.winner_id, height/1.2)}
    //   </View>
    // )

    return null
  }

  renderArrow = (active, height) => {
    if(active){
      return(
        <View style={[{height}, MainStyles.flexCenter]}>
          <Image
          style={{width:9, height:18, resizeMode:'contain', tintColor:this.state.style.set_color}}
          source={require('../scorearrow.png')}
          />
        </View>
      )
    }else{
      return(
        <View style={{width:6, height}}/>
      )
    }
  }

  renderVisualGameScore = (data, height) => {
    var { mode } = this.state
    if(!data.winner_id && mode !== 'scheduled' && data.game_state){

      var scores = [0,0]
      if(data.game_state){
        scores = [data.game_state.home_score || 0, data.game_state.away_score || 0]
      }

      return(
        <View key={'gamescore'} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:20, marginRight:10, height, width:30}]}>
          {this.renderVisualSetScore(scores[0], null, null, null, true, data.tiebreak_scores, this.state.style.game_color)}
          <View style={{height:10, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}}/>
          {this.renderVisualSetScore(scores[1], null, null, null, true, data.tiebreak_scores, this.state.style.game_color)}
        </View>
      )
    }else{return null}
  }

  renderVisualSetScores = (scores, index, height, activeSet) => {
    var tiebreak_scores = {}
    var setScore = scores ? (scores[index] ? [scores[index].home_score, scores[index].away_score] : ['','']) : ['', '']
    var showScores = this.state.mode !== 'scheduled'
    return(
      <View key={index} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:0, height, width:60, backgroundColor:activeSet && this.state.style.background_activeSet ? this.state.style.background_activeSet : 'transparent' }]}>
        {showScores ? this.renderVisualSetScore(setScore[0], setScore[1], index+1, 'home', setScore[0] >= setScore[1], tiebreak_scores, activeSet && this.state.style.font_activeSet ? this.state.style.font_activeSet : this.state.style.set_color, activeSet) : null}
        <View style={{height:10, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}}/>
        {showScores ? this.renderVisualSetScore(setScore[1], setScore[0], index+1, 'away', setScore[1] >= setScore[0], tiebreak_scores, activeSet && this.state.style.font_activeSet ? this.state.style.font_activeSet : this.state.style.set_color, activeSet) : null}
      </View>
    )
  }

  renderVisualSetScore = (score, otherScore, set, player, active, tiebreak_scores, color, activeScore) => {
    var tb = null
    // if(score === 0){score = '0'}
    if(score === 50){score = 'AD'}
    // if(score === 6 && otherScore === 7){
    //   if(tiebreak_scores?[set]){
    //     tb =
    //     <Text style={{position:'absolute', top:-6, right:-6, fontSize:12, fontFamily:'Averta-Bold', color:'white', opacity:active ? 1 : 0.5, letterSpacing:-0}}>
    //     {tiebreak_scores[set][player]}
    //     </Text>
    //   }
    // }

    return(
      <View style={[MainStyles.flexCenter]}>
        <Text style={{fontSize, color, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:-0}}>
        {score}
        </Text>
      </View>
    )
  }

  //Buttons
  renderButtons = () => {
    var options = ['Wimbledon', 'Roland Garros', 'ATP1000', 'ATP250', 'GTL', 'Default']

    return(
      <View style={[{width:'100%', marginTop:40}, MainStyles.flexCenter]}>

        <Pressable onPress={this.reset} style={{padding:20, minWidth:140, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10, marginBottom:20}}>
          <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
          Back to Match List
          </Text>
        </Pressable>

        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
        Pick a theme
        </Text>
        <View style={[{width:'100%', padding:20, flexWrap:'wrap', marginTop:0}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {options.map((item, index) => this.renderOption(item, index))}
        </View>
      </View>
    )
  }

  renderOption = (item, index) => {
    return(
      <Pressable onPress={()=>this.changeTheme(item)} style={{padding:20, minWidth:140, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10}}>
        <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
        {item}
        </Text>
      </Pressable>
    )
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:'white', fontFamily:'Averta-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to open its scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item.id)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'Averta-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:'white', fontSize:16, fontFamily:'Averta-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : 'white', fontSize:16, fontFamily:'Averta-Regular'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }


  render() {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexStartCenter]}>
        {mode === 'selectMatch' ? this.renderSelectMatch() : this.renderScoreboard() }
        {mode === 'selectMatch' ? null : this.renderButtons()}
      </View>
    )
  }
}

export default Item;
