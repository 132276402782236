import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, ScrollView, SectionList } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

var height = 160
var width = 550
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

var animation_duration = 350

var blue = "#389CFF"


//Widths
var width_stat = 150

var borderColor = 'rgba(0,0,0,0.1)'
var borderWidth = 1

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
    };
  }

  componentDidMount = async () => {
    this.getRatings()
  }

  getRatings = async () => {
    var matches = []
    await firebase.firestore()
    .collection('summaries_sr').doc('scheduled')
    .get().then(doc => {
      if(doc.exists && doc.data()){
        matches = Object.values(doc.data().matches||{})
      }
    })

    var matches_clean = []
    await Promise.all(matches.map(async match => {
      var votes = 0

      if(match.docId){
        //VOTES
        await firebase.firestore()
        .collection('matches_sr').doc(match.docId)
        .collection('data').doc('votes')
        .get()
        .then(doc => {
          if(doc.exists && doc.data()){
            if(doc.data().votes){
              var votes_1 = doc.data().votes["0"] ? doc.data().votes["0"].length : 0
              var votes_2 = doc.data().votes["1"] ? doc.data().votes["1"].length : 0

              votes = votes_1 + votes_2
            }
          }
          match.votes = votes
        })

        //NOTIFICATIONS
        await firebase.firestore()
        .collection('matches_sr').doc(match.docId)
        .collection('notifications').doc('all')
        .get()
        .then(doc => {
          if(doc.exists && doc.data()){
            if(doc.data().tokens){
              votes = doc.data().tokens.length || 0
            }
          }
          match.votes = (match.votes || 0) + votes
        })
      }
    }))

    await matches.sort((a, b) => (a.votes < b.votes) ? 1 : -1);

    // await Promise.all(matches.map(async match => {
    //   console.log(match.votes, match.competitors[0].name, match.competitors[1].name)
    // }))

    this.setState({loading:false, matches})

    // console.log(CircularJSON.stringify({matches:matches_clean}))
  }

  componentWillUnmount = async () => {

  }

  //Functions
  firestoreAPI = async (url_raw) => {
    var data = null

    var language = GLOBAL.language || 'en'

    var tz = await moment.tz.guess(true)
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    await axios
    .get(url)
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  }

  setup = async (id, stats_season, stats_tournament, stats_surface) => {
    clearTimeout(this.updateData)

    console.log('Updating data')

    var stats_options = [
      // {label:'Title Favorites', value:'winner_forecast'},
      {label:'Serve Rating', value:'serve_rating'},
      {label:'Aces', value:'aces', showLabel:'Aces'},
      {label:'Double Faults', value:'double_faults', showLabel:'Double Faults'},
      {label:'Aces Per Match', value:'aces_average', showLabel:'Aces per match'},
      {label:'Double Faults Per Match', value:'double_faults_average', showLabel:'Double Faults per match'},
      {label:'1st Serve In', value:'serve1In'},
      {label:'1st Serve %', value:'serve1In'},
      {label:'1st Serve Win', value:'serve1Won'},
      {label:'1st Serve Points Won', value:'serve1Won'},
      {label:'2nd Serve Win', value:'serve2Won'},
      {label:'2nd Serve Points Won', value:'serve2Won'},
      {label:'Break Points Saved', value:'bpSaved'},
      {label:'Service Points Won', value:'servePointsWon'},
      {label:'Service Games Won', value:'serveGamesWon'},
      {label:'Return Rating', value:'return_rating'},
      {label:'1st Return Won', value:'return1Won'},
      {label:'2nd Return Won', value:'return2Won'},
      {label:'1st Return Points Won', value:'return1Won'},
      {label:'2nd Return Points Won', value:'return2Won'},
      {label:'Break Points Won', value:'bpWon'},
      {label:'Return Games Won', value:'returnGamesWon'},
      {label:'Return Points Won', value:'returnPointsWon'},
      {label:'Pressure Point Rating', value:'pressure_rating'},
    ]

    //Get the match info
    var docId = this.state.docId
    var competitors = this.state.competitors
    var competitors_docs = this.state.competitors_docs
    var competitors_photos = this.state.competitors_photos
    var surface = this.state.surface

    if(!docId){
      var season_id = null
      await firebase.firestore()
      .collection('matches_sr')
      .where('id', '==', id)
      .get()
      .then(async querySnapshot => {
        if(querySnapshot.size > 0){
          await querySnapshot.forEach(async (doc) => {
            docId = doc.id
            competitors = doc.data().competitors || [{},{}]
            competitors_docs = doc.data().competitors_docs || []
            competitors_photos = doc.data().competitor_photos || []
            season_id = doc.data().season_id
          })
        }
      })

      //Get the tournament data
      var surface_raw = null
      await firebase.firestore()
      .collection('summaries_sr').doc('tournaments_helper')
      .get()
      .then(doc => {
        if(doc.exists && doc.data()){
          try{
            surface_raw = (doc.data().tournaments[season_id].surface).toLowerCase()
          }catch(e){
            console.log(e)
          }
        }
      })

      //Figure out the surface
      if(surface_raw.includes("clay")){
        surface = 'clay'
      }else if(surface_raw.includes("grass")){
        surface = 'grass'
      }else if(surface_raw.includes("indoor")){
        surface = 'indoor'
      }else{
        surface = 'hard'
      }
    }

    //Get the two players
    var p1 = competitors[0]
    var p2 = competitors[1]

    //Get the match stats
    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getMatchInfo?="+docId+"?=stats?=Match"
    var stats_match = await this.firestoreAPI(url)

    var stats_tournament = this.state.stats_tournament
    var stats_surface = this.state.stats_surface
    var stats_season = this.state.stats_season

    if(!stats_season || !stats_tournament || !stats_surface){
      console.log('Fetching player stats')
      stats_tournament = [{},{}]
      stats_surface = [{},{}]
      stats_season = [{},{}]

      //Get the broken down stats for each player
      await Promise.all(competitors.map(async (player, player_i) => {
        console.log(competitors_docs[player_i], season_id)
        //Tournament stats
        await firebase.firestore()
        .collection('players_sr').doc(competitors_docs[player_i])
        .collection('statistics').doc('2021')
        .collection('tournaments').doc(season_id)
        .get()
        .then(async doc => {
          if(doc.exists && doc.data()){
            await Promise.all(doc.data().statistics.map(async tournament_stat => {
              stats_tournament[player_i][tournament_stat.title] = tournament_stat
            }))
          }
        })

        //Surface & Season stats
        await firebase.firestore()
        .collection('players_sr').doc(competitors_docs[player_i])
        .collection('statistics').doc('2021')
        .get()
        .then(async doc => {
          if(doc.exists && doc.data()){
            stats_surface[player_i] = doc.data().all[surface] || {}
            stats_season[player_i] = doc.data().all.all || {}
          }
        })
      }))
    }

    //For each player, cycle through the match stats, find the equivalent stat for season, tournament and surface and set into object
    var stats = stats_match.data

    if(!stats){
      stats = [
        {
          title:'Service',
          data:[
            {title:'Serve Rating', value:'serve_rating', values:['-','-']},
            {title:'Aces', value:'aces', showLabel:'Aces', values:['-','-']},
            {title:'Double Faults', value:'double_faults', showLabel:'Double Faults', values:['-','-']},
            {title:'1st Serve %', value:'serve1In', values:['-','-']},
            {title:'1st Serve Points Won', value:'serve1Won', values:['-','-']},
            {title:'2nd Serve Points Won', value:'serve2Won', values:['-','-']},
            {title:'Break Points Saved', value:'bpSaved', values:['-','-']},
            {title:'Service Points Won', value:'servePointsWon', values:['-','-']},
          ]
        },
        {
          title:'Return',
          data:[
            {title:'Return Rating', value:'return_rating', values:['-','-']},
            {title:'1st Return Points Won', value:'return1Won', values:['-','-']},
            {title:'2nd Return Points Won', value:'return2Won', values:['-','-']},
            {title:'Break Points Won', value:'bpWon', values:['-','-']},
          ]
        },
      ]
    }

    // console.log('Automatically flag talking points if the variance is greater than 15% maybe')

    await Promise.all(stats.map(async (stats_section, section_index) => {
      await Promise.all(stats_section.data.map(async stats_item => {
        var stat_id = stats_options.map(function(e) { return e.label; }).indexOf(stats_item.title)

        if(stat_id >= 0){
          var stat_key = stats_options[stat_id].value

          var p1_tournament = await this.cleanStat(stats_tournament[0][stat_key], stats_item.values[0])
          var p1_surface = await this.cleanStat(stats_surface[0][stat_key], stats_item.values[0])
          var p1_season = await this.cleanStat(stats_season[0][stat_key], stats_item.values[0])

          var p2_tournament = await this.cleanStat(stats_tournament[1][stat_key], stats_item.values[1])
          var p2_surface = await this.cleanStat(stats_surface[1][stat_key], stats_item.values[1])
          var p2_season = await this.cleanStat(stats_season[1][stat_key], stats_item.values[1])


          stats_item.values_tournament = [p1_tournament, p2_tournament]
          stats_item.values_surface = [p1_surface, p2_surface]
          stats_item.values_season = [p1_season, p2_season]
        }
      }))
    }))

    stats = stats.slice(0,2)

    await this.setState({stats, stats_tournament, stats_surface, stats_season, docId, competitors, competitors_docs, competitors_photos, surface, loading:false})

    this.updateData = setTimeout(async () => {
      this.setup()
    },30 * 1000)
  }

  cleanStat = async (stat, stat_match) => {
    if(stat){
      if(stat.matches && stat.values){
        stat.values_override = Math.round(stat.values[0] / stat.matches * 10) / 10
      }

      if(stat.values && stat_match){
        if(stat.values[0]){
          var v1 = stat_match
          var v2 = stat.values_override || stat.values[0]

          if(v1){
            if(v1.toString().includes("%")){
              v1 = v1.split("%")[0]
            }
          }

          if(v2){
            if(v2.toString().includes("%")){
              v2 = v2.split("%")[0]
              stat.values[0] = v2.split(" ")[0]+"%"
            }
          }

          var difference = (v1 - v2)/v2

          if(!Number.isNaN(difference) && difference !== 0){
            stat.opacity = Math.abs(difference)
            stat.difference = difference
            stat.change = Math.round(([difference,]*100))+"%"

            if(stat.difference > 0){
              stat.change = "+"+stat.change
            }

            stat.reverse = stat.title === 'double_faults'
          }
        }
      }
    }

    return stat
  }


  //Renders
  renderLoading = () => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color='black'/>
      </View>
    )
  }

  renderContent = () => {
    var matches = this.state.matches || []
    if(matches.length === 0){
      return(
        <Text>
        No matches available at the moment.
        </Text>
      )
    }else{
      return(
        <View style={[{width:'100%', flex:1, paddingHorizontal:32}]}>
          {this.renderMatch({}, true)}
          <FlatList
            ListHeaderComponent={this.renderSectionListHeader}
            data={matches}
            style={[{width:'100%', maxWidth:1200}]}
            contentContainerStyle={[{paddingBottom:40, width:'100%'}]}
            keyExtractor={(item, index) => index}
            renderItem={this.renderMatch}
            // stickySectionHeadersEnabled={true}
          />
        </View>
      )
    }
  }

  renderMatch = ({item, index}, bold) => {
    return(
      <View style={[{width:400, marginVertical:16}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Text style={{width:100, fontWeight:bold ? '900' : '300'}}>
        {item ? item.votes : "Popularity"}
        </Text>
        <Text style={{fontWeight:bold ? '900' : '300'}}>
        {item ? item.competitors[0].name + " vs " + item.competitors[1].name : 'Match'}
        </Text>
      </View>
    )
  }

  render() {
    var { loading } = this.state
    return(
      <View style={[{width:'100%'}]} style={[{flex:1, width:'100%', height:'100%', backgroundColor:'white'}, MainStyles.flexCenter]}>
        {loading ? this.renderLoading() : this.renderContent() }
      </View>
    )
  }
}

export default Item;
