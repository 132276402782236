import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 160
var width = 550
var spacing = 10
var fontSize = 30
var moment = require('moment-timezone');

var stringChangeTime = 8000

var animation_duration = 350

var blue = "#389CFF"

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      tippers_raw:[],
      stats:[],
      stats_list:[],
      stats_serving:[],
      stats_receiving:[],
      stats_options:[],
      matchTime:'0:00',
      matchInfo:'',

      activeIndex_stats:-1,
      activeIndex_tippers:-1,

      activeIndex_h2h:-1,
      h2h:[],

      tippers:[],
      stats:[],
      stats_obj:{},
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',

      stats_view:"Match",

      stats_opacity: new Animated.Value(1),
      h2h_opacity: new Animated.Value(1),

      images:[null, null],
      names:[null, null],
      flags:[null, null],
    };
  }

  componentDidMount = async () => {
    await this.setup()
    this.changeTheme()
  }

  componentWillUnmount = async () => {
    if(this.listenMatch){
      this.listenMatch()
    }
    if(this.listenMatchStats){
      this.listenMatchStats()
    }
    clearTimeout(this.updateString_tippers)
    clearTimeout(this.updateString_h2h)
    if(this.listenControls){
      this.listenControls()
    }
  }

  reset = async () => {
    await this.setState({
      match:{},
      style:{},

      loading:true,
      firstSet:true,

      mode:'selectMatch',
      matches:[],
      topTippers:[],
      stats:[],

      activeIndex_stats:-1,
      activeIndex_tippers:-1,
      tippers:[],
      stats:[],
      string_stats:'',
      string_tippers:'',
      stats_title:'Match Stats',
      delay:0,
    })
    this.setup()
  }

  setup = async () => {
    //Get the selected match from Firestore
    this.listenControls = await firebase.firestore()
    .collection('streaming').doc("C5aaURtU7WANlm1jDEzW")
    .onSnapshot(async (doc) => {
      if(doc.exists && doc.data()){
        var id = doc.data().matchId || null
        var delay = doc.data().delay || 0
        await this.setState({delay})
        if(id !== this.state.id){
          await this.setState({id})
          this.selectMatch(id)
        }
      }
    })
  }

  selectMatch = async (id) => {
    this.setState({id})

    if(this.listenMatch){
      this.listenMatch()
    }

    //Get the match from firestore and listen
    this.listenMatch = await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .onSnapshot(this.handleMatch)
  }

  handleMatch = async ( querySnapshot ) => {
    try{
      var match = {}
      var docId = null

      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          match = doc.data()
          docId = doc.id

          //Correct the player names
          if(match.competitors[0].name.includes(', ')){
            match.competitors[0].name_full = (match.competitors[0].name.split(", "))[1] + " " + (match.competitors[0].name.split(", "))[0]
            match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
          }

          if(match.competitors[1].name.includes(', ')){
            match.competitors[1].name_full = (match.competitors[1].name.split(", "))[1] + " " + (match.competitors[1].name.split(", "))[0]
            match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
          }
        })
      }

      if(this.state.firstSet && docId){
        this.setupPlayers(match)
      }

      //Calculate the matchTime
      var durationString = null
      if(match.startedAt){
        var startedAt = match.startedAt
        var finishedAt = !match.finishedAt ? Date.now() : match.finishedAt

        var duration = (moment.duration(finishedAt - startedAt).as('hours')).toString()

        //Clean string
        var durationHours = Number(duration.split('.')[0])
        if(durationHours < 6){
          var durationMinutesNumber = Math.round(Number("."+duration.split('.')[1]) * 60)
          var durationMinutesRaw = durationMinutesNumber == 60 ? 59 : durationMinutesNumber
          var durationMinutes = durationMinutesRaw.toString().length == 1 ? "0"+durationMinutesRaw : durationMinutesRaw
          durationString = durationHours + ':' + durationMinutes
        }
      }

      //Calculate the duration string
      var matchTime = durationString ? "Match Time: "+durationString : ''

      //Flags
      var country_corrections = {
        ["GRE"]:'GRC',
        ["GER"]:'DEU',
        ["LAT"]:'LVA',
        ["CRO"]:'HRV',
        ["SUI"]:'CHE',
        ["CHI"]:'CHL',
      }
      var countries_fix = Object.keys(country_corrections)

      await Promise.all(match.competitors.map(async (player, p_index) => {
        if(countries_fix.includes(player.country_code)){
          player.country_code = country_corrections[player.country_code]
        }
      }))

      //Country code
      var code_1 = await convertIso3Code(match.competitors[0].country_code)
      var code_2 = await convertIso3Code(match.competitors[1].country_code)
      var flag_1 = code_1 ? code_1.iso2 : null
      var flag_2 = code_2 ? code_2.iso2 : null

      var names = [{first:match.competitors[0].name.split(", ")[1], last:match.competitors[0].name.split(", ")[0]}, {first:match.competitors[1].name.split(", ")[1], last:match.competitors[1].name.split(", ")[0]}]
      var flags = [flag_1, flag_2]


      //Match Info string
      var matchInfo = match.court_name || match.season_name
      if(match.round){
        matchInfo = matchInfo + " - " + match.round
      }

      var delay = this.state.delay || 0

      // alert(delay + " seconds delay")

      await setTimeout(async ()=>{
        await this.setState({names, flags, match, matchTime, matchInfo, loading:false, mode:'match', firstSet:false})
      },delay * 1000)


    }catch(e){
      console.log('Error handling match', e)
    }
  }

  setupPlayers = async (match) => {
    if(match.competitors[0].name.includes(". ")){
      var lastname_1 = (match.competitors[0].name.split(". ")[1]).toLowerCase()
      var lastname_2 = (match.competitors[1].name.split(". ")[1]).toLowerCase()

      var image_1 = match.season_name.toLowerCase().includes('women') ? null : "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_1+"_full_ao20.png"
      var image_2 = match.season_name.toLowerCase().includes('women') ? null : "https://www.atptour.com/-/media/tennis/players/gladiator/2020/"+lastname_2+"_full_ao20.png"

      //Get player profiles
      var image_official_1 = await this.getPlayer(match.competitors[0].id)
      var image_official_2 = await this.getPlayer(match.competitors[1].id)

      var images = [image_official_1 || image_1, image_official_2 || image_2]
      var names = [match.competitors[0].name_full, match.competitors[1].name_full]

      this.setState({images, names})
    }
  }

  getPlayer = async (id) => {
    var image = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.data()){
            if(doc.data().image_official){
              image = doc.data().image_official
            }
          }
        })
      }
    })

    return image
  }

  setupListeners = async (docId, match) => {
    var status = match.match_status
    //Get stats & h2h
    this.listenMatchStats = await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('info').doc('stats')
    .onSnapshot(this.handleStats)

    this.listenTippers = await firebase.firestore()
    .collection('streaming').doc("yBhmZeRfAZ7rzm8jemwX")
    .onSnapshot(this.handleTippers)

    await firebase.firestore()
    .collection('matches_sr').doc(docId)
    .collection('info').doc('data')
    .get()
    .then(async(doc)=>{
      if(doc.exists && doc.data()){
        var stats = []
        var stats_title = 'H2H Record'
        var h2h = doc.data().h2h_v2

        if(h2h){
          //Generate the H2H record string
          var record = h2h.wins
          var leader_string = ''
          if(record[0] > record[1]){
            leader_string = match.competitors[0].name + " leads the H2H " + record[0] + "-" + record[1]
          }else if(record[1] > record[0]){
            leader_string = match.competitors[1].name + " leads the H2H " + record[1] + "-" + record[0]
          }else{
            //Tied
            leader_string = "H2H is tied at " + record[0] + "-" + record[1]
          }

          // stats.push(leader_string)
          var title_h2h = leader_string

          var matches = h2h.matches || []
          await Promise.all(matches.map(async (h2h_match, index) => {
            stats.push(h2h_match.winner + " won in the "+ h2h_match.round + " at the " + h2h_match.tournament + " in " + moment(h2h_match.timestamp, 'x').format('YYYY') + "  -  " + h2h_match.score)
          }))

          await this.setState({h2h:stats, title_h2h})
          this.changeString_h2h()
        }

      }
    })
  }

  handleTippers = async (doc) => {
    //Set state and setup string changing timers
    // var tippers = [
    //   "Here's the first stat",
    //   "Here's the next stat",
    //   "And what about htis stats",
    // ]

    var tippers = []

    if(doc.data() && doc.exists){

      var view_mode = doc.data().view_mode || {}
      if(view_mode.stats){
        if(view_mode.stats !== this.state.stats_view){
          this.changeStats(view_mode.stats)
        }
      }

      tippers = doc.data().topTippers || []
      var tippers_raw = doc.data().topTippers || []

      tippers = [tippers.join("   ·   ")]

      await this.setState({tippers, tippers_raw})

      this.changeString_tippers()
    }
  }

  handleStats = async (doc) => {
    var { match } = this.state
    if(doc.data() && doc.exists){
      var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM"]
      var stats_list = []

      var stats_obj = {}
      var stats_options = []

      var stats_serving = []
      var stats_receiving = []

      var statsRaw = doc.data().stats || []

      //Setup names
      var p1 = match.competitors[0].name || ''
      var p2 = match.competitors[1].name || ''

      //Promise through stats and add values
      if(statsRaw){
        await Promise.all(statsRaw.map(async stat_period => {
          // if(stat_period.title === 'Match'){
            var period_stats = await this.cleanStats(stat_period.stats.stats || [])
            stats_options.push(stat_period.title)
            stats_obj[stat_period.title] = period_stats
          // }
        }))
      }

      await this.setState({stats_obj, stats_options})

      this.changeStats()

      var stats_title = 'Match Stats'
      await this.setState({stats, stats_title, stats_list})
      this.changeString_stats()

    }else{
      var stats = ["Match Stats powered by TNNS - DOWNLOADTENNIS.COM", "Stats available soon"]
      await this.setState({stats})
      this.changeString_stats()
    }
  }

  cleanStats = async (period_stats) => {
    var stats_serving = []
    var stats_receiving = []
    var blocked_stats = ["Return Games Won"]

    await Promise.all(period_stats.map(async stat_group => {
      await Promise.all(stat_group.data.map(async stat => {
        if(stat.values){
          if((stat.values[0] || stat.values[0] === 0) && (stat.values[1] || stat.values[1] === 0) && !blocked_stats.includes(stat.title)){
            var stat_obj = {title:stat.title, values:stat.values}

            if((stat_group.title === 'Service' && stat.title !== "Service Games Won") || stat_group.title === 'General'){
              stats_serving.push(stat_obj)
            }
            if(stat_group.title === 'Return' || stat_group.title === 'Time Violations'){
              stats_receiving.push(stat_obj)
            }
          }
        }
      }))
    }))

    return { stats_serving, stats_receiving }
  }

  changeTheme = async (theme) => {
    // var style = {
    //   background_main:"#101012",
    //   background_match:'#18181B',
    //   background_tnns:'#18181B',
    //   background_scores:"#18181B",
    //   background_ticker:'#18181B',
    //   background_tippers:"#18181B",
    //   font_tippers:"white",
    //   font_ticker:'white',
    //   font_color:'white',
    //   game_color:'white',
    //   set_color:'white',
    //   player_color:'white',
    //   player_font:'Averta-Bold',
    //   ball_color:GLOBAL.colors.volt,
    //   event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
    //   borderRadius:10,
    //   letterSpacing:0.5,
    //   alternatingBackground:'rgba(0,0,0,0.24)',
    //   reverseOrder:true,
    // }

    var style = {
      background_main:"black",
      background_match:'black',
      background_tnns:'#18181B',
      background_scores:"transparent",
      background_ticker:'#18181B',
      // background_activeSet:'#fcd946',
      background_activeSet:'transparent',
      background_tippers:"gold",
      font_tippers:"white",
      font_activeSet:'white',
      font_ticker:'white',
      font_color:'white',
      game_color:'#fcd946',
      set_color:'white',
      player_color:'white',
      player_font:'Roboto-Bold',
      ball_color:"gold",
      event_logo:"https://pbs.twimg.com/profile_images/1113016926324830208/rS-cyyc7_400x400.png",
      borderRadius:0,
      letterSpacing:0.5,
      alternatingBackground:'rgba(0,0,0,0.24)',
      reverseOrder:true,
    }

    return this.setState({style, theme})
  }

  changeStats = async (id) => {
    var { stats_obj, stats_options, stats_view } = this.state

    await Animated.timing(this.state.stats_opacity, {
      toValue: 0,
      duration: animation_duration,
    }).start();

    await setTimeout(async ()=>{
      if(id){
        await this.setState({stats_view:id})
      }

      var stats_id = stats_obj[this.state.stats_view] ? this.state.stats_view : 'Match'

      if(id === 'H2H'){
        stats_id = 'H2H'
      }

      await this.setState({stats_view:stats_id})

      var stats_receiving = stats_obj[stats_id] ? stats_obj[stats_id].stats_receiving : []
      var stats_serving = stats_obj[stats_id] ? stats_obj[stats_id].stats_serving : []

      await this.setState({stats_receiving, stats_serving})

      await Animated.timing(this.state.stats_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, 350)
  }

  //
  changeStatsView = async (id) => {
    await firebase.firestore()
    .collection('streaming').doc('yBhmZeRfAZ7rzm8jemwX')
    .set({
      view_mode:{
        stats:id,
      }
    },{merge:true})
  }

  //String changers
  changeString_stats = async () => {
    clearTimeout(this.updateString_stats)

    var { activeIndex_stats, stats } = this.state
    var nextIndex = activeIndex_stats+1
    if((activeIndex_stats+1) >= (stats.length)){
      nextIndex = 0
    }

    var string_stats = stats[nextIndex]

    await this.setState({string_stats, activeIndex_stats:nextIndex})

    this.updateString_stats = setTimeout(async () => {
      this.changeString_stats()
    }, stringChangeTime)
  }

  changeString_h2h = async () => {
    clearTimeout(this.updateString_h2h)

    await Animated.timing(this.state.h2h_opacity, {
      toValue: 0,
      duration: animation_duration,
    }).start();

    await setTimeout(async ()=>{
      var { activeIndex_h2h, h2h } = this.state
      var nextIndex = activeIndex_h2h+1
      if((activeIndex_h2h+1) >= (h2h.length)){
        nextIndex = 0
      }

      var string_h2h = h2h[nextIndex]

      await this.setState({string_h2h, activeIndex_h2h:nextIndex})

      console.log('H2H string updated', string_h2h)

      this.updateString_h2h = setTimeout(async () => {
        this.changeString_h2h()
      }, stringChangeTime)

      await Animated.timing(this.state.h2h_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();

    }, animation_duration)
  }

  changeString_tippers = async () => {
    clearTimeout(this.updateString_tippers)
    var { activeIndex_tippers, tippers } = this.state

    var nextIndex = activeIndex_tippers+1
    if((activeIndex_tippers+1) >= (tippers.length)){
      nextIndex = 0
    }

    var string_tippers = tippers[nextIndex]
    await this.setState({string_tippers, activeIndex_tippers:nextIndex})

    this.updateString_tippers = setTimeout(async () => {
      this.changeString_tippers()
    }, stringChangeTime / 2)
  }

  changeImage = async () => {
    var newImage = prompt('Change the image by entering the new URL')

    if(newImage){
      var style = this.state.style
      style.event_logo = newImage
      this.setState({style})
    }
  }


  //Renders
  renderScoreboard = () => {
    var { string_stats, string_tippers, stats_list, stats_serving, stats_receiving, matchTime, matchInfo } = this.state

    var width = 720

    var showStats = stats_serving.length > 0
    // console.log('show stats?', showStats, stats_serving.length, stats_serving)
    // var showStats = false

    return(
      <View style={[{width, backgroundColor: 'transparent' || this.state.style.background_main, padding:0}, MainStyles.flexStartCenter]}>
        {
          // this.renderTicker(matchInfo, 'Info', matchTime)
        }
        {this.renderScore()}
      </View>
    )
  }

  renderCard = (ratio, marginRight, marginLeft, title, content) => {
    return(
      <View style={{width:ratio, height:'100%', paddingRight:marginRight ? spacing : 0, paddingLeft:marginLeft ? spacing : 0}}>
        <View style={[{width:'100%', height:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_ticker, overflow:'hidden'}, MainStyles.flexStartCenter]}>
          {content}
        </View>
      </View>
    )
  }

  renderTippers = (width) => {
    var { tippers_raw } = this.state
    return(
      <View style={{width, marginTop:spacing*0, flexGrow:1}}>
        <View style={[{width:'100%', borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tippers, flexGrow:1}, MainStyles.flexStartCenter]}>
          {this.renderStatTitle('Top Tippers', true, this.state.style.font_tippers, true)}
          {tippers_raw.map((item, index) => this.renderTipper(item, index))}
        </View>
      </View>
    )
  }

  renderTipper = (item, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style.alternatingBackground : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_tippers, textAlign:'left'}}>
          {item}
        </Text>
      </View>
    )
  }

  renderStats = (data, title) => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.renderStatTitle(this.state.stats_view)}
        {data.map((item, index) => this.renderStat(item, index))}
      </View>
    )
  }

  renderStat = (data, index) => {
    var alternating = index % 2 === 0
    return(
      <View style={[{width:'100%', backgroundColor:alternating ? this.state.style.alternatingBackground : 'transparent', padding:spacing}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Animated.Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, textAlign:'left', opacity:this.state.stats_opacity}}>
          {data.title}
        </Animated.Text>

        <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
          <Animated.Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, width:'50%', textAlign:'left', opacity:this.state.stats_opacity}}>
          {data.values[0]}
          </Animated.Text>
          <Animated.Text style={{fontFamily:'Averta-Regular', fontSize:18, color:this.state.style.font_ticker, width:'50%', textAlign:'left', opacity:this.state.stats_opacity}}>
          {data.values[1]}
          </Animated.Text>
        </View>
      </View>
    )
  }

  renderStatTitle = (title, hideNames, color, disableAnimation) => {
    var { match } = this.state

    var opacity = !disableAnimation ? this.state.stats_opacity : 1

    return(
      <View style={[{width:'100%', padding:spacing*2, paddingHorizontal:spacing}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>
        <Animated.Text style={{fontSize:24, color:color || this.state.style.font_ticker, fontFamily:'Averta-Black', width:'100%', textAlign:'left', opacity}}>
        {title}
        </Animated.Text>

        {
          hideNames ? null :
          <View style={[{width:'50%'}, MainStyles.flexRow, MainStyles.flexCenter]}>
            <Text style={{fontFamily:'Averta-Bold', fontSize:18, color:color || this.state.style.font_ticker, width:'50%', textAlign:'left', opacity}}>
            {match.competitors[0].abbreviation}
            </Text>
            <Text style={{fontFamily:'Averta-Bold', fontSize:18, color:color || this.state.style.font_ticker, width:'50%', textAlign:'left', opacity}}>
            {match.competitors[1].abbreviation}
            </Text>
          </View>
        }
      </View>
    )
  }

  //

  renderTicker = (string, title, right) => {
    return(
        <View style={[{width:'100%', backgroundColor:blue, height:60, paddingHorizontal:0}, MainStyles.flexCenter]}>

          <View style={[{width:'100%', backgroundColor:blue, height:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <View style={[{flexGrow:1}, MainStyles.flexCenterStart]}>
              <Text style={{fontSize:20, color:this.state.style.font_ticker, fontFamily:'Averta-Bold', textAlign:'left', paddingLeft:spacing*2}}>
              {string}
              </Text>
            </View>

            <View style={[{height:'100%', paddingHorizontal:spacing*2}, MainStyles.flexCenter]}>
              <Text style={{fontSize:20, color:this.state.style.font_ticker, fontFamily:'Averta-Regular', textAlign:'left'}}>
              {right}
              </Text>
            </View>
          </View>

        </View>
    )
  }

  renderFeatureCard = () => {
    return(
      <View style={[{width:height*1.5, height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tnns}, MainStyles.flexCenter]}>
        <Image
        style={{resizeMode:'contain', width:'100%', height:'100%', tintColor:this.state.style.tint_tnns || 'white'}}
        source={require('../../images/poweredbytnns.png')}
        />
      </View>
    )
  }

  renderImage = (source, disableMargin, onPress) => {
    return(
      <Pressable onPress={onPress || null}>
        <Image
        style={{height, width:height, borderRadius:this.state.style.borderRadius, marginLeft:disableMargin ? 0 : spacing, resizeMode:'cover'}}
        source={source}
        />
      </Pressable>
    )
  }

  renderPreviewImage = (width) => {
    var { id, match, images, names, string_h2h, title_h2h } = this.state

    var image_1 = images[0] || null
    var image_2 = images[1] || null

    var name_1 = names[0] || null
    var name_2 = names[1] || null

    var height = 350

    var img_height = height - 40
    var img_width = img_height / 737 * 463

    var name_style = {fontFamily:'Averta-Black', color:'white', fontSize:36, color:this.state.style.font_tippers, textTransform:'uppercase', textAlign:'center', width:'85%', flexWrap:'wrap'}
    var secondary_style = {fontFamily:'Averta-Semibold', fontSize:20, color:this.state.style.font_tippers, textTransform:'uppercase', opacity:0.5, textAlign:'center'}

    // if(image_1 && image_1){
      return(
        <View style={{width, marginTop:spacing*0, flexGrow:1, marginLeft:spacing*2, paddingRight:spacing*2}}>
          <View style={[{width:'100%', height, borderRadius:this.state.style.borderRadius, backgroundColor:this.state.style.background_tippers, flexGrow:1, overflow:'hidden', padding:20}, MainStyles.flexCenter]}>

            <Image
            style={{width:'100%', height:'100%', resizeMode:'cover', opacity:0.25, position:'absolute'}}
            source={{uri:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"}}
            />

            <View style={[{}, MainStyles.flexCenter]}>
              <Text style={[secondary_style, {marginBottom:20}]}>
              {match.season_name.split(", ")[0] + " - " + match.round}
              </Text>

              <Text style={[name_style]}>
              {name_1}
              </Text>

              <Text style={[secondary_style, {marginVertical:5}]}>
              vs
              </Text>

              <Text style={[name_style]}>
              {name_2}
              </Text>

            </View>

            <Image
            style={{position:'absolute', left:-35, bottom:0, height:img_height, width:img_width, resizeMode:'contain'}}
            source={{uri:image_1}}
            />

            <Image
            style={{position:'absolute', right:-35, bottom:0, height:img_height, width:img_width, resizeMode:'contain'}}
            source={{uri:image_2}}
            />

          </View>

          <View style={{width:'100%', marginTop:spacing, backgroundColor:this.state.style.background_scores, padding:spacing*2, borderRadius:this.state.style.borderRadius}}>
            <Text style={[secondary_style, {marginBottom:10, marginTop:0, color:this.state.style.player_color, opacity:1, fontFamily:'Averta-Black', fontSize:24}]}>
            {title_h2h}
            </Text>
            <Animated.Text style={[secondary_style, {marginBottom:0, width:'100%', fontFamily:'Averta-Semibold', color:this.state.style.player_color, opacity:this.state.h2h_opacity}]}>
            {string_h2h}
            </Animated.Text>
          </View>
        </View>
      )
    // }
  }


  //Score renders
  renderScore = () => {
    var item = this.state.match
    return(
      <View style={[{height, flex:1, width:'100%', borderRadius:this.state.style.borderRadius, marginHorizontal:spacing*2, backgroundColor:'transparent' || this.state.style.background_match, overflow:'hidden', paddingVertical:20}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {
          !this.state.style.leftTab ? null :
          <View style={{height:'100%', width:10, marginRight:5, backgroundColor:this.state.style.leftTab}}/>
        }
        <View style={[{flex:1, height:'100%', paddingHorizontal:spacing, paddingRight:10}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{flex:1, marginRight:spacing*2, height:'100%'}, MainStyles.flexColumn, MainStyles.flexCenterStart]}>
            {this.renderPlayer(item.competitors[0], 0, item.winner_id, item.seeds)}
            <View style={{height:34}}/>
            {this.renderPlayer(item.competitors[1], 1, item.winner_id, item.seeds)}
          </View>
          {this.renderVisualScores(item)}
        </View>
      </View>
    )
  }

  renderPlayer = (item, index, winner_id, seeds) => {

    var { flags } = this.state

    var winner = winner_id ? winner_id === item.id : false

    var textStyle = {fontSize, color:this.state.style.player_color, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:this.state.style.letterSpacing}

    var flag = flags[index]

    var game = this.state.match.game_state || {}
    var player_gs_id = index === 0 ? 'home' : 'away'
    var isServing = game.serving === player_gs_id && !winner_id

    var renderServing = null
    var renderLeft = null

    if(isServing){
      // renderServing = <View style={{height:16, width:16, borderRadius:100, backgroundColor:this.state.style.ball_color, marginLeft:15}}/>

      // renderServing = <Text style={{marginLeft:15, fontSize:20}}>🎾</Text>

      // if(this.state.theme === 'Wimbledon'){
        renderLeft =
        <Image
        style={{width:12, height:24, resizeMode:'contain', tintColor:this.state.style.game_color, transform:[{scaleX:-1}]}}
        source={require('../scorearrow.png')}
        />
      // }
    }

    if(winner){
      renderServing = <Text style={{fontSize:22}}>✅</Text>
      renderLeft = null
    }

    var seed = null
    if(seeds){
      seed = seeds[item.id]
    }

    var flag_width = 30
    var flag_height = flag_width / 20 * 16


    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%'}]}>

        <View style={[{width:30}, MainStyles.flexCenterStart]}>
        {renderLeft}
        </View>

        <View style={[{marginRight:20, marginLeft:10, borderColor:'rgba(0,0,0,0.08)', borderWidth:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <ReactCountryFlag
            countryCode={flag}
            svg
            style={{
                width: flag_width,
                height: flag_height,
            }}
            title="US"
          />
        </View>

        <View style={[{flex:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <Text style={[textStyle,{opacity:winner || !winner_id ? 1 : 0.5, marginLeft:!flag ? 10 : 0}]} numberOfLines={1} adjustsFontSizeToFit>
          {item.name}
          </Text>
          <Text style={[textStyle, {opacity:0.33, marginLeft:10, fontFamily:this.state.style.player_font}]}>
          {seed}
          </Text>
        </View>


        <View style={[{width:60}, MainStyles.flexCenterEnd]}>
        {renderServing}
        </View>

      </View>
    )
  }

  renderVisualScores = (data) => {
    //Setup number of sets required
    var best_of = data.best_of || 3
    var scores = data.period_scores ? new Array(data.period_scores.length) : []
    scores.fill('')

    var height = 100

    return(
      <View style={[{height:'100%', backgroundColor:this.state.style.background_scores}, MainStyles.flexRow, MainStyles.flexEndCenter]}>
        {
          this.state.style.reverseOrder ?
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          {this.renderVisualGameScore(data, height)}
          </View>
          :
          <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
          {this.renderVisualGameScore(data, height)}
          {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height, index === (data.period_scores.length - 1)))}
          </View>
        }

        {this.renderVisualArrow(data, height)}
      </View>
    )
  }

  renderVisualArrow = (data, height) => {
    // return(
    //   <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:10, height:height}]}>
    //     {this.renderArrow(data.competitors[0].id === data.winner_id, height/1.2)}
    //     {this.renderArrow(data.competitors[1].id === data.winner_id, height/1.2)}
    //   </View>
    // )

    return null
  }

  renderArrow = (active, height) => {
    if(active){
      return(
        <View style={[{height}, MainStyles.flexCenter]}>
          <Image
          style={{width:9, height:18, resizeMode:'contain', tintColor:this.state.style.set_color}}
          source={require('../scorearrow.png')}
          />
        </View>
      )
    }else{
      return(
        <View style={{width:6, height}}/>
      )
    }
  }

  renderVisualGameScore = (data, height) => {
    var { mode } = this.state
    if(!data.winner_id && mode !== 'scheduled' && data.game_state){

      var scores = [0,0]
      if(data.game_state){
        scores = [data.game_state.home_score || 0, data.game_state.away_score || 0]
      }

      return(
        <View key={'gamescore'} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:20, marginRight:10, height:72, width:30}]}>
          {this.renderVisualSetScore(scores[0], null, null, null, true, data.tiebreak_scores, this.state.style.game_color)}
          <View style={[{height:40, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}, MainStyles.flexCenter]}>
            <Text style={{fontFamily:this.state.style.player_font, color:this.state.style.game_color, fontSize:16, opacity:1}}>
            Points
            </Text>
          </View>
          {this.renderVisualSetScore(scores[1], null, null, null, true, data.tiebreak_scores, this.state.style.game_color)}
        </View>
      )
    }else{return null}
  }

  renderVisualSetScores = (scores, index, height, activeSet) => {
    var tiebreak_scores = {}
    var setScore = scores ? (scores[index] ? [scores[index].home_score, scores[index].away_score] : ['','']) : ['', '']
    var showScores = this.state.mode !== 'scheduled'

    var setNumber = index + 1
    var setString = ''
    if(setNumber === 1){setString = '1st'}
    if(setNumber === 2){setString = '2nd'}
    if(setNumber === 3){setString = '3rd'}
    if(setNumber === 4){setString = '4th'}
    if(setNumber === 5){setString = '5th'}

    return(
      <View key={index} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:0, height:'100%', width:72, backgroundColor:activeSet && this.state.style.background_activeSet ? this.state.style.background_activeSet : 'transparent', borderRightColor:'rgba(255,255,255,0.15)', borderRightWidth:0 }]}>
        {showScores ? this.renderVisualSetScore(setScore[0], setScore[1], index+1, 'home', setScore[0] >= setScore[1], tiebreak_scores, activeSet && this.state.style.font_activeSet ? this.state.style.font_activeSet : this.state.style.set_color, activeSet) : null}
        <View style={[{height:40, width:'100%', backgroundColor:'rgba(255,255,255,0.0)'}, MainStyles.flexCenter]}>
          <Text style={{fontFamily:'Roboto-Regular', color:this.state.style.set_color, fontSize:16, opacity:1}}>
          {setString}
          </Text>
        </View>
        {showScores ? this.renderVisualSetScore(setScore[1], setScore[0], index+1, 'away', setScore[1] >= setScore[0], tiebreak_scores, activeSet && this.state.style.font_activeSet ? this.state.style.font_activeSet : this.state.style.set_color, activeSet) : null}
      </View>
    )
  }

  renderVisualSetScore = (score, otherScore, set, player, active, tiebreak_scores, color, activeScore) => {
    var tb = null
    // if(score === 0){score = '0'}
    if(score === 50){score = 'AD'}
    // if(score === 6 && otherScore === 7){
    //   if(tiebreak_scores?[set]){
    //     tb =
    //     <Text style={{position:'absolute', top:-6, right:-6, fontSize:12, fontFamily:'Averta-Bold', color:'white', opacity:active ? 1 : 0.5, letterSpacing:-0}}>
    //     {tiebreak_scores[set][player]}
    //     </Text>
    //   }
    // }

    return(
      <View style={[MainStyles.flexCenter]}>
        <Text style={{fontSize, color, fontFamily:this.state.style.player_font, textAlign:'left', letterSpacing:-0}}>
        {score}
        </Text>
      </View>
    )
  }


  //Select match
  renderSelectMatch = () => {
    var { loading, matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
        {
          loading ?
          <Text style={{fontSize:24, color:'white', fontFamily:'Averta-Bold', textAlign:'center', letterSpacing:0}}>
          Loading matches
          </Text>
          :
          this.renderMatchOptions()
        }
      </View>
    )
  }

  renderMatchOptions = () => {
    var { matches } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%'}, MainStyles.flexCenter]}>
      <FlatList
      style={[{flex:1, width:'100%'}]}
      contentContainerStyle={[{width:'100%'}, MainStyles.flexCenter]}
      renderItem={this.renderMatch}
      data={matches}
      ListHeaderComponent={
        <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[MainStyles.flexCenterStart]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
            Upcoming coverage
            </Text>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
            Tap a match to open its scoreboard
            </Text>
          </View>
          <View style={[MainStyles.flexCenter, {marginTop:20}]}>
            <Image
            style={{height:60, width:60, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
            source={{uri:"https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj"}}
            />
            <Image
            style={{height:16, width:16, borderRadius:100, resizeMode:'contain', marginTop:-8,marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
            source={require('../appicon.png')}
            />
          </View>
        </View>
      }
      />
      </View>
    )
  }

  renderMatch = ({index, item}) => {
    var categoryColor = GLOBAL.colors.lightBlue
    var category = item.category

    if(category.includes('WTA')){ categoryColor = GLOBAL.colors.purple }
    if(category === 'Challenger'){ categoryColor = GLOBAL.colors.green }
    if(category.includes('ITF')){ categoryColor = GLOBAL.colors.darkBlue }

    return(
      <Pressable onPress={()=>this.selectMatch(item.id)} style={[{padding:10, borderRadius:10, backgroundColor:'#18181B', margin:10, width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {flexShrink:1}]}>
          <Text style={{color:categoryColor, fontSize:16, marginRight:10, fontFamily:'Averta-Bold'}}>
          {item.category}
          </Text>
          <Text style={{color:'white', fontSize:16, fontFamily:'Averta-Bold', flexShrink:1, marginRight:10}} numberOfLines={1}>
          {item.competitors[0].name} vs {item.competitors[1].name}
          </Text>
        </View>
        <Text style={{color:item.isLive ? GLOBAL.colors.pink : 'white', fontSize:16, fontFamily:'Averta-Regular'}}>
        {item.isLive ? "LIVE" : moment(item.start_time_timestamp, 'x').fromNow()}
        </Text>
      </Pressable>
    )
  }


  render = () => {
    var { loading, mode } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'transparent'}, MainStyles.flexCenter]}>
        {mode === 'selectMatch' ? null : this.renderScoreboard() }
      </View>
    )
  }
}

export default Item;
