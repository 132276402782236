import React, { Component } from 'react';
import { Grid, Cell, Navigation, Row, Col } from 'react-mdl';
import { Link, BrowserRouter as Router, Route } from 'react-router-dom';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'
import '@firebase/messaging'
import '@firebase/storage'
import "firebase/auth";

import exportFromJSON from 'export-from-json'

import { Hook, Console, Decode } from 'console-feed'

import { AnimateOnChange } from 'react-animation'

//Material
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';

var followers = [
  'hereforalol'
]

var moment = require('moment-timezone');
var randomstring = require("randomstring")

// var followers = ['hereforalol']

class Screen extends Component {


//Setup
constructor(props) {
  super(props);
  this.state = {
    viewport: {
      latitude: 37.785164,
      longitude: -100,
      zoom: 3.5,
      bearing: 0,
      pitch: 0
    },
    popupInfo: null,
    dataActive: [],
    dataInactive: [],
    dataTargeted: [],
    dataDates: [],
    questions: '',
    // authenticated: false,
    authenticated: false,
    activeIndex: 0,
    dateString: '',
    logs: [],
    password: '',
    defaultGroups: [],
    defaultGroupsData: {},
  };
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
}

async componentDidMount(){
  Hook(window.console, log => {
    this.setState(({ logs }) => ({ logs: [...logs, Decode(log)] }))
  })
  await this.initializeFirebase();
  //Setup auth listener
  firebase.auth().onAuthStateChanged(this.handleAuthentication);
}

handleAuthentication = (user) => {
  if (user) {
    // User is signed in.
    console.log('User signed in')
    this.setState({authenticated:true})
    // ...
  } else {
    // User is signed out.
    console.log('User not signed in')
    this.setState({authenticated:false})
    this.authenticate()
  }
}

async authenticate() {
  if(!this.state.authenticated){
    const email = 'lewis@download4pm.com'
    const password = prompt('Enter password')

    return firebase.auth().signInWithEmailAndPassword(email, password)
    .then(function(result){
      console.log('Result', result)
    })
    .catch(function(error) {
      // Handle Errors here.
      console.log("error", error)
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }
}

initializeFirebase = async () => {
  var config = {
    apiKey: "AIzaSyCdXGz5UJhZQjNpEz4FBtJCUbCahGHaJ4A",
    authDomain: "app-4pm.firebaseapp.com",
    databaseURL: "https://app-4pm.firebaseio.com",
    projectId: "app-4pm",
    storageBucket: "app-4pm.appspot.com",
    messagingSenderId: "450215977644",
    appId: "1:450215977644:web:4a9cb20f1afc42b214b509",
    measurementId: "G-YESKQ2TETN"
  };

  await app.initializeApp(config);
}

async testFunction(startPoint){
  var start = startPoint || '0'
  // console.log('Getting users, starting at', start)
  // await firebase.firestore()
  // .collection('users')
  // .orderBy('userId')
  // .startAfter(start)
  // .limit(20)
  // .get()
  // .then(async(querySnapshot)=>{
  //   var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  //   // var tokens = []
  //   await querySnapshot.forEach(async (doc) => {
  //     var data = doc.data()
  //     if(data.fcmToken){
  //       console.log('Added token', data.school, data.grade, doc.id)
  //       console.log(data.fcmToken)
  //       await firebase.firestore()
  //       .collection('schools').doc(data.school)
  //       .collection('grades').doc(data.grade)
  //       .collection('subscriptions').doc('anonymous')
  //       .set({
  //         tokens: firebase.firestore.FieldValue.arrayUnion(data.fcmToken)
  //       },{merge:true})
  //     }
  //   })
  //
  //   //Run again if lastVisible
  //   if(lastVisible !== startPoint){
  //     await this.testFunction(lastVisible)
  //   }
  // })
}

async cleanChats(){
  var chats = []

  await firebase.firestore()
  .collection('chats')
  // .where('members', 'array-contains', 'aStVMFZQdTRbBoJTjWnM0HwxSh32')
  .get()
  .then(querySnapshot => {
    return querySnapshot.forEach(async (doc) => {
      if(!doc.data().lastUpdated){
        var data = doc.data()
        data.id = doc.id
        chats.push(data)
      }
    })
  })

  console.log('Deleting', chats.length, 'chats')

  await Promise.all(chats.map(async chat => {
    await firebase.firestore()
    .collection('chats').doc(chat.id)
    .delete()
    console.log('Delete', chat.id)
  }))
}

async setChatTokens(){
  var chats = []

  await firebase.firestore()
  .collection('chats')
  .orderBy('lastMessage.sent', 'desc')
  .limit(100)
  .get()
  .then(querySnapshot => {
    return querySnapshot.forEach(async (doc) => {
      var chatData = doc.data()
      chatData.id = doc.id
      chats.push(chatData)
    })
  })

  await Promise.all(chats.map(async chat => {
    var tokens = []
    var members = chat.members || []
    await Promise.all(members.map(async member => {
      await firebase.firestore()
      .collection('users').doc(member)
      .get()
      .then(doc => {
        if(doc.exists){
          var userData = doc.data()
          if(userData.fcmToken){
            tokens.push(userData.fcmToken)
          }
        }
        return
      })
    }))

    console.log('Would set tokens', chat.id, tokens.length)

    //Set tokens for chat
    await firebase.firestore()
    .collection('chats').doc(chat.id)
    .collection('info').doc('tokens')
    .set({
      tokens
    },{merge:true})

  }))
}


async setCryptoIds(lastVisibleInput){
  var lastVisible = lastVisibleInput || "0"
  var limit = 10
  var moreAvailable = false
  //Get users
  var users = []
  await firebase.firestore()
  .collection('users')
  // .where('school', '==', 'hs-1')
  .orderBy('userId')
  .startAfter(lastVisible)
  .limit(limit)
  .get()
  .then(querySnapshot=>{
    lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]
    moreAvailable = querySnapshot.docs.length === limit
    return querySnapshot.forEach(async (doc) => {
      users.push(doc.data())
    })
  })

  //For each user, generate a cryptoID and check not already existing
  await Promise.all(users.map(async user => {
    var userData = user || {}

    if(userData.firstName){

      var userId = userData.userId || null
      var firstName = userData.firstName || null
      var bitmojiUrl = userData.bitmojiUrl || '(null)'
      var cryptoId = randomstring.generate(24)

      var userObj = {
        userId,
        firstName,
        bitmojiUrl,
        identifiers:{
          randomChat: cryptoId
        }
      }

      if(userId && firstName){
        await firebase.firestore()
        .collection('users').doc(userId)
        .set({
          identifiers:{
            randomChat: cryptoId
          }
        },{merge:true})

        await firebase.firestore()
        .collection('usersWeb').doc(userId)
        .set(userObj,{merge:true})
      }

      console.log('Added', userId)
    }
  }))

  if(moreAvailable){
    this.setCryptoIds(lastVisible)
  }

}

async generateChats(){
  const check = prompt('Confirm upload')
  if(check === 'confirm'){
    // var rooms = ['fortnite', 'memes', '💩 talk', 'politics', 'TikTok', 'YouTube', 'advice', 'among us']
    //
    // // var rooms = ['fortnite']
    // var usersRaw = []
    //
    // //Get users to add as dummy members of chats
    // await firebase.firestore()
    // .collection('chats').doc('nfPqCKXbbYiNiJlOO2FV')
    // .get()
    // .then(doc => {
    //   usersRaw = doc.data().members
    //   return
    // })
    //
    // await Promise.all(rooms.map(async chat => {
    //
    //   var usersShuffled = await this.shuffle([...usersRaw])
    //
    //   //Generate random number and splice the users
    //   var minUsers = 40
    //   var variableUsers = Math.floor(Math.random() * 80)
    //
    //   var totalUsers = minUsers + variableUsers
    //
    //   var users = usersShuffled.slice(0, totalUsers)
    //
    //   //Create room
    //   var chatObj = {
    //     bitmojis:{},
    //     createdAt: new Date(),
    //     lastUpdated: new Date(),
    //     members: [],
    //     name: chat,
    //     privacy: 'global',
    //     settings:{
    //       anonymous: false,
    //       bitmojiOnly: true,
    //     },
    //     typing:{},
    //   }
    //
    //   var chatId = null
    //
    //   await firebase.firestore()
    //   .collection('chats')
    //   .add(chatObj)
    //   .then(doc => {
    //     chatId = doc.id
    //     console.log('Chat added', chat)
    //     return
    //   })
    //
    //   //Add users as members
    //   await firebase.firestore()
    //   .collection('chats').doc(chatId)
    //   .set({
    //     members: firebase.firestore.FieldValue.arrayUnion(...users)
    //   },{merge:true})
    //
    //   //Mute the dummy users
    //   await firebase.firestore()
    //   .collection('chats').doc(chatId)
    //   .set({
    //     muted: firebase.firestore.FieldValue.arrayUnion(...users)
    //   },{merge:true})
    //
    // }))

    // await firebase.firestore()
    // .collection('chats')
    // .where('privacy', '==', 'global')
    // .get()
    // .then(querySnapshot => {
    //   return querySnapshot.forEach(async (doc) => {
    //     var chatId = doc.id
    //
    //     await firebase.firestore()
    //     .collection('chats').doc(chatId)
    //     .collection('info').doc('tokens')
    //     .set({
    //       tokens:[],
    //     },{merge:true})
    //   })
    // })
  }
}

//
async shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}


//
async addPollToChat(){
  const check = prompt('Confirm upload')
  if(check === 'confirm'){
    var chats = []

    await firebase.firestore()
    .collection('chats')
    // .where('privacy', '==', 'grade')
    .where('gradeChat', '==', true)
    // .where('school', '==', 'hs-1')
    .get()
    .then(async(querySnapshot)=>{
      await querySnapshot.forEach(async (doc) => {
        var chatData = doc.data()
        chatData.id = doc.id
        chats.push(chatData)
      })
    })

    var userId = 'VhQ695MtyYaP7HTO0dQZiqFGZAE3'
    var bitmojiUrl = 'https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/bitmojis%2Fbitmojiplaceholder.png?alt=media&token=9ce9ae92-d127-4c9b-b016-d5b439fb4b43'


    //Set into chat
    await Promise.all(chats.map(async chat => {

      // var question = (chat.grade === '2026' || chat.grade === '2025') ? 'pretended to be sick so I could miss school' : 'gotten a hickey'

      //Create poll object
      var poll = {
        sticker:{
          id:'thisOrThat',
          // options: ['lie to your crush','lie to your best friend'],
          options: ['be hairless but allergic to the sun 😷', 'be hairy and always sweating 🥵'],
          votes:{
            0:[],
            1:[],
          },
          totalVotes:0,
          bitmojis:{
            0:[],
            1:[],
          },
        },
        // sticker:{
        //   id:'neverHaveIEver',
        //   question,
        //   options: ['i have','never'],
        //   votes:{
        //     0:[],
        //     1:[],
        //   },
        //   totalVotes:0,
        //   bitmojis:{
        //     0:[],
        //     1:[],
        //   },
        // },
        text: null,
        systemMessage: false,
        seenBy:[],
        sent: new Date(),
        sentTimestamp: Date.now(),
        lastUpdated: new Date(),
        lastUpdatedTimestamp: Date.now(),
        user:{
          userId,
          bitmojiUrl,
          name: "grade gc",
          firstName: "grade gc",
          lastName: ""
        }
      }

      // console.log('Would post to chat', chat.id, chat.grade, chat.school, poll)

      return firebase.firestore()
      .collection('chats').doc(chat.id)
      .collection('messages')
      .add(poll)
      .then(doc => {
        console.log('Poll added', chat.grade, chat.school)
      })
    }))
  }
}


//Send post
async addPost(){
  const check = prompt('Confirm upload')
  if(check === 'confirm'){
    console.log('Adding post')

    //Get all schools where usercount is >= 10
    var grades = []

    await firebase.firestore()
    .collection('schools')
    // .where('id', '==', 'hs-1')
    // .where('userCount', '>=', 10)
    .where('timezone', '==', 'PT')
    .get()
    .then(async(querySnapshot)=>{
      return querySnapshot.forEach(async (doc) => {
        //For each school, get the active grades and push into array of {school, grade, timezone}
        var school = doc.id
        var timezone = doc.data().timezone
        var activeGrades = doc.data().activeGrades
        var name = doc.data().name

        if(activeGrades["2021"]){grades.push({school, grade:'2021', timezone, name})}
        if(activeGrades["2022"]){grades.push({school, grade:'2022', timezone, name})}
        if(activeGrades["2023"]){grades.push({school, grade:'2023', timezone, name})}
        if(activeGrades["2024"]){grades.push({school, grade:'2024', timezone, name})}
        if(activeGrades["2025"]){grades.push({school, grade:'2025', timezone, name})}
        if(activeGrades["2026"]){grades.push({school, grade:'2026', timezone, name})}
      })
    })

    var defaultExpiry = 48 * 60 * 60 * 1000 //48 hours
    var createdAt = new Date()
    var createdAtTimestamp = Date.now()
    var expiresAt = new Date(new Date().getTime() + defaultExpiry);
    var expiresAtTimestamp = Date.now() + (defaultExpiry)

    //Promise all
    await Promise.all(grades.map(async grade => {
      //Fetch the release date, post string and create the scheduledPost obj
      var scheduledFor = await this.generateReleaseTime(grade.timezone)
      var post = "i've got a massive crush on someone in my maths class but i've got no chance 😩"

      var postObj = {
        createdAt,
        createdAtTimestamp,
        expiresAt,
        expiresAtTimestamp,
        anonymous: true,
        category: 'anonymous',
        comments: 0,
        grade: grade.grade,
        imageUrl: null,
        location: null,
        locationName: null,
        reactions:{
          shocked:['test-1', 'test-2']
        },
        reactionsCount:2,
        school: grade.school,
        text: post,
        user:{
          firstName: 'tyler',
          lastName: 'baker',
          bitmojiUrl: 'https://sdk.bitmoji.com/render/panel/4eb70837-b416-4f6d-96e0-970c6885b305-AVVvUmpBaCYFx1iMg1HH52mBXa_XYA-v1.png?transparent=1&palette=1',
          userId: 'test-user-1'
        },
        notifyAll:true,
      }

      //Upload to scheduled posts
      console.log('Post', grade.name, grade.grade, scheduledFor, postObj)

      //Firebase
      // await firebase.firestore()
      // .collection('posts')
      // .add(postObj)

    }))

  }else{
    console.log('Aborted')
  }
}

async addPostSingleSchool(){
  var school = 'hs-3128'
  var timezone = 'PT'

  const check = prompt('Confirm upload')

  if(check === 'confirm'){
    console.log('Adding post')

    var grades = ['2021','2022','2023','2024']

    var defaultExpiry = 48 * 60 * 60 * 1000 //48 hours
    var createdAt = new Date()
    var createdAtTimestamp = Date.now()
    var expiresAt = new Date(new Date().getTime() + defaultExpiry);
    var expiresAtTimestamp = Date.now() + (defaultExpiry)

    //Promise all
    await Promise.all(grades.map(async grade => {
      //Fetch the release date, post string and create the scheduledPost obj
      var scheduledFor = await this.generateReleaseTime(timezone)
      var post = "i've got a massive crush on someone in my biology class with mr rasor but i've got no chance 😩"

      var postObj = {
        createdAt,
        createdAtTimestamp,
        expiresAt,
        expiresAtTimestamp,
        anonymous: true,
        category: 'anonymous',
        comments: 0,
        grade: grade,
        imageUrl: null,
        location: null,
        locationName: null,
        reactions:{
          love:['test-1', 'test-2'],
          shocked:['test-3', 'test-4', 'test-5']
        },
        reactionsCount:5,
        school,
        text: post,
        user:{
          firstName: 'tyler',
          lastName: 'baker',
          bitmojiUrl: 'https://sdk.bitmoji.com/render/panel/4eb70837-b416-4f6d-96e0-970c6885b305-AVVvUmpBaCYFx1iMg1HH52mBXa_XYA-v1.png?transparent=1&palette=1',
          userId: 'test-user-1'
        },
        notifyNone:true,
      }

      //Upload to scheduled posts
      console.log('Post', postObj)

      // //Firebase
      // await firebase.firestore()
      // .collection('posts')
      // .add(postObj)
    }))

  }else{
    console.log('Aborted')
  }
}

async generateReleaseTime(timezone){
  var releaseTime = null

  var timezoneString = null
  if(timezone === "test"){ timezoneString = "America/Los_Angeles" }
  if(timezone === "PT"){ timezoneString = "America/Los_Angeles" }
  if(timezone === "MT"){ timezoneString = "America/Denver" }
  if(timezone === "CT"){ timezoneString = "America/Chicago" }
  if(timezone === "ET"){ timezoneString = "America/New_York" }

  //Get current date and time for that timezone
  var hour = moment.tz(moment(), timezoneString).format('H')
  var date = moment.tz(moment(), timezoneString).format('YYYY-MM-DD')

  //Set release time 8:54PM
  var time = '20:54'
  // var time = '14:44'
  var releaseTimeString = date + " " + time

  var releaseTime = moment.tz(releaseTimeString, timezoneString).format()

  var releaseDate = new Date(releaseTime)

  return releaseDate
}


//Find accounts
async getAccounts(){
  const searchTerm = prompt('Enter a search term')

  console.log('Searching for accounts under', searchTerm)

  if(searchTerm && searchTerm !== ''){

    //Split accounts into array
    var accounts = []
    if(searchTerm.split(";").length > 1){
      accounts = searchTerm.split(";")
    }else{
      accounts = [searchTerm]
    }

    console.log('Starting, processing', searchTerm.length, 'search terms')

    var followers = []
    var endCursor = ''
    var i = 0

    //For each account
    for (let i = 0; i < accounts.length; i++) {
      var account = accounts[i]
      let responseData = await this.getAccountsAPI(account, '')
      followers = followers.concat(responseData.data)
    }

    console.log(followers.length, 'accounts found for', searchTerm)

    var followersData = []

    //Get the account names and followers
    for (let i = 0; i < followers.length; i++) {
      console.log('Getting follower', i)

      await setTimeout(async () => {
        let followerData = await this.getProfile(followers[i], i)
        followersData.push(followerData)
      }, 2000)
    }

    const saveCsv = require('save-csv')
    await saveCsv(followersData, {filename:searchTerm+'-'+(Date.now())+'.csv'})
  }
}

async getAccountsAPI(searchTerm, endCursorId){
  var resultData = {
    data: [],
    endCursor: false,
  }

  const axios = require("axios");

  await axios({
  "method":"GET",
  "url":"https://instagram30.p.rapidapi.com/rapi/search/"+searchTerm,
  "headers":{
  "content-type":"application/octet-stream",
  "x-rapidapi-host":"instagram30.p.rapidapi.com",
  "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
  "useQueryString":true
  }
  })
  .then((response)=>{
    console.log('Api response', response)
    var endCursor = response.data.has_more ? response.data.end_cursor : false
    var followers = []

    for (let i = 0; i < response.data.items.length; i++) {
      var follower = response.data.items[i].item
      followers.push(follower.username)
    }

    // console.log(followers.length, 'followers fetched', account)

    resultData = {
      data: followers,
      endCursor
    }

    return
  })
  .catch((error)=>{
    console.error(error)
  })


    // axios({
    // "method":"GET",
    // "url":"https://instagram30.p.rapidapi.com/rapi/search/washington",
    // "headers":{
    // "content-type":"application/octet-stream",
    // "x-rapidapi-host":"instagram30.p.rapidapi.com",
    // "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
    // "useQueryString":true
    // }
    // })
    // .then((response)=>{
    //   console.log(response)
    // })
    // .catch((error)=>{
    //   console.log(error)
    // })

  return resultData
}



//Find followers
async startIG(followersIncluded, followingsIncluded, fast){
  var followersRaw = []
  var profiles = []
  var snapchatAccounts = []
  const campus = prompt('Which uni / school is this?')
  const accountsRaw = prompt('Enter accounts, separated by a ;')

  if(campus && campus !== "" && accountsRaw && accountsRaw !== ''){
    //Split accounts into array
    var accounts = []
    if(accountsRaw.split(";").length > 1){
      accounts = accountsRaw.split(";")
    }else{
      accounts = [accountsRaw]
    }

    console.log('Starting, processing', accounts.length, 'accounts')

    //For each account
    for (let i = 0; i < accounts.length; i++) {
      var account = accounts[i]
      var followingsResponse = [];
      var followersResponse = [];

      if(followersIncluded){
        followersResponse = await this.getFollowers(account)
      }
      if(followingsIncluded){
        console.log('Getting followings', account)
        followingsResponse = await this.getFollowings(account)
      }
      followersRaw = followersRaw.concat(followersResponse).concat(followingsResponse)
    }

    console.log('All followers found', followersRaw.length)

    //Remove duplicates in the array
    var followers = []
    for (let i = 0; i < followersRaw.length; i++) {
      if(!followers.includes(followersRaw[i])){
        followers.push(followersRaw[i])
      }
    }

    console.log('Duplicates removed, new array length', followers.length)


    var followersData = []
    var snapchatUsernames = []

    // For each follower, get their profile
    // for (let i = 0; i < followers.length; i++) {
    // for (let i = 0; i < cvchs.length; i++) {
    //   let followerData = await this.getProfile(cvchs[i].username, i)
    //   if(followerData.bio !== null){
    //     followersData.push(followerData)
    //   }
    //   if(followerData.snapchatUsername.length > 0){
    //     snapchatUsernames = snapchatUsernames.concat(followerData.snapchatUsername)
    //   }
    // }

    //Split followers into groups of promises
    var splitArray = require('split-array');

    var numberOfFollowers = 60 //requests per group
    var rateLimit = 1 //seconds

    var followerGroups = splitArray(followers, numberOfFollowers);

    for (let i = 0; i < followerGroups.length; i++) {


      var followersInGroup = followerGroups[i]


      if(fast){
        // // Faster
        await Promise.all(followersInGroup.map(async follower => {
          let followerData = await this.getProfile(follower, fast)
          if(followerData.bio !== null){
            followersData.push(followerData)
          }
          if(followerData.snapchatUsername.length > 0){
            snapchatUsernames = snapchatUsernames.concat(followerData.snapchatUsername)
          }
        }))
      }else{
        // //Slower rate
        for (let j = 0; j < followersInGroup.length; j++) {
          var follower = followersInGroup[j]
          let followerData = await this.getProfile(follower, fast)
          if(followerData.bio !== null){
            followersData.push(followerData)
          }
          if(followerData.snapchatUsername.length > 0){
            snapchatUsernames = snapchatUsernames.concat(followerData.snapchatUsername)
          }
        }
      }


      //Wait if needed for limiter
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(rateLimit * 1000);

      console.log('Group',i, 'of', followerGroups.length, 'finished')
    }

    // await Promise.all(followers.map(async follower => {
    //   let followerData = await this.getProfile(follower)
    //   if(followerData.bio !== null){
    //     followersData.push(followerData)
    //   }
    //   if(followerData.snapchatUsername.length > 0){
    //     snapchatUsernames = snapchatUsernames.concat(followerData.snapchatUsername)
    //   }
    // }))

    console.log('Snapchat usernames', ((snapchatUsernames.length/followersData.length)*100)+"% of users", snapchatUsernames.length, snapchatUsernames)
    console.log('Bios finished', followersData.length)
    console.log('Snapchat data', snapchatUsernames)
    console.log('Followers data', followersData)

    // exportFromJSON({ snapchatUsernames, "snapchatUsernames", 'xls' })

    const fileName1 = 'snapchatUsernames-'+campus+'-'+(new Date())
    const fileName2 = 'followersData-'+campus+'-'+(new Date())
    const exportType = 'xls'

    var data1 = snapchatUsernames

    //Export result to csv file
    // // console.log('Exporting to csv, ', profiles.length, 'profiles found')
    const saveCsv = require('save-csv')

    // await saveCsv(snapchatUsernames, {filename:'snapchatUsernames-'+campus+'-'+(Date.now())+'.csv'})
    await saveCsv(followersData, {filename:campus+'-'+(Date.now())+'.csv'})
  }else{
    console.log('Cancelled')
  }

}

async getFollowers(account){
  console.log('Getting followers for account', account)

  var followers = []
  var endCursor = ''

  //Run loop until endCursor is false
  while (endCursor !== false){
    let responseData = await this.getFollowersApi(account, endCursor)
    followers = followers.concat(responseData.data)
    console.log(followers.length, "out of", responseData.totalFollowers, account, endCursor)
    endCursor = responseData.endCursor
  }

  console.log(followers.length, 'followers found for', account, followers)

  return followers

}

async getFollowersApi(account, endCursorId){
  var endCursor = (endCursorId && endCursorId !== '') ? "end_cursor=" + endCursorId + "&" : ""
  var username = "username=" + account

  var resultData = {
    data: [],
    endCursor: false,
  }

  const axios = require("axios");

  await axios({
  "method":"GET",
  "url":"https://instagram-data1.p.rapidapi.com/followers",
  "headers":{
  "content-type":"application/octet-stream",
  "x-rapidapi-host":"instagram-data1.p.rapidapi.com",
  "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
  "useQueryString":true
  },
  "params":{
    "end_cursor":endCursorId,
    "username":account
  }
  })
  .then((response)=>{
    // console.log(response)
    var endCursor = response.data.has_more ? response.data.end_cursor : false
    var totalFollowers = response.data.count
    var followers = []

    for (let i = 0; i < response.data.collector.length; i++) {
      var follower = response.data.collector[i]
      followers.push(follower.username)
    }

    // console.log(followers.length, 'followers fetched', account)

    resultData = {
      data: followers,
      endCursor,
      totalFollowers,
    }

    return
  })
  .catch(async (error)=>{
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('Type 1, data',error.response.data);
      console.log('Type 1, status',error.response.status);
      console.log('Type 1, headers',error.response.headers);

      //If we get an error here, is it worth waiting 5s then trying again?
      const action = prompt('Check the name and reenter (leave blank if ok)')

      if(action == '' || !action){
        let secondData = await this.getFollowersApi(account, endCursorId)
        resultData = secondData
      }else{
        let secondData = await this.getFollowersApi(action, null)
        resultData = secondData
      }
      // resultData = secondData

      return

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Type 2',error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Type 3', error.message);
    }
  })

  //Wait a second
  const delay = ms => new Promise(res => setTimeout(res, ms));
  await delay(2000);

  return resultData
}

async wait(ms){
   var start = new Date().getTime();
   var end = start;
   while(end < start + ms) {
     end = new Date().getTime();
  }
}

async getFollowings(account){
  console.log('Getting followings for account', account)

  var followers = []
  var endCursor = ''

  //Run loop until endCursor is false
  while (endCursor !== false){
    let responseData = await this.getFollowingsApi(account, endCursor)
    followers = followers.concat(responseData.data)
    console.log(followers.length, "out of", responseData.totalFollowers, account)
    endCursor = responseData.endCursor
  }

  console.log(followers.length, 'followers found for', account, followers)

  return followers

}

async getFollowingsApi(account, endCursorId){
  var endCursor = (endCursorId && endCursorId !== '') ? "end_cursor=" + endCursorId + "&" : ""
  var username = "username=" + account

  var resultData = {
    data: [],
    endCursor: false,
  }

  const axios = require("axios");

  await axios({
  "method":"GET",
  "url":"https://instagram-data1.p.rapidapi.com/followings",
  "headers":{
  "content-type":"application/octet-stream",
  "x-rapidapi-host":"instagram-data1.p.rapidapi.com",
  "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
  "useQueryString":true
  },
  "params":{
    "end_cursor":endCursorId,
    "username":account
  }
  })
  .then((response)=>{
    var endCursor = response.data.has_more ? response.data.end_cursor : false
    var totalFollowers = response.data.count
    var followers = []

    for (let i = 0; i < response.data.collector.length; i++) {
      var follower = response.data.collector[i]
      followers.push(follower.username)
    }

    // console.log(followers.length, 'followers fetched', account)

    resultData = {
      data: followers,
      endCursor,
      totalFollowers,
    }

    return
  })
  .catch(async(error)=>{
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('Type 1, data',error.response.data);
      console.log('Type 1, status',error.response.status);
      console.log('Type 1, headers',error.response.headers);

      //If we get an error here, is it worth waiting 5s then trying again?
      console.log('Pause to try again')
      //
      // const delay = ms => new Promise(res => setTimeout(res, ms));
      // await delay(10000);
      //
      // console.log('Trying again')
      //
      // let secondData = await this.getFollowingsApi(account, endCursorId)
      // resultData = secondData

      const action = prompt('Check the name and reenter (leave blank if ok)')

      if(action == '' || !action){
        let secondData = await this.getFollowersApi(account, endCursorId)
        resultData = secondData
      }else{
        let secondData = await this.getFollowersApi(action, null)
        resultData = secondData
      }

      return

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Type 2',error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Type 3', error.message);
    }
  })

  return resultData
}

async getProfile(account, fast){
  console.log('Fetching profile', account)
  var fullname = ''
  var bio = null
  var username = account
  var followersCount = 0

  const axios = require("axios");

  // await axios({
  // "method":"GET",
  // "url":"https://instagram30.p.rapidapi.com/rapi/user/"+account,
  // "headers":{
  // "content-type":"application/octet-stream",
  // "x-rapidapi-host":"instagram30.p.rapidapi.com",
  // "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
  // "useQueryString":true
  // },
  // "params":{
  //   "username":account
  // }
  // })
  // .then((response)=>{
  //   bio = response.data.biography;
  //   fullname = response.data.fullname;
  //   followersCount = response.data.followersCount;
  // })
  // .catch((error)=>{
  //   console.log(error)
  // })

  // await fetch("https://easy-instagram-service.p.rapidapi.com/username?random=x8n3nsj2&username="+account, {
  // 	"method": "GET",
  // 	"headers": {
  // 		"x-rapidapi-host": "easy-instagram-service.p.rapidapi.com",
  // 		"x-rapidapi-key": "ce4ada3272msha76e21177162314p142955jsnfff9737048a9"
  // 	}
  // })
  // .then(response => response.json())
  // .then(data => {
  //   bio = data.biography;
  //   fullname = data.full_name;
  //   followersCount = data.follower;
  //   console.log(data)
  // })
  // .catch(err => {
  // 	console.log(err);
  // });

  if(fast){
    //Paid but fast (sometimes unreliable)
    await axios({
    "method":"GET",
    "url":"https://easy-instagram-service.p.rapidapi.com/username",
    "headers":{
    "content-type":"application/octet-stream",
    "x-rapidapi-host":"easy-instagram-service.p.rapidapi.com",
    "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
    "useQueryString":true
    },
    "params":{
      "username":account
    }
    })
    .then((response)=>{
      bio = response.data.biography;
      fullname = response.data.full_name;
      followersCount = response.data.follower;
      console.log(response.data.biography)
    })
    .catch((error)=>{
      console.log(error)
    })
  }else{
    //Slow but free
    await axios({
    method: 'GET',
    url: 'https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/bulk_profile',
    params: {ig: account},
    headers: {
      'x-rapidapi-key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9',
      'x-rapidapi-host': 'instagram-bulk-profile-scrapper.p.rapidapi.com'
    }
    })
    .then((response)=>{
      bio = response.data[0].biography;
      fullname = response.data[0].full_name;
      followersCount = response.data[0].follower_count;
      console.log(response.data[0].biography)
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  // await axios({
  //   "method":"GET",
  //   "url":"https://instagram39.p.rapidapi.com/getProfile",
  //   "headers":{
  //   "content-type":"application/octet-stream",
  //   "x-rapidapi-host":"instagram39.p.rapidapi.com",
  //   "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
  //   "useQueryString":true
  //   },"params":{
  //   "username":account
  // }
  // })
  // .then((response)=>{
  //   // bio = response.data.biography;
  //   // fullname = response.data.full_name;
  //   // followersCount = response.data.edge_followed_by;
  //   console.log(response)
  // })
  // .catch((error)=>{
  //   console.log(error)
  // })

  // await axios({
  //   "method":"GET",
  //   "url":"https://instagram39.p.rapidapi.com/getProfile",
  //   "headers":{
  //   "content-type":"application/octet-stream",
  //   "x-rapidapi-host":"instagram39.p.rapidapi.com",
  //   "x-rapidapi-key":"ce4ada3272msha76e21177162314p142955jsnfff9737048a9",
  //   "useQueryString":true
  //   },"params":{
  //     "username":account
  //   }
  //   })
  //   .then((response)=>{
  //     console.log(response)
  //
  //     // bio = response.data.biography;
  //     // fullname = response.data.full_name;
  //     // followersCount = response.data.follower;
  //   })
  //   .catch((error)=>{
  //     console.log(error)
  // })

  // await fetch("https://instagram30.p.rapidapi.com/rapi/user/"+account+"?username="+account, {
  // 	"method": "GET",
  // 	"headers": {
  // 		"x-rapidapi-host": "instagram30.p.rapidapi.com",
  // 		"x-rapidapi-key": "ce4ada3272msha76e21177162314p142955jsnfff9737048a9"
  // 	}
  // })
  // .then(response => response.json())
  // .then(data => {
    // bio = data.biography;
    // fullname = data.fullname;
    // followersCount = data.followersCount;
  // })
  // .catch(err => {
  // 	console.log(err);
  // });

  //Try to isolate the snapchat handle if exists
  let snapchatUsername = await this.getSnapchatUsername(bio)

  //Return
  var dataReturn = {
    fullname, bio:JSON.stringify(bio), username, snapchatUsername, followersCount
  }
  //
  // //Wait a second
  // const delay = ms => new Promise(res => setTimeout(res, ms));
  // await delay(1000);

  return dataReturn

}

async getSnapchatUsername(bio){
  //Try to extract a snap handle from the bio if exists
  // var stringTest = "amos:simacho and join my crew!"
  // var string = stringTest.toLowerCase()
  var string = bio ? bio.toLowerCase() : ''

  var snapchatUsername = '';

  //Test to see if snapchat identifiers are found in bio
  if(bio !== ''){
    var cases = ['snap', 'amos', 'sc', '👻', 'snapchat', '👻snap', 's:']

    for (let i = 0; i < cases.length; i++) {
      var inString = string.includes(cases[i])
      if(inString){
        //Possible match found, process
        let parsedUsername = await this.handleSnapchatCase(string, cases[i], bio)
        if(parsedUsername !== null){
          snapchatUsername = parsedUsername
        }
      }
    }
  }

  return snapchatUsername
}

async handleSnapchatCase(string, substring, bio){
  //Test to see whether the username can be extracted
  //Consider special characters, spaces between the substring and username

  var cases = [
    substring,
    substring+" ",
    substring+":",
    substring+": ",
    substring+"/",
    substring+"/ ",
    substring+"//",
    substring+"// ",
    substring+"~",
    substring+"~ ",
    substring+"~~",
    substring+"~~ ",
    substring+" ~ ",
    substring+"://",
    substring+":// ",
    substring+":/",
    substring+":/ ",
    substring+" : ",
    substring+"•",
    substring+"• ",
    substring+" • ",
    substring+"|",
    substring+"| ",
    substring+" | ",
    substring+"-",
    substring+"- ",
    substring+" - ",
    substring+" -",
    substring+"@",
    substring+" @",
    substring+"@ ",
    substring+" @ ",
    substring+";",
    substring+"; ",
    substring+":@",
    substring+": @",
    substring+": @ ",
    substring+">",
    substring+" >",
    substring+"> ",
    substring+" > ",
    substring+". ",
    substring+" . ",
    substring+"-@",
    substring+" ~",
    substring+" :",
    substring+"//:",
  ]

  var snapchatUsername = null

  //For each case, test if it exists
  for (let i = 0; i < cases.length; i++) {
    var test = cases[i]
    var inString = string.includes(test)
    if(inString){
      //Match found
      //Split and check if first character is a space
      var splitString = string.split(test)
      if(splitString[1].charAt(0).match(/[a-z]/i)){
        //First letter is a character
        //Check whether the username is at the end of bio or in the middle
        if(splitString[1].split(" ").length > 1){
          //Middle of bio
          snapchatUsername = splitString[1].split(" ")[0]
        }else{
          //End of bio
          snapchatUsername = splitString[1]
        }

        //Check it doesn't match any common words with sc at the start
        if((test+snapchatUsername).includes('school') || (test+snapchatUsername).includes('science') || (test+snapchatUsername).includes('discussion') || (test+snapchatUsername).includes('discover') || (test+snapchatUsername).includes('scholar') || (test+snapchatUsername).includes('scribe') || (test+snapchatUsername).includes('scorpio')){
          //Reset
          snapchatUsername = null
        }

        if(snapchatUsername){
          console.info('Snapchat username found', snapchatUsername)
        }
      }
    }
  }

  // if(snapchatUsername){
  //   if(snapchatUsername.charAt(snapchatUsername.length - 1) == '"'){
  //     snapchatUsername = snapchatUsername.substring(0, snapchatUsername.length - 1);
  //   }
  // }

  // var dataResponse = {
  //   username: snapchatUsername,
  //   raw: JSON.stringify(bio)
  // }

  return snapchatUsername
}


//
async testTiktokAPI(){
  const axios = require("axios");

  var data = null
  var url = 'https://vm.tiktok.com/ZSqNVMNg/'

  await axios({
    method: 'GET',
    url: 'https://tiktok4.p.rapidapi.com/video/info',
    params: {video_url: url},
    headers: {
     'x-rapidapi-key': 'ce4ada3272msha76e21177162314p142955jsnfff9737048a9',
     'x-rapidapi-host': 'tiktok4.p.rapidapi.com'
    }
  })
  .then(async (response) => {
    console.log(response)
  	data = response.data ? response.data.itemStruct : null
    console.log(data)

  }).catch((error) => {
  	console.error(error);
  });
}

async uploadTiktokTest(){
  const fs = require('fs');
  // const download = require('download');

  var id = 'tiktoktestid'

  var cover = "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/08bdf3f0d95b47cb8ea7ec7c85b6921a_1575915341?x-expires=1605697200&x-signature=i8Mzd7%2Bi%2BZSjpL9MQ80kIJmspFE%3D"
  var dynamicCover = "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/5cf5c53bfac4409295ea9534f1d019cc_1575915341?x-expires=1605697200&x-signature=Belv0XTI%2FdVDE3W5ynRjjyOTieA%3D"

  // //Download the image from url
  // fs.writeFileSync('temp/cover.webp', await download(cover));
  //
  console.log('Donezo')
  //
  // //Upload to bucket
  // var folderRef = 'tiktok/'+id+'/dynamicCover.webp'
  // const reference = firebase.storage().ref(folderRef)
  //
  // //Upload to Storage
  // await reference.putFile(previewImage)
  //
  // //Get download link from Storage
  // const url = await storage()
  // .ref(folderRef)
  // .getDownloadURL();



  //Update the tiktok object
}

//Renders

  renderOptions(){
    return(
      <div>
        <div style={{marginBottom:40}}>
          <p style={{fontFamily:'Averta-Black', fontSize:60, color:'white', marginBottom:30}}>
          hey lewis
          </p>
          <p className="loading-font" style={{opacity:0.67, color:'white', fontFamily:'Averta-Bold'}}>what needs to be done</p>
        </div>

        <div style={{marginBottom:10}}>
          <Button
          // onClick={()=>this.setCryptoIds()}
          // onClick={()=>this.cleanChats()}
          // onClick={()=>this.testFunction()}
          // onClick={()=>this.addPollToChat()}
          // onClick={()=>this.testTiktokAPI()}
          // onClick={()=>this.uploadTiktokTest()}
          onClick={()=>this.setChatTokens()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Run function</Button>
        </div>

        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.addPollToChat()}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Add game to grade chat</Button>
        </div>

        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.addPostSingleSchool()}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Add posts for single school</Button>
        </div>

        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.getAccounts()}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Enter search term</Button>
        </div>
        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.startIG(true, false, false)}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Enter accounts (followers only)</Button>
        </div>
        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.startIG(false, true, false)}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Enter accounts (followings only)</Button>
        </div>
        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.startIG(true, true, false)}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Enter accounts (followers and followings)</Button>
        </div>

        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.startIG(true, true, true)}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Enter accounts (followers and followings) - FAST mode</Button>
        </div>

        <div style={{marginBottom:10}}>
          <Button
          onClick={()=>this.testFunction()}
          // onClick={()=>this.testFunction()}
          variant="contained" color="secondary" size="large" classes={{ root: 'button-pollgenerate', label: 'button-pollgenerate-label' }}>Test function</Button>
        </div>
      </div>
    )
  }

  renderConsole(){
    return(
      <Console
        logs={this.state.logs}
        variant="dark"
        filter={['log', 'error', 'info']}
        style={{backgroundColor:'red'}}
      />
    )
  }

  handleChange(event) {
    this.setState({password: event.target.value});
  }

  handleSubmit(event) {
    this.authenticateUser()
  }

  renderContent(){
    const styles = {
      root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    };

    if(!this.state.authenticated){
      return(
        <div className="vertical flexCenter width-100">
          <p className="loading-font">Authenticating...</p>
        </div>
      )
    }else{
      return(
        <div className="flexColumn vertical width-100" >
          <Cell col={12} className="vertical flexStart">
          {this.renderOptions()}
          <div style={{backgroundColor:'#0B041F', opacity:1, marginTop:100}}>
          {this.renderConsole()}
          </div>
          </Cell>
        </div>
      )

    }
  }

  render() {

    return(
      <div className="height">

        <Grid className="background-main flexStart" style={{marginVertical:50}}>

        {this.renderContent()}

        </Grid>

      </div>
    )
  }
}

export default Screen;
