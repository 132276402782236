import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { View, Text } from "react-native";

import badge from './appstorebadgedark.png';
import image1 from '../assets/images/1.png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import MainStyles from './mainstyles.js'
import GLOBAL from './global.js'

//Module
import ReactGA from 'react-ga';

import FormData from 'form-data';
import fs from 'fs';
import fetch from "node-fetch";

var ProgressBar = require('progress');
var moment = require('moment-timezone');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = async () => {
    console.log('Mount AI Gen')
    // await this.initializeFirebase()

    await this.createFineTune()
  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
      authDomain: "tennis-lwts.firebaseapp.com",
      projectId: "tennis-lwts",
      storageBucket: "tennis-lwts.appspot.com",
      messagingSenderId: "414782898270",
      appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
      measurementId: "G-D3SD6S9JJW"
    };

    await app.initializeApp(config);

    //Anon auth
    await firebase.auth().signInAnonymously()
    .then(() => {
      // Signed in..
      console.log('signed in')
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  createFineTune = async () => {

    console.log('Creating fine tune')

    const API_KEY = 'sd_ungouFJsJYFFMrXPjFY6HBGnbnGTkN';
    const DOMAIN = 'http://www.astria.ai';
    let formData = new FormData();


    formData.append('tune[title]', 'John Smith');
    formData.append('tune[branch]', 'fast');
    formData.append('tune[name]', 'person');

    // Load all JPGs from ./samples directory and append to FormData
    // let files = fs.readdirSync('./samples');
    // files.forEach(file => {
    //   formData.append('tune[images][]', fs.createReadStream(`./samples/${file}`), file);
    // });
    // formData.append('tune[callback]', 'https://optional-callback-url.com/to-your-service-when-ready');

    let options = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + API_KEY
        },
      body: formData,
      mode: 'no-cors',
    };

    fetch(DOMAIN + '/tunes', options)
    .then(r => r.json())
    .then(r => console.log(r));

    console.log('Fine tune creation done')
  }

  //Renders
  renderLogo = () => {
    return(
      <View style={[{width:'100%', paddingVertical:GLOBAL.padding}, MainStyles.flexCenter]}>
        <Text>
        Delicious
        </Text>
      </View>
    )
  }


  render = () => {

    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'#121212'}, MainStyles.flexCenter]}>
        <View style={{height:844, width:390, backgroundColor:'white', borderWidth:1, borderColor:GLOBAL.style.borderColor, padding:GLOBAL.padding}}>
          {this.renderLogo()}
        </View>
      </View>
    )
  }
}

export default Landing;
