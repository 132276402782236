import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import { AnimateOnChange } from 'react-animation'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, TextInput, ScrollView, SectionList, Dimensions } from "react-native";

import Bracket from './bracket.js'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';
const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

var players = require("./players.json");
var player_images = require("./playerimages.json");

var height = 120
var moment = require('moment-timezone');

var stringChangeTime = 8000

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      game:null,
    };
  }

  componentDidMount = async () => {
    this.setup()

    // this.openBracketGame({
    //   docId: "m4Xt9m30H6upCny01kFP",
    //   format: "Men's - Singles",
    //   logo: null,
    //   name: "BNP Paribas Open - ATP",
    //   rank: "-",
    //   score: "-",
    //   tournament_docId: "HaSK6t8A9E6aiiUTqxLL",
    // })
  }

  componentWillUnmount = async () => {

  }

  firestoreAPI = async (url_raw, params) => {
    var data = null

    var language = GLOBAL.language || 'en'

    var tz = await moment.tz.guess(true)
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    if(!params){
      params = {}
    }

    params.userId = 'test-user'
    params.streaming = true

    await axios
    .get(url, {params})
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  }

  setup = async () => {
    //Get the fantasy home
    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/fantasy_api"
    var data_r = await this.firestoreAPI(url, {mode:'home', versionNumber:200})
    var { data } = data_r
    var { sections } = data

    //Promise through sections and clean
    var sections_clean = []
    await Promise.all(sections.map(async (section, index) => {
      if(section.title.toLowerCase().includes("in play")){
        var events = section.data[0].data
        sections[index].data = events
        section.showRow = true
        section.title = 'Pick a bracket'
        sections_clean.push(section)
      }
    }))

    this.setState({loading:false, sections:sections_clean})
  }

  openBracketGame = (game) => {
    //Override the docId
    // game.docId = "jzbuWmPW0KI1yxUx6Tj3"

    this.getLeaderboard(game)

    // this.setState({game})
  }

  getLeaderboard = async (game) => {
    this.setState({loading:true, game})

    console.log(game)

    var { tournament_docId, docId, format } = game

    if(!format){
      format = "Men's - Singles"
    }

    var eliminated = {}
    if(tournament_docId && format){
      await firebase.firestore()
      .collection('tournaments').doc(tournament_docId)
      .collection('eliminated').doc(format)
      .get()
      .then(async doc => {
        if(doc.exists && doc.data()){
          eliminated = doc.data().eliminated || {}
        }
      })
    }

    await this.setState({eliminated})

    console.log(eliminated)

    //Get standings
    await firebase.firestore()
    .collection('fantasyLeagues').doc("xcpy9ntuKVgItJBJFAQH")
    .collection('brackets').doc(docId)
    .collection('summaries').doc("leaderboard")
    .get().then(this.handleLeaders)


  }

  handleLeaders = async (doc) => {
    var standings_raw = doc.data().standings || []

    var standings = []
    await Promise.all(standings_raw.map(async (standing, index) => {

      //Get their key picks
      var picks = standing.key_picks || {}

      var winner = await this.fetchPlayer(picks['W'])
      var finalist = await this.fetchPlayer(picks['F'])
      var sf1 = await this.fetchPlayer(picks['SF1'])
      var sf2 = await this.fetchPlayer(picks['SF2'])

      var key_picks = {
        winner, finalist, sf1, sf2
      }


      var playerObj = {
        rank:standing.ranking,
        score:standing.score.overall,
        percentage:standing.score.percentage,
        name:standing.username || "user_"+standing.userId.substring(0, 6),
        key_picks,
      }

      if(playerObj.score > 0){
        standings.push(playerObj)
      }

    }))

    console.log('Standings', standings.length, standings[0])

    this.setState({standings, loading:false})
  }

  fetchPlayer = async (id) => {
    var { eliminated } = this.state

    var player = players[id] || {}
    player.image = player_images[id] || null

    //Tidy name
    if(player.name){
      var name_split = player.name.split(", ")
      if(name_split.length > 1){
        player.name = name_split[1].charAt(0) + ". " + name_split[0]
      }
    }

    //Get flag
    var country_corrections = {
      ["GRE"]:'GRC',
      ["GER"]:'DEU',
      ["LAT"]:'LVA',
      ["CRO"]:'HRV',
      ["SUI"]:'CHE',
      ["BUL"]:'BGR',
      ['TPE']:'TWN',
      ['RSA']:'ZAF',
      ['DEN']:'DNK',
      ['CHI']:'CHL',
      ['POR']:'PRT',
      ['NED']:'NLD',
    }
    var country_code = country_corrections[player.country_code] || player.country_code
    var flag_code = await convertIso3Code(country_code)
    player.flag = flag_code ? flag_code.iso2 : null

    return player

  }

  //


  renderContent = () => {
    var { game, loading } = this.state
    return(
      <View style={[{flex:1}, MainStyles.flexStartCenter]}>
        {loading ? this.renderLoading() : game ? this.renderLeaderboard() : this.renderSelector()}
      </View>
    )
  }

  renderLoading = () => {
    return(
      <View style={[{flex:1}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color='black'/>
      </View>
    )
  }

  renderSelector = () => {
    return(
      <>
      {this.renderBracketChallenge()}
      </>
    )
  }

  renderBracketChallenge = () => {
    var { loading, sections } = this.state
    return(
      <SectionList
        ListHeaderComponent={this.renderFantasyHeader}
        sections={sections || []}
        style={{width:'100%', borderTopColor:GLOBAL.style.color_border, borderTopWidth:0}}
        contentContainerStyle={{paddingBottom:60}}
        keyExtractor={(item, index) => index}
        renderItem={this.renderGameRow}
        renderSectionHeader={({ section: { title, subtitle, hideSeparator, sectionTitle, hideTitle,  showLeaderboardAbove} }, index) => (this.renderTitle({title, subtitle, hideSeparator, sectionTitle, hideTitle, showLeaderboardAbove}))}
        stickySectionHeadersEnabled={false}
        numColumns={2}
        // onRefresh={()=>this.callAPI(true)}
        // refreshing={this.state.refreshing}
      />
    )

    // return
  }

  renderLeaderboard = () => {
    var { loading, standings } = this.state
    return(
      <>
      {this.renderLeaderboardItem({item:{}}, true)}
      <FlatList
        // ListHeaderComponent={this.renderFantasyHeader}
        data={standings || []}
        style={{width:'100%', borderTopColor:GLOBAL.style.color_border, borderTopWidth:0}}
        contentContainerStyle={{paddingBottom:60}}
        keyExtractor={(item, index) => index}
        renderItem={this.renderLeaderboardItem}
        // renderSectionHeader={({ section: { title, subtitle, hideSeparator, sectionTitle, hideTitle,  showLeaderboardAbove} }, index) => (this.renderTitle({title, subtitle, hideSeparator, sectionTitle, hideTitle, showLeaderboardAbove}))}
        // stickySectionHeadersEnabled={false}
        // numColumns={2}
        // onRefresh={()=>this.callAPI(true)}
        // refreshing={this.state.refreshing}
      />
      </>
    )

    // return
  }

  renderLeaderboardItem = ({item, index}, header) => {
    var textStyle = {color:header ? 'white' : '#121212', fontSize:14, fontFamily:header ? 'Roboto-Bold' : 'Roboto-Regular'}
    var isEven = index % 2 === 0
    var backgroundColor = header ? GLOBAL.style.color_accent_1 : isEven ? 'transparent' : 'rgba(0,0,0,0.05)'
    if(item){
      var key_picks = item.key_picks || {}
      return(
        <View style={[{width:'100%', padding:15, paddingVertical:header ? 7.5 : 15, backgroundColor}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
          <Text style={[textStyle, {width:70}]}>
          {header ? 'Rank' : item.rank}
          </Text>
          <Text style={[textStyle, {width:160}]}>
          {header ? 'Username' : item.name}
          </Text>
          <Text style={[textStyle, {width:50, marginLeft:10, marginRight:15}]}>
          {header ? 'Score' : item.score}
          </Text>
          {this.renderLeaderboardPlayer(key_picks.winner, textStyle, header, 'Winner')}
          {this.renderLeaderboardPlayer(key_picks.finalist, textStyle, header, 'Finalist')}
          {this.renderLeaderboardPlayer(key_picks.sf1, textStyle, header, 'SF1')}
          {this.renderLeaderboardPlayer(key_picks.sf2, textStyle, header, 'SF2')}
        </View>
      )
    }else{
      return null
    }
  }

  renderLeaderboardPlayer = (item, textStyle, header, headerString) => {
    var { eliminated } = this.state
    if(item || header){
      var eliminatedRound = item ? eliminated[item.id] : false
      var borderColor = eliminatedRound ? GLOBAL.colors.pink : 'rgba(0,0,0,0)'
      var flag = header ? null : item.flag
      var opacity = eliminatedRound ? 0.5 : 1
      return(
        <View style={[{flex:0.25, paddingHorizontal:7.5}, MainStyles.flexCenter]}>
          <View style={[{flex:1, borderWidth:0, borderColor, paddingVertical:10, borderRadius:2.5, opacity}, MainStyles.flexCenter, MainStyles.flexRow]}>
            {
              !flag ? null :
              <View style={{marginRight:7.5}}>
              <ReactCountryFlag
                countryCode={flag}
                svg
                style={{
                    width: 18,
                    height: 14,
                }}
                title="US"
              />
              </View>
            }
            <Text style={[textStyle]} numberOfLines={1}>
            {header ? headerString : item.name || 'No pick'}
            </Text>
          </View>
          {
            !eliminatedRound ? null :
            <View style={{borderRadius:2.5, backgroundColor:GLOBAL.colors.pink, paddingHorizontal:5, paddingVertical:2, position:'absolute', bottom:-10}}>
              <Text style={{fontFamily:'Roboto-Bold', color:'white', fontSize:10}}>
              Lost {eliminatedRound}
              </Text>
            </View>
          }
        </View>
      )
    }else{return null}
  }

  //

  renderGameRow = ({item, index, type, leaderboard_mode}) => {
    var { activeIndex } = this.state

    if(!item){
      return null
    }else if(item.type === 'username'){
      return null
    }else if(item.type === 'leaderboards' || item.mode){
      return null
    }else{
      return(
        <View key={item.key} style={[MainStyles.flexCenterStart, {width:'100%', flexWrap:'wrap', paddingHorizontal:15}]}>
          {
            Array.isArray(item) ?
            item.map((item2, index2) => this.renderBracketGame({item:item2, index:index2}))
            :
            this.renderBracketGame({item:item, index:index})
          }
        </View>
      )
    }
  }

  renderBracketGame = ({item, index}) => {
    var margin = index % 2 === 0 ? {paddingRight:5} : {paddingLeft:5}

    var img_size = 28

    var tag = item.tag || {}
    var featured_players = item.featured_players || [null, null, null]
    var title = item.title || item.name
    var subtitle = item.subtitle || "Fantasy"
    var status = item.status
    var backgroundColor = '#121212'
    var color = "white"

    var player_size = dimensions.width / 3.6

    var small_device = dimensions.width < 300

    var logo = item.logo

    var bg1 = backgroundColor
    var bg2 = 'rgba(0,0,0,0.2)'

    var borderRadius = 5

    var bg_image = item.backgroundImage || "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fpreview_bg.png?alt=media&token=a62f1239-4c1c-4420-aa03-1efd847c186d"

    return(
      <View key={index} style={[{width:'100%', maxWidth:400, marginBottom:10}, (true ? null : margin)]}>
        <Pressable onPress={()=>this.openBracketGame(item)} style={[{width:'100%', height:110, borderRadius, backgroundColor, padding:0, overflow:'hidden'}, MainStyles.flexBetweenCenter]}>

          {
            <Image style={{width:'100%', height:'100%', position:'absolute', opacity:0.75}} source={{uri:bg_image}}/>

          }

          <View style={[{width:'100%', flex:1}, MainStyles.flexStartEnd]}>
            {
              !item.tag ? null :
              <View style={{paddingHorizontal:10, paddingVertical:10, backgroundColor:GLOBAL.style.alternatingBackground, borderBottomRightRadius:0, borderBottomLeftRadius:5}}>
                <Image
                style={[{
                  height:img_size,
                  width:img_size * (tag.aspectRatio || 2)
                  }]}
                source={{uri:tag.url}}
                // resizeMode={FastImage.resizeMode.contain}
                // tintColor={tag.tintColor || null}
                />
              </View>
            }
          </View>

          <View style={[{marginBottom:10, width:'100%', paddingBottom:0, paddingHorizontal:10}, MainStyles.flexCenterStart]}>

            <Text style={{fontSize:small_device ? 14 : 14, color, fontFamily:'Averta-Bold', letterSpacing:0, textAlign:'left'}} numberOfLines={2}>
            {title}
            </Text>
            <Text style={{fontSize:small_device ? 12 : 14, color, fontFamily:'Averta-Regular', opacity:0.5, marginTop:2.5}}>
            {subtitle}
            </Text>
          </View>

        </Pressable>
      </View>
      )
  }

  renderSeparator = (disable) => {
    if(!disable){
      return(
        <View style={{width:dimensions.width, paddingHorizontal:0}}>
          <View style={{width:'100%', height:1, backgroundColor:GLOBAL.style.color_border, marginVertical:15, opacity:0.5}}/>
        </View>
      )
    }else{return null}
  }

  renderTitle = ({title, subtitle, hideSeparator, sectionTitle, hideTitle, showLeaderboardAbove}) => {
    var { infoMessage, points, activeIndex } = this.state
    if(!hideTitle){
      return(
        <>
        {showLeaderboardAbove ? this.renderFantasyLeaderboard(showLeaderboardAbove) : null}
        {this.renderSeparator(hideSeparator)}
        <View style={{width:'100%', marginBottom:7.5, paddingHorizontal:15}}>
          {
            !sectionTitle ? null :
            <Text style={{color:GLOBAL.style.color_font, fontSize:20, marginBottom:5, fontFamily:'Averta-Black', textAlign:'left', width:'100%', letterSpacing:1.5, textTransform:'uppercase', paddingHorizontal:0}}>
            {sectionTitle}
            </Text>
          }

          {
            !title ? null :
            <Text style={{color:GLOBAL.style.color_font, fontSize:14, fontFamily:'Averta-Bold', textAlign:'left', width:'100%', letterSpacing:1.5, textTransform:'uppercase', paddingHorizontal:0}}>
            {title}
            </Text>
          }

          {
            !subtitle ? null :
            <Text style={{color:GLOBAL.style.color_font, fontSize:14, fontFamily:'Averta-Regular', textAlign:'left', width:'100%', letterSpacing:0, marginTop:2.5, opacity:0.5, paddingHorizontal:0}}>
            {subtitle}
            </Text>
          }
        </View>
        </>
      )
    }else{return null}
  }

  //
  renderBracket = () => {
    var { game } = this.state
    return(
      <>
      <Bracket game={game}/>
      </>
    )
  }

  renderHeader = () => {
    var { game } = this.state
    // if(!game){
      return(
        <View style={{width:'100%'}}>
          <View style={[{width:'100%', backgroundColor:'#15015C', borderBottomColor:'rgba(255,255,255,0.1)', borderBottomWidth:0, paddingHorizontal:15, paddingVertical:15, marginTop:0}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>

          <View style={[MainStyles.flexCenter]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:40}}>
            TNNS
            </Text>
            <Text style={{color:GLOBAL.colors.volt, fontFamily:'Averta-Bold', fontSize:20, marginTop:-5}}>
            FANTASY
            </Text>
          </View>

          <View style={[MainStyles.flexCenterEnd, {marginLeft:0}]}>
            <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, opacity:0.5}}>
            JOIN THE GAME
            </Text>
            <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:20, marginTop:0}}>
            TNNSLIVE.COM
            </Text>
          </View>

          </View>
        </View>
      )
    // }else{return null}
  }

  render() {
    var { loading, tippers } = this.state
    return(
      <>
      {this.renderHeader()}
      <View style={{flex:1, width:'100%', backgroundColor:'#fff'}}>
        {this.renderContent()}
      </View>
      </>
    )
  }
}

export default Item;
