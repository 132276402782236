import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { View, Text, Pressable, Linking, Image, ActivityIndicator } from "react-native";

//Module
import ReactGA from 'react-ga';

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from './mainstyles.js'
import GLOBAL from './global.js'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id:this.props.match.params.id,
      format:this.props.match.params.format,
      options:[],
      name:null,
      url:"",
      loading:true,
    };
  }

  componentDidMount = async () => {
    var { id, format } = this.props.match.params

    //Get the draw
    var data = {}
    await this.initializeFirebase()

    await firebase.firestore()
    .collection('tournaments').doc(id)
    .get()
    .then(doc => {
      if(doc.data()){
        data = doc.data() || {}
      }
    })

    if(data){
      var draws = data.live_draws_tnns || []

      await Promise.all(draws.map(async (draw, index)=> {
        var accept = false
        if(format === 'ms' && draw.title === "Men's Singles"){accept = true}
        else if(format === 'md' && draw.title === "Men's Doubles"){accept = true}
        else if(format === 'ws' && draw.title === "Women's Singles"){accept = true}
        else if(format === 'wd' && draw.title === "Women's Doubles"){accept = true}

        if(accept){
          var url = draw.url
          this.setState({url, loading:false})
        }
      }))

    }
  }

  componentWillUnmount = async () => {

  }

  initializeFirebase = async () => {
    var config = {
      apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
      authDomain: "tennis-lwts.firebaseapp.com",
      projectId: "tennis-lwts",
      storageBucket: "tennis-lwts.appspot.com",
      messagingSenderId: "414782898270",
      appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
      measurementId: "G-D3SD6S9JJW"
    };

    await app.initializeApp(config);

    //Anon auth
    await firebase.auth().signInAnonymously()
    .then(() => {
      // Signed in..
      console.log('signed in')
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  setup = async () => {
    //Check which user it is and display their streaming tools
    var options = []
    var name = null
    var image = null

    var users = {
      ["camwilliams-ft8asd9"]:{
        options: [
          {title:'Delay Controls', url:'/streamingtool/camwilliams-ft8asd9/controls', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Match Selector', url:'/streamingtool/camwilliams-ft8asd9/selector', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Live Data Hub', url:'/streamingtool/camwilliams-ft8asd9/data'},
          {title:'Live Match Stats', url:'/streamingtool/camwilliams-ft8asd9/stats-al29skd10'},
          {title:'Live Score Ticker', url:'/streamingtool/camwilliams-ft8asd9/livescoreticker-kj28siud'},
          {title:'Live Score Panel', url:'/streamingtool/camwilliams-ft8asd9/livescorepanel'},
          {title:'Live Chat Vote', url:'/streamingtool/camwilliams-ft8asd9/livechatvote-mn92kda8d'},
          {title:'Match Scoreboard', url:'/streamingtool/camwilliams-ft8asd9/scoreboard'},
          {title:'Match Popularity', url:'/streamingtool/camwilliams-ft8asd9/popularity'},
          {title:'Live Win Predictor', url:'/streamingtool/camwilliams-ft8asd9/predictor'},
        ],
        name: 'Cam',
        image: "https://yt3.ggpht.com/ytc/AAUvwnhL8u3JpBzlsRcFYspmmO1OcFmlN-wun_veNctIgw=s176-c-k-c0x00ffffff-no-rj"
      },
      ["gametolove-las82kd"]:{
        options: [
          {title:'Live Scoreboard', url:'/streamingtool/gametolove-las82kd/livescoreboardalt-al923kdn2jd8'},
          // {title:'Live Scoreboard (OBS)', url:'/streamingtool/gametolove-las82kd/livescoreboard-kasid87w3b'},
          {title:'Live Scoreboard (Backup)', url:'/streamingtool/gametolove-las82kd/livescoreboardgs-01njd82nad2k'},
          {title:'Top Tippers', url:'/streamingtool/gametolove-las82kd/toptippers-akd82jad02'},
          {title:'Match Popularity', url:'/streamingtool/gametolove-las82kd/popularity'},
          {title:'Fantasy Brackets', url:'/streamingtool/gametolove-las82kd/brackets'},
          {title:'Fantasy Leaderboard', url:'/streamingtool/gametolove-las82kd/leaderboard'},
        ],
        name: 'JG & Ben',
        image: "https://yt3.ggpht.com/ytc/AAUvwnjwFNOi01eEXz_zi3Y5h1OsCYFoCRRwNn8EKGU6QQ=s176-c-k-c0x00ffffff-no-rj"
      },
      ["theslicetennis-a02ks9"]:{
        options: [
          {title:'Live Scoreboard', url:'/streamingtool/theslicetennis-a02ks9/livescoreboard'},
        ],
        name: 'Stephen',
        image: "https://yt3.ggpht.com/ytc/AKedOLQwVo7PjJGsiX29IEQSkvc8ekU2eQ0fQ8reZZ9ORQ=s176-c-k-c0x00ffffff-no-rj-mo"
      },
      ["tnns-a92ksd3"]:{
        options: [
          {title:'Match Selector', url:'/streamingtool/tnns-a92ksd3/selector', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Live Scoreboard', url:'/streamingtool/tnns-a92ksd3/youtube-ls9d2dkd20di0'},
          {title:'Chat', url:'/streamingtool/tnns-a92ksd3/chat'},
        ],
        name: 'TNNS',
        image: null
      },
      ["tennishead-lad92mks"]:{
        options: [
          {title:'Scores Widget', url:'/streamingtool/tennishead-lad92mks/scores', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
        ],
        name: 'Tennis Head',
        image: 'https://scontent.fakl2-1.fna.fbcdn.net/v/t1.6435-9/118763922_10158610455414851_5434854700315411904_n.png?_nc_cat=1&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=QLy8xsPfdIAAX-rleIZ&_nc_ht=scontent.fakl2-1.fna&oh=f50a97a607573a02ac77c57820621b46&oe=60FC15D0'
      },
      ["tennisbrothers-lad9s20ejds"]:{
        options: [
          {title:'Match Selector (Scoreboard)', url:'/streamingtool/tennisbrothers-lad9s20ejds/selector', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Match Selector (Key Match)', url:'/streamingtool/tennisbrothers-lad9s20ejds/selector-other', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Match Controls', url:'/streamingtool/tennisbrothers-lad9s20ejds/controls', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Rankings', url:'/streamingtool/tennisbrothers-lad9s20ejds/rankings'},
          {title:'Match Stats', url:'/streamingtool/tennisbrothers-lad9s20ejds/match-stats'},
          {title:'Match Scoreboard', url:'/streamingtool/tennisbrothers-lad9s20ejds/scoreboard'},
          {title:'Key Match Tracker', url:'/streamingtool/tennisbrothers-lad9s20ejds/keymatch'},
          {title:'TNNS Live Vote', url:'/streamingtool/tennisbrothers-lad9s20ejds/vote'},
          {title:'Subscriber Count', url:'/streamingtool/tennisbrothers-lad9s20ejds/subs'},
          // {title:'Subscriber Count', url:'/streamingtool/tennisbrothers-lad9s20ejds/subscribers'},
        ],
        name: 'Lucian & Felix',
        image: "https://yt3.ggpht.com/ytc/AAUvwni990XyTtWrvXAyk_6WE8_wCT0A9odiqTGftoulGw=s176-c-k-c0x00ffffff-no-rj"
      },
      ["tweenerhead-ka92nad652"]:{
        options: [
          {title:'Match Selector (Scoreboard)', url:'/streamingtool/tweenerhead-ka92nad652/selector', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Match Controls', url:'/streamingtool/tweenerhead-ka92nad652/controls', backgroundColor:GLOBAL.colors.volt, color:'rga(0,0,0,0.75)'},
          {title:'Match Stats', url:'/streamingtool/tweenerhead-ka92nad652/match-stats'},
          {title:'Match Scoreboard', url:'/streamingtool/tweenerhead-ka92nad652/scoreboard'},
        ],
        name: 'Philip',
        image: "https://yt3.ggpht.com/8rQG2cumqu7VBzPwO0_3OUnkdeMvrKNeXLsi4LoyQmuYKoKrmYm7GY80K8pY7EmmR8zVdvNnKw=s88-c-k-c0x00ffffff-no-rj"
      },
    }

    var user = users[this.state.user]

    options = user.options
    name = user.name
    image = user.image

    return this.setState({options, name, image})
  }


  //Renders
  renderOptions = () => {
    var { options } = this.state
    return(
      <View style={[{width:'100%', justifyContent:'center', alignItems:'center', height:'100%', flexDirection:'column'}]}>
      {this.renderTitle()}

      </View>
    )
  }

  renderOption = (item, index) => {
    return(
      <Pressable onPress={()=>Linking.openURL(item.url)} style={{padding:20, minWidth:200, backgroundColor:item.backgroundColor || '#2C1470', borderRadius:10, marginHorizontal:10, marginBottom:20}}>
        <Text style={{color:item.color || 'white', fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
        {item.title}
        </Text>
      </Pressable>
    )
  }

  renderTitle = () => {
    var { name, image } = this.state
    return(
      <View style={{marginBottom:40, justifyContent:'center', alignItems:'center'}}>
        {
          <Image
          style={{height:120, width:120, borderRadius:100, resizeMode:'cover', marginBottom:0, backgroundColor:'rgba(255,255,255,0.05)'}}
          source={image ? {uri:image} : require('./appicon.png')}
          />
        }
        {
          !image ? null :
          <Image
          style={{height:30, width:30, borderRadius:100, resizeMode:'contain', marginTop:-15,marginBottom:20, backgroundColor:'rgba(255,255,255,0.05)', borderColor:'#2C1470', borderWidth:0}}
          source={require('./appicon.png')}
          />
        }
        <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:42, textAlign:'center'}}>
        Hey {name}!
        </Text>
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'center', opacity:0.5, marginTop:5}}>
        Pick from one of your tools
        </Text>
      </View>
    )
  }


  render() {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'#121212', justifyContent:'flex-center', alignItems:'flex_center'}, MainStyles.flexCenter]}>
      {
        loading ? <ActivityIndicator size='small' color='white'/> :
        <iframe style={{flex:1, width:'100%', borderWidth:0}} src={this.state.url+"#view=fitH"} title="PDF Draw" height="100%" width="100%"></iframe>
      }
      </View>
    )
  }
}

export default Landing;
