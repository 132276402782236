import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { AnimateOnChange } from 'react-animation'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, TextInput } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var moment = require('moment-timezone');

var stringChangeTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tippers:[],
      loading:true,
    };
  }

  componentDidMount = async () => {
    // this.setup()
  }

  componentWillUnmount = async () => {

  }

  triggerSound = async (data) => {
    var id = data.id
    if(id){
      await firebase.firestore()
      .collection('streaming').doc(this.props.id)
      .set({
        sounds:{
          [id]:Date.now()
        }
      },{merge:true})
    }
    // var soundbite = new Audio(SerenaGrunt)
    // soundbite.play()
  }

  renderSound = ({index, item}) => {
    return(
      <Pressable onPress={()=>this.triggerSound(item)} style={[{width:400, marginVertical:10, height:50, borderRadius:5, backgroundColor:'#2C1470'}, MainStyles.flexCenter]}>
        <Text style={{fontSize:16, color:'white'}}>
        {item.title}
        </Text>
      </Pressable>
    )
  }


  render = () => {
    var { loading } = this.state

    var sounds = [
      {title:'Test 1 - Serena', id:'test'},
      {title:'Test 2 - Alert', id:'test2'},
    ]

    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
        <Text>
        </Text>
        <FlatList
        style={{flex:1, paddingBottom:50}}
        contentContainerStyle={{flex:1, width:'100%', paddingBottom:50}}
        renderItem={this.renderSound}
        data={sounds}
        ListHeaderComponent={
          <View style={[{width:600}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
            <View style={[MainStyles.flexCenterStart]}>
              <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:32, textAlign:'left', marginTop:40}}>
              Sounds
              </Text>
              <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:16, textAlign:'left', marginTop:10, marginBottom:20, opacity:0.5}}>
              Tap a sound to play it through the scoreboard
              </Text>
            </View>
          </View>
        }
        />
      </View>
    )
  }
}

export default Item;
