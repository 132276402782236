import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LandingPage from './landingpage';
import Streaming from './streaming'
import StreamingTool from './streamingtool'
import Draw from './draw'
import Betting from './betting'
import AIGenerator from './aigenerator'
import BettingSystem from './bettingsystem'

// import Privacy from './privacy';
// import Terms from './terms';
// import Download from './download';
// import Placeholder from './placeholder';
// import GetCrush from './getcrush';
// import Map from './map';
// import GeneratePolls from './generatepolls';
// import InvitedUser from './invitedUser'
// import JoinGroup from './joingroup.js'
// import JoinSchool from './joinschool.js'
// import Tennis from './tennis.js'

import RandomChat from './randomchat'
import IG from './ig';

import Polls from './polls';

import Invite from './invite';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`)
});


// const Main = () => (
//   <Switch>
//     <Route exact path="/" component={LandingPage} />
//
//     <Route path="/privacy" component={Privacy} />
//     <Route path="/getcrush" component={GetCrush} />
//     <Route path="/terms" component={Terms} />
//
//
//     <Route path="/MNd8EanBzevnLSoOvuFc" component={Map} />
//     <Route path="/VkP5U9zudUXfye05g27q" component={GeneratePolls} />
//
//     <Route path="/lwts2393" component={IG} />
//
//     <Route path="/polls/:user/:poll" component={Polls} />
//
//     <Route path="/letschat/:cryptoId" component={RandomChat} />
//     <Route path="/invite/:inviteId" component={InvitedUser} />
//     <Route path="/joinGroup/:chatId" component={JoinGroup} />
//     <Route path="/joinschool/:schoolUnlockId" component={JoinSchool} />
//
//     <Route path='/download' component={() => {
//          window.location.href = 'https://apps.apple.com/app/apple-store/id1488092059?pt=120714149&ct=Web%20Download&mt=8';
//          return null;
//     }}/>
//
//
//   </Switch>
// )

const Main = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route path="/betting" component={Betting} />
    <Route path="/aigenerator" component={AIGenerator} />
    <Route path="/betting-system/:id" component={BettingSystem} />
    <Route path="/draw/:id/:format" component={Draw} />
    <Route path="/streaming/:user" component={Streaming} />
    <Route path="/streamingtool/:user/:toolId" component={StreamingTool} />
  </Switch>
)

export default Main;
