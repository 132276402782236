import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { HideUntilLoaded } from 'react-animation'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


class Item extends Component {

  render() {

    var className = "sticker-image";
    var image = 'https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/gem-border.png?alt=media&token=4959773c-1279-4717-9f7f-6d2026bf3d07';
    if(this.props.image === 'appicon'){
      image = 'https://firebasestorage.googleapis.com/v0/b/app-4pm.appspot.com/o/appicon.png?alt=media&token=324ff722-4843-448c-ab2d-9adbadc2a082'
      className = 'sticker-image-appicon'
    }

    return(
      <div className="sticker-wrapper">

        <div className="width-100">
        <HideUntilLoaded
        animationIn="bounceIn"
        imageToLoad={image}
        >
        <img src={image} alt="Poll image" className={className}/>
        </HideUntilLoaded>

        </div>

        <div className="sticker-wrapper-inside">

          <p className="sticker-title">
          {this.props.line1}
          </p>

          <p className="sticker-subtitle">
          {this.props.line2}
          </p>

        </div>

      </div>


    )
  }
}

export default Item;
