import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Flags from 'country-flag-icons/react/3x2'
import { AnimateOnChange } from 'react-animation'
import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, FlatList, Dimensions, Animated, KeyboardAvoidingView, TextInput } from "react-native";

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

import Message from './tnns-message.js'

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';

var height = 120
var width = 1920
var spacing = 10
var spacing_main = 20
var fontSize = 40

var moment = require('moment-timezone');
const axios = require("axios");
var sr_api_key = "df3uy5dec79jvzrh93y76kfm"
var CircularJSON = require('circular-json')
var stringSimilarity = require('string-similarity');

var colors = {
  primary:'#17171D',
  secondary:'white',
  accent:'#60348C',
  score_bg:'#1B1B20',
  score_font:'white',
  bg_opacity:0.2,
  main_font:'white',
  bg_stats:'#17171D',
  logo:null,
}

var volt = '#B6FE00'

var backgroundColor = 'rgba(0,0,0,0.15)'
var borderColor = 'rgba(255,255,255,0.15)'

var docId = "htcBQ4ARm8K3bD3bWtPs"


//Wimbledon theme
// colors = {
//   primary:"white",
//   secondary:'#007945',
//   accent:"#60348C",
//   score_bg:'rgba(255,255,255,0.5)',
//   score_font:'#17171D',
//   bg_opacity:0.2,
//   main_font:'black',
//   bg_stats:'#E2E2E4',
//   logo:'#60348C',
// }
// borderColor = 'rgba(0,0,20,0.5)'
// backgroundColor = 'rgba(0,0,20,0.04)'


var title_style = {fontFamily:'Averta-Bold', color:colors.main_font, fontSize:24, letterSpacing:0, textTransform:'uppercase'}

var stringChangeTime = 8000

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

var updateTime = 8000

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messages:[],
      matchId:null,
      mounted:true,
    };
  }

  componentDidMount = async () => {
    console.log('mounted functions')
    // this.runFunctions()
    this.getLive_sr()
  }

  componentWillUnmount = async () => {
    this.setState({mounted:false})
  }

  runFunctions = async () => {
    this.runLive_sr()
  }

  //
  async runLive_sr(){
    var time_limit = Date.now() + (58 * 1000) //55s max time to start another update
    var time_lag = 4000 //1s per update
    var time_batches = Math.floor((60*1000)/time_lag) //Updates per 1min
    var throttle = require('promise-ratelimit')(time_lag)
    var update_array = Array(time_batches).fill('')

    return Promise.all(update_array.map(async (update_array_item, update_index) => {
      if(time_limit > Date.now()){
        try{
          await throttle().then(async function() {
            console.log('Updating SR live', update_index+1)
            return this.getLive_sr()
          });
        }catch(e){
          console.log('Error with SR live update', e)
        }
      }
    }))

    if(this.state.mounted){
      // this.runLive_sr()
    }
  }

  async getSportradar(url){
    var data = null
    await axios.get(url)
    .then(async (response) => {
      data = response.data
      return
    }).catch( async (error) => {
    	console.error('Sportradar Error');
      console.error('Url', url)
      console.error('Response', error.response.status, error.response.statusText, error.response.headers["x-mashery-error-code"])
    });

    return data
  }

  async getLive_sr(){
    // console.log('Updating live')
    //Get live matches
    var url = "https://api.sportradar.com/tennis/production/v3/en/schedules/live/summaries.json?api_key="+sr_api_key
    var data = await this.getSportradar(url)

    // var data = require('./sportradar_data/livematches.json')
    var matchesRaw = data ? (data.summaries || []) : []

    //Get the matches object
    var matches = await this.handleMatches_sr(matchesRaw, false, true)

    var live_atp = {}
    var live_wta = {}
    var live_challenger = {}
    var live_itf = {}
    var live_other = {}

    try{
      //Cycle through matches and don't include those which are
      for (const [key, value] of Object.entries(matches)) {

        var atp_match = value.season_name.includes('ATP') || ((value.season_name.includes('French Open') || value.season_name.includes('Wimbledon')) && value.season_name.includes('Men'))
        var wta_match = value.season_name.includes('WTA') || ((value.season_name.includes('French Open') || value.season_name.includes('Wimbledon')) && value.season_name.includes('Women'))
        var challenger_match = value.season_name.includes('Challenger')
        var itf_match = value.competition_name.includes('Itf')

        //Remove not_started
        var blockedStatuses = ["not_started", "suspended"]
        if(blockedStatuses.includes(value.match_status)){
          //Delete from object
          delete matches[key]
        }else{
          if(atp_match && !challenger_match){
            live_atp[value.id] = value
          }else if(wta_match){
            live_wta[value.id] = value
          }else if(challenger_match){
            live_challenger[value.id] = value
          }else if(itf_match){
            live_itf[value.id] = value
          }else{
            live_other[value.id] = value
          }
        }
      }

      //Update matches in Firestore helpers
      await firebase.firestore()
      .collection('summaries_sr').doc('live_atp')
      .set({
        matches:live_atp,
      })

      await firebase.firestore()
      .collection('summaries_sr').doc('live_wta')
      .set({
        matches:live_wta,
      })

      await firebase.firestore()
      .collection('summaries_sr').doc('live_challenger')
      .set({
        matches:live_challenger,
      })

      await firebase.firestore()
      .collection('summaries_sr').doc('live_itf')
      .set({
        matches:live_itf,
      })

      await firebase.firestore()
      .collection('summaries_sr').doc('live_other')
      .set({
        matches:live_other,
      })
    }catch(e){
      console.log('Error with live summaries', e)
    }

    return firebase.firestore()
    .collection('summaries_sr').doc('live')
    .set({
      matches,
    })
  }

  async handleMatches_sr(matches, disableH2H, updateIfLive){
    var matchesObject = {}

    await Promise.all(matches.map(async (match, index)=> {
      try{
        // if(index === 2){
          var matchObj = await this.createMatchObject_sr(match)

          //Isolate the statistics, set into dedicated doc and then remove from score object
          var match_stats = match.statistics || null
          var match_event = match.sport_event || null
          var match_info_string = ''

          //Check if match already exists in Firestore
          var existingMatch = await this.checkMatchExists_sr(match_event.id)

          //If match isLive and has come from getLive_sr, then update the match obj (prevent older data from summaries etc from updating the doc)
          var liveStatuses = ['match_about_to_start', 'live', '1st_set', '2nd_set', '3rd_set', '4th_set', '5th_set', 'interrupted']

          if(existingMatch){
            var docId = existingMatch.docId
            var matchObj_old = existingMatch.data

            //Set isLive
            matchObj.isLive = liveStatuses.includes(matchObj.match_status) || liveStatuses.includes(matchObj.status)

            //Attach the seeds
            if(matchObj_old.seeds){
              matchObj.seeds = matchObj_old.seeds
            }

            if(docId){
              //Attach the docId
              matchObj.docId = docId
            }

            //Player IDs for indexing
            try{
              if(!matchObj_old.player_ids){
                matchObj.player_ids = [matchObj.competitors[0].id, matchObj.competitors[1].id]
              }
            }catch(e){
              console.log('Error attaching player ids', e)
            }

            //Check if match chat should be activated
            try{
              matchObj.liveChat = await this.checkLiveChat(matchObj, matchObj_old)
            }catch(e){
              console.log('Error setting match chat', e)
            }

            //Commentary
            if(matchObj_old.live_commentary){
              matchObj.live_commentary = matchObj_old.live_commentary
            }

            //Attach the startedAt / finishedAt time
            if(matchObj_old.startedAt && matchObj.match_status !== 'interrupted'){
              matchObj.startedAt = matchObj_old.startedAt
            }

            if(matchObj.match_status === 'suspended'){
              matchObj.startedAt = null
            }

            if(matchObj_old.finishedAt){
              matchObj.finishedAt = matchObj_old.finishedAt
            }

            //Check if the match is missing a finishedAt time (handles matches without p2p coverage)
            if(!matchObj_old.finishedAt && matchObj.winner_id && matchObj_old.startedAt){
              matchObj.finishedAt = Date.now()
            }

            //Attach audio url
            if(matchObj_old.live_audio){
              matchObj.live_audio = matchObj_old.live_audio
            }

            //Attach highlights
            if(matchObj_old.highlights){
              matchObj.highlights = matchObj_old.highlights
            }

            if(matchObj_old.preview){
              matchObj.preview = matchObj_old.preview
            }

            //Attach the photos
            if(matchObj_old.competitor_photos){
              matchObj.competitor_photos = matchObj_old.competitor_photos
            }

            //Match time string
            if(matchObj_old.match_time_string){
              matchObj.match_time_string = matchObj_old.match_time_string
            }

            //Attach the tiebreak scores
            if(matchObj_old.tiebreak_scores){
              matchObj.tiebreak_scores = matchObj_old.tiebreak_scores
            }

            //Status correction
            if(matchObj.match_status === 'start_delayed'){
              matchObj.match_status = 'Start delayed'
            }

            //Reset the game_score && last_point_result if missing
            if(matchObj.game_state){
              if(!matchObj.game_state.home_score){
                matchObj.game_state.home_score = 0
              }
              if(!matchObj.game_state.away_score){
                matchObj.game_state.away_score = 0
              }
              if(!matchObj.game_state.last_point_result){
                matchObj.game_state.last_point_result = null
              }

              try{
                if(matchObj.isLive){

                  if(matchObj_old.coverage.game_by_game){
                    //Game by game coverage only
                    match_info_string = 'Game by Game Coverage'
                  }

                  if(matchObj.game_state){
                    if(matchObj.game_state.point_type){
                      //Special point
                      // if(matchObj.game_state.point_type === 'match'){ match_info_string = 'Match Point' }
                      // if(matchObj.game_state.point_type === 'set'){ match_info_string = 'Set Point' }
                      // if(matchObj.game_state.point_type === 'break'){ match_info_string = 'Break Point' }

                      //Get the leading player
                      // var leading_player = matchObj.game_state.home_score > matchObj.game_state.away_score ? 0 : (matchObj.game_state.home_score < matchObj.game_state.away_score) ? 1 : null
                      // if((leading_player === 0 || leading_player === 1) && match_info_string && match_info_string !== ''){
                      //   if(!matchObj.competitors[leading_player].name.includes("/")){
                      //     match_info_string = match_info_string + " " + (matchObj.competitors[leading_player].name).split(", ")[0]
                      //   }else{
                      //     match_info_string = match_info_string + " " + (matchObj.competitors[leading_player].abbreviation)
                      //   }
                      // }
                    }

                    //Check if first serve outcome available
                    if(matchObj.game_state.ball_status){
                      if(matchObj.game_state.ball_status === 'second_serve' || matchObj.game_state.ball_status === 'fault'){
                        if(match_info_string !== ''){
                          match_info_string = match_info_string + ' - ' + '2nd Serve'
                        }else{
                          match_info_string = '2nd Serve'
                        }
                      }
                    }

                    if(matchObj.game_state.last_point_result && (match_info_string === '' || !match_info_string)){
                      var last_points_accepted = ['ace', 'double_fault']

                      if(last_points_accepted.includes(matchObj.game_state.last_point_result) && !(matchObj.game_state.home_score === 0 && matchObj.game_state.away_score === 0)){

                        var stat_id_string = null

                        if(matchObj.game_state.last_point_result === 'ace'){
                          match_info_string = 'Ace'
                          stat_id_string = 'aces'
                        }

                        if(matchObj.game_state.last_point_result === 'double_fault'){
                          match_info_string = 'Double Fault'
                          stat_id_string = 'double_faults'
                        }

                        //Get the name of who's serving
                        var serving_player = matchObj.game_state.serving === 'home' ? 0 : (matchObj.game_state.serving === 'away' ? 1 : null)

                        // console.log('Serving action', match_info_string, stat_id_string, serving_player)

                        if((serving_player === 0 || serving_player === 1) && !matchObj.game_state.tie_break){
                          //Try to attach the number of aces / double faults
                          try{
                            if(match_stats && stat_id_string){
                              var last_point_stats = match_stats.totals.competitors[serving_player].statistics[stat_id_string]
                              // console.log('Stats', last_point_stats)
                              if(last_point_stats >= 0){
                                match_info_string = match_info_string + " #" + (last_point_stats === 0 ? 1 : last_point_stats)
                              }
                            }
                          }catch(e){
                            console.log('Error attaching # to string', e)
                          }

                          if(!matchObj.competitors[serving_player].name.includes("/")){
                            match_info_string = match_info_string + " by " + (matchObj.competitors[serving_player].name).split(", ")[0]
                          }else{
                            match_info_string = match_info_string + " by " + matchObj.competitors[serving_player].abbreviation
                          }
                        }
                      }
                    }
                  }

                }
              }catch(e){
                console.log('Error with game_state', e)
              }

            }else{
              if(matchObj_old.game_state){
                matchObj.game_state = {
                  home_score: 0,
                  away_score: 0,
                }
              }
            }

            //If play is interrupted, update the info string
            if(matchObj.match_status === 'interrupted'){
              match_info_string = 'Play has temporarily stopped'
            }

            //Set game_state string
            matchObj.game_state_string = match_info_string

            console.log('Match info string', match_info_string)

            //Correct the names
            try{
              matchObj.competitors[0].name = await this.correctName(matchObj.competitors[0].name)
              matchObj.competitors[1].name = await this.correctName(matchObj.competitors[1].name)
            }catch(e){
              console.log('Error with corrections to names', e)
            }

            //Match exists, check if need to update
            //Conditions for change: start time, start time confirmed, court, coverage (JSON), round, score , match status
            var changed_time = moment(matchObj_old.start_time.seconds, 'X').format('x') !== moment(matchObj.start_time).format('x')
            var changed_time_confirmed = matchObj_old.start_time_confirmed !== matchObj.start_time_confirmed
            var changed_court = matchObj_old.court !== matchObj.court
            var changed_coverage = CircularJSON.stringify(matchObj_old.coverage) !== CircularJSON.stringify(matchObj.coverage)
            var changed_round = matchObj_old.round !== matchObj.round
            // var changed_score = CircularJSON.stringify(matchObj_old.period_scores) !== CircularJSON.stringify(matchObj.period_scores)
            var changed_competitors = !matchObj_old.competitors
            var changed_home_score = matchObj_old.game_state && matchObj.game_state ? matchObj_old.game_state.home_score !== matchObj.game_state.home_score : true
            var changed_away_score = matchObj_old.game_state && matchObj.game_state ? matchObj_old.game_state.away_score !== matchObj.game_state.away_score : true
            var changed_chat = matchObj_old.liveChat !== matchObj.liveChat

            var changed_status = matchObj_old.status !== matchObj.status
            var changed_match_status = matchObj_old.match_status !== matchObj.match_status

            // var seeds_changed = CircularJSON.stringify(matchObj_old.seeds) !== CircularJSON.stringify(matchObj.seeds)
            var seeds_changed = false

            var requiresUpdate = changed_home_score || changed_away_score || changed_time || changed_time_confirmed || changed_court || changed_coverage || changed_round || changed_status || changed_match_status || changed_competitors || seeds_changed || changed_chat

            if((liveStatuses.includes(matchObj.status) || liveStatuses.includes(matchObj.match_status)) && requiresUpdate && !updateIfLive){
              //Match is live, requires update but didn't come from runLive, don't update
              requiresUpdate = false
            }

            // try{
            //   //If match isLive and matchChat is closed, open it
            //   if((liveStatuses.includes(matchObj.status) || liveStatuses.includes(matchObj.match_status)) && matchObj.live_chat){
            //     if(!matchObj.live_chat.open){
            //       //Open the chat
            //       await firebase.firestore()
            //       .collection('matches_sr').doc(docId)
            //       .set({
            //         live_chat:{
            //           open:true,
            //         }
            //       }, {merge:true})
            //     }
            //   }
            //
            //   //If match finished 10 mins ago and the chat is open, close it
            //   if(matchObj.finishedAt < (Date.now() - (10 * 60 * 1000)) && matchObj.live_chat){
            //     if(matchObj.live_chat.open){
            //       //Open the chat
            //       await firebase.firestore()
            //       .collection('matches_sr').doc(docId)
            //       .set({
            //         live_chat:{
            //           open:false,
            //         }
            //       }, {merge:true})
            //     }
            //   }
            // }catch(e){
            //   null
            // }

            if(requiresUpdate){

              await firebase.firestore()
              .collection('matches_sr').doc(docId)
              .set(matchObj, {merge:true})

              console.log('Match updated', matchObj.id)

              //Update stats
              if(match_stats){
              // if(match_stats && matchObj.match_status !== 'not_started' && matchObj.match_status !== 'match_about_to_start' && matchObj.match_status !== 'start_delayed'){
                try{
                  //Format the stats
                  var formattedStats = await this.formatStats(match_stats)

                  if(!formattedStats.stats_valid){
                    formattedStats = []
                  }

                  await firebase.firestore()
                  .collection('matches_sr').doc(docId)
                  .collection('info').doc('data')
                  .set({
                    statistics:formattedStats,
                  }, {merge:true})

                }catch(e){
                  console.error('Error with stats', e)
                }
              }
            }

          }else{
            //New match, requires setup
            //Add the players to the profile update queue
            var isDoubles = match_event.competitors[0].players || false
            var player_requests = []

            if(disableH2H){
              //Match is historic, disable notifications being sent in the new match
              matchObj.disableNotifications = true
            }

            if(isDoubles){
              //Doubles
              player_requests = [{id:match_event.competitors[0].players[0].id},{id:match_event.competitors[0].players[1].id},{id:match_event.competitors[1].players[0].id},{id:match_event.competitors[1].players[1].id},]

              try{
                matchObj[match_event.competitors[0].players[0].id] = true
                matchObj[match_event.competitors[0].players[1].id] = true
                matchObj[match_event.competitors[1].players[0].id] = true
                matchObj[match_event.competitors[1].players[1].id] = true
              }catch(e){
                console.log('')
              }
            }else{
              //Singles
              player_requests = [{id:match_event.competitors[0].id},{id:match_event.competitors[1].id}]

              try{
                matchObj[match_event.competitors[0].id] = true
                matchObj[match_event.competitors[1].id] = true
              }catch(e){
                console.log('')
              }
            }

            matchObj.createdAt = new Date()

            if(!disableH2H){

              //Ensure profile exists (or request if missing) and update player form
              var form_categories = ["ATP", "WTA"]
              // var form_needed = form_categories.includes(matchObj.category)
              var form_needed = true

              await this.checkProfilesExist(player_requests, form_needed)

            }else{
              // console.log('Not getting profiles, H2H disabled')
            }

            //Match doesn't exist, create
            var docId_new = null

            await firebase.firestore()
            .collection('matches_sr')
            .add(matchObj)
            .then(doc => {
              docId_new = doc.id
              return
            })

            console.log('Added match', matchObj.id)

            //Request the h2h from FCF
            // var fetchH2H = matchObj.category === 'ATP' || matchObj.category === 'WTA' || matchObj.category === 'Challenger' //TEMPORARY - only H2H for ATP and WTA matches
            var fetchH2H = true

            if(!disableH2H && fetchH2H){
              console.log('Fetching H2H', matchObj.category, matchObj.id)
              await firebase.firestore()
              .collection('requests_sr').doc('h2h')
              .set({
                h2h: firebase.firestore.FieldValue.arrayUnion({
                  docId:docId_new,
                  players: [match_event.competitors[0].id, match_event.competitors[1].id],
                })
              },{merge:true})
            }else{
              console.log('Not requesting H2H', matchObj.category, 'Was H2H disabled already?', disableH2H)
            }
          }

          //Delete unnecessary data from objects
          delete matchObj.group_ids
          delete matchObj.group_names
          delete matchObj.bracket_number
          delete matchObj.competition_id
          // delete matchObj.competition_name
          // delete matchObj.level
          delete matchObj.parent_id
          delete matchObj.court_capacity
          delete matchObj.court_id
          delete matchObj.coverage

          //Statuses to exclude from objects
          var blockedStatuses = ['cancelled']

          if(!blockedStatuses.includes(matchObj.status)){
            matchesObject[matchObj.id] = matchObj
          }

      }catch(e){
        console.error('Error with handle match', e)
      }
    }))

    return matchesObject
  }

  async formatStats(statsRaw, matchData, data_gs, isTotalStats){

    //Helpers
    var stats1 = statsRaw.totals.competitors[0].statistics
    var stats2 = statsRaw.totals.competitors[1].statistics

    //Checks
    var hasWinners = stats1.backhand_errors || stats1.backhand_errors === 0

    //Setup values
    var serveRating = {title:'Serve Rating', values:['', '']}
    var aces = {title:'Aces', values:[stats1.aces || 0, stats2.aces || 0]}
    var df = {title:'Double Faults', values:[stats1.double_faults || 0, stats2.double_faults || 0]}
    var serve1In = {title:'1st Serve %', values:['', '']}
    var serve1Won = {title:'1st Serve Points Won', values:['', '']}
    var serve2Won = {title:'2nd Serve Points Won', values:['', '']}
    var bpSaved = {title:'Break Points Saved', values:['', '']}
    var serveGamesWon = {title:'Service Games Won', values:[(stats1.service_games_won || 0), (stats2.service_games_won || 0)]}

    var returnRating = {title:'Return Rating', values:['', '']}
    var return1Won = {title:'1st Return Points Won', values:['', '']}
    var return2Won = {title:'2nd Return Points Won', values:['', '']}
    var bpWon = {title:'Break Points Won', values:['', '']}
    var returnGamesWon = {title:'Return Games Won', values:[(stats1.games_won || 0) - (stats1.service_games_won || 0), (stats2.games_won || 0) - (stats2.service_games_won || 0)]}

    var clutchRating = {title:'Pressure Point Rating', values:['', '']}
    // var maxPointsRow = {title:'Max Points In A Row', values:[stats1.max_points_in_a_row, stats2.max_points_in_a_row]}
    var servePoints = {title:'Service Points Won', values:['', '']}
    var returnPoints = {title:'Return Points Won', values:['', '']}
    var totalPoints = {title:'Total Points Won', values:[stats1.points_won || 0, stats2.points_won || 0]}

    // var maxGamesRow = {title:'Max Games In A Row', values:[stats1.max_games_in_a_row || '', stats2.max_games_in_a_row || '']}
    var serveGames = {title:'Service Games Won', values:[stats1.service_games_won || 0, stats2.service_games_won || 0]}
    var returnGames = {title:'Return Games Won', values:[(stats1.games_won || 0) - (stats1.service_games_won || 0), (stats2.games_won || 0) - (stats2.service_games_won || 0)]}
    var totalGames = {title:'Total Games Won', values:[stats1.games_won || 0, stats2.games_won || 0]}
    var tiebreakers = {title:'Tiebreakers Won', values:[stats1.tiebreaks_won || 0, stats2.tiebreaks_won || 0]}

    var rg_won = returnGames.values
    var sg_won = serveGames.values

    //Calculate required values
    var players = [0,1]
    await Promise.all(players.map(async i => {
      var iOther = i === 0 ? 1 : 0

      var stat = statsRaw.totals.competitors[i].statistics
      var statOther = statsRaw.totals.competitors[iOther].statistics

      //Helpers
      var allServePoints = stat.first_serve_successful + stat.second_serve_successful + stat.double_faults
      var allServePointsOther = statOther.first_serve_successful + statOther.second_serve_successful + statOther.double_faults

      //1st serve in
      var stat1 = await this.getPercentageString(stat.first_serve_successful, allServePoints)
      serve1In.values[i] = stat1

      //1st serve won
      var stat2 = await this.getPercentageString(stat.first_serve_points_won, stat.first_serve_successful)
      serve1Won.values[i] = stat2

      //2nd serve won
      var stat3 = await this.getPercentageString(stat.second_serve_points_won, (stat.second_serve_successful + (stat.double_faults || 0)))
      serve2Won.values[i] = stat3

      //bp saved
      var stat4 = await this.getPercentageString(statOther.total_breakpoints - statOther.breakpoints_won, statOther.total_breakpoints)
      bpSaved.values[i] = stat4

      //1st return won
      var stat5 = await this.getPercentageString(statOther.first_serve_successful - statOther.first_serve_points_won, statOther.first_serve_successful)
      return1Won.values[i] = stat5

      //2nd return won
      var stat6 = await this.getPercentageString(statOther.second_serve_successful - statOther.second_serve_points_won, statOther.second_serve_successful + (statOther.double_faults || 0))
      return2Won.values[i] = stat6

      //bp converted
      var stat7 = await this.getPercentageString(stat.breakpoints_won, stat.total_breakpoints)
      bpWon.values[i] = stat7

      //Pressure point rating (all break points)
      var stat8 = await this.getPercentageString((statOther.total_breakpoints - statOther.breakpoints_won)+stat.breakpoints_won, statOther.total_breakpoints + stat.total_breakpoints)
      clutchRating.values[i] = stat8

      //Serve points won
      var stat9 = await this.getPercentageString(stat.first_serve_points_won + stat.second_serve_points_won, allServePoints)
      servePoints.values[i] = stat9

      //Return points won
      var stat10 = await this.getPercentageString(allServePointsOther - (statOther.first_serve_points_won + statOther.second_serve_points_won), allServePointsOther)
      returnPoints.values[i] = stat10

      //Serve rating
      try{

        // Formula
        // Ace % - Double Faults % + 1st Serve % + 1st Serve Points Won % + 2nd Serve Points Won % + Break Points Saved % + Service Games Won %
        var serve_rating_player = ((stat.aces / allServePoints * 100) || 0) - ((stat.double_faults / allServePoints * 100) || 0) + ((stat.first_serve_successful / allServePoints * 100) || 0) + ((stat.first_serve_points_won / stat.first_serve_successful * 100) || 100) + ((stat.second_serve_points_won / stat.second_serve_successful * 100) || 100) + (((statOther.total_breakpoints - statOther.breakpoints_won) / statOther.total_breakpoints * 100) || 100)
        serveRating.values[i] = Math.round(serve_rating_player)


        //Formula
        //1st Serve Return Points Won % + 2nd Serve Return Points Won % + Break Points Converted % + Return Games Won %
        var returnGamesWon = ((rg_won[i] / (rg_won[i] + sg_won[iOther])) * 100) || 0
        var return_rating_player = (((statOther.first_serve_successful - statOther.first_serve_points_won) / statOther.first_serve_successful * 100) || 50) + (((statOther.second_serve_successful - statOther.second_serve_points_won) / statOther.second_serve_successful * 100) || 50) + ((stat.breakpoints_won / stat.total_breakpoints * 100 * 1.75) || 0) + returnGamesWon
        returnRating.values[i] = Math.round(return_rating_player)
      }catch(e){
        console.log('Error with serve & return rating', e)
      }
    }))


    //Setup competitors
    var competitor1 = JSON.parse(JSON.stringify(statsRaw.totals.competitors[0]))
    var competitor2 = JSON.parse(JSON.stringify(statsRaw.totals.competitors[1]))

    delete competitor1.statistics
    delete competitor2.statistics

    //Check if the stats are valid
    var stats_valid = ((stats1.points_won || 0) + (stats2.points_won || 0)) > 0

    var stats = {
      competitors:[competitor1, competitor2],
      stats_valid,
      stats:[
        {
          title:'Service',
          data:[serveRating, aces, df, serve1In, serve1Won, serve2Won, bpSaved, serveGamesWon]
        },
        {
          title:'Return',
          data:[returnRating, return1Won, return2Won, bpWon, returnGamesWon]
        },
        {
          title:'Points',
          data:[clutchRating, servePoints, returnPoints, totalPoints]
        },
        {
          title:'Games',
          data:[serveGames, returnGames, totalGames]
        },
      ]
    }

    //Check if we have GS stats and no SR winners
    if(!hasWinners && isTotalStats){
      //SR names
      // var p1_sr = ((matchData.competitors[0].name).split(", ")[0]).toLowerCase()
      // var p2_sr = ((matchData.competitors[1].name).split(", ")[0]).toLowerCase()

      var p1_sr = ((matchData.competitors[0].name).split(", ")[0]).toLowerCase()
      var p2_sr = ((matchData.competitors[1].name).split(", ")[0]).toLowerCase()

      //Check if matching GS match
      await Promise.all(data_gs.map(async match => {
        var p1_gs = (match.player[0].name).toLowerCase()
        var p2_gs = (match.player[1].name).toLowerCase()

        // if(p1_gs.includes(". ")){
        //   //Singles names
        //   p1_gs = p1_gs.split(". ")[1]
        //   p2_gs = p2_gs.split(". ")[1]
        // }

        //String compare
        var p1_test = await stringSimilarity.compareTwoStrings(p1_sr, p1_gs)
        var p2_test = await stringSimilarity.compareTwoStrings(p2_sr, p2_sr)

        var buffer = 0.5

        // if(p1_gs === p1_sr && p2_gs === p2_sr){
        if(p1_test >= buffer && p2_test >= buffer){
          //Match found
          // console.log('Match found --->')
          // console.log(p1_sr, p1_gs, p1_test)
          // console.log(p2_sr, p2_gs, p2_test)
          var hasValidStats = false
          try{
            hasValidStats = match.player[0].stats.period.type[2].stat[0].name === 'Winners'

            if(hasValidStats){
              // console.log('Adding GS stats', p1_sr, p2_sr, [ match.player[0].stats.period.type[2].stat[0].value || 0, match.player[1].stats.period.type[2].stat[0].value || 0 ], [ match.player[0].stats.period.type[2].stat[1].value || 0, match.player[1].stats.period.type[2].stat[1].value || 0 ])
              stats = {
                competitors:[competitor1, competitor2],
                stats_valid,
                stats:[
                  {
                    title:'Service',
                    data:[
                      {title:'Winners', values:[ match.player[0].stats.period.type[2].stat[0].value || 0, match.player[1].stats.period.type[2].stat[0].value || 0 ]},
                      {title:'Unforced Errors', values:[ match.player[0].stats.period.type[2].stat[1].value || 0, match.player[1].stats.period.type[2].stat[1].value || 0 ]},
                      serveRating, aces, df, serve1In, serve1Won, serve2Won, bpSaved, serveGamesWon
                    ]
                  },
                  {
                    title:'Return',
                    data:[returnRating, return1Won, return2Won, bpWon, returnGamesWon]
                  },
                  {
                    title:'Points',
                    data:[clutchRating, servePoints, returnPoints, totalPoints]
                  },
                  {
                    title:'Games',
                    data:[serveGames, returnGames, totalGames]
                  },
                ]
              }
            }
          }catch(e){
            console.log('')
          }
        }
      }))

    }

    if(hasWinners){
      //Calculate the total winners, errors and unforced errors
      var winners_total_1 = (stats1.backhand_winners||0) + (stats1.drop_shot_winners||0) + (stats1.forehand_winners||0) + (stats1.lob_winners||0) + (stats1.overhead_stroke_winners||0)
      var winners_total_2 = (stats2.backhand_winners||0) + (stats2.drop_shot_winners||0) + (stats2.forehand_winners||0) + (stats2.lob_winners||0) + (stats2.overhead_stroke_winners||0)

      var ue_total_1 = (stats1.backhand_unforced_errors||0) + (stats1.drop_shot_unforced_errors||0) + (stats1.forehand_unforced_errors||0) + (stats1.lob_unforced_errors||0) + (stats1.overhead_stroke_unforced_errors||0)
      var ue_total_2 = (stats2.backhand_unforced_errors||0) + (stats2.drop_shot_unforced_errors||0) + (stats2.forehand_unforced_errors||0) + (stats2.lob_unforced_errors||0) + (stats2.overhead_stroke_unforced_errors||0)

      //Setup values
      var winners_total = {title:'Winners', values:[winners_total_1, winners_total_2 || 0]}
      var ue_total = {title:'Unforced Errors', values:[ue_total_1, ue_total_2 || 0]}


      //Set object
      stats = {
        competitors:[competitor1, competitor2],
        stats_valid,
        stats:[
          {
            title:'Service',
            data:[serveRating, winners_total, ue_total, aces, df, serve1In, serve1Won, serve2Won, bpSaved, serveGamesWon]
          },
          {
            title:'Return',
            data:[returnRating, return1Won, return2Won, bpWon, returnGamesWon]
          },
          {
            title:'Winners',
            data:[
              {title:'Forehand', values:[ stats1.forehand_winners || 0, stats2.forehand_winners || 0]},
              {title:'Backhand', values:[ stats1.backhand_winners || 0, stats2.backhand_winners || 0]},
              {title:'Volleys', values:[ stats1.volley_winners || 0, stats2.volley_winners || 0]},
              {title:'Lobs', values:[ stats1.lob_winners || 0, stats2.lob_winners || 0]},
              {title:'Overheads', values:[ stats1.overhead_stroke_winners || 0, stats2.overhead_stroke_winners || 0]},
              {title:'Returns', values:[ stats1.return_winners || 0, stats2.return_winners || 0]},
            ]
          },
          {
            title:'Unforced Errors',
            data:[
              {title:'Forehand', values:[ stats1.forehand_unforced_errors || 0, stats2.forehand_unforced_errors || 0]},
              {title:'Backhand', values:[ stats1.backhand_unforced_errors || 0, stats2.backhand_unforced_errors || 0]},
              {title:'Volleys', values:[ stats1.volley_unforced_errors || 0, stats2.volley_unforced_errors || 0]},
              {title:'Lobs', values:[ stats1.lob_unforced_errors || 0, stats2.lob_unforced_errors || 0]},
              {title:'Overheads', values:[ stats1.overhead_stroke_unforced_errors || 0, stats2.overhead_stroke_unforced_errors || 0]},
            ]
          },
          {
            title:'Points',
            data:[clutchRating, servePoints, returnPoints, totalPoints]
          },
          {
            title:'Games',
            data:[serveGames, returnGames, totalGames]
          },
        ]
      }
    }

    return stats

  }

  async createMatchObject_sr(match, abbreviate){
    //Create the tidied match object
    var matchObj = match.sport_event_status || {}

    //Last Updated
    matchObj.lastUpdated = new Date()
    matchObj.lastUpdatedTimestamp = Date.now()

    var match_event = match.sport_event || {}
    var context = match_event.sport_event_context || {}
    var venue = match_event.venue || {}

    var match_groups = context.groups || []

    //Promise through the groups
    var group_ids = []
    var group_names = []

    await Promise.all(match_groups.map(async (group, index)=> {
      group_ids.push(group.id)
      group_names.push(group.name)
    }))

    //Attach the required court and tournament info from context
    matchObj.competition_name = context.competition.name || null
    matchObj.competition_id = context.competition.id || null

    matchObj.season_name = context.season.name || null
    matchObj.season_id = context.season.id || null

    matchObj.parent_id = context.competition.parent_id || null

    matchObj.group_ids = group_ids || null
    matchObj.group_names = group_names || null

    matchObj.best_of = context.mode ? context.mode.best_of || null : null
    matchObj.season = context.season ? context.season.year || null : null

    matchObj.order = context.stage ? context.stage.order || null : null

    matchObj.id = match_event.id || null

    matchObj.start_time = new Date(match_event.start_time) || null
    matchObj.start_time_confirmed = match_event.start_time_confirmed || null
    matchObj.start_time_timestamp = moment(matchObj.start_time).format('x')

    matchObj.category = context.category.name || null
    matchObj.type = context.competition.type || null
    matchObj.gender = context.competition.gender || null
    matchObj.level = context.competition.level || null

    //If match has a resume time, update the start time
    try{
      if(match_event.resume_time){

        //First add the original schedule to the object
        matchObj.original_time_timestamp = matchObj.start_time_timestamp

        matchObj.resume_time = new Date(match_event.resume_time)
        matchObj.resume_time_timestamp = moment(matchObj.resume_time).format('x')

        matchObj.start_time = new Date(match_event.resume_time) || null
        matchObj.start_time_timestamp = moment(matchObj.resume_time).format('x')

        //If match is currently suspended, show a To Be Finished status
        if(matchObj.status === 'suspended'){
          matchObj.status_string = 'To Be Finished'
        }
      }
    }catch(e){
      console.log('')
    }

    matchObj.mode = match_event.mode || null

    matchObj.coverage = match_event.coverage.sport_event_properties || null

    try{
      if(context.round){
        if(context.round.name){
          var round = await this.cleanRoundName(context.round.name, abbreviate)
          matchObj.round = round
        }else{
          matchObj.round = null
        }
      }else{
        matchObj.round = null
      }
    }catch(e){
      console.log('')
    }


    matchObj.court_name = venue.name || null
    matchObj.court_id = venue.id || null
    matchObj.court_capacity = venue.capacity || null
    matchObj.competitors = match_event.competitors || []

    //Correct the country codes
    try{
      matchObj.competitors[0].country_code = await this.correctCountryCode(matchObj.competitors[0].country_code)
      matchObj.competitors[1].country_code = await this.correctCountryCode(matchObj.competitors[1].country_code)
    }catch(e){
      console.log('')
    }

    if(abbreviate){
      delete matchObj.court_id
      delete matchObj.start_time_confirmed
      delete matchObj.away_score
      delete matchObj.home_score
      delete matchObj.season_name
      delete matchObj.lastUpdatedTimestamp
      delete matchObj.coverage
      delete matchObj.order
      delete matchObj.season
      delete matchObj.lastUpdated
      delete matchObj.court_capacity
    }

    return matchObj
  }

  async checkMatchExists_sr(id){
    var existingMatch = null

    await firebase.firestore()
    .collection('matches_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        //Set into an object
        existingMatch = {}
        await querySnapshot.forEach(async (doc) => {
          existingMatch.docId = doc.id
          existingMatch.data = doc.data()
        })
      }
      return
    })

    return existingMatch
  }

  async getPercentageString (top, bottom){
    if(bottom === 0){
      return "0%" + " (" +top+"/"+bottom+")"
    }else{
      return Math.round(top / bottom * 100) + "%" + " (" +top+"/"+bottom+")"
    }

  }

  async checkProfilesExist(profiles, formNeeded){
    //For each profile, check it exists in Firestore, else add to request
    var player_requests = []
    var form_requests = []

    await Promise.all(profiles.map(async (profile, index) => {
      var player_exists = false

      await firebase.firestore()
      .collection('players_sr')
      .where('id', '==', profile.id)
      .get()
      .then(async (querySnapshot) => {
        if(querySnapshot.size > 0){
          await querySnapshot.forEach(async (doc) => {
            player_exists = true
          })
        }
        return
      })

      if(!player_exists){
        player_requests.push(profile)
      }

      if(formNeeded){
        form_requests.push(profile)
      }
    }))

    if(player_requests.length > 0){
      await firebase.firestore()
      .collection('requests_sr').doc('profiles')
      .set({
        profiles: firebase.firestore.FieldValue.arrayUnion(...player_requests)
      },{merge:true})
    }

    if(form_requests.length > 0){
      await firebase.firestore()
      .collection('requests_sr').doc('form')
      .set({
        profiles: firebase.firestore.FieldValue.arrayUnion(...form_requests)
      },{merge:true})
    }
  }

  async checkPlayerExists_sr(id, forceUpdate){
    var docId = null
    var lastUpdated = 0

    //Check in firestore
    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          docId = doc.id
          lastUpdated = doc.data().lastUpdatedTimestamp
        })
      }
      return
    })

    //If missing or the profile is stale (older than a week old), get profile from SR and create profileObj
    var buffer = Date.now() - ( 7 * 24 * 60 * 60 * 1000 )

    if(!docId || (lastUpdated < buffer)){
      //Get profile data
      var url = "https://api.sportradar.com/tennis/production/v3/en/competitors/"+id+"/profile.json?api_key=df3uy5dec79jvzrh93y76kfm"
      var data = await this.getSportradar(url)

      if(data){
        data.id = data.competitor.id
        data.name = data.competitor.name
        data.country = data.competitor.country
        data.abbreviation = data.competitor.abbreviation
        data.gender = data.competitor.gender

        delete data.competitor
        delete data.competitions_played

        //Try to get wikipedia profile
        var name_url = data.name.split(', ')[1] + " " + data.name.split(', ')[0]
        var playerUrl = "https://en.wikipedia.org/wiki/"+name_url
        var fetchedProfile = await this.scrapeProfile(playerUrl)
        if(fetchedProfile){
          data.info_wikipedia = fetchedProfile
        }else{
          data.info_wikipedia = {
            failed: true,
            lastUpdated: Date.now(),
            url: null,
          }
        }

        data.lastUpdatedTimestamp = Date.now()

        var dataComplete = data.id && data.name && data.country && data.abbreviation && data.gender

        if(dataComplete){
          if(!docId){
            //Add new player
            await firebase.firestore()
            .collection('players_sr')
            .add(data)
            .then(doc => {
              docId = doc.id
              return
            })
            console.log('Profile added for', data.name)
          }else{
            //Update existing
            await firebase.firestore()
            .collection('players_sr').doc(docId)
            .set(data, {merge:true})
            console.log('Profile updated for', data.name)
          }
        }
      }
    }

    return docId
  }

  async getPlayerProfile_sr(id){
    var data = null

    await firebase.firestore()
    .collection('players_sr')
    .where('id', '==', id)
    .get()
    .then(async (querySnapshot) => {
      if(querySnapshot.size > 0){
        await querySnapshot.forEach(async (doc) => {
          if(doc.exists){
            data = doc.data()
          }
        })
      }
      return
    })

    return data
  }

  async correctName(name){
    var names = {
      ["Bianca Vanessa Andreescu"]: "Bianca Andreescu",
      ["Andreescu, Bianca Vanessa"]: "Andreescu, Bianca",
      ["Ekaterina (1996) Makarova"]: "Ekaterina Makarova",
      ["Makarova, Ekaterina (1996)"]: "Makarova, Ekaterina",
      ["(1996) Makarova, Ekaterina"]: "Makarova, Ekaterina",
    }

    var name_clean = names[name] || name

    if(names[name]){
      console.log('Name corrected', name, name_clean)
    }

    return name_clean
  }

  async cleanRoundName (roundRaw, abbreviate){
    var roundSplit = ((roundRaw.split("_")).join(" "))
    var round = await this.capitalize(roundSplit)

    if(abbreviate){
      if(roundRaw === 'round_of_128'){round = 'R128'}
      if(roundRaw === 'round_of_64'){round = 'R64'}
      if(roundRaw === 'round_of_32'){round = 'R32'}
      if(roundRaw === 'round_of_16'){round = 'R16'}
      if(roundRaw === 'quarterfinal'){round = 'QF'}
      if(roundRaw === 'semifinal'){round = 'SF'}
      if(roundRaw === 'final'){round = 'F'}
    }

    return round
  }

  async correctCountryCode (country){
    //Try to correct the country code
    var CountryQuery = require('country-query')
    var code = country || ''

    if(country){
      //Search using the code structure used by Sportradar
      var country_1 = CountryQuery.findByCca3(country)
      if(country_1){
        //Get the IOC code
        code = country_1.cioc
      }
    }

    return code
  }

  async scrapeProfile (urlScrape){
    console.log('')
  }

  async capitalize (s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  //



  render() {
    const { message, mode } = this.state;

    // return this.renderCustom()
    // return this.renderNormal()

    try{
      return(
        <View style={[{flex:1, backgroundColor:"white"}, MainStyles.flexCenter]}>
          <Text style={{color:"black"}}>
          Running Functions
          </Text>
        </View>
      )
    }catch(e){
      console.log(e)
      return null
    }
  }
}

export default Item;
