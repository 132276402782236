import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, Dimensions, Switch, Animated } from "react-native";

//Helpers
import MainStyles from '../mainstyles.js'
import GLOBAL from '../global.js'

//Module
import ReactGA from 'react-ga';
var CircularJSON = require('circular-json')
const axios = require("axios");
var height = 100

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var match_width = 260

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match:{},
      style:{},
      loading: true,
      activeIndex:0,
      matches: [],
      matches_ticker: [],
      matches_ticker_divs: [],
      changingMatches:true,

      score_opacity: new Animated.Value(1),

      //Show settings
      show_atp:true,
      show_wta:true,
      show_live:true,
      show_completed:true,
      show_singles:true,
      show_doubles:false,

      firstSet:true,

      tournaments:{},
    };
  }

  componentDidMount = async () => {
    // await this.getTournaments()
    await this.setup_camLiveScores()
  }

  componentWillUnmount = async () => {

  }

  //Functions
  getTournaments = async () => {
    var tournaments = {}
    var tournaments_raw = []

    await firebase.firestore()
    .collection('summaries_sr').doc('tournaments_active_tour')
    .get()
    .then(async (doc) => {
      if(doc.exists()){
        tournaments_raw = doc.data().tournaments || []
      }
      return
    })


  }

  getData = async (url_raw) => {
    var data = null

    var language = 'en'

    var tz = 'Pacific/Sydney'
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    await axios
    .get(url)
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  }

  setup_camLiveScores_old = async () => {
    var { show_atp, show_wta, show_live, show_completed, show_singles, show_doubles } = this.state

    clearTimeout(this.updateData)

    console.log('Updating scores')

    var refreshTime = 2 * 60 * 1000 //2 mins

    //Arrays
    var live_raw = []
    var completed_raw = []

    //Get live and completed for ATP and WTA
    var live_atp = []
    var live_wta = []
    var completed_atp = []
    var completed_wta = []
    var promises_api = [0,1,2,3]

    await Promise.all(promises_api.map(async promise_api => {
      var url = null
      if(promise_api === 0){
        live_atp = await this.getData("https://us-central1-tennis-lwts.cloudfunctions.net/api_web_getTourLiveMatches?=atp?=visual")
      }
    }))

    //Setup the allowed formats and tour array
    var allowed_tours = []
    var allowed_formats = []

    if(show_atp){allowed_tours.push('ATP')}
    if(show_wta){allowed_tours.push('WTA')}
    if(show_singles){allowed_formats.push('singles')}
    if(show_doubles){allowed_formats.push('doubles')}

    var live = []
    var completed = []

    //Promise through Live, filter tours and formats
    await Promise.all(live_raw.map(async match => {
      if(allowed_tours.includes(match.category) && allowed_formats.includes(match.type) && match.round && !match.round.toLowerCase().includes('qualif')){
        live.push(match)
      }
      if(match.category === 'Davis Cup'){
        live.push(match)
      }
    }))

    //Promise through Completed, filter tours and formats and any matches finished more than X hours ago
    var completed_limit = 24 * 60 * 60 * 1000 //Within last 24 hours
    await Promise.all(completed_raw.map(async match => {
      if(allowed_tours.includes(match.category) && allowed_formats.includes(match.type) && (match.finishedAt >= (Date.now() - completed_limit)) && match.round && !match.round.toLowerCase().includes('qualif')){
        completed.push(match)
      }
      if(match.category === 'Davis Cup'){
        completed.push(match)
      }
    }))

    //Sort live by startedAt
    await live.sort((a, b) => (a.startedAt > b.startedAt) ? 1 : -1);

    //Sort completed by finishedAt
    await completed.sort((a, b) => (a.finishedAt < b.finishedAt) ? 1 : -1);

    if(live.length > 12){
      completed = completed.slice(0, (completed.length > 10 ? 8 : completed.length-1))
    }

    var matches = live.concat(completed)
    var matchesObj = {}

    //For each match, push into the ticker format
    await Promise.all(matches.map(async match => {
      var winner = match.winner_id === match.competitors[0].id ? match.competitors[0] : match.competitors[1]
      var loser = match.winner_id === match.competitors[0].id ? match.competitors[1] : match.competitors[0]

      //Check if we need to reverse the score string
      var reverseScore = false
      if(match.winner_id){
        reverseScore = match.winner_id === match.competitors[1].id
      }else{
        if(match.away_score > match.home_score){
          reverseScore = true
        }else if(match.home_score === match.away_score){
          //Tied, see if one player is leading the set
          if(match.period_scores){
            if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){

            }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
              reverseScore = true
            }else{

            }
          }
        }
      }

      //Tourn name
      var round = match.round || 'Qualifying'

      if(round.toLowerCase() === 'round of 128'){ round = 'R128' }
      if(round.toLowerCase() === 'round of 64'){ round = 'R64' }
      if(round.toLowerCase() === 'round of 32'){ round = 'R32' }
      if(round.toLowerCase() === 'round of 16'){ round = 'R16' }
      if(round.toLowerCase() === 'quarterfinal'){ round = 'QF' }
      if(round.toLowerCase() === 'semifinal'){ round = 'SF' }
      if(round.toLowerCase() === 'final'){ round = 'F' }

      try{
        match.tourn_name = (match.season_name.split("2021 ").length > 1 ?  match.season_name.split("2021 ")[1] : match.season_name).split(", ")[0] + " - " + round

        if(match.tourn_name.includes("Wimbledon")){
          match.tourn_name = match.tourn_name.replace(" Singles 2021 ", " ")
        }
      }catch(e){
        match.tourn_name = match.season_name
      }

      //Score string
      var score_string = await this.generateResultString(match, match.tiebreak_scores, reverseScore)
      match.score_string = score_string

      var winnerName = winner.name
      var loserName = loser.name

      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }

      //Country corrections
      // var countries_fix = ["GRE", "GER", "LAT"]
      var country_corrections = {
        ["GRE"]:'GRC',
        ["GER"]:'DEU',
        ["LAT"]:'LVA',
        ["CRO"]:'HRV',
        ["SUI"]:'CHE',
        ["BUL"]:'BGR',
      }
      var countries_fix = Object.keys(country_corrections)

      console.log(match.competitors)

      await Promise.all(match.competitors.map(async (player, p_index) => {
        if(countries_fix.includes(player.country_code)){
          player.country_code = country_corrections[player.country_code]

          // player.county_code = 'SVN'
        }
      }))

      //Country code
      var code_1 = await convertIso3Code(match.competitors[0].country_code)
      var code_2 = await convertIso3Code(match.competitors[1].country_code)
      match.competitors[0].country_cca = code_1 ? code_1.iso2 : null
      match.competitors[1].country_cca = code_2 ? code_2.iso2 : null

      //Sort the order of players depending on who is leading
      if(match.winner_id){
        //Middle string
        match.middleString = 'def'

        //Match has finished
        if(match.winner_id === match.competitors[1].id){
          //Reverse the players
          match.competitors = [
            match.competitors[1],
            match.competitors[0],
          ]
        }
      }else{
        match.middleString = 'leads'

        try{
          //On-going, check who is leading
          if(match.home_score > match.away_score){

          }else if(match.away_score > match.home_score){
            match.competitors = [
              match.competitors[1],
              match.competitors[0],
            ]
          }else{
            //Tied, see if one player is leading the set
            if(match.period_scores){
              if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){
                match.middleString = 'leads'
              }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
                match.competitors = [
                  match.competitors[1],
                  match.competitors[0],
                ]
                match.middleString = 'leads'
              }else{
                match.middleString = 'vs'
              }
            }else{
              match.middleString = 'vs'
            }
          }
        }catch(e){
          console.log('Error', e)
        }
      }

      //Add to object
      if(!matchesObj[match.tourn_name]){
        matchesObj[match.tourn_name] = {
          data:[],
          title:match.tourn_name,
        }
      }

      matchesObj[match.tourn_name].data.push(match)

    }))

    var matchesObj_array = Object.values(matchesObj)
    var matches_clean = []

    await matchesObj_array.sort((a, b) => (a.title > b.title ? 1 : -1))

    await Promise.all(matchesObj_array.map(async tournamentObj => {
      //Sort the matches by start_time_timestamp
      await tournamentObj.data.sort((a, b) => (Number(a.start_time_timestamp) < Number(b.start_time_timestamp)) ? 1 : -1)
      matches_clean = matches_clean.concat(tournamentObj.data)
    }))

    //Set the width of cards and cards per rotation
    // var matches_per_render = Math.floor(((dimensions.width - 100) / match_width))
    var matches_per_render = 1

    if(this.state.firstSet){
      await this.setState({firstSet:false})
      this.changeToNextScores()
      await this.setState({loading:false, matches:matches_clean, matches_per_render})
    }else{
      setTimeout(async()=>{
        await this.setState({loading:false, matches:matches_clean, matches_per_render})
      }, 10 * 1000)
    }

    //Set timeout
    this.updateData = setTimeout(async () => {
      this.setup_camLiveScores()
    },refreshTime)
  }

  capitalizeEachWord = async (str) => {
    var splitStr = str.toLowerCase().split(' ');
     for (var i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
     }
     // Directly return the joined string
     return splitStr.join(' ');
  }

  setup_camLiveScores = async () => {
    var { show_atp, show_wta, show_live, show_completed, show_singles, show_doubles } = this.state

    clearTimeout(this.updateData)

    console.log('Updating scores')

    var refreshTime = 2 * 60 * 1000 //2 min refresh time

    //Arrays
    var live_raw = []
    var completed_raw = []

    if(show_live){
      //Get Live
      await firebase.firestore()
      .collection('summaries_sr').doc('live')
      .get()
      .then(async (doc) => {
        live_raw = Object.values(doc.data().matches)
      })
    }

    if(show_completed){
      //Get completed
      await firebase.firestore()
      .collection('summaries_sr').doc('completed')
      .get()
      .then(async (doc) => {
        completed_raw = Object.values(doc.data().matches)
      })
    }

    //Setup the allowed formats and tour array
    var allowed_tours = []
    var allowed_formats = []

    if(show_atp){allowed_tours.push('ATP')}
    if(show_wta){allowed_tours.push('WTA')}
    if(show_singles){allowed_formats.push('singles')}
    if(show_doubles){allowed_formats.push('doubles')}

    var live = []
    var completed = []

    // console.log(allowed_tours)

    //Promise through Live, filter tours and formats
    await Promise.all(live_raw.map(async match => {
      var match_round = match.round || ''
      if(allowed_tours.includes(match.category) && allowed_formats.includes(match.type) && (match_round || match.competition_name.includes('Final')) && !match_round.toLowerCase().includes('qualif')){
        // console.log('INCLUDING', match)
        live.push(match)
      }
      if(match.category === 'Davis Cup'){
        live.push(match)
      }
    }))

    //Promise through Completed, filter tours and formats and any matches finished more than X hours ago
    var completed_limit = 24 * 60 * 60 * 1000 //Within last 24 hours
    await Promise.all(completed_raw.map(async match => {
      if(allowed_tours.includes(match.category) && allowed_formats.includes(match.type) && (match.finishedAt >= (Date.now() - completed_limit)) && match.round && !match.round.toLowerCase().includes('qualif')){
        completed.push(match)
      }
      if(match.category === 'Davis Cup'){
        completed.push(match)
      }
    }))

    //Sort live by startedAt
    await live.sort((a, b) => (a.startedAt > b.startedAt) ? 1 : -1);

    //Sort completed by finishedAt
    await completed.sort((a, b) => (a.finishedAt < b.finishedAt) ? 1 : -1);

    if(live.length > 12){
      completed = completed.slice(0, (completed.length > 10 ? 8 : completed.length-1))
    }

    var matches = live.concat(completed)
    var matchesObj = {}

    //For each match, push into the ticker format
    await Promise.all(matches.map(async match => {
      var winner = match.winner_id === match.competitors[0].id ? match.competitors[0] : match.competitors[1]
      var loser = match.winner_id === match.competitors[0].id ? match.competitors[1] : match.competitors[0]

      if(match.competitors[0].name.toUpperCase() === match.competitors[0].name){
        match.competitors[0].name = await this.capitalizeEachWord(match.competitors[0].name)
      }
      if(match.competitors[1].name.toUpperCase() === match.competitors[1].name){
        match.competitors[1].name = await this.capitalizeEachWord(match.competitors[1].name)
      }

      //Check if we need to reverse the score string
      var reverseScore = false
      if(match.winner_id){
        reverseScore = match.winner_id === match.competitors[1].id
      }else{
        if(match.away_score > match.home_score){
          reverseScore = true
        }else if(match.home_score === match.away_score){
          //Tied, see if one player is leading the set
          if(match.period_scores){
            if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){

            }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
              reverseScore = true
            }else{

            }
          }
        }
      }

      //Tourn name
      var round = match.round || 'Qualifying'

      if(round.toLowerCase() === 'round of 128'){ round = 'R128' }
      if(round.toLowerCase() === 'round of 64'){ round = 'R64' }
      if(round.toLowerCase() === 'round of 32'){ round = 'R32' }
      if(round.toLowerCase() === 'round of 16'){ round = 'R16' }
      if(round.toLowerCase() === 'quarterfinal'){ round = 'QF' }
      if(round.toLowerCase() === 'semifinal'){ round = 'SF' }
      if(round.toLowerCase() === 'final'){ round = 'F' }

      try{
        match.tourn_name = (match.season_name.split("2021 ").length > 1 ?  match.season_name.split("2021 ")[1] : match.season_name).split(", ")[0] + " - " + round

        if(match.tourn_name.includes("Wimbledon")){
          match.tourn_name = match.tourn_name.replace(" Singles 2021 ", " ")
        }
      }catch(e){
        match.tourn_name = match.season_name
      }

      //Score string
      var score_string = await this.generateResultString(match, match.tiebreak_scores, reverseScore)
      match.score_string = score_string

      match.set_scores = match.period_scores
      match.reverseScore = reverseScore

      match.serving = null

      try{
        match.serving = match.game_state.serving === 'home' ? 0 : 1
      }catch(e){
        console.log(e)
      }

      var winnerName = winner.name
      var loserName = loser.name

      if(match.competitors[0].name.includes(', ')){
        match.competitors[0].name = (match.competitors[0].name.split(", "))[1].charAt(0) + ". " + (match.competitors[0].name.split(", "))[0]
      }

      if(match.competitors[1].name.includes(', ')){
        match.competitors[1].name = (match.competitors[1].name.split(", "))[1].charAt(0) + ". " + (match.competitors[1].name.split(", "))[0]
      }

      //Country corrections
      // var countries_fix = ["GRE", "GER", "LAT"]
      var country_corrections = {
        ["GRE"]:'GRC',
        ["GER"]:'DEU',
        ["LAT"]:'LVA',
        ["CRO"]:'HRV',
        ["SUI"]:'CHE',
        ["BUL"]:'BGR',
      }
      var countries_fix = Object.keys(country_corrections)

      // console.log(match.competitors)

      await Promise.all(match.competitors.map(async (player, p_index) => {
        if(countries_fix.includes(player.country_code)){
          player.country_code = country_corrections[player.country_code]
        }
      }))

      //Country code
      var code_1 = await convertIso3Code(match.competitors[0].country_code)
      var code_2 = await convertIso3Code(match.competitors[1].country_code)
      match.competitors[0].country_cca = code_1 ? code_1.iso2 : null
      match.competitors[1].country_cca = code_2 ? code_2.iso2 : null

      //Sort the order of players depending on who is leading
      if(match.winner_id){
        //Middle string
        match.middleString = 'def'

        //Match has finished
        if(match.winner_id === match.competitors[1].id){
          //Reverse the players
          match.competitors = [
            match.competitors[1],
            match.competitors[0],
          ]
        }
      }else{
        match.middleString = 'leads'

        try{
          //On-going, check who is leading
          if(match.home_score > match.away_score){

          }else if(match.away_score > match.home_score){
            match.competitors = [
              match.competitors[1],
              match.competitors[0],
            ]
          }else{
            //Tied, see if one player is leading the set
            if(match.period_scores){
              if(match.period_scores[match.period_scores.length-1].home_score > match.period_scores[match.period_scores.length-1].away_score){
                match.middleString = 'leads'
              }else if(match.period_scores[match.period_scores.length-1].home_score < match.period_scores[match.period_scores.length-1].away_score){
                match.competitors = [
                  match.competitors[1],
                  match.competitors[0],
                ]
                match.middleString = 'leads'
              }else{
                match.middleString = 'vs'
              }
            }else{
              match.middleString = 'vs'
            }
          }
        }catch(e){
          console.log('Error', e)
        }
      }

      //Add to object
      if(!matchesObj[match.tourn_name]){
        matchesObj[match.tourn_name] = {
          data:[],
          title:match.tourn_name,
        }
      }

      matchesObj[match.tourn_name].data.push(match)

    }))

    var matchesObj_array = Object.values(matchesObj)
    var matches_clean = []

    await matchesObj_array.sort((a, b) => (a.title > b.title ? 1 : -1))

    await Promise.all(matchesObj_array.map(async tournamentObj => {
      //Sort the matches by start_time_timestamp
      await tournamentObj.data.sort((a, b) => (Number(a.start_time_timestamp) < Number(b.start_time_timestamp)) ? 1 : -1)
      matches_clean = matches_clean.concat(tournamentObj.data)
    }))

    //Set the width of cards and cards per rotation
    // var matches_per_render = Math.floor(((dimensions.width - 100) / match_width))
    var matches_per_render = 1

    if(this.state.firstSet){
      await this.setState({firstSet:false})
      this.changeToNextScores()
      await this.setState({loading:false, matches:matches_clean, matches_per_render})
    }else{
      setTimeout(async()=>{
        await this.setState({loading:false, matches:matches_clean, matches_per_render})
      }, 10 * 1000)
    }

    //Set timeout
    this.updateData = setTimeout(async () => {
      this.setup_camLiveScores()
    },refreshTime)
  }

  generateResultString = async (data, tiebreak_scores, reverseScore) => {
    var score = ''

    try{
      var scores = data.period_scores || []
      //Check the winner to decide order
      var winner_id = reverseScore ? data.competitors[1].id : data.competitors[0].id
      var winner_score_id = winner_id === data.competitors[0].id ? 'home_score' : 'away_score'
      var loser_score_id = winner_id === data.competitors[1].id ? 'home_score' : 'away_score'
      var winner_tb_id = winner_id === data.competitors[0].id ? 'home' : 'away'
      var loser_tb_id = winner_id === data.competitors[1].id ? 'home' : 'away'

      var middle = "-"

      //Create the set strings
      var stringsArray = []
      for (let i = 0; i < scores.length; i++) {
        var scoreString = scores[i][winner_score_id] + middle + scores[i][loser_score_id]

        //Check if there was a tiebreaker
        if((scores[i][winner_score_id] === 7 && scores[i][loser_score_id] === 6) && tiebreak_scores){
          //Winner won the set in tb
          if(tiebreak_scores[i+1]){
            if(tiebreak_scores[i+1][loser_tb_id]){
              scoreString = scores[i][winner_score_id] + middle + scores[i][loser_score_id] +"("+tiebreak_scores[i+1][loser_tb_id]+")"
            }
          }
        }
        if((scores[i][winner_score_id] === 6 && scores[i][loser_score_id] === 7) && tiebreak_scores){
          //Winner lost the set in tb
          if(tiebreak_scores[i+1]){
            if(tiebreak_scores[i+1][winner_tb_id]){
              scoreString =  scores[i][winner_score_id] + middle + scores[i][loser_score_id] + "("+tiebreak_scores[i+1][winner_tb_id] + ")"
            }
          }
        }

        stringsArray.push(
          scoreString
        )
      }

      score = stringsArray.join("  ")

      //Walkover handling
      if(data.match_status === 'walkover'){
        score = "Walkover"
      }

      //Retirement handling
      if(data.match_status === 'retired'){
        score = score + " ret"
      }

      if(data.match_status === 'defaulted'){
        score = score + " def"
      }
    }catch(e){
      console.log('Error', e)
    }

    //Return the string
    return score
  }

  changeToNextScores = async () => {
    clearTimeout(this.updateView)

    var { matches_per_render, activeIndex, matches } = this.state

    var nextIndex = activeIndex + 1 //starting index of next render

    await this.setState({changingMatches:true})

    var updateTime = matches_per_render * 8000 //8s per 4
    var animation_duration = 350

    this.updateOpacity = setTimeout(async () => {
      await Animated.timing(this.state.score_opacity, {
        toValue: 0,
        duration: animation_duration,
      }).start();
    }, updateTime - animation_duration)

    this.updateOpacity2 = setTimeout(async()=>{
      // Fade In new text
      await Animated.timing(this.state.score_opacity, {
        toValue: 1,
        duration: animation_duration,
      }).start();
    }, updateTime)

    this.updateView = setTimeout(async()=>{
      // setTimeout(async () => {
        var changingIndex = nextIndex < (matches.length) ? nextIndex : 0
        await this.setState({activeIndex:changingIndex, changingMatches:false})

        console.log('Starting index changed to', changingIndex, 'Matches per render', matches_per_render, 'Matches in carousel', matches.length, matches[changingIndex])
      // }, animation_duration)

      this.changeToNextScores()
    }, updateTime)
  }

  //Render Matches
  render_camLiveScores = () => {
    var { matches, matches_per_render, activeIndex, matches_ticker, matches_ticker_divs } = this.state
    var sliceIndex = ((matches_per_render + activeIndex) > (matches.length - 1) ? (matches.length - 1) : (matches_per_render + activeIndex))
    var render_data = matches[activeIndex] ? matches[activeIndex] : null

    var img_height = 56
    var width = img_height / 94 * 450

    var grey = "#E4E4EC"
    var yellow = '#fcd946'

    return(
      <View style={[{width:289*1.25, height:185*1.25, overflow:'hidden', marginBottom:50, backgroundColor:'white'}, MainStyles.flexCenter, MainStyles.flexColumn]}>

      <View style={[{width:'100%', backgroundColor:yellow, padding:20, paddingVertical:10}, MainStyles.flexBetweenEnd, MainStyles.flexRow]}>
        {
          // <Text style={{fontSize:20, color:'black', fontFamily:'Roboto-Black', textAlign:'left', width:'100%'}}>
          // Latest Scores
          // </Text>
        }
        <Animated.Text style={{fontSize:18, color:'black', fontFamily:'Roboto-Bold', width:'100%', textAlign:'left', opacity:1, marginBottom:1}} numberOfLines={1}>
        {render_data ? render_data.tourn_name : ''}
        </Animated.Text>
      </View>

      <Animated.View style={[{flexGrow:1, opacity:this.state.score_opacity, width:'100%'}, MainStyles.flexColumn, MainStyles.flexCenter]}>
      {render_data ? this.render_liveScoreAlt(render_data, 0) : null}
      </Animated.View>

      <View style={[{width:'100%', backgroundColor:grey, padding:20, paddingVertical:10}, MainStyles.flexCenterEnd]}>
        <Image
        style={{height:30, width:30, resizeMode:'cover', borderRadius:5, overflow:'hidden', position:'absolute', left:20}}
        source={require('../../images/appstore.png')}
        />
        <Text style={{fontSize:10, color:'black', fontFamily:'Roboto-Medium', textAlign:'right', marginHorizontal:0, marginBottom:0, textTransform:'uppercase', opacity:0.5}}>
        Powered by
        </Text>
        <Text style={{fontSize:20, color:'black', fontFamily:'Averta-Black', textAlign:'right', marginHorizontal:0, textTransform:'uppercase'}}>
        downloadtennis.com
        </Text>
      </View>

      </View>
    )
  }

  render_camLiveScoresOld = () => {
    var { matches, matches_per_render, activeIndex, matches_ticker, matches_ticker_divs } = this.state
    var sliceIndex = ((matches_per_render + activeIndex) > (matches.length - 1) ? (matches.length - 1) : (matches_per_render + activeIndex))
    var render_data = matches[activeIndex] ? matches[activeIndex] : null

    var img_height = 56
    var width = img_height / 94 * 450

    var grey = "#E4E4EC"
    var yellow = '#fcd946'

    return(
      <View style={[{width:'100%', height:img_height-15, overflow:'hidden', marginBottom:50}, MainStyles.flexCenter, MainStyles.flexColumn]}>

        <View style={[{width:'100%', backgroundColor:'white', marginBottom:0}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
          <View style={[{height:img_height, backgroundColor:yellow, padding:10, paddingVertical:0}, MainStyles.flexCenter]}>
            <Text style={{fontSize:18, color:'black', fontFamily:'Roboto-Bold', textAlign:'left', marginHorizontal:5}}>
            Scores
            </Text>
          </View>
          <View
          style={{
            zIndex:2,
            marginLeft:-25,
            marginRight:-25,
            transform:[{scaleY:-1}],
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: 25,
            borderRightWidth: 25,
            borderBottomWidth: 50,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: yellow,
          }}
          />
          {
            <>
            <View style={[{maxWidth:300, height:'100%', paddingHorizontal:25, paddingRight:5, backgroundColor:grey}, MainStyles.flexCenter]}>
              <Animated.Text style={{fontSize:18, color:'black', fontFamily:'Roboto-Bold', width:'100%', textAlign:'center', opacity:1}} numberOfLines={1}>
              {render_data ? render_data.tourn_name : ''}
              </Animated.Text>
            </View>
            <View
            style={{
              zIndex:-1,
              marginLeft:-25,
              transform:[{scaleY:-1}],
              width: 0,
              height: 0,
              backgroundColor: "transparent",
              borderStyle: "solid",
              borderLeftWidth: 25,
              borderRightWidth: 25,
              borderBottomWidth: 50,
              borderLeftColor: "transparent",
              borderRightColor: "transparent",
              borderBottomColor: grey,
            }}
            />
            </>
          }
          {
            // render_data.map((item, index) => this.render_camLiveScore(item, index))
            <Animated.View style={[{flexGrow:1, height:'100%', opacity:this.state.score_opacity}, MainStyles.flexRow, MainStyles.flexCenter]}>
            {render_data ? this.render_liveScoreAlt(render_data, 0) : null}
            </Animated.View>
          }
          <View
          style={{
            zIndex:2,
            marginLeft:-25,
            marginRight:-25,
            transform:[{scaleY:1}],
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: 25,
            borderRightWidth: 25,
            borderBottomWidth: 50,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: yellow,
          }}
          />
          <View style={[{height:img_height, backgroundColor:yellow, padding:10, paddingVertical:0, marginLeft:0, zIndex:3, paddingLeft:20}, MainStyles.flexCenterEnd]}>
            <Image
            style={{height:30, width:30, resizeMode:'cover', borderRadius:5, overflow:'hidden', position:'absolute', left:-25}}
            source={require('../../images/appstore.png')}
            />
            <Text style={{fontSize:10, color:'black', fontFamily:'Roboto-Medium', textAlign:'right', marginHorizontal:0, marginBottom:-4, textTransform:'uppercase', opacity:0.5}}>
            Powered by
            </Text>
            <Text style={{fontSize:20, color:'black', fontFamily:'Averta-Black', textAlign:'right', marginHorizontal:0, textTransform:'uppercase'}}>
            downloadtennis.com
            </Text>
          </View>
        </View>

      </View>
    )
  }

  render_liveScoreAlt = (item, index) => {
    var textStyle = {fontSize:20, color:'black', fontFamily:'Roboto-Bold', textAlign:'left', letterSpacing:-0.25}
    // try{
      return(
        <>
        {this.render_isLive(item.winner_id, textStyle)}
        <View key={item.id} style={[{paddingHorizontal:20, width:'100%', height:'100%', borderRightWidth:0, borderRightColor:'rgba(0,0,0,0.12)'}, MainStyles.flexCenterStart, MainStyles.flexColumn]}>
          {this.renderPlayerAlt(item.competitors[0], item.winner_id, item.seeds, textStyle, item.period_scores || {}, item.reverseScore, item.serving, 0)}
          <View style={{height:20}}/>
          {this.renderPlayerAlt(item.competitors[1], item.winner_id, item.seeds, textStyle, item.period_scores || {}, item.reverseScore, item.serving, 1)}
        </View>
        </>
      )
    // }catch(e){
    //   return null
    // }
  }

  render_isLive = (winner, textStyle) => {
    if(winner){
      return null
    }else{
      // return(
      //   <View style={[{height:'100%', paddingLeft:40, position:'absolute', left:0}, MainStyles.flexCenter]}>
      //     <Text style={[textStyle, {color:'red'}]}>
      //     LIVE
      //     </Text>
      //   </View>
      // )
      return null
    }
  }

  renderAltScores = (item, textStyle) => {
    return(
      <Text style={[textStyle, {marginLeft:0, marginTop:15, fontSize:22, fontFamily:'Roboto-Regular', opacity:1}]}>
      {item.score_string}
      </Text>
    )
  }

  render_camLiveScore = (item, index) => {
    try{
      return(
        <View key={item.id} style={[{padding:5, paddingRight:0, paddingLeft:10, borderRightWidth:1, borderRightColor:'rgba(0,0,0,0.33)'}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{flexShrink:1, marginRight:20}, MainStyles.flexColumn, MainStyles.flexCenterStart]}>
            {this.renderPlayer(item.competitors[0], item.winner_id, item.seeds)}
            <View style={{height:5}}/>
            {this.renderPlayer(item.competitors[1], item.winner_id, item.seeds)}
          </View>
          {this.renderVisualScores(item)}
        </View>
      )
    }catch(e){
      return null
    }
  }

  renderPlayerAlt = (item, winner_id, seeds, textStyle, set_scores, reverseScore, serving, index) => {

    var hasWinner = winner_id
    var winner = winner_id === item.id

    var flag = null
    if(item.country_cca){
      flag = item.country_cca
    }

    var isDoubles = item.name.includes("/")

    if(isDoubles){
      flag = null
    }

    var seed = null
    if(seeds){
      if(seeds[item.id]){
        seed = seeds[item.id]
      }
    }

    return(
      <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {width:'100%', opacity:1}]}>

        <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {flex:1, paddingRight:10}]}>
          {
            <View style={[{width:24, height:20, marginRight:10, borderColor:'rgba(0,0,0,0.05)', backgroundColor:'rgba(0,0,0,0.05)', borderWidth:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
              {
                !flag ? null :
                <ReactCountryFlag
                  countryCode={flag}
                  svg
                  style={{
                      width: 24,
                      height: 20,
                  }}
                  title="US"
                />
              }
            </View>
          }



          <Text style={[textStyle,{opacity:hasWinner && !winner ? 0.5 : 1, fontSize:24, flexShrink:1, fontFamily:hasWinner && !winner ? 'Roboto-Medium' : 'Roboto-Bold'}]} numberOfLines={1}>
          {item.name}
          </Text>

          {
            !seed ? null :
            <Text style={[textStyle,{opacity:0.5, fontSize:18, marginLeft:7.5, marginTop:4}]} numberOfLines={1}>
            {seed}
            </Text>
          }

          {
            // hasWinner && winner ? <Text style={[{fontSize:14, marginLeft:10}]}>✅</Text> : null
          }

          {
            !hasWinner && serving === index ? <View style={{height:14, width:14, borderRadius:100, backgroundColor:GLOBAL.colors.volt, borderWidth:1, borderColor:'rgba(0,0,0,0.1)', marginLeft:10}}/> : null
          }
        </View>

        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-10}]}>
        {
          set_scores && Array.isArray(set_scores) ?
          set_scores.map((item, index_2) => this.renderSetScore(item, reverseScore, index))
          : null
        }
        </View>

      </View>
    )
  }

  renderSetScore = (scores, reverseScore, index) => {
    var score = index === 0 ? scores.home_score : scores.away_score
    var other_score = index === 1 ? scores.home_score : scores.away_score
    if(reverseScore){
      score = index === 1 ? scores.home_score : scores.away_score
      other_score = index === 0 ? scores.home_score : scores.away_score
    }
    return(
      <Text style={{width:30, fontFamily:'Roboto-Medium', color:'black', fontSize:24, textAlign:'center', opacity:other_score > score ? 0.5 : 1}}>
      {score}
      </Text>
    )
  }

  renderPlayer = (item, winner_id, seeds) => {

    var winner = winner_id === item.id
    var textStyle = {fontSize:14, color:'black', fontFamily:'Averta-Bold', textAlign:'left', letterSpacing:-0.25}

    var flag = null
    if(item.country_cca){
      flag = item.country_cca
    }

    var isDoubles = item.name.includes("/")

    if(isDoubles){
      flag = null
    }

    var seed = null
    if(seeds){
      if(seeds[item.id]){
        seed = seeds[item.id]
      }
    }

    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {flexShrink:1}]}>

        {
          !flag ? null :
          <View style={[{width:25, marginRight:0}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <ReactCountryFlag
              countryCode={flag}
              svg
              style={{
                  width: 18,
                  height: 14,
              }}
              title="US"
            />
          </View>
        }

        <Text style={[textStyle,{opacity:winner ? 1 : 0.5}]} numberOfLines={1}>
        {item.name}
        </Text>

        {
          !seed ? null :
          <Text style={[textStyle,{opacity:0.25, fontSize:12, marginLeft:7.5, marginTop:1}]} numberOfLines={1}>
          {seed}
          </Text>
        }
      </View>
    )
  }

  renderVisualScores = (data) => {
    //Setup number of sets required
    var best_of = data.best_of || 3
    var scores = data.period_scores ? new Array(data.period_scores.length) : []
    scores.fill('')

    var height = 46

    return(
      <View style={[{height}, MainStyles.flexRow, MainStyles.flexEndCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-4}]}>
        {this.renderVisualGameScore(data, height)}
        {scores.map((item, index) => this.renderVisualSetScores(data.period_scores, index, height))}
        </View>
        {this.renderVisualArrow(data, height)}
      </View>
    )
  }

  renderVisualArrow = (data, height) => {
    return(
      <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:10, height:height}]}>
        {this.renderArrow(data.competitors[0].id === data.winner_id, height/2)}
        {this.renderArrow(data.competitors[1].id === data.winner_id, height/2)}
      </View>
    )
  }

  renderArrow = (active, height) => {
    if(active && this.state.mode !== 'scheduled'){
      return(
        <View style={[{height}, MainStyles.flexCenter]}>
          <Image
          style={{width:6, height:12, resizeMode:'contain', tintColor:'black'}}
          source={require('../scorearrow.png')}
          />
        </View>
      )
    }else{
      return(
        <View style={{width:6, height}}/>
      )
    }
  }

  renderVisualGameScore = (data, height) => {
    var { mode } = this.state
    if(!data.winner_id && mode !== 'scheduled' && data.game_state){

      var scores = [0,0]
      if(data.game_state){
        scores = [data.game_state.home_score || 0, data.game_state.away_score || 0]
      }

      return(
        <View key={'gamescore'} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:10, marginRight:5, height, width:30}]}>
          {this.renderVisualSetScore(scores[0], null, null, null, true, data.tiebreak_scores)}
          <View style={{height:5, width:'100%', backgroundColor:'rgba(255,255,255,0.03)'}}/>
          {this.renderVisualSetScore(scores[1], null, null, null, true, data.tiebreak_scores)}
        </View>
      )
    }else{return null}
  }

  renderVisualSetScores = (scores, index, height, data, tiebreak_scores) => {
    var setScore = scores ? (scores[index] ? [scores[index].home_score, scores[index].away_score] : ['','']) : ['', '']
    var showScores = this.state.mode !== 'scheduled'
    return(
      <View key={index} style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginLeft:5, height, width:20}]}>
        {showScores ? this.renderVisualSetScore(setScore[0], setScore[1], index+1, 'home', setScore[0] >= setScore[1], tiebreak_scores) : null}
        <View style={{height:5, width:'100%', backgroundColor:'rgba(255,255,255,0.03)'}}/>
        {showScores ? this.renderVisualSetScore(setScore[1], setScore[0], index+1, 'away', setScore[1] >= setScore[0], tiebreak_scores) : null}
      </View>
    )
  }

  renderVisualSetScore = (score, otherScore, set, player, active, tiebreak_scores) => {
    var tb = null
    // if(score === 0){score = '0'}
    if(score === 50){score = 'AD'}
    // if(score === 6 && otherScore === 7){
    //   if(tiebreak_scores?[set]){
    //     tb =
    //     <Text style={{position:'absolute', top:-6, right:-6, fontSize:12, fontFamily:'Averta-Bold', color:'white', opacity:active ? 1 : 0.5, letterSpacing:-0}}>
    //     {tiebreak_scores[set][player]}
    //     </Text>
    //   }
    // }

    return(
      <View style={[MainStyles.flexCenter]}>
        <Text style={{fontSize:14, fontFamily:'Averta-Bold', color:'black', opacity:active ? 1 : 0.5, letterSpacing:-0}}>
        {score}
        </Text>
        {tb}
      </View>
    )
  }

  //Render Switches
  renderSwitches = () => {
    var { show_atp, show_wta, show_live, show_completed, show_singles, show_doubles } = this.state
    var options = [
      {
        title:'Tour',
        options:[
          {label:'ATP', isEnabled:show_atp, toggleSwitch:()=>this.setState({show_atp:!show_atp})},
          {label:'WTA', isEnabled:show_wta, toggleSwitch:()=>this.setState({show_wta:!show_wta})},
        ],
      },
      {
        title:'Modes',
        options:[
          {label:'Live', isEnabled:show_live, toggleSwitch:()=>this.setState({show_live:!show_live})},
          {label:'Recently Completed', isEnabled:show_completed, toggleSwitch:()=>this.setState({show_completed:!show_completed})},
        ],
      },
      {
        title:'Formats',
        options:[
          {label:'Singles', isEnabled:show_singles, toggleSwitch:()=>this.setState({show_singles:!show_singles})},
          {label:'Doubles', isEnabled:show_doubles, toggleSwitch:()=>this.setState({show_doubles:!show_doubles})},
        ],
      },
    ]

    return(
      <View style={[{flexWrap:'wrap'}, MainStyles.flexCenter, MainStyles.flexRow]}>
      {options.map((item, index) => this.renderSwitchGroup(item, index))}
      </View>
    )
  }

  renderSwitchGroup = (item, index) => {
    return(
      <View key={item.title} style={[{marginHorizontal:20, marginBottom:40, padding:20, backgroundColor:'#18181B', borderRadius:20}, MainStyles.flexCenterStart]}>
        <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:24, textAlign:'center', opacity:1, marginBottom:20, letterSpacing:0.5}}>
        {item.title}
        </Text>
        <View style={[{},MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {item.options.map((item, index) => this.renderSwitch(item, index))}
        </View>
      </View>
    )
  }

  renderSwitch = (item, index) => {
    var { isEnabled, toggleSwitch, label } = item
    return(
      <View key={label} style={[MainStyles.flexCenterStart, {width:150}]}>
        <Switch
          trackColor={{ false: "white", true: GLOBAL.colors.teal }}
          thumbColor={isEnabled ? GLOBAL.colors.volt : 'white'}
          onValueChange={toggleSwitch}
          value={isEnabled}
        />
        <Text style={{color:'white', fontFamily:'Averta-Regular', fontSize:14, textAlign:'center', opacity:1, marginTop:5}}>
        {label}
        </Text>
      </View>
    )
  }

  render() {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width:'100%', height:'100%', backgroundColor:'black'}, MainStyles.flexCenter]}>
      {this.render_camLiveScores()}
      {this.renderSwitches()}
      </View>
    )
  }
}

export default Item;
