import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"

//Firebase
import app from 'firebase/app';
import firebase from '@firebase/app';
import '@firebase/firestore'

import { View, Text, Pressable, Linking, Image, ActivityIndicator, Alert, Dimensions, Switch, TextInput, ScrollView, Animated } from "react-native";
import { AnimateOnChange } from 'react-animation'

//YouTube
import { YouTubeLiveChat } from 'youtube-live-chat-ts';

//Helpers
import MainStyles from '../mainstyles.js'

//Module
import ReactGA from 'react-ga';

var height = 100

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

const handler = new YouTubeLiveChat("AIzaSyCDjoN2vEAYLYeH2h9puW9--E0x_7gAFMo");
var moment = require('moment-timezone');

var match_width = 260

var poll_width = 240
var poll_padding = 20

var timeLimit = 60 //60 minutes

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      firstSet: true,
      votes_1: new Animated.Value(0),
      votes_2: new Animated.Value(0),
      votes_3: new Animated.Value(0),
      votes_4: new Animated.Value(0),
      votes_total: 0,
      vote_count_1: 0,
      vote_count_2: 0,
      vote_count_3: 0,
      vote_count_4: 0,
      vote_perc_1:'0%',
      vote_perc_2:'0%',
      vote_perc_3:'0%',
      vote_perc_4:'0%',
      video_id: null,
      votes_count: 0,
      // video_url: "https://www.youtube.com/watch?v=cI0zN1nG3PQ",
      video_url:null,
      option_1:null,
      option_2:null,
      option_3:null,
      option_4:null,
    };
  }

  componentDidMount = async () => {
    this.setupFirebase()
  }

  componentWillUnmount = async () => {
    this.stopYouTubeListener()
    if(this.listenFirebase){
      this.listenFirebase()
    }
  }

  //Functions
  setupFirebase = async () => {
    this.listenFirebase = await firebase.firestore()
    .collection('streaming').doc('C5aaURtU7WANlm1jDEzW')
    .onSnapshot(this.handleFirebase)
  }

  handleFirebase = async (doc) => {
    var votes = doc.data().votes || {}
    var poll = doc.data().poll || null
    var video_url = doc.data().video_url || null
    var startedAt = doc.data().startedAt || 0

    var votes_count = (Object.values(votes)).length || 0

    await this.setState({poll, video_url, votes_count})

    if(poll){
      var question = poll.title || null
      var option_1 = poll.options[0].title || null
      var option_2 = poll.options[1].title || null
      var option_3 = poll.options[2].title || null
      var option_4 = poll.options[3].title || null

      this.setState({option_1, option_2, option_3, option_4, question})
    }

    if(this.state.firstSet && video_url && (startedAt >= (Date.now() - (10 * 60 * 1000)))){
      this.setupYouTubeListener()
    }

    this.updateVotes(votes)

  }

  resetFirebase = async () => {
    await firebase.firestore()
    .collection('streaming').doc('C5aaURtU7WANlm1jDEzW')
    .set({
      votes:{},
      poll:null,
      video_url:null,
    },{merge:true})

    await this.stopYouTubeListener()
  }

  resetVoteCount = async () => {
    await firebase.firestore()
    .collection('streaming').doc('C5aaURtU7WANlm1jDEzW')
    .set({
      votes:{},
    },{merge:true})
  }

  setupPoll = async (video_url) => {
    var { firstSet, startedAt, question, chat_id, option_1, option_2, option_3, option_4 } = this.state

    var poll = {
      title:question,
      options:[
        {title:option_1, command:'1', votes:0},
        {title:option_2, command:'2', votes:0},
        {title:option_3, command:'3', votes:0},
        {title:option_4, command:'4', votes:0},
      ]
    }

    return firebase.firestore()
    .collection('streaming').doc('C5aaURtU7WANlm1jDEzW')
    .set({
      poll,
      video_url
    },{merge:true})
  }

  updateVotes = async (votesObj) => {
    //
    var barWidth = poll_width - (poll_padding*2)

    //Setup vote counts
    var votes = Object.values(votesObj)

    var votes_total = 0
    var vote_count_1 = 0
    var vote_count_2 = 0
    var vote_count_3 = 0
    var vote_count_4 = 0

    await Promise.all(votes.map(async vote => {
      if(vote === '1' && this.state.option_1){
        vote_count_1 += 1
        votes_total += 1
      }
      if(vote === '2' && this.state.option_2){
        vote_count_2 += 1
        votes_total += 1
      }
      if(vote === '3' && this.state.option_3){
        vote_count_3 += 1
        votes_total += 1
      }
      if(vote === '4' && this.state.option_4){
        vote_count_4 += 1
        votes_total += 1
      }
    }))

    //Update the bar widths
    var votes_1_width = Math.round(vote_count_1 / votes_total * barWidth) || 0
    var votes_2_width = Math.round(vote_count_2 / votes_total * barWidth) || 0
    var votes_3_width = Math.round(vote_count_3 / votes_total * barWidth) || 0
    var votes_4_width = Math.round(vote_count_4 / votes_total * barWidth) || 0

    //Set the percentage strings
    var vote_perc_1 = (Math.round(vote_count_1 / votes_total * 100) || 0) + "%"
    var vote_perc_2 = (Math.round(vote_count_2 / votes_total * 100) || 0) + "%"
    var vote_perc_3 = (Math.round(vote_count_3 / votes_total * 100) || 0) + "%"
    var vote_perc_4 = (Math.round(vote_count_4 / votes_total * 100) || 0) + "%"

    await this.setState({votes_1_width, votes_2_width, votes_3_width, votes_4_width, vote_perc_1, vote_perc_2, vote_perc_3, vote_perc_4, vote_count_1, vote_count_2, vote_count_3, vote_count_4, votes_total})

    console.log('Widths',votes_1_width, votes_2_width, votes_3_width, votes_4_width,'%', vote_perc_1, vote_perc_2, vote_perc_3, vote_perc_4, 'Count', vote_count_1, vote_count_2, vote_count_3, vote_count_4, 'Total', votes_total)

    var animation_duration = 1000
    Animated.timing(this.state.votes_1, {
      toValue: votes_1_width,
      duration: animation_duration,
    }).start();
    Animated.timing(this.state.votes_2, {
      toValue: votes_2_width,
      duration: animation_duration,
    }).start();
    Animated.timing(this.state.votes_3, {
      toValue: votes_3_width,
      duration: animation_duration,
    }).start();
    Animated.timing(this.state.votes_4, {
      toValue: votes_4_width,
      duration: animation_duration,
    }).start();
  }

  stopYouTubeListener = async () => {
    console.log('Closing poll')
    handler.stop(this.state.video_id)
    clearTimeout(this.stopPoll)
    await this.setState({firstSet:true, startedAt:Date.now()})
  }

  setupYouTubeListener = async () => {
    var { firstSet, video_url } = this.state

    console.log('Setting up YouTube listener')

    if(!video_url || video_url === ''){
      alert("Don't forget to add the video url from YouTube")
    }else{
      //Set the video id
      var video_id = (video_url.split("="))[1]
      await this.setState({video_id})

      //Setup the poll
      await this.setupPoll(video_url)

      if(firstSet){
        await this.setState({firstSet:false, startedAt:Date.now()})
      }

      try{
        const liveChatId = await handler.getLiveChatIdFromVideoId(video_id); // The chat ID is *not* the video ID!
        handler.listen(liveChatId).subscribe((chatMessage) => {
          if (chatMessage.snippet.type === 'textMessageEvent') {
            try{
              this.newMessage(chatMessage)
            }catch(e){
              console.log('Error with new message', e)
            }
          }
        });

        this.stopPoll = setTimeout(async()=>{
          this.stopYouTubeListener()
        }, (timeLimit * 60 * 1000)) //only run the poll for 15 mins max
      }catch(e){
        console.log('Error with YT listener', e)
      }
    }
  }

  newMessage = async (message_data) => {
    var { poll, startedAt, vote_count_1, vote_count_2, vote_count_3, vote_count_4, votes_total, option_1, option_2, option_3, option_4 } = this.state

    var snippet = message_data.snippet

    var sentAt = moment(snippet.publishedAt).format('x')
    var message = (snippet.displayMessage).toString()

    var votes = ['1','2','3','4']
    var barWidth = poll_width - (poll_padding*2)

    if(votes.includes(message) && sentAt >= startedAt && this.state['option_'+message] && this.state['option_'+message] !== ''){
      await firebase.firestore()
      .collection('streaming').doc('C5aaURtU7WANlm1jDEzW')
      .set({
        votes:{
          [message_data.authorDetails.channelId+"_"+Date.now()]:message
          // [message_data.authorDetails.channelId]:message //old version
        }
      },{merge:true})
    }
  }

  newMessageOld = async (message_data) => {
    var { poll, startedAt, vote_count_1, vote_count_2, vote_count_3, vote_count_4, votes_total, option_1, option_2, option_3, option_4 } = this.state

    var snippet = message_data.snippet

    var sentAt = moment(snippet.publishedAt).format('x')
    var message = (snippet.displayMessage).toString()

    var votes = ['1','2','3','4']
    var barWidth = poll_width - (poll_padding*2)

    if(votes.includes(message) && sentAt >= startedAt && this.state['option_'+message] && this.state['option_'+message] !== ''){
      //Valid vote, update the count
      if(message === '1'){
        vote_count_1 = vote_count_1 + 1
        votes_total = votes_total + 1
      }
      if(message === '2'){
        vote_count_2 = vote_count_2 + 1
        votes_total = votes_total + 1
      }
      if(message === '3'){
        vote_count_3 = vote_count_3 + 1
        votes_total = votes_total + 1
      }
      if(message === '4'){
        vote_count_4 = vote_count_4 + 1
        votes_total = votes_total + 1
      }

      var messageIndex = Number(message)-1

      //Update the bar widths
      var votes_1_width = Math.round(vote_count_1 / votes_total * barWidth)
      var votes_2_width = Math.round(vote_count_2 / votes_total * barWidth)
      var votes_3_width = Math.round(vote_count_3 / votes_total * barWidth)
      var votes_4_width = Math.round(vote_count_4 / votes_total * barWidth)

      //Update the lastVoteString
      var lastVoteString = message_data.authorDetails.displayName + " voted for " + poll.options[messageIndex].title

      //Set the percentage strings
      var vote_perc_1 = Math.round(vote_count_1 / votes_total * 100) + "%"
      var vote_perc_2 = Math.round(vote_count_2 / votes_total * 100) + "%"
      var vote_perc_3 = Math.round(vote_count_3 / votes_total * 100) + "%"
      var vote_perc_4 = Math.round(vote_count_4 / votes_total * 100) + "%"

      await this.setState({votes_1_width, votes_2_width, votes_3_width, votes_4_width, vote_perc_1, vote_perc_2, vote_perc_3, vote_perc_4, vote_count_1, vote_count_2, vote_count_3, vote_count_4, votes_total, lastVoteString})

      console.log('Widths',votes_1_width, votes_2_width, votes_3_width, votes_4_width,'%', vote_perc_1, vote_perc_2, vote_perc_3, vote_perc_4, 'Count', vote_count_1, vote_count_2, vote_count_3, vote_count_4, 'Total', votes_total, lastVoteString)

      var animation_duration = 1000
      Animated.timing(this.state.votes_1, {
        toValue: votes_1_width,
        duration: animation_duration,
      }).start();
      Animated.timing(this.state.votes_2, {
        toValue: votes_2_width,
        duration: animation_duration,
      }).start();
      Animated.timing(this.state.votes_3, {
        toValue: votes_3_width,
        duration: animation_duration,
      }).start();
      Animated.timing(this.state.votes_4, {
        toValue: votes_4_width,
        duration: animation_duration,
      }).start();
    }
  }

  updateYouTubeCount = async () => {
    var { firstSet, video_url } = this.state

    if(!video_url || video_url === ''){
      alert("Don't forget to add the video url from YouTube")
    }else{
      //If first set, set the timer when the vote starts
      if(firstSet){
        await this.setState({startedAt:Date.now(), firstSet:false})
      }

      //Set the video id
      var video_id = (video_url.split("="))[1]
      await this.setState({video_id})

      //Check this isn't a very old poll
      var buffer = Date.now() - (3 * 60 * 60 * 1000) //3hr buffer

      if(this.state.startedAt > buffer){
        try{
          this.setYouTubeCount()
        }catch(e){
          console.log('Error getting votes', e)
        }
      }else{
        console.log('Not updating, poll expired')
        handler.stop(this.state.video_id)
      }
    }

  }

  setYouTubeCount = async () => {
    var { firstSet, startedAt, question, chat_id, option_1, option_2, option_3, option_4 } = this.state

    console.log('Getting YouTube count')

    //Get the YouTube data
    var messages = []

    //Demo messages array
    var messages_demo_1 = Array(42).fill('1')
    var messages_demo_2 = Array(58 + (Math.round(Math.random()*100))).fill('2')
    var messages_demo_3 = Array(23).fill('3')
    messages = messages_demo_1.concat(messages_demo_2).concat(messages_demo_3)
    //----->

    //Calculate the votes for the four options
    var votes_1 = 0
    var votes_2 = 0
    var votes_3 = 0
    var votes_4 = 0
    var votes_total = 0

    //Promise through all messages and count votes
    await Promise.all(messages.map(async message => {
      //Check vote was sent after activating poll
      var sentAt = Date.now() + 20 //demo time
      if(sentAt > startedAt){
        var message_string = message //demo

        if(option_1 && option_1 !== '' && message === '1'){
          votes_1 += 1
          votes_total += 1
        }
        if(option_2 && option_2 !== '' && message === '2'){
          votes_2 += 1
          votes_total += 1
        }
        if(option_3 && option_3 !== '' && message === '3'){
          votes_3 += 1
          votes_total += 1
        }
        if(option_4 && option_4 !== '' && message === '4'){
          votes_4 += 1
          votes_total += 1
        }
      }
    }))

    //Calc the percentages
    var barWidth = 360
    // votes_1 = Math.round(votes_1 / votes_total * 100) + '%'
    // votes_2 = Math.round(votes_2 / votes_total * 100) + '%'
    // votes_3 = Math.round(votes_3 / votes_total * 100) + '%'
    // votes_4 = Math.round(votes_4 / votes_total * 100) + '%'

    var votes_1_width = Math.round(votes_1 / votes_total * barWidth)
    var votes_2_width = Math.round(votes_2 / votes_total * barWidth)
    var votes_3_width = Math.round(votes_3 / votes_total * barWidth)
    var votes_4_width = Math.round(votes_4 / votes_total * barWidth)

    votes_1 = Math.round(votes_1 / votes_total * 100) + "%"
    votes_2 = Math.round(votes_2 / votes_total * 100) + "%"
    votes_3 = Math.round(votes_3 / votes_total * 100) + "%"
    votes_4 = Math.round(votes_4 / votes_total * 100) + "%"

    //Setup the poll object
    var poll = {
      title:question,
      options:[
        {title:option_1, command:'1', votes:votes_1},
        {title:option_2, command:'2', votes:votes_2},
        {title:option_3, command:'3', votes:votes_3},
        {title:option_4, command:'4', votes:votes_4},
      ]
    }

    await this.setState({poll})

    //Animate the bars
    var animation_duration = 1000
    Animated.timing(this.state.votes_1, {
      toValue: votes_1_width,
      duration: animation_duration,
    }).start();
    Animated.timing(this.state.votes_2, {
      toValue: votes_2_width,
      duration: animation_duration,
    }).start();
    Animated.timing(this.state.votes_3, {
      toValue: votes_3_width,
      duration: animation_duration,
    }).start();
    Animated.timing(this.state.votes_4, {
      toValue: votes_4_width,
      duration: animation_duration,
    }).start();
  }

  updateField = (state_ref, value) => {
    this.setState({[state_ref]:value})
  }

  //Renders
  renderPoll = () => {
    var { poll, lastVoteString, votes_1_width, votes_2_width, votes_3_width, votes_4_width, vote_perc_1, vote_perc_2, vote_perc_3, vote_perc_4, votes_count } = this.state

    var cardStyle = {width:'100%', padding:poll_padding, borderRadius:0, backgroundColor:'rgba(0,0,5,1)', borderWidth:1, borderColor:'rgba(255,255,255,0.05)', marginVertical:0}

    if(poll){
      return(
        <View style={[cardStyle, MainStyles.flexCenter]}>
          <Text style={{color:'white', fontSize:18, textAlign:'center', fontFamily:'Roboto-Bold'}}>
          {poll.title}
          </Text>
          {this.renderPollOption(poll.options[0], '1')}
          {this.renderPollOption(poll.options[1], '2')}
          {this.renderPollOption(poll.options[2], '3')}
          {this.renderPollOption(poll.options[3], '4')}
          {
            <Text style={{color:'white', fontSize:16, fontFamily:'Roboto-Bold', opacity:1, marginTop:20}}>
              {votes_count + ' votes'}
            </Text>
          }
        </View>
      )
    }else{
      return(
        <View style={[cardStyle, MainStyles.flexCenter]}>
          <Text style={{color:'white', fontSize:20, fontFamily:'Roboto-Bold'}}>
          Setup your poll and click 'Start Poll'
          </Text>
          <Text style={{color:'white', fontSize:14, fontFamily:'Roboto-Bold', opacity:0.33, marginTop:2}}>
          Live votes will be counted for up to {timeLimit} minutes
          </Text>
        </View>
      )
    }
  }

  renderPollOption = (item, ref) => {
    var fontSize = 20
    var borderRadius = 2

    var width = this.state['votes_'+ref]
    var votes = this.state['vote_perc_'+ref]

    if(item.title && item.title !== ''){
      return(
        <View style={[{width:'100%', marginTop:20}, MainStyles.flexCenter]}>
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
            <View style={[{}, MainStyles.flexCenterStart]}>
              <Text style={{color:'white', fontSize, fontFamily:'Roboto-Bold'}}>
              {item.title}
              </Text>
              <Text style={{color:'white', fontSize:fontSize-4, fontFamily:'Roboto-Regular', opacity:0.33, marginTop:2}}>
              Type {item.command} in the chat
              </Text>
            </View>
            <Text style={{color:'white', fontSize, fontFamily:'Roboto-Bold'}}>
            {votes || '0%'}
            </Text>
          </View>

          <View style={[{width:'100%', height:12, borderRadius, backgroundColor:'rgba(255,255,255,0.1)', marginTop:10}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <Animated.View style={{width, height:'100%', borderRadius, backgroundColor:'#fcd946'}}/>
          </View>

        </View>
      )
    }else{return null}
  }

  renderControls = () => {
    return(
      <View style={[{width:'100%'}, MainStyles.flexCenter]}>
        {this.renderInput('YouTube Video Link', this.state.video_url, 'https://www.youtube.com/watch?v=cI0zN1nG3PQ', 'video_url')}
        {this.renderInput('Question', this.state.question, 'Poll question', 'question')}
        {this.renderInput('Option 1', this.state.option_1, 'Option 1', 'option_1')}
        {this.renderInput('Option 2', this.state.option_2, 'Option 2', 'option_2')}
        {this.renderInput('Option 3 (optional)', this.state.option_3, 'Option 3', 'option_3')}
        {this.renderInput('Option 4 (optional)', this.state.option_4, 'Option 4', 'option_4')}
        <View style={[MainStyles.flexColumn, MainStyles.flexCenter, {marginTop:20}]}>
          {this.renderButton('Start Poll', this.setupYouTubeListener)}
          {this.renderButton('Stop Poll', this.stopYouTubeListener)}
          {this.renderButton('Reset Vote Count', this.resetVoteCount)}
          {this.renderButton('Reset Poll', this.resetFirebase)}
        </View>
      </View>
    )
  }

  renderInput = (title, value, placeholder, state_ref) => {
    return(
      <View style={{width:'100%', marginBottom:20}}>
        <Text style={{color:'white', fontSize:14, fontFamily:'Averta-Regular'}}>
        {title}
        </Text>
        <View style={{width:'100%', marginTop:5, borderRadius:5, backgroundColor:'#18181B'}}>
          <TextInput
          style={{color:'white', fontSize:18, fontFamily:'Averta-Regular', padding:20}}
          value={value}
          onChangeText={(value)=>this.updateField(state_ref, value)}
          placeholderTextColor="rgba(255,255,255,0.5)"
          placeholder={placeholder}
          />
        </View>
      </View>
    )
  }

  renderButton = (text, onPress) => {
    return(
      <Pressable onPress={onPress} style={{padding:20, minWidth:200, backgroundColor:'#2C1470', borderRadius:10, marginHorizontal:10, marginBottom:20}}>
        <Text style={{color:'white', fontFamily:'Averta-Bold', fontSize:16, textAlign:'center', opacity:1}}>
        {text}
        </Text>
      </Pressable>
    )
  }

  render() {
    return(
      <ScrollView style={{flex:1, width:'100%', backgroundColor:'#121212'}} contentContainerStyle={[{width:'100%', backgroundColor:'#121212', paddingTop:40}, MainStyles.flexStartCenter]}>
        <View style={[{width:poll_width, backgroundColor:'black'}, MainStyles.flexCenter]}>
          {this.renderPoll()}
        </View>
        <View style={{width:poll_width, marginTop:40}}>
        {this.renderControls()}
        </View>
      </ScrollView>
    )
  }
}

export default Item;
